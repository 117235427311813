import React from 'react';

import { useNotification } from '../../../hooks/NotificationContext';

import NotificationList from '../NotificationList';
import NotificationItem from '../NotificationItem';
import NotificationLoadMoreButton from '../NotificationLoadMoreButton';

const NotificationSchedule: React.FC = () => {
  const {
    detailedNotifications,
    handleUpdateScheduledNotification,
    loadMoreNotifications,
  } = useNotification();
  const { scheduledNotifications, take, skip, total } = detailedNotifications;

  return (
    <NotificationList>
      {scheduledNotifications.map(notification => (
        <NotificationItem
          id={notification.id}
          description={notification.description}
          read={notification.read}
          created_at={notification.created_at}
          parameter={notification.parameter}
          handleMarkingRead={handleUpdateScheduledNotification}
        />
      ))}
      {total > scheduledNotifications.length && (
        <NotificationLoadMoreButton
          loadMore={() => loadMoreNotifications(take + skip)}
        />
      )}
    </NotificationList>
  );
};

export default NotificationSchedule;
