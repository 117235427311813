import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
`;

export const SelectRow = styled.div`
  margin: 8px 0;
`;

export const StepRow = styled.div``;
