import React from 'react';

import { Container, Loading } from './styles';

interface Props {
  loading: boolean;
}

const Loader: React.FC<Props> = ({ loading }) => {
  return loading ? (
    <Container>
      <Loading />
    </Container>
  ) : (
    <></>
  );
};

export default Loader;
