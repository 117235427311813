import React, { useRef, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import api from '../../services/api';

import { useFunnel } from '../../hooks/FunnelContext';
import { useLoader } from '../../hooks/LoaderContext';

import handleFormData from '../../utils/handleFormData';
import handleResponseError from '../../utils/handleResponseError';

import SelectForm from '../SelectForm';
import getValidationErrors from '../../utils/getValidationErrors';
import NegotiationFunnel from '../../models/NegotiationFunnel';

interface Props {
  open: boolean;
  negotiation_id: string;
  funnel_id: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStepId: React.Dispatch<React.SetStateAction<string>>;
  handleChangeNegotiationFunnel: (negotiationFunnel: NegotiationFunnel) => void;
}

const ReplaceNegotiationFunnelDialog: React.FC<Props> = ({
  open,
  negotiation_id,
  funnel_id,
  setOpen,
  setStepId,
  handleChangeNegotiationFunnel,
}) => {
  const { funnels } = useFunnel();
  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const dataHandled = handleFormData({
          ...data,
        });

        const schema = Yup.object().shape({
          funnel_id: Yup.string().nullable().required('Funil é obrigatório!'),
        });

        await schema.validate(dataHandled, {
          abortEarly: false,
        });

        const { data: negotiationFunnel } = await api.patch<NegotiationFunnel>(
          `clients/${negotiation_id}/funnel`,
          {
            ...dataHandled,
          },
        );

        handleChangeNegotiationFunnel(negotiationFunnel);
        setStepId(negotiationFunnel.last_step_id);
        setOpen(false);

        enqueueSnackbar('Funil alterado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      enqueueSnackbar,
      setOpen,
      setStepId,
      handleChangeNegotiationFunnel,
      negotiation_id,
    ],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
        <DialogTitle id="confirmation-dialog-title">
          Alterar Funil da Negociação
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <SelectForm
              name="funnel_id"
              label="Funil"
              values={funnels
                .filter(({ id }) => id !== funnel_id)
                .map(({ id, name }) => ({
                  id,
                  name,
                }))}
              hiddenDefaultOption
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button type="submit" autoFocus color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ReplaceNegotiationFunnelDialog;
