import styled from 'styled-components';

const CaptionRow = styled.div`
  max-width: 232px;
`;

const Image = styled.img`
  max-width: 232px;
  border-radius: 8px;
`;

export { CaptionRow, Image };
