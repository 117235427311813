import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { List } from './styles';

const NotificationList: React.FC = ({ children }) => (
  <Scrollbars
    style={{
      height: 360,
    }}
  >
    <List>{children}</List>
  </Scrollbars>
);

export default NotificationList;
