import styled, { css } from 'styled-components';
import { Card } from '@material-ui/core';

interface ContainerProps {
  ref?: React.RefObject<HTMLDivElement>;
  isDragging?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  cursor: pointer;

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(111, 167, 186, 0.6);
      background: transparent;
      box-shadow: none;
      cursor: grabbing;
      border-radius: 8px;

      * {
        opacity 0;
      }
    `};
`;

export const CustomCard = styled(Card)`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
`;
