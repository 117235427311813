import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  FilterList,
  List,
  WhatsApp,
  BarChart,
  Event,
  ExitToApp,
  Settings,
} from '@material-ui/icons';
import {
  Avatar,
  MenuItem,
  IconButton,
  Tooltip,
  Badge,
} from '@material-ui/core';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import logo from '../../assets/logo3.png';

import getAbbreviationByName from '../../utils/getAbbreviationByName';

import { useSession } from '../../hooks/SessionContext';
import { useWindowDimensions } from '../../hooks/WindowDimensionsContext';
import { ContactContext } from '../../hooks/ContactContext';

import Notification from '../Notification';

import {
  Container,
  Content,
  Logo,
  LeftSide,
  RightSide,
  RightBorder,
  CustomMenu,
} from './styles';

const Header: React.FC = () => {
  const { user, signOut } = useSession();
  const { windowDimensions } = useWindowDimensions();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { contactsDetails, activateAudio, enableDisableSound } = useContext(
    ContactContext,
  );

  return (
    <Container>
      <Content>
        <LeftSide mobile={windowDimensions.resolution === 'mobile'}>
          <nav>
            <span className="nav-links-wrapper">
              <NavLink className="nav-link" to="/funnels">
                <Tooltip title="Funis">
                  <FilterList aria-label="Funis" fontSize="default" />
                </Tooltip>
              </NavLink>
              <NavLink className="nav-link" to="/negotiations-list">
                <Tooltip title="Transferências em Massa">
                  <List
                    aria-label="Transferências em Massa"
                    fontSize="default"
                  />
                </Tooltip>
              </NavLink>
              <NavLink className="nav-link" to="/chats">
                {window.location.pathname !== '/chats' && (
                  <Tooltip title="WhatsApp">
                    <Badge
                      badgeContent={
                        contactsDetails?.unreaded_conversations?.quantity
                      }
                      color="error"
                    >
                      <WhatsApp aria-label="Clientes" fontSize="default" />
                    </Badge>
                  </Tooltip>
                )}
                {window.location.pathname === '/chats' && (
                  <Tooltip title="WhatsApp">
                    <WhatsApp aria-label="Clientes" fontSize="default" />
                  </Tooltip>
                )}
              </NavLink>
              <NavLink className="nav-link" to="/schedules">
                <Tooltip title="Agendamentos">
                  <Event aria-label="Agendamentos" fontSize="default" />
                </Tooltip>
              </NavLink>
              <NavLink className="nav-link" to="/charts">
                <Tooltip title="Gráficos">
                  <BarChart aria-label="Gráficos" fontSize="default" />
                </Tooltip>
              </NavLink>
            </span>
          </nav>
        </LeftSide>
        {windowDimensions.resolution !== 'mobile' && (
          <div>
            <NavLink to="/">
              <Logo src={logo} alt="Moove" />
            </NavLink>
          </div>
        )}
        <div>
          <RightSide>
            <RightBorder />
            {windowDimensions.resolution !== 'mobile' && (
              <div className="notifications">
                <Notification />
                {activateAudio && (
                  <IconButton>
                    <Tooltip title="Desativar Som">
                      <VolumeUpIcon
                        aria-label="Som"
                        fontSize="default"
                        onClick={enableDisableSound}
                      />
                    </Tooltip>
                  </IconButton>
                )}
                {!activateAudio && (
                  <IconButton>
                    <Tooltip title="Ativar Som">
                      <VolumeOffIcon
                        aria-label="Som"
                        fontSize="default"
                        onClick={enableDisableSound}
                      />
                    </Tooltip>
                  </IconButton>
                )}
              </div>
            )}
            <IconButton
              className="avatar-btn"
              aria-controls="settings-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Avatar>{getAbbreviationByName(user.name)}</Avatar>
            </IconButton>
            <CustomMenu
              id="settings-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
            >
              <MenuItem onClick={handleClose}>
                <NavLink to="/settings/profiles">
                  <Settings aria-label="Configurações" fontSize="small" />
                  Configurações
                </NavLink>
              </MenuItem>
              <MenuItem onClick={() => signOut()}>
                <ExitToApp aria-label="Sair" fontSize="small" />
                Sair
              </MenuItem>
            </CustomMenu>
          </RightSide>
        </div>
      </Content>
    </Container>
  );
};

export default Header;
