import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

type Resolution =
  | 'mobile'
  | '480p'
  | '720p'
  | '900p'
  | '1080p'
  | '1440p'
  | '2160p';

type Props = {
  width: number;
  height: number;
  resolution: Resolution;
};

interface State {
  windowDimensions: Props;
}

export const WindowDimensionsContext = createContext<State>({} as State);

export const WindowDimensionsProvider: React.FC = ({ children }) => {
  const getWindowDimensions = useCallback((): Props => {
    const { innerWidth: width, innerHeight: height } = window;

    let resolution: Resolution;

    if (width < 854) {
      resolution = 'mobile';
    } else if (width < 1280) {
      resolution = '480p';
    } else if (width < 1600) {
      resolution = '720p';
    } else if (width < 1920) {
      resolution = '900p';
    } else if (width < 2560) {
      resolution = '1080p';
    } else if (width < 3840) {
      resolution = '1440p';
    } else {
      resolution = '2160p';
    }

    return {
      width,
      height,
      resolution,
    };
  }, []);

  const [windowDimensions, setWindowDimensions] = useState<Props>(
    getWindowDimensions(),
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [getWindowDimensions]);

  return (
    <WindowDimensionsContext.Provider
      value={{
        windowDimensions,
      }}
    >
      {children}
    </WindowDimensionsContext.Provider>
  );
};

export const useWindowDimensions = (): State => {
  const context = useContext(WindowDimensionsContext);

  if (!context)
    throw new Error(
      'useWindowDimensions must be used within a WindowDimensionsProvider',
    );

  return context;
};
