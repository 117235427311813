import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { parseISO, format, isAfter } from 'date-fns';
import {
  TrendingUp,
  TrendingDown,
  Edit,
  Done,
  Close,
  LockOpen,
  Delete,
  PersonAdd,
  WhatsApp,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';
import { ContactDTO } from '../../../services/ContactService';

import { SessionContext } from '../../../hooks/SessionContext';
import { ContactContext } from '../../../hooks/ContactContext';
import { useFunnel } from '../../../hooks/FunnelContext';
import { useLoader } from '../../../hooks/LoaderContext';

import { getBase64 } from '../../../utils/showFile';
import handleResponseError from '../../../utils/handleResponseError';
import { formatPrice } from '../../../utils/formatPrice';

import Negotiation from '../../../models/Negotiation';
import NegotiationFunnel from '../../../models/NegotiationFunnel';
import NegotiationStatus from '../../../models/NegotiationStatus';
import Funnel from '../../../models/Funnel';
import Step from '../../../models/Step';
import PermissionType from '../../../models/PermissionType';
import StorageType from '../../../models/StorageType';

import EditContactName from '../../../components/EditContactDialog';
import Select from '../../../components/Select';

import ChooseContact from '../ChooseContact';
import ChooseLossReason from './ChooseLossReason';
import ChooseNextFunnel from './ChooseNextFunnel';

import {
  CustomCard,
  CustomAvatar,
  LeftTitle,
  CustomDivider,
  ContactRow,
  StepsRow,
  StatusRow,
  ButtonGreen,
  ButtonRed,
  ButtonBlue,
  ButtonIcon,
  ButtonRedOutlined,
  ButtonIconOutlined,
  FunnelRow,
} from './styles';

interface Props {
  negotiation?: Negotiation;
  negotiation_funnel?: NegotiationFunnel;
  steps: Step[];
  contact?: ContactDTO;
  status: NegotiationStatus;
  photo?: string;
  handleSelectContact: (contact: ContactDTO) => void;
  handleSelectStep: (step: Step) => void;
  handleSelectCurrentNegotiationFunnel?: (
    negotiation_funnel: NegotiationFunnel,
  ) => void;
  handleEnableFields: () => void;
  setViewingMode: React.Dispatch<React.SetStateAction<boolean>>;
  viewingMode: boolean;
  handleCancel: () => void;
}

const Header: React.FC<Props> = ({
  negotiation,
  negotiation_funnel,
  steps,
  contact,
  status,
  photo,
  handleSelectContact,
  handleSelectStep,
  handleSelectCurrentNegotiationFunnel,
  setViewingMode,
  viewingMode,
  handleCancel,
}) => {
  const [openChooseContact, setOpenChooseContact] = useState<boolean>(false);
  const [formattedPrice, setFormattedPrice] = useState<string>(formatPrice(0));
  const [contactFocused, setContactFocused] = useState<boolean>(false);
  const [chooseLossReason, setChooseLossReason] = useState<boolean>(false);
  const [openAlertContact, setOpenAlertContact] = useState<boolean>(false);
  const [photoBase64, setPhotoBase64] = useState<string | undefined>(photo);
  const [nextFunnels, setNextFunnels] = useState<Funnel[]>([]);
  const responsibility =
    negotiation?.user_negotiation_funnel_view?.responsibility || 'direct';

  const { user } = useContext(SessionContext);
  const { setPagination, handleContactNegotiation } = useContext(
    ContactContext,
  );

  const { enqueueSnackbar } = useSnackbar();
  const { funnels } = useFunnel();
  const { setLoading } = useLoader();

  const history = useHistory();

  useEffect(() => {
    const price = negotiation?.value ?? '0';

    setFormattedPrice(formatPrice(Number(price)));
  }, [negotiation]);

  const negotiationFunnels = useMemo(() => {
    return funnels.filter(funnel =>
      negotiation?.negotiations_funnels
        ?.map(negotiation_funnel => negotiation_funnel.funnel_id)
        ?.includes(funnel.id),
    );
  }, [negotiation, funnels]);

  const handleChangeStep = useCallback(
    async (step: Step) => {
      handleSelectStep(step);
    },
    [handleSelectStep],
  );

  const handleReopen = useCallback(async () => {
    try {
      if (negotiation) {
        setLoading(true);

        await api.post(`negotiations/${negotiation.id}/reopen`);

        if (contact) handleContactNegotiation(contact.phone, negotiation);

        enqueueSnackbar('Negociação reaberta com sucesso!', {
          variant: 'success',
        });

        history.goBack();
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    setLoading,
    handleContactNegotiation,
    history,
    contact,
    negotiation,
  ]);

  const handleDelete = useCallback(async () => {
    try {
      if (negotiation) {
        setLoading(true);

        await api.delete(`clients/${negotiation.id}`);

        if (contact) handleContactNegotiation(contact.phone);

        enqueueSnackbar('Negociação excluída com sucesso!', {
          variant: 'success',
        });

        history.goBack();
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    setLoading,
    handleContactNegotiation,
    history,
    contact,
    negotiation,
  ]);

  const handleOpenChooseContact = useCallback(() => {
    setOpenChooseContact(
      !viewingMode || status === NegotiationStatus.InProgress,
    );
  }, [viewingMode, status]);

  const handleCloseChooseContact = useCallback(
    async (selectedContact?: ContactDTO) => {
      setOpenChooseContact(false);

      if (selectedContact && selectedContact.id !== contact?.id) {
        handleSelectContact(selectedContact);

        const base64 = await getBase64(
          selectedContact.photo,
          StorageType.Contact,
        );

        setPhotoBase64(base64);
      }
    },
    [contact, handleSelectContact],
  );

  const handleFinalizeNegotiation = useCallback(
    async ({
      won,
      loss_reason_id,
      funnel_id,
    }: {
      won?: boolean;
      loss_reason_id?: string;
      funnel_id?: string;
    }) => {
      try {
        if (negotiation) {
          setLoading(true);

          await api.post(`clients/${negotiation.id}/negotiations`, {
            won,
            loss_reason_id,
            funnel_id,
          });

          if (contact) handleContactNegotiation(contact.phone);

          enqueueSnackbar('Negociação concluída com sucesso!', {
            variant: 'success',
          });

          history.goBack();
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleContactNegotiation,
      history,
      contact,
      negotiation,
    ],
  );

  const handleLoadNextFunnels = useCallback(async () => {
    try {
      if (negotiation) {
        setLoading(true);

        const { data } = await api.get<Funnel[]>(
          `processes/funnels/${negotiation.negotiation_funnel.funnel_id}`,
        );

        if (data.length > 1) {
          setNextFunnels(data);
        } else {
          handleFinalizeNegotiation({
            won: true,
          });
        }
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, handleFinalizeNegotiation, negotiation]);

  const handleLossNegotiation = useCallback(
    (loss_reason_id: string) => {
      handleFinalizeNegotiation({
        won: false,
        loss_reason_id,
      });
    },
    [handleFinalizeNegotiation],
  );

  const handleWonNegotiation = useCallback(
    (funnel_id: string) => {
      handleFinalizeNegotiation({
        won: true,
        funnel_id,
      });
    },
    [handleFinalizeNegotiation],
  );

  const handleOpenChat = useCallback(() => {
    if (contact) {
      setPagination({
        phone: contact?.phone,
        take: 1,
        skip: 0,
      });
      history.push(`/chats`, {
        searchedNamePhone: contact?.phone,
        defaultNegotiationId: negotiation?.id,
      });
    } else {
      setOpenAlertContact(true);
    }
  }, [history, setPagination, contact, negotiation]);

  const startDate = useMemo(() => {
    return format(
      negotiation_funnel?.created_at
        ? parseISO(negotiation_funnel?.created_at)
        : new Date(),
      'dd/MM/yyyy',
    );
  }, [negotiation_funnel]);

  const statusName = useMemo(() => {
    switch (status) {
      case NegotiationStatus.Won:
        return 'Ganho';
      case NegotiationStatus.Lost:
        return 'Perdido';
      default:
        return 'Em aberto';
    }
  }, [status]);

  const handleCurrentNegotiationFunnel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (negotiation) {
        const id = event.target.value as string;

        handleSelectCurrentNegotiationFunnel?.(
          negotiation.negotiations_funnels.filter(
            negotiation_funnel => negotiation_funnel.funnel_id === id,
          )[0],
        );
      }
    },
    [handleSelectCurrentNegotiationFunnel, negotiation],
  );

  const isIndirectResponsible = responsibility === 'indirect';

  const isSuperior = useMemo(
    () => user.permissions.includes(PermissionType.Superior),
    [user.permissions],
  );

  const canSeeActions = useMemo(
    () =>
      viewingMode &&
      negotiation_funnel &&
      status === NegotiationStatus.InProgress &&
      (isSuperior || !isIndirectResponsible),
    [
      viewingMode,
      negotiation_funnel,
      status,
      isSuperior,
      isIndirectResponsible,
    ],
  );

  const canEditContact = useMemo(
    () =>
      (!viewingMode || status === NegotiationStatus.InProgress) &&
      (isSuperior || !isIndirectResponsible),
    [viewingMode, status, isSuperior, isIndirectResponsible],
  );

  const canReopenNegotiation = useMemo(
    () =>
      viewingMode &&
      status !== NegotiationStatus.InProgress &&
      (isSuperior || !isIndirectResponsible) &&
      negotiation &&
      negotiation_funnel &&
      (negotiation.negotiations_funnels ?? [])
        .map(negotiation_funnel => negotiation_funnel.created_at)
        .filter(created_at =>
          isAfter(
            parseISO(created_at),
            parseISO(negotiation_funnel.created_at),
          ),
        ).length === 0,
    [
      viewingMode,
      status,
      negotiation,
      negotiation_funnel,
      isSuperior,
      isIndirectResponsible,
    ],
  );

  return (
    <>
      {openChooseContact && (
        <ChooseContact
          handleClose={handleCloseChooseContact}
          current_contact={contact}
        />
      )}
      {chooseLossReason && negotiation_funnel && (
        <ChooseLossReason
          chooseLossReason={chooseLossReason}
          funnelId={negotiation_funnel.funnel_id}
          setChooseLossReason={setChooseLossReason}
          handleLossNegotiation={handleLossNegotiation}
        />
      )}
      {nextFunnels.length > 0 && (
        <ChooseNextFunnel
          funnels={nextFunnels}
          handleWonNegotiation={handleWonNegotiation}
          handleCancel={() => setNextFunnels([])}
        />
      )}
      <CustomCard>
        {viewingMode && (
          <FunnelRow>
            {negotiation &&
              negotiation_funnel &&
              negotiationFunnels.length > 1 && (
                <Select
                  name="funnel_id"
                  label="Funil"
                  height={29}
                  defaultValue={negotiation_funnel.funnel_id}
                  value={negotiation_funnel.funnel_id}
                  values={negotiationFunnels.map(funnel => {
                    return {
                      id: funnel.id,
                      name: funnel.name,
                    };
                  })}
                  onChange={handleCurrentNegotiationFunnel}
                />
              )}
            <ButtonIconOutlined
              style={{ color: 'grey', border: '1px solid grey' }}
              startIcon={<Close />}
              onClick={() => history.push('/funnels')}
            />
          </FunnelRow>
        )}
        <ContactRow>
          <CustomAvatar
            src={contactFocused ? undefined : photoBase64}
            alt={contact?.name}
            isNegotiationInProgress={canEditContact}
            onMouseEnter={() => {
              setContactFocused(canEditContact);
            }}
            onMouseLeave={() => setContactFocused(false)}
            onClick={() => {
              if (canEditContact) handleOpenChooseContact();
            }}
          >
            <PersonAdd />
          </CustomAvatar>
          <LeftTitle>
            <EditContactName
              phone={contact?.phone}
              name={contact?.name}
              handleOpenChooseContact={handleOpenChooseContact}
            />
            <Typography
              variant="subtitle1"
              component="p"
              style={{
                marginLeft: 8,
              }}
            >
              {formattedPrice}
            </Typography>
          </LeftTitle>
          <CustomDivider />
          {canSeeActions && (
            <>
              <ButtonBlue
                startIcon={<WhatsApp aria-label="Chat" fontSize="default" />}
                onClick={() => handleOpenChat()}
              >
                Iniciar Conversa
              </ButtonBlue>
              <ButtonGreen
                startIcon={<TrendingUp />}
                onClick={handleLoadNextFunnels}
              >
                Ganhar
              </ButtonGreen>
              <ButtonRed
                startIcon={<TrendingDown />}
                onClick={() => setChooseLossReason(true)}
              >
                Perder
              </ButtonRed>
              <ButtonIcon
                startIcon={<Edit />}
                onClick={() => setViewingMode(false)}
              />
              {isSuperior && (
                <ButtonIconOutlined
                  startIcon={<Delete />}
                  onClick={handleDelete}
                />
              )}
            </>
          )}
          {!viewingMode &&
            (!negotiation || status === NegotiationStatus.InProgress) && (
              <ButtonGreen startIcon={<Done />} type="submit">
                Salvar
              </ButtonGreen>
            )}
          {!viewingMode &&
            (!negotiation || status === NegotiationStatus.InProgress) && (
              <ButtonRedOutlined
                startIcon={<Close />}
                onClick={() => handleCancel()}
              >
                Cancelar
              </ButtonRedOutlined>
            )}
          {canReopenNegotiation && (
            <ButtonGreen startIcon={<LockOpen />} onClick={handleReopen}>
              Reabrir
            </ButtonGreen>
          )}
        </ContactRow>
        {!!viewingMode && (
          <StepsRow>
            <ButtonGroup
              fullWidth
              size="small"
              color="primary"
              disabled={!canSeeActions}
            >
              {steps.map(step => (
                <Button
                  key={step.id}
                  variant={
                    negotiation_funnel?.last_step_id === step.id
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => handleChangeStep(step)}
                >
                  {step.name}
                </Button>
              ))}
            </ButtonGroup>
          </StepsRow>
        )}
        <StatusRow>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginRight: '32px' }}
          >
            {`Data de início: ${startDate}`}
          </Typography>
          <Typography variant="subtitle1" component="span">
            {`Situação: ${statusName}`}
          </Typography>
        </StatusRow>
      </CustomCard>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openAlertContact}
      >
        <DialogTitle id="confirmation-dialog-title">Atenção</DialogTitle>
        <DialogContent dividers>
          Cliente não possui contato de Whatsapp vinculado!
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setOpenAlertContact(false)}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
