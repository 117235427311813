import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  subYears,
  format,
  parseISO,
  endOfDay,
  startOfDay,
  isAfter,
} from 'date-fns';

interface Props extends DialogProps {
  handleCustomDate: (start: Date, end: Date) => void;
}

const CustomDateDialog: React.FC<Props> = ({ handleCustomDate, ...rest }) => {
  const [dateStart, setDateStart] = useState<Date>(subYears(new Date(), 1));
  const [dateEnd, setDateEnd] = useState<Date>(new Date());

  const isInvalidRange = useMemo(() => isAfter(dateStart, dateEnd), [
    dateStart,
    dateEnd,
  ]);

  return (
    <Dialog
      title="Data Personalizada"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      {...rest}
    >
      <DialogTitle id="customized-dialog-title">Data Personalizada</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {isInvalidRange && (
            <Alert severity="error">
              A data de início não pode ser maior que a data fim!
            </Alert>
          )}
          <Grid item xs={6}>
            <TextField
              id="dateStart"
              variant="outlined"
              type="date"
              size="small"
              label="Data Início"
              value={format(dateStart, 'yyyy-MM-dd')}
              onChange={({ target }) => {
                setDateStart(oldDateStart => {
                  try {
                    format(parseISO(target.value), 'yyyy-MM-dd');

                    return parseISO(target.value);
                  } catch {
                    return oldDateStart;
                  }
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="dateEnd"
              type="date"
              variant="outlined"
              size="small"
              label="Data Fim"
              value={format(dateEnd, 'yyyy-MM-dd')}
              onChange={({ target }) => {
                setDateEnd(oldDateEnd => {
                  try {
                    format(parseISO(target.value), 'yyyy-MM-dd');

                    return parseISO(target.value);
                  } catch {
                    return oldDateEnd;
                  }
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          disabled={isInvalidRange}
          onClick={() => {
            handleCustomDate(startOfDay(dateStart), endOfDay(dateEnd));
          }}
          color="primary"
        >
          Consultar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDateDialog;
