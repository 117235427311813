import styled from 'styled-components';

interface Props {
  me: boolean;
  isImageOrPdf: boolean;
}

const Content = styled.section<Props>`
  width: fit-content;
  padding: ${props => (props.isImageOrPdf ? '2px' : '8px')};
  border-radius: 8px;
  background: ${props => (props.me ? '#40adbd' : '#fff')};
  color: ${props => (props.me ? '#fff' : '#000')};

  margin-left: ${props => (props.me ? 'auto' : 0)};
  border: 1px solid #ddd;

  &:hover {
    cursor: ${props => (props.isImageOrPdf ? 'pointer' : 'text')};
  }

  span {
    margin-right: 8px;
  }

  @keyframes blink {
    from {
      background-color: #ddd;
    }
    to {
      background-color: grey;
    }
  }
  @keyframes blinkLight {
    from {
      background-color: '';
    }
    to {
      background-color: #ddd;
    }
  }
`;

export { Content };
