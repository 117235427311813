import Negotiation from '../models/Negotiation';
import NegotiationFunnel from '../models/NegotiationFunnel';
import NegotiationStatus from '../models/NegotiationStatus';

const getNegotiationStatus = (negotiation?: Negotiation) => {
  let status = NegotiationStatus.InProgress;

  if (negotiation) {
    const { negotiation_funnel } = negotiation;

    if (negotiation_funnel.user_id_exclusion)
      status = negotiation_funnel.won
        ? NegotiationStatus.Won
        : NegotiationStatus.Lost;
  }

  return status;
};

export const getStatusByNegotiationFunnel = (
  negotiationFunnel: NegotiationFunnel,
) => {
  let status = NegotiationStatus.InProgress;

  if (negotiationFunnel.user_id_exclusion)
    status = negotiationFunnel.won
      ? NegotiationStatus.Won
      : NegotiationStatus.Lost;

  return status;
};

export default getNegotiationStatus;
