import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Button, Box, FormLabel, Typography } from '@material-ui/core';
import { Close, Add } from '@material-ui/icons';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api';

import { useFunnel } from '../../../../hooks/FunnelContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import Funnel from '../../../../models/Funnel';

import Input from '../../../../components/Input';
import InputGeneric from '../../../../components/InputGeneric';

import { InputWrapper } from './styles';

interface Props {
  handleOnSave?: () => void;
  funnel?: Funnel;
}

type StepRequest = {
  id?: string;
  name: string;
  order: number;
};

interface FormData {
  name: string;
}

const AddEditFunnel: React.FC<Props> = ({ handleOnSave, funnel }) => {
  const [requestedSteps, setRequestedSteps] = useState<StepRequest[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { loadFunnels } = useFunnel();
  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    setRequestedSteps(
      funnel?.steps?.map(({ id, name, order }) => ({ id, name, order })) ?? [
        {
          name: '',
          order: 1,
        },
      ],
    );
  }, [funnel]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name } = data;

        if (funnel) {
          await api.put<Funnel>(`/funnels/${funnel.id}`, {
            name,
          });

          await api.post(`/funnels/${funnel.id}/steps`, {
            steps: requestedSteps,
          });

          handleOnSave?.();
        } else {
          const createdFunnel = await api.post<Funnel>('/funnels', {
            name,
          });

          await api.post(`/funnels/${createdFunnel.data.id}/steps`, {
            steps: requestedSteps,
          });

          handleOnSave?.();
        }
        enqueueSnackbar(
          `Funil e etapas ${funnel ? 'atualizados' : 'salvos'} com sucesso!`,
          {
            variant: 'success',
          },
        );
        await loadFunnels();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleOnSave,
      loadFunnels,
      funnel,
      requestedSteps,
    ],
  );

  const handleDeleteFunnel = useCallback(async () => {
    try {
      setLoading(true);

      if (funnel) {
        await api.delete(`/funnels/${funnel.id}`);

        enqueueSnackbar('Funil e suas etapas deletados com sucesso!', {
          variant: 'success',
        });

        handleOnSave?.();

        await loadFunnels();
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, handleOnSave, loadFunnels, funnel]);

  const handleStepChange = useCallback((order: number, name: string) => {
    setRequestedSteps(oldRequestedSteps =>
      oldRequestedSteps.map(oldRequestedStep => {
        if (oldRequestedStep.order === order)
          return {
            ...oldRequestedStep,
            name,
          };

        return oldRequestedStep;
      }),
    );
  }, []);

  const handleStepAdd = useCallback((index: number) => {
    setRequestedSteps(oldRequestedSteps => {
      let order = 0;

      oldRequestedSteps.splice(++index, 0, {
        name: '',
        order: 0,
      });

      return oldRequestedSteps.map(oldRequestedStep => ({
        ...oldRequestedStep,
        order: ++order,
      }));
    });
  }, []);

  const handleStepRemove = useCallback((step: StepRequest, index: number) => {
    setRequestedSteps(oldRequestedSteps => {
      let order = 0;

      oldRequestedSteps.splice(index, 1);

      return [
        ...oldRequestedSteps.map(oldRequestedStep => ({
          ...oldRequestedStep,
          order: ++order,
        })),
      ];
    });
  }, []);

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {funnel ? (
          <FormLabel>
            <Typography variant="h6">Editar Funil</Typography>
          </FormLabel>
        ) : (
          <FormLabel>
            <Typography variant="h6">Novo Funil</Typography>
          </FormLabel>
        )}
        <Input
          id="name"
          name="name"
          label="Nome do funil"
          style={{ maxWidth: '400px' }}
          defaultValue={funnel?.name}
        />
        <div>
          <Box mb="1rem">
            <FormLabel>
              <Typography variant="h6">Etapas</Typography>
            </FormLabel>
            {requestedSteps.map((step, idx) => (
              <InputWrapper key={`step-wrapper-${step.order}`}>
                <InputGeneric
                  key={`step-${step.order}`}
                  label="Nome da etapa"
                  style={{ maxWidth: '400px' }}
                  value={step.name}
                  inputProps={{
                    maxLength: 23,
                  }}
                  onChange={e => handleStepChange(step.order, e.target.value)}
                />
                <Button
                  key={`button-add-${step.order}`}
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ borderRadius: '32px' }}
                  type="button"
                  onClick={() => handleStepAdd(idx)}
                >
                  <Add fontSize="small" />
                </Button>
                <Button
                  key={`button-remove-${step.order}`}
                  color="secondary"
                  size="small"
                  variant="outlined"
                  style={{ borderRadius: '32px' }}
                  type="button"
                  onClick={() => handleStepRemove(step, idx)}
                >
                  <Close fontSize="small" />
                </Button>
              </InputWrapper>
            ))}
          </Box>
        </div>
        <Button
          color="primary"
          size="small"
          variant="contained"
          style={{ borderRadius: '32px', marginRight: '6px' }}
          type="submit"
        >
          {funnel ? 'Atualizar' : 'Salvar'}
        </Button>
        {funnel ? (
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            style={{ borderRadius: '32px' }}
            type="button"
            onClick={() => handleDeleteFunnel()}
          >
            Deletar
          </Button>
        ) : (
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            style={{ borderRadius: '32px' }}
            type="button"
            onClick={() => handleOnSave?.()}
          >
            Cancelar
          </Button>
        )}
      </Form>
    </>
  );
};

export default AddEditFunnel;
