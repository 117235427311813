import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { useDrag } from 'react-dnd';

import { getBase64 } from '../../../utils/showFile';
import { formatPrice } from '../../../utils/formatPrice';
import getAbbreviationByName from '../../../utils/getAbbreviationByName';
import getNegotiationStatus from '../../../utils/getNegotiationStatus';

import { useSession } from '../../../hooks/SessionContext';

import Negotiation from '../../../models/Negotiation';
import NegotiationStatus from '../../../models/NegotiationStatus';
import StorageType from '../../../models/StorageType';
import PermissionType from '../../../models/PermissionType';

import { Container } from './styles';

interface ItemProps {
  negotiation: Negotiation;
  idx: number;
  listIdx: number;
}

const Item: React.FC<ItemProps> = ({ negotiation, idx, listIdx }) => {
  const [photo, setPhoto] = useState<string>();

  const { responsibility } = negotiation.user_negotiation_funnel_view;
  const status = useMemo(() => getNegotiationStatus(negotiation), [
    negotiation,
  ]);

  const { user } = useSession();
  const history = useHistory();

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const isIndirectResponsible = responsibility === 'indirect';

  const isSuperior = useMemo(
    () => user.permissions.includes(PermissionType.Superior),
    [user.permissions],
  );

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CARD', idx, listIdx, negotiation },
    collect: monitor => ({
      isDragging:
        monitor.isDragging() &&
        status === NegotiationStatus.InProgress &&
        (isSuperior || !isIndirectResponsible),
    }),
  });

  dragRef(ref);

  useEffect(() => {
    async function loadPhoto() {
      const base64 = await getBase64(
        negotiation.contact?.photo,
        StorageType.Contact,
      );

      setPhoto(base64);
    }

    loadPhoto();
  }, [negotiation.contact]);

  const valueFormatted = useMemo(() => {
    return formatPrice(Number(negotiation.value) ?? 0);
  }, [negotiation.value]);

  const isInProgress = useMemo(() => {
    return status === NegotiationStatus.InProgress;
  }, [status]);

  const navigateToEditNegotiation = useCallback(() => {
    history.push(`clients/${negotiation.id}/edit`, {
      negotiation,
      photo,
    });
  }, [history, negotiation, photo]);

  return (
    <Container
      ref={
        isInProgress && (isSuperior || !isIndirectResponsible) ? ref : undefined
      }
      isDragging={isDragging}
    >
      <ListItem
        style={{
          backgroundColor: '#fff',
          padding: '0 8px',
        }}
        onClick={navigateToEditNegotiation}
      >
        <ListItemAvatar>
          <Avatar src={photo} />
        </ListItemAvatar>
        <ListItemText
          primary={{
            ...(
              <Tooltip title={negotiation?.name ?? negotiation?.contact?.name}>
                <Typography variant="body2" noWrap>
                  {negotiation?.name ?? negotiation?.contact?.name}
                </Typography>
              </Tooltip>
            ),
          }}
          secondary={{
            ...(
              <Tooltip title={valueFormatted}>
                <Typography variant="caption" noWrap>
                  {valueFormatted}
                </Typography>
              </Tooltip>
            ),
          }}
        />
        {negotiation.negotiation_funnel.user && (
          <ListItemAvatar
            style={{
              paddingLeft: 24,
            }}
          >
            <Avatar
              style={{
                width: 24,
                height: 24,
              }}
            >
              <Tooltip title={negotiation.negotiation_funnel.user.name}>
                <Typography variant="caption">
                  {getAbbreviationByName(
                    negotiation.negotiation_funnel.user.name,
                  )}
                </Typography>
              </Tooltip>
            </Avatar>
          </ListItemAvatar>
        )}
      </ListItem>
      <Divider
        component="li"
        style={{
          backgroundColor: '#ddd',
        }}
      />
    </Container>
  );
};

export default Item;
