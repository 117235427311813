import React from 'react';
import { Tooltip } from '@material-ui/core';

import { formatPrice } from '../../../utils/formatPrice';

import { Container } from './styles';

type Props = {
  tooltip: string;
  color: string;
  quantity: number;
  total_value: number;
  average_value: number;
};

const Card: React.FC<Props> = ({
  tooltip,
  color,
  quantity,
  total_value,
  average_value,
}) => (
  <Tooltip title={tooltip}>
    <Container color={color}>
      <div>
        <h2>{quantity}</h2>
      </div>
      <div>
        <h2>{formatPrice(total_value)}</h2>
      </div>
      <div>
        <h2>{formatPrice(average_value)}</h2>
      </div>
    </Container>
  </Tooltip>
);

export default Card;
