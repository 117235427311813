import React, { useMemo } from 'react';
import {
  DoneOutlined,
  DoneAllOutlined,
  ErrorOutline,
} from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { Container } from './styles';

interface Props {
  time?: Date;
  status?: number;
  from_me: boolean;
}

const MessageInfo: React.FC<Props> = ({ time, status, from_me }) => {
  const failedMessage = useMemo(
    () =>
      `Falha no envio da mensagem.
      Verifique se passaram mais de 24 horas desde que o cliente respondeu pela última vez.
      Caso sim, envie uma mensagem de texto e aguarde resposta.`,
    [],
  );

  return (
    <Container>
      <caption>
        {time ? format(zonedTimeToUtc(time, 'America/Sao_Paulo'), 'HH:mm') : ''}
      </caption>
      {from_me && (!status || status === 1) && <DoneOutlined />}
      {from_me && status === 2 && <DoneAllOutlined />}
      {from_me && status === 3 && (
        <DoneAllOutlined
          style={{
            color: '#65FF00',
          }}
        />
      )}
      {from_me && status === 4 && (
        <>
          <Tooltip title={failedMessage}>
            <caption>Falha no envio</caption>
          </Tooltip>
          <Tooltip title={failedMessage}>
            <ErrorOutline />
          </Tooltip>
        </>
      )}
    </Container>
  );
};

export default MessageInfo;
