import React, { useState, useCallback } from 'react';
import {
  Grid,
  Button,
  FormLabel,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api';

import { useFunnel } from '../../../../hooks/FunnelContext';
import { UserDTO, useResponsible } from '../../../../hooks/ResponsibleContext';
import { useSession } from '../../../../hooks/SessionContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import handleResponseError from '../../../../utils/handleResponseError';

interface Props {
  user: UserDTO;
}

const UserFunnel: React.FC<Props> = ({ user }) => {
  const [checkedFunnelsIds, setCheckedFunnelsIds] = useState<string[]>(
    user.funnels,
  );

  const { user: userSession } = useSession();
  const { funnels } = useFunnel();
  const { loadResponsibles } = useResponsible();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      await api.post(`users/${user.id}/funnels`, {
        ids: checkedFunnelsIds,
      });

      await loadResponsibles();

      enqueueSnackbar('Funis atualizados com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, loadResponsibles, user, checkedFunnelsIds]);

  const handleChangeCheckedFunnels = useCallback((id: string) => {
    setCheckedFunnelsIds(oldCheckedFunnelId =>
      oldCheckedFunnelId.includes(id)
        ? [...oldCheckedFunnelId.filter(oldId => oldId !== id)]
        : [...oldCheckedFunnelId, id],
    );
  }, []);

  const handleCheckAll = useCallback(() => {
    setCheckedFunnelsIds(oldCheckedFunnelsIds =>
      oldCheckedFunnelsIds.length === funnels.length
        ? []
        : funnels.map(funnel => funnel.id),
    );
  }, [funnels]);

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormLabel>
          <Typography variant="h6">Funis</Typography>
        </FormLabel>
        {funnels.length > 0 && (
          <div>
            <Checkbox
              disabled={userSession.id === user.id}
              checked={funnels.length === checkedFunnelsIds.length}
              onChange={handleCheckAll}
            />
            <FormLabel>Selecionar todos</FormLabel>
            <Divider />
          </div>
        )}
        {funnels.map(funnel => (
          <div>
            <Checkbox
              key={funnel.id}
              disabled={userSession.id === user.id}
              checked={checkedFunnelsIds.includes(funnel.id)}
              onChange={e => handleChangeCheckedFunnels(e.target.name)}
              name={funnel.id}
            />
            <FormLabel>{funnel.name}</FormLabel>
          </div>
        ))}
      </Grid>
      {userSession.id !== user.id && funnels.length > 0 && (
        <Grid item xs={12}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            style={{ borderRadius: '32px', marginTop: '16px' }}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default UserFunnel;
