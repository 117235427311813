import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  TextField,
  Tooltip,
  InputAdornment,
  Badge,
} from '@material-ui/core';
import {
  PersonAddOutlined,
  FilterListOutlined,
  ReplayOutlined,
  HelpOutlined,
  SearchOutlined,
  ClearOutlined,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { ContactContext } from '../../../hooks/ContactContext';
import { MessageContext } from '../../../hooks/MessageContext';
import { useWindowDimensions } from '../../../hooks/WindowDimensionsContext';

import Message from '../../../models/Message';

import CreateContactDialog from './CreateContactDialog';
import ChooseAdvancedFilters from './ChooseAdvancedFilters';

import {
  Header,
  InputSearchRow,
  MoreOptions,
  ListItem,
  CustomScrollbar,
  CustomListItemText,
} from './styles';

interface Props {
  searchedNamePhone?: string;
}

const Contacts: React.FC<Props> = () => {
  const state = useLocation<Props>().state || {};
  const [
    openCreateContactDialog,
    setOpenCreateContactDialog,
  ] = useState<boolean>(false);
  const [isChooseAdvancedFilters, setChooseAdvancedFilters] = useState<boolean>(
    false,
  );

  const [searchedNamePhone, setSearchedNamePhone] = useState<string>(
    state?.searchedNamePhone ?? '',
  );

  const history = useHistory();
  const { windowDimensions } = useWindowDimensions();
  const {
    selectContact,
    setPagination,
    contacts,
    selectedContact,
    advancedFiltersCount,
  } = useContext(ContactContext);

  const { changeSearchMessage, changeKeySearch } = useContext(MessageContext);

  useEffect(() => {
    if (!searchedNamePhone)
      setPagination({
        take: 20,
        skip: 0,
      });
  }, [searchedNamePhone, setPagination]);

  const handleChangePagination = useCallback(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      take: 5,
      skip: oldPagination.skip + oldPagination.take,
    }));
  }, [setPagination]);

  const handleChangeSearch = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter')
        setPagination({
          take: 20,
          skip: 0,
          name: searchedNamePhone,
          phone: searchedNamePhone,
        });
    },
    [setPagination, searchedNamePhone],
  );

  const handleClearSearch = useCallback(() => {
    setSearchedNamePhone('');

    setPagination(oldPagination => ({
      ...oldPagination,
      take: 20,
      skip: 0,
      name: undefined,
      phone: undefined,
    }));
  }, [setPagination]);

  const lastDateMessages = useCallback((messages: Message[]) => {
    const today = format(
      zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
      'dd/MM/yyyy',
    );

    for (let i = 0; i < messages.length; i++) {
      const { time } = messages[i];

      if (time) {
        const lastDate = format(
          zonedTimeToUtc(time, 'America/Sao_Paulo'),
          'dd/MM/yyyy',
        );

        if (lastDate === today)
          return format(zonedTimeToUtc(time, 'America/Sao_Paulo'), 'HH:mm');

        return lastDate;
      }
    }

    return '';
  }, []);

  return (
    <>
      {openCreateContactDialog && (
        <CreateContactDialog
          open={openCreateContactDialog}
          setOpen={setOpenCreateContactDialog}
          redirect
        />
      )}
      {isChooseAdvancedFilters && (
        <ChooseAdvancedFilters
          open={isChooseAdvancedFilters}
          setOpen={setChooseAdvancedFilters}
        />
      )}
      <Header>
        <InputSearchRow>
          <TextField
            id="search-name-phone"
            name="search-name-phone"
            label="Nome ou celular..."
            variant="outlined"
            fullWidth
            size="small"
            value={searchedNamePhone}
            onChange={e => setSearchedNamePhone(e.target.value)}
            onKeyPress={handleChangeSearch}
            InputProps={{
              startAdornment:
                searchedNamePhone.length === 0 ? (
                  <InputAdornment position="start">
                    <SearchOutlined color="disabled" fontSize="small" />
                  </InputAdornment>
                ) : (
                  <IconButton
                    style={{
                      padding: 0,
                      paddingRight: 8,
                    }}
                    color="primary"
                    size="small"
                    onClick={handleClearSearch}
                  >
                    <ClearOutlined color="disabled" fontSize="small" />
                  </IconButton>
                ),
            }}
          />
        </InputSearchRow>
        <MoreOptions>
          <IconButton
            edge="start"
            color="primary"
            onClick={() => setOpenCreateContactDialog(true)}
          >
            <PersonAddOutlined />
          </IconButton>
          <IconButton
            edge="end"
            color="primary"
            onClick={() => setChooseAdvancedFilters(true)}
            style={{
              paddingRight: 22,
            }}
          >
            <Badge badgeContent={advancedFiltersCount} color="primary" showZero>
              <FilterListOutlined />
            </Badge>
          </IconButton>
        </MoreOptions>
      </Header>
      <CustomScrollbar>
        {contacts.map(contact => (
          <ListItem
            key={contact.id}
            button
            divider
            style={{ background: '#fff' }}
            onClick={() => {
              selectContact(contact);
              changeSearchMessage(false);
              changeKeySearch('');

              if (windowDimensions.resolution === 'mobile')
                history.push(`/contact`);
            }}
          >
            <ListItemAvatar>
              <Avatar src={contact.photo} alt={contact.name} />
            </ListItemAvatar>
            <ListItemText primary={contact.name} />
            <ListItemAvatar
              style={{
                paddingLeft: 24,
              }}
            >
              <>
                {contact?.messages?.length > 0 && (
                  <CustomListItemText
                    primary={lastDateMessages(contact.messages)}
                  />
                )}
                {(contact.last_negotiations_funnels.length === 0 ||
                  contact.last_negotiations_funnels.filter(
                    ({ user_id }) => !user_id,
                  ).length > 0) && (
                  <Tooltip
                    title="Contato sem responsável"
                    style={{
                      marginRight:
                        contact.messages.filter(m => m.unread_message).length >
                        0
                          ? '10px'
                          : '0px',
                      float:
                        contact.messages.filter(m => m.unread_message).length >
                        0
                          ? 'none'
                          : 'right',
                    }}
                  >
                    <HelpOutlined color="disabled" fontSize="small" />
                  </Tooltip>
                )}
                <Badge
                  style={{
                    float: 'right',
                    marginTop: '1.3vh',
                    marginRight: '10px',
                  }}
                  badgeContent={
                    selectedContact?.id === contact.id
                      ? 0
                      : contact.messages.filter(m => m.unread_message).length
                  }
                  color="secondary"
                />
              </>
            </ListItemAvatar>
          </ListItem>
        ))}
        <ListItem
          alignItems="center"
          key="button-load-contacts"
          button
          divider
          onClick={handleChangePagination}
        >
          <ListItemAvatar>
            <ReplayOutlined />
          </ListItemAvatar>
          <ListItemText primary="Carregar mais..." />
        </ListItem>
      </CustomScrollbar>
    </>
  );
};

export default Contacts;
