import styled from 'styled-components';

const Content = styled.section`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  audio {
    height: 24px;
    margin-right: 8px;
    max-width: 100%;
  }
`;

export { Content };
