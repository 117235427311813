import Mailing from '../models/Mailing';

import api from './api';

const handleMailingUploadFile = async ({
  file,
  separator,
}: {
  file: File;
  separator: string;
}): Promise<Mailing> => {
  const bytes = file.size / 1000;

  if (bytes > 1000 * 5)
    throw new Error('O tamanho máximo de arquivo permitido é 5MB.');

  const formData = new FormData();
  formData.append('file', file);

  const { data } = await api.post<Mailing>('mailings', formData, {
    params: {
      separator,
    },
  });

  return data;
};

export type MailingDTO = {
  total_count: number;
  mailings: Mailing[];
};

const loadMailings = async (
  take: number,
  skip: number,
): Promise<MailingDTO> => {
  const { data } = await api.get<MailingDTO>('mailings', {
    headers: {
      take,
      skip,
    },
  });

  return data;
};

export { handleMailingUploadFile, loadMailings };
