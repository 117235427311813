import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  float: right;

  caption {
    font-size: 9px;
    margin-right: 8px;
    margin-top: 2px;
  }

  svg {
    width: 13px;
    height: 13px;
  }
`;

export { Container };
