enum NegotiationLogbookType {
  CreateNegotiation = 'create-negotiation',
  CreateNegotiationChatbot = 'create-negotiation-chatbot',
  CreateNegotiationReceptiveFunnel = 'create-negotiation-receptive-funnel',
  CreateNegotiationExternalRequest = 'create-negotiation-external-request',
  UpdateNegotiation = 'update-negotiation',
  WonNegotiation = 'won-negotiation',
  LostNegotiation = 'lost-negotiation',
  ReopenedNegotiation = 'reopened-negotiation',
  CreateFile = 'create-file',
  CreateAnnotation = 'create-annotation',
  CreateAnnotationExternalRequest = 'create-annotation-external-request',
  ExchangeNegotiationFunnel = 'exchange-negotiation-funnel',
  AutomaticMessage = 'automatic-message',
}

export default NegotiationLogbookType;
