import React from 'react';
import { Typography } from '@material-ui/core';

import NegotiationFunnel from '../../../../models/NegotiationFunnel';
import Contact from '../../../../models/Contact';

import Schedule from '../../../Schedule';

import { Container, Title, Content } from './styles';

type Props = {
  contact: Contact;
  negotiationFunnel: NegotiationFunnel;
};

const CalendarSession: React.FC<Props> = ({ negotiationFunnel, contact }) => (
  <Container>
    <Title>
      <Typography variant="h6">Agendamentos</Typography>
    </Title>
    <Content>
      <Schedule
        negotiation_id={negotiationFunnel.negotiation_id}
        contact_id={contact.id}
        contact_name={contact.name}
        disabledContact
        small
        height="320px"
      />
    </Content>
  </Container>
);

export default CalendarSession;
