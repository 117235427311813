import React, { useMemo } from 'react';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { formatPrice } from '../../../utils/formatPrice';

import NegotiationStatisticDTO from '../dtos/NegotiationStatisticDTO';

import { Container, Title, Item, TotalValue } from './styles';

interface Props extends TooltipProps<ValueType, NameType> {
  negotiationsStatistics: NegotiationStatisticDTO;
  started_color: string;
  won_color: string;
  lost_color: string;
  open_color: string;
}

const NegotiationStatisticTooltipChart: React.FC<Props> = ({
  negotiationsStatistics,
  active,
  label,
  started_color,
  won_color,
  lost_color,
  open_color,
}) => {
  const details = useMemo(
    () => negotiationsStatistics.details.find(detail => detail.name === label),
    [negotiationsStatistics.details, label],
  );

  return active && details ? (
    <Container>
      <Title>{label}</Title>
      <Item color={started_color}>
        <p>{`Iniciados: ${details.quantity}`}</p>
      </Item>
      <Item color={won_color}>
        <p>{`Ganhos: ${details.won.quantity}`}</p>
        <TotalValue>{formatPrice(details.won.total_value)}</TotalValue>
      </Item>
      <Item color={lost_color}>
        <p>{`Perdidos: ${details.lost.quantity}`}</p>
        <TotalValue>{formatPrice(details.lost.total_value)}</TotalValue>
      </Item>
      <Item color={open_color}>
        <p>{`Em aberto: ${details.open.quantity}`}</p>
        <TotalValue>{formatPrice(details.open.total_value)}</TotalValue>
      </Item>
    </Container>
  ) : null;
};

export default NegotiationStatisticTooltipChart;
