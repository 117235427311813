import React from 'react';
import {
  OutlinedInputProps,
  OutlinedInput,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';

import { CustomFormControl } from './styles';

export interface InputGenericProps extends Omit<OutlinedInputProps, 'error'> {
  error?: string;
  padding?: string;
}

const InputGeneric: React.FC<InputGenericProps> = ({
  label,
  error,
  padding,
  ...rest
}) => {
  return (
    <CustomFormControl
      variant="outlined"
      size="small"
      fullWidth
      error={!!error}
    >
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        label={label}
        style={{
          padding,
        }}
        {...rest}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </CustomFormControl>
  );
};

export default InputGeneric;
