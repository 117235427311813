import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import { PersonAddOutlined } from '@material-ui/icons';

import { ContactDTO, findAllContacts } from '../../../services/ContactService';

import InputGeneric, {
  InputGenericProps,
} from '../../../components/InputGeneric';

import CreateContactDialog from '../../Chat/Contacts/CreateContactDialog';

import ContactItem from './ContactItem';

interface Props {
  title?: string;
  current_contact?: ContactDTO;
  handleClose: (contact?: ContactDTO) => void;
}

const ChooseContact: React.FC<Props> = ({
  title = 'Selecione o contato',
  current_contact,
  handleClose,
}) => {
  const inputRef = useRef<InputGenericProps>(null);
  const [contacts, setContacts] = useState<ContactDTO[]>([]);
  const [value, setValue] = useState<string | undefined>(
    current_contact?.phone,
  );
  const [showCreateContact, setShowCreateContact] = useState<boolean>(false);

  useEffect(() => {
    async function loadContacts() {
      const data = await findAllContacts({
        take: 20,
        skip: 0,
      });

      if (current_contact) {
        setContacts([
          current_contact,
          ...data.filter(contact => contact.phone !== current_contact.phone),
        ]);
      } else {
        setContacts(data);
      }
    }

    loadContacts();
  }, [current_contact]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
    },
    [],
  );

  const handleOk = useCallback(() => {
    const contact = contacts.find(c => c.phone === value);

    handleClose(contact);
  }, [contacts, value, handleClose]);

  const handleFindContactsByNamePhone = useCallback(
    async (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        const searchedWord = inputRef.current?.value as string;

        const regexOnlyNumbers = new RegExp('^[0-9]+$');

        const isPhone = regexOnlyNumbers.test(searchedWord);

        const data = await findAllContacts({
          name: isPhone ? undefined : searchedWord,
          phone: isPhone ? searchedWord : undefined,
          take: 20,
          skip: 0,
        });

        setContacts(data);
      }
    },
    [],
  );

  const handleCreateContact = useCallback(async (contact: ContactDTO) => {
    setContacts(oldContacts => [contact, ...oldContacts]);

    setValue(contact.phone);
  }, []);

  return (
    <>
      {showCreateContact && (
        <CreateContactDialog
          open={showCreateContact}
          setOpen={setShowCreateContact}
          handleCreateContact={handleCreateContact}
          withoutNegotiation={1}
        />
      )}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {title}
            <IconButton
              edge="start"
              color="primary"
              size="medium"
              style={{
                padding: 0,
              }}
              onClick={() => setShowCreateContact(true)}
            >
              <PersonAddOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            width: 400,
            padding: 16,
          }}
        >
          <InputGeneric
            inputRef={inputRef}
            label="Digite nome ou celular..."
            onKeyPress={handleFindContactsByNamePhone}
          />
          <RadioGroup
            aria-label="ringtone"
            name="ringtone"
            onChange={handleChange}
          >
            {contacts.map(contact => (
              <FormControlLabel
                key={contact.phone}
                value={contact.phone}
                checked={contact.phone === value}
                control={<Radio />}
                label={<ContactItem contact={contact} />}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChooseContact;
