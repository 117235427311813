import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Space from '../../../../components/Space';

interface Props extends DialogProps {
  handleUploadConfirm: (separator: string) => void;
  handleUploadCancel: () => void;
  filename: string;
}

const MailingUploadConfirmDialog: React.FC<Props> = ({
  handleUploadConfirm,
  handleUploadCancel,
  filename,
  ...rest
}) => {
  const [separator, setSeparator] = useState<string>('semicolon');

  const handleSeparator = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSeparator(e.target.value),
    [],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      {...rest}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title">Upload de Mailing</DialogTitle>
      <DialogContent dividers>
        <Typography>{filename}</Typography>
        <Space orientation="vertical" size="16px" />
        <FormControl component="fieldset">
          <RadioGroup value={separator} onChange={handleSeparator}>
            <FormControlLabel
              value="semicolon"
              control={<Radio />}
              label="Ponto e Vírgula"
            />
            <FormControlLabel
              value="comma"
              control={<Radio />}
              label="Vírgula"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUploadCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleUploadConfirm(separator)} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MailingUploadConfirmDialog;
