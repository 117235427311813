import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 1rem;

  .MuiFormControl-fullWidth {
    width: auto;
    margin-bottom: 0;
  }

  button {
    margin: 0 6px;
  }
`;
