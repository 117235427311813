import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Button,
  Box,
  FormLabel,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api';

import { useFunnel } from '../../../../hooks/FunnelContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import Funnel from '../../../../models/Funnel';
import ProductModel from '../../../../models/Product';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import Input from '../../../../components/Input';

interface Props {
  handleOnSave?: () => void;
  refresh: () => Promise<void>;
  product?: ProductModel;
}

interface FormData {
  name: string;
}

const AddEditProduct: React.FC<Props> = ({
  handleOnSave,
  refresh,
  product,
}) => {
  const [checkedFunnelsIds, setCheckedFunnelsIds] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { funnels, loadFunnels } = useFunnel();
  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    const loadProductFunnels = async () => {
      try {
        if (product) {
          setLoading(true);

          const { data } = await api.get<Funnel[]>(
            `/products/${product.id}/funnels`,
          );

          setCheckedFunnelsIds(data.map(funnel => funnel.id));
        } else {
          setCheckedFunnelsIds([]);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadProductFunnels();
  }, [enqueueSnackbar, setLoading, product]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name } = data;

        const funnels = checkedFunnelsIds.map(id => ({ id }));

        if (product) {
          await api.put<ProductModel>(`/products/${product.id}`, {
            name,
          });

          await api.post(`/products/${product.id}/funnels`, {
            funnels,
          });

          refresh();
        } else {
          const { data } = await api.post<ProductModel>('/products', {
            name,
          });

          await api.post(`/products/${data.id}/funnels`, {
            funnels,
          });

          refresh();
        }

        await loadFunnels();

        handleOnSave?.();

        enqueueSnackbar(
          `Produto ${product ? 'alterado' : 'salvo'} com sucesso!`,
          {
            variant: 'success',
          },
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleOnSave,
      refresh,
      loadFunnels,
      product,
      checkedFunnelsIds,
    ],
  );

  const handleDeleteProduct = useCallback(async () => {
    try {
      if (product) {
        setLoading(true);

        await api.delete(`/products/${product.id}`);

        await loadFunnels();

        refresh();

        handleOnSave?.();
      }

      enqueueSnackbar('Produto excluído com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    handleOnSave,
    enqueueSnackbar,
    refresh,
    setLoading,
    loadFunnels,
    product,
  ]);

  const handleCheckAll = useCallback(() => {
    setCheckedFunnelsIds(OldCheckedFunnelsIds =>
      OldCheckedFunnelsIds.length === funnels.length
        ? []
        : funnels.map(funnel => funnel.id),
    );
  }, [funnels]);

  const handleCheckFunnel = useCallback((name: string) => {
    setCheckedFunnelsIds(oldCheckedFunnelsIds =>
      oldCheckedFunnelsIds.includes(name)
        ? [...oldCheckedFunnelsIds.filter(id => id !== name)]
        : [...oldCheckedFunnelsIds, name],
    );
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input
        id="name"
        name="name"
        label="Nome do produto"
        style={{ maxWidth: '400px' }}
        defaultValue={product?.name}
      />
      <div>
        <Box mb="1rem">
          <FormLabel>
            <Typography variant="h6">Funis</Typography>
          </FormLabel>
          {funnels.length > 0 && (
            <div>
              <Checkbox
                checked={funnels.length === checkedFunnelsIds.length}
                onChange={handleCheckAll}
              />
              <FormLabel>Selecionar todos</FormLabel>
              <Divider />
            </div>
          )}
          {funnels.map(funnel => (
            <div key={funnel.id}>
              <Checkbox
                id={funnel.id}
                name={funnel.id}
                checked={checkedFunnelsIds.includes(funnel.id)}
                onChange={e => handleCheckFunnel(e.target.name)}
              />
              <FormLabel>{funnel.name}</FormLabel>
            </div>
          ))}
        </Box>
      </div>
      <Button
        color="primary"
        size="small"
        variant="contained"
        style={{ borderRadius: '32px', marginRight: '6px' }}
        type="submit"
      >
        {product ? 'Alterar' : 'Salvar'}
      </Button>
      {product ? (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          style={{ borderRadius: '32px' }}
          type="button"
          onClick={() => handleDeleteProduct()}
        >
          Excluir
        </Button>
      ) : (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          style={{ borderRadius: '32px' }}
          type="button"
          onClick={() => handleOnSave?.()}
        >
          Cancelar
        </Button>
      )}
    </Form>
  );
};

export default AddEditProduct;
