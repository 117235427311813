import React, { useState, useCallback } from 'react';
import {
  Grid,
  Button,
  FormLabel,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import handleResponseError from '../../../../utils/handleResponseError';

import api from '../../../../services/api';

import { UserDTO, useResponsible } from '../../../../hooks/ResponsibleContext';
import { useSession } from '../../../../hooks/SessionContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import Permission from '../../../../models/Permission';
import PermissionType from '../../../../models/PermissionType';

interface Props {
  user: UserDTO;
  permissions: Permission[];
}

const UserPermission: React.FC<Props> = ({ user, permissions }) => {
  const [checkedPermissionsIds, setCheckedPermissionsIds] = useState<string[]>(
    user.permissions,
  );

  const { user: userSession } = useSession();
  const { loadResponsibles } = useResponsible();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      await api.post(`users/${user.id}/permissions`, {
        slugs: checkedPermissionsIds,
      });

      await loadResponsibles();

      enqueueSnackbar('Permissões atualizadas com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    setLoading,
    loadResponsibles,
    user,
    checkedPermissionsIds,
  ]);

  const handleChangePermission = useCallback((name: string) => {
    setCheckedPermissionsIds(oldCheckedPermissionsIds =>
      oldCheckedPermissionsIds.includes(name)
        ? [...oldCheckedPermissionsIds.filter(slug => slug !== name)]
        : [...oldCheckedPermissionsIds, name],
    );
  }, []);

  const handleCheckAll = useCallback(() => {
    setCheckedPermissionsIds(oldCheckedPermissionsIds =>
      oldCheckedPermissionsIds.length === permissions.length
        ? []
        : permissions
            .filter(permission => permission.slug !== PermissionType.AdminUser)
            .map(permission => permission.slug),
    );
  }, [permissions]);

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormLabel>
          <Typography variant="h6">Permissões</Typography>
        </FormLabel>
        {permissions.length > 0 && (
          <div>
            <Checkbox
              disabled={userSession.id === user.id}
              checked={permissions.length === checkedPermissionsIds.length}
              onChange={handleCheckAll}
            />
            <FormLabel>Selecionar todos</FormLabel>
            <Divider />
          </div>
        )}
        {permissions.map(permission => (
          <div key={permission.slug}>
            <Checkbox
              disabled={
                userSession.id === user.id ||
                permission.slug === PermissionType.AdminUser ||
                (permission.slug === PermissionType.Superior &&
                  !userSession.permissions.includes(PermissionType.AdminUser))
              }
              checked={checkedPermissionsIds.includes(permission.slug)}
              onChange={e => handleChangePermission(e.target.name)}
              name={permission.slug}
            />
            <FormLabel>{permission.description}</FormLabel>
          </div>
        ))}
      </Grid>
      {userSession.id !== user.id && permissions.length > 0 && (
        <Grid item xs={12}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            style={{ borderRadius: '32px', marginTop: '16px' }}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default UserPermission;
