import React, { useState, useEffect } from 'react';
import { SelectProps, Typography, Divider } from '@material-ui/core';
import { PersonOutlined, PeopleOutlined } from '@material-ui/icons';

import { useResponsible } from '../../../hooks/ResponsibleContext';

import { CustomSelect, CustomMenuItem } from './styles';

interface Props extends SelectProps {
  funnel_id: string;
}

const SelectUsersGroup: React.FC<Props> = ({ funnel_id, ...rest }) => {
  const [value, setValue] = useState<string>();
  const {
    selectedResponsible,
    users,
    teams,
    selectResponsible,
  } = useResponsible();

  useEffect(() => {
    if (selectedResponsible)
      setValue(
        selectedResponsible.team
          ? `${selectedResponsible.user.id}-team`
          : selectedResponsible.user.id,
      );
  }, [selectedResponsible, funnel_id, users]);

  return (
    <>
      {value && (
        <CustomSelect id="user" label="Usuário" value={value} {...rest}>
          {users
            .filter(user => user.active && user.funnels.includes(funnel_id))
            .map(inferior => (
              <CustomMenuItem
                key={inferior.id}
                value={inferior.id}
                onClick={() => {
                  selectResponsible({
                    user: inferior,
                    team: false,
                  });
                }}
              >
                <PersonOutlined />
                <Typography variant="subtitle2">{inferior.name}</Typography>
              </CustomMenuItem>
            ))}
          <Divider />
          {teams
            .filter(user => user.active && user.funnels.includes(funnel_id))
            .map(team => (
              <CustomMenuItem
                key={team.id}
                value={`${team.id}-team`}
                onClick={() => {
                  selectResponsible({
                    user: team,
                    team: true,
                  });
                }}
              >
                <PeopleOutlined />
                <Typography variant="subtitle2">{team.name}</Typography>
              </CustomMenuItem>
            ))}
        </CustomSelect>
      )}
    </>
  );
};

export default SelectUsersGroup;
