import styled from 'styled-components';
import { Button } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-left: 20px;
`;

const CustomButton = styled(Button)`
  && {
    margin-left: 16px;
    text-transform: none;
  }
`;

export { Container, CustomButton };
