enum NegotiationDateRange {
  All = 'all',
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'this-week',
  LastWeek = 'last-week',
  ThisMonth = 'this-month',
  LastMonth = 'last-month',
  LastSixMonths = 'last-six-months',
  CustomDate = 'custom-date',
}

export default NegotiationDateRange;
