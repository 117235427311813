import React, { useState, useCallback, useMemo } from 'react';

import {
  PieChart,
  Pie,
  PieLabelRenderProps,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Line,
  LineChart,
} from 'recharts';
import { useSnackbar } from 'notistack';
import { Divider } from '@material-ui/core';

import handleResponseError from '../../utils/handleResponseError';

import { useLoader } from '../../hooks/LoaderContext';

import api from '../../services/api';

import FilterDTO from '../../dtos/FilterDTO';

import NegotiationStatisticDTO from './dtos/NegotiationStatisticDTO';

import FilterHeader from '../../components/FilterHeader';

import Card from './Card';
import GeneralInfoItem from './GeneralInfoItem';
import NegotiationStatisticTooltipChart from './NegotiationStatisticTooltipChart';

import { Container, GeneralInfo, GeneralInfoTitle } from './styles';

interface LossReasonDTO {
  id: string;
  name: string;
  count: number;
}

export interface CustomPieLabelRenderProps extends PieLabelRenderProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
  fill: string;
}

const Chart: React.FC = () => {
  const [lossesReasons, setLoassesReasons] = useState<LossReasonDTO[]>([]);
  const [
    negotiationsStatistics,
    setNegotiationsStatistics,
  ] = useState<NegotiationStatisticDTO>({
    won: {
      quantity: 0,
      total_value: 0,
      average_value: 0,
    },
    lost: {
      quantity: 0,
      total_value: 0,
      average_value: 0,
    },
    open: {
      quantity: 0,
      total_value: 0,
      average_value: 0,
    },
    details: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const { setLoading } = useLoader();

  const loadLossesReasons = useCallback(
    async (filters: FilterDTO) => {
      try {
        setLoading(true);

        const {
          userId,
          funnelId,
          productId,
          channelId,
          dateStart,
          dateEnd,
          hierarchy,
        } = filters;

        const { data } = await api.get<LossReasonDTO[]>(
          'reports/losses-reasons',
          {
            params: {
              userId,
              funnelId,
              productId,
              channelId,
              dateStart,
              dateEnd,
              hierarchy,
            },
          },
        );

        setLoassesReasons(data.filter(({ count }) => count > 0));
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  const loadNegotiationsStatistics = useCallback(
    async (filters: FilterDTO) => {
      try {
        setLoading(true);

        const {
          userId,
          funnelId,
          productId,
          channelId,
          dateStart,
          dateEnd,
          hierarchy,
        } = filters;

        const { data } = await api.get<NegotiationStatisticDTO>(
          'reports/negotiations',
          {
            params: {
              userId,
              funnelId,
              productId,
              channelId,
              dateStart,
              dateEnd,
              hierarchy,
            },
          },
        );

        setNegotiationsStatistics(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  const handleChartFilters = useCallback(
    (filters: FilterDTO) => {
      loadNegotiationsStatistics(filters);
      loadLossesReasons(filters);
    },
    [loadNegotiationsStatistics, loadLossesReasons],
  );

  // const generateRandomRGB = useCallback(() => {
  //   const randomBetween = (min: number, max: number) =>
  //     min + Math.floor(Math.random() * (max - min + 1));

  //   const r = randomBetween(0, 255);
  //   const g = randomBetween(0, 255);
  //   const b = randomBetween(0, 255);

  //   return `rgb(${r}, ${g}, ${b})`;
  // }, []);

  const renderLabel = useCallback(
    ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      fill,
    }: CustomPieLabelRenderProps) => {
      const RADIAN = Math.PI / 180;
      const radius = 25 + innerRadius + (outerRadius - innerRadius);
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fill={fill}
          fontSize={12}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    },
    [],
  );

  const blues = useMemo(
    () => [
      '#87CEEB',
      '#0099CC',
      '#00B2EE',
      '#BFEFFF',
      '#33A1C9',
      '#507786',
      '#38B0DE',
      '#236B8E',
      '#0D4F8B',
      '#1C86EE',
      '#60AFFE',
      '#007FFF',
      '#4372AA',
      '#1464F4',
      '#1B3F8B',
    ],
    [],
  );
  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        <FilterHeader handleChartFilters={handleChartFilters} />
      </div>
      <Container>
        <Card title="Informativo Geral">
          <GeneralInfo>
            <GeneralInfoTitle>
              <div>
                <h5>Quantidade</h5>
              </div>
              <div>
                <h5>Valor Total</h5>
              </div>
              <div>
                <h5>Valor Médio</h5>
              </div>
            </GeneralInfoTitle>
            <Divider />
            <GeneralInfoItem
              tooltip="Negócios Ganhos"
              color="green"
              quantity={negotiationsStatistics.won.quantity}
              total_value={negotiationsStatistics.won.total_value}
              average_value={negotiationsStatistics.won.average_value}
            />
            <Divider />
            <GeneralInfoItem
              tooltip="Negócios Perdidos"
              color="red"
              quantity={negotiationsStatistics.lost.quantity}
              total_value={negotiationsStatistics.lost.total_value}
              average_value={negotiationsStatistics.lost.average_value}
            />
            <Divider />
            <GeneralInfoItem
              tooltip="Negócios em Aberto"
              color="grey"
              quantity={negotiationsStatistics.open.quantity}
              total_value={negotiationsStatistics.open.total_value}
              average_value={negotiationsStatistics.open.average_value}
            />
          </GeneralInfo>
        </Card>
        <Card title="Negócios Iniciados">
          <ResponsiveContainer>
            <LineChart
              data={negotiationsStatistics.details.map(
                ({ name, quantity }) => ({
                  name,
                  Iniciados: quantity,
                }),
              )}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Legend />
              <Line dataKey="Iniciados" stroke={blues[2]} legendType="rect" />
              <Tooltip
                content={{
                  ...(
                    <NegotiationStatisticTooltipChart
                      negotiationsStatistics={negotiationsStatistics}
                      started_color={blues[2]}
                      won_color="#000"
                      lost_color="#000"
                      open_color="#000"
                    />
                  ),
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Card>
        <Card title="Ganhos e Perdas">
          <ResponsiveContainer>
            <BarChart
              data={negotiationsStatistics.details.map(
                ({ name, quantity, won, lost }) => ({
                  name,
                  Iniciados: quantity,
                  Ganhos: won.quantity,
                  Perdidos: lost.quantity,
                }),
              )}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Bar dataKey="Iniciados" fill={blues[0]} />
              <Bar dataKey="Ganhos" fill={blues[1]} />
              <Bar dataKey="Perdidos" fill={blues[2]} />
              <Tooltip
                content={{
                  ...(
                    <NegotiationStatisticTooltipChart
                      negotiationsStatistics={negotiationsStatistics}
                      started_color={blues[0]}
                      won_color={blues[1]}
                      lost_color={blues[2]}
                      open_color="#000"
                    />
                  ),
                }}
              />
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </Card>
        <Card title="Motivos de Perdas">
          {lossesReasons.length > 0 && (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={lossesReasons}
                  label={renderLabel}
                  labelLine
                  dataKey="count"
                >
                  {lossesReasons.map((lossReason, index) => (
                    <Cell key={lossReason.id} fill={blues[index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Card>
      </Container>
    </>
  );
};

export default Chart;
