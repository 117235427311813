import React, { useState, useCallback, useMemo, useContext } from 'react';
import { ButtonGroup, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { format, parseISO } from 'date-fns';

import handleResponseError from '../../../../utils/handleResponseError';

import { useSession } from '../../../../hooks/SessionContext';
import { useResponsible } from '../../../../hooks/ResponsibleContext';
import { useFunnel } from '../../../../hooks/FunnelContext';
import { useLoader } from '../../../../hooks/LoaderContext';
import { ContactContext } from '../../../../hooks/ContactContext';

import api from '../../../../services/api';

import Negotiation from '../../../../models/Negotiation';
import NegotiationFunnel from '../../../../models/NegotiationFunnel';
import Step from '../../../../models/Step';

import Select from '../../../../components/Select';
import ReplaceNegotiationFunnelDialog from '../../../../components/ReplaceNegotiationFunnelDialog';

import { Container, SelectRow, StepRow } from './styles';

type Option = {
  id: string;
  name: string;
};

type Props = {
  negotiations: Negotiation[];
  negotiation: Negotiation;
  negotiationFunnel: NegotiationFunnel;
  handleSelectedNegotiation: (negotiation: Negotiation) => void;
  handleSelectedNegotiationFunnel: (
    negotiationFunnel: NegotiationFunnel,
  ) => void;
  handleChangeNegotiationFunnel: (negotiationFunnel: NegotiationFunnel) => void;
};

const HeaderSession: React.FC<Props> = ({
  negotiations,
  negotiation,
  negotiationFunnel,
  handleSelectedNegotiation,
  handleSelectedNegotiationFunnel,
  handleChangeNegotiationFunnel,
}) => {
  const [stepId, setStepId] = useState<string>(negotiationFunnel.last_step_id);
  const [userId, setUserId] = useState<string | undefined>(
    negotiationFunnel.user_id,
  );
  const [
    openReplaceFunnelDialog,
    setOpenReplaceFunnelDialog,
  ] = useState<boolean>(false);

  const { user } = useSession();
  const { users } = useResponsible();
  const { funnels } = useFunnel();
  const { loadContacts } = useContext(ContactContext);
  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeResponsible = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      try {
        setLoading(true);

        const id = event.target.value as string;

        await api.patch(`clients/${negotiation.id}/responsible`, {
          user_id: event.target.value,
        });

        await loadContacts();

        setUserId(id);

        handleSelectedNegotiation({
          ...negotiation,
          negotiation_funnel: {
            ...negotiation.negotiation_funnel,
            user_id: id,
          },
          negotiations_funnels: negotiation.negotiations_funnels.map(
            negotiationFunnel => ({
              ...negotiationFunnel,
              user_id:
                negotiationFunnel.funnel_id ===
                negotiation.negotiation_funnel.funnel_id
                  ? id
                  : negotiationFunnel.user_id,
            }),
          ),
        });

        enqueueSnackbar('Responsável atualizado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      handleSelectedNegotiation,
      setLoading,
      loadContacts,
      negotiation,
    ],
  );

  const handleChangeStep = useCallback(
    async (step: Step) => {
      try {
        setLoading(true);

        await api.put(`steps/${step.id}/clients/${negotiation.id}`);

        setStepId(step.id);

        handleSelectedNegotiation({
          ...negotiation,
          negotiation_funnel: {
            ...negotiation.negotiation_funnel,
            last_step_id: step.id,
          },
          negotiations_funnels: negotiation.negotiations_funnels.map(
            negotiationFunnel => ({
              ...negotiationFunnel,
              last_step_id:
                negotiationFunnel.funnel_id ===
                negotiation.negotiation_funnel.funnel_id
                  ? step.id
                  : negotiationFunnel.last_step_id,
            }),
          ),
        });

        enqueueSnackbar('Etapa atualizada com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, handleSelectedNegotiation, negotiation],
  );

  const mappedNegotiationOptions = useMemo(
    () =>
      negotiations.map(({ id, name, created_at }) => ({
        id,
        name: `${format(parseISO(created_at), 'dd/MM/yyyy HH:mm')} - ${name}`,
      })),
    [negotiations],
  );

  const mappedFunnelOptions = useMemo((): Option[] => {
    const options: Option[] = [];

    negotiation.negotiations_funnels.forEach(({ funnel_id, created_at }) => {
      const funnel = funnels.find(funnel => funnel.id === funnel_id);

      if (funnel)
        options.push({
          id: funnel_id,
          name: `${format(parseISO(created_at), 'dd/MM/yyyy HH:mm')} - ${
            funnel.name
          }`,
        });
    });

    return options;
  }, [funnels, negotiation]);

  const mappedUserOptions = useMemo(
    (): Option[] =>
      users
        .filter(({ id, active }) => active || id === negotiationFunnel.user_id)
        .map(({ id, name }) => ({
          id,
          name,
        })),
    [users, negotiationFunnel],
  );

  const steps = useMemo((): Step[] => {
    if (!negotiationFunnel) return [];

    const funnel = funnels.find(
      funnel => funnel.id === negotiationFunnel.funnel_id,
    );

    if (!funnel) return [];

    return funnel.steps;
  }, [funnels, negotiationFunnel]);

  const handleChangeNegotiation = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      const negotiation = negotiations.find(
        negotiation => negotiation.id === id,
      );

      if (negotiation) {
        handleSelectedNegotiation(negotiation);
        setUserId(negotiation.negotiation_funnel.user_id);
        setStepId(negotiation.negotiation_funnel.last_step_id);
      }
    },
    [handleSelectedNegotiation, negotiations],
  );

  const handleChangeFunnel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      const negotiationFunnel = negotiation.negotiations_funnels.find(
        negotiationFunnel => negotiationFunnel.funnel_id === id,
      );

      if (negotiationFunnel) {
        handleSelectedNegotiationFunnel(negotiationFunnel);
        setUserId(negotiationFunnel.user_id);
        setStepId(negotiationFunnel.last_step_id);
      }
    },
    [handleSelectedNegotiationFunnel, negotiation],
  );

  return (
    <>
      <ReplaceNegotiationFunnelDialog
        open={openReplaceFunnelDialog}
        setOpen={setOpenReplaceFunnelDialog}
        setStepId={setStepId}
        negotiation_id={negotiation.id}
        funnel_id={negotiationFunnel.funnel_id}
        handleChangeNegotiationFunnel={handleChangeNegotiationFunnel}
      />
      <Container>
        <Typography variant="h6">Negociação</Typography>
        <SelectRow>
          <Select
            name="negotiation_id"
            label="Negociação"
            fullWidth
            value={negotiation.id}
            values={mappedNegotiationOptions}
            withDefaultOption={false}
            onChange={handleChangeNegotiation}
          />
        </SelectRow>
        <SelectRow>
          <Select
            name="funnel_id"
            label="Funil"
            fullWidth
            value={negotiationFunnel.funnel_id}
            values={mappedFunnelOptions}
            withDefaultOption={false}
            disabled
            onChange={handleChangeFunnel}
          />
        </SelectRow>
        <SelectRow>
          <Select
            name="user_id"
            label="Responsável"
            fullWidth
            value={userId}
            values={mappedUserOptions}
            onChange={handleChangeResponsible}
            disabled={!!negotiationFunnel.user_id_exclusion}
          />
        </SelectRow>
        <StepRow>
          <ButtonGroup fullWidth size="small" color="primary">
            {steps.map(step => (
              <Button
                key={step.id}
                variant={stepId === step.id ? 'contained' : 'outlined'}
                onClick={() => handleChangeStep(step)}
                disabled={!!negotiationFunnel.user_id_exclusion}
              >
                {step.name.charAt(0).toUpperCase()}
              </Button>
            ))}
          </ButtonGroup>
        </StepRow>
        {user.permissions.includes('superior') &&
          !negotiationFunnel.user_id_exclusion && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{
                width: '100%',
                marginTop: 16,
              }}
              onClick={() => setOpenReplaceFunnelDialog(true)}
            >
              Trocar Funil
            </Button>
          )}
      </Container>
    </>
  );
};

export default HeaderSession;
