const getAbbreviationByName = (name: string) => {
  const names = name.split(' ');

  if (names.length === 1) return names[0].substring(0, 2).toUpperCase();

  const firstName = names[0];
  const lastName = names[names.length - 1];

  return `${firstName.charAt(0).toUpperCase()}${lastName
    .charAt(0)
    .toUpperCase()}`;
};

export default getAbbreviationByName;
