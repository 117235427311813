import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';

import api from '../../services/api';
import { createNegotiationFile } from '../../services/NegotiationFileService';
import { ContactDTO } from '../../services/ContactService';

import { useResponsible } from '../../hooks/ResponsibleContext';
import { useFunnel } from '../../hooks/FunnelContext';
import { useLoader } from '../../hooks/LoaderContext';

import handleFormData from '../../utils/handleFormData';
import handleFormDataError from '../../utils/handleFormDataError';
import handleResponseError from '../../utils/handleResponseError';
import getNegotiationStatus from '../../utils/getNegotiationStatus';

import Negotiation from '../../models/Negotiation';
import NegotiationFunnel from '../../models/NegotiationFunnel';
import Step from '../../models/Step';
import Product from '../../models/Product';
import Channel from '../../models/Channel';
import Age from '../../models/Age';
import NegotiationStatus from '../../models/NegotiationStatus';

import Input from '../../components/Input';
import InputNumber from '../../components/InputNumber';
import InputPhone from '../../components/InputPhone';
import SelectForm from '../../components/SelectForm';
import Schedule from '../Schedule';

import Header from './Header';
import TabLogbooks from './TabLogbooks';
import TabFiles from './TabFiles';

import { Container, Content, CustomGrid } from './styles';

interface Props {
  negotiation: Negotiation;
  contact?: ContactDTO;
  photo?: string;
}

const ClientPage: React.FC = () => {
  const {
    negotiation,
    contact: defaultContact = negotiation.contact,
    photo,
  } = useLocation<Props>().state;

  const { users } = useResponsible();
  const { funnels } = useFunnel();

  const [currentNegotiationFunnel, setCurrentNegotiationFunnel] = useState<
    NegotiationFunnel | undefined
  >(negotiation?.negotiation_funnel);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [contact, setContact] = useState<ContactDTO | undefined>(
    defaultContact,
  );
  const [stepId, setStepId] = useState<string>();
  const [products, setProducts] = useState<Product[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [ages, setAges] = useState<Age[]>([]);
  const [viewingMode, setViewingMode] = useState<boolean>(!!negotiation);
  const [name, setName] = useState<string | undefined>(
    negotiation?.name ? negotiation.name : '',
  );
  const [userId, setUserId] = useState<string>();
  const [minPhone, setMinPhone] = useState<number>(0);

  const [refreshFiles, setRefreshFiles] = useState<boolean>(false);
  const [refreshLogs, setRefreshLogs] = useState<boolean>(false);

  const formRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  const steps = useMemo(() => {
    if (!currentNegotiationFunnel) return [];

    return (
      funnels.find(funnel => funnel.id === currentNegotiationFunnel.funnel_id)
        ?.steps ?? []
    );
  }, [currentNegotiationFunnel, funnels]);

  useEffect(() => {
    setStepId(currentNegotiationFunnel?.last_step_id ?? steps?.[0]?.id);
  }, [currentNegotiationFunnel, steps]);

  useEffect(() => {
    setUserId(currentNegotiationFunnel?.user_id);
  }, [currentNegotiationFunnel]);

  useEffect(() => {
    const loadAges = async () => {
      try {
        const { data } = await api.get<Age[]>('ages');

        setAges(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    };

    loadAges();
  }, [enqueueSnackbar]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        if (currentNegotiationFunnel) {
          const { data } = await api.get<Product[]>('products', {
            params: {
              funnel_id: currentNegotiationFunnel.funnel_id,
            },
          });

          setProducts(data);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    };

    loadProducts();
  }, [enqueueSnackbar, currentNegotiationFunnel]);

  useEffect(() => {
    const loadChannels = async () => {
      try {
        if (currentNegotiationFunnel) {
          const { data } = await api.get<Channel[]>('channels', {
            params: {
              funnel_id: currentNegotiationFunnel.funnel_id,
            },
          });

          setChannels(data);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    };

    loadChannels();
  }, [enqueueSnackbar, currentNegotiationFunnel]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        if (negotiation) {
          await api.put(`clients/${negotiation.id}`, data);

          enqueueSnackbar('Cliente atualizado com sucesso!', {
            variant: 'success',
          });
        }

        setViewingMode(true);
        setRefreshLogs(true);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
        setRefreshLogs(false);
      }
    },
    [enqueueSnackbar, setLoading, negotiation],
  );

  const validateFormData = useCallback(
    async data => {
      try {
        if (!viewingMode) {
          formRef.current?.setErrors({});

          const dataHandled = handleFormData({
            ...data,
            step_id: stepId,
            contact_id: contact?.id,
          });

          if (dataHandled.step_id === null)
            throw new Error('É obrigatório selecionar uma etapa!');

          const schema = Yup.object().shape({
            name: Yup.string()
              .nullable()
              .required('Nome do contato é obrigatório!'),
            client_landline: dataHandled.contact_id
              ? Yup.string()
                  .nullable()
                  .min(minPhone, 'Preencha todos os dígitos ou deixe em branco')
                  .notRequired()
              : Yup.string()
                  .nullable()
                  .min(minPhone, 'Preencha todos os dígitos')
                  .required('Telefone é obrigatório!'),
            expectation_date: Yup.string()
              .nullable()
              .min(8, 'Data de expectativa deve conter 8 dígitos!')
              .notRequired(),
            anniversary_date: Yup.string()
              .nullable()
              .min(8, 'Data de aniversário deve conter 8 dígitos!')
              .notRequired(),
            company_employer_number: Yup.string()
              .nullable()
              .min(14, 'CNPJ deve conter 14 dígitos!')
              .notRequired(),
            client_social_number: Yup.string()
              .nullable()
              .min(11, 'CPF deve conter 11 dígitos!')
              .notRequired(),
            address_zip_code: Yup.string()
              .nullable()
              .min(8, 'CEP deve conter 8 dígitos!')
              .notRequired(),
          });

          await schema.validate(dataHandled, {
            abortEarly: false,
          });

          handleSubmit(dataHandled);
        }
      } catch (err) {
        const message = handleFormDataError(err, formRef);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [handleSubmit, enqueueSnackbar, viewingMode, stepId, contact, minPhone],
  );

  const handleSelectContact = useCallback(
    async (selectedContact: ContactDTO) => {
      try {
        setContact(selectedContact);

        if (!name || name.length === 0) {
          setName(selectedContact.name);
        }

        if (negotiation) {
          setLoading(true);

          await api.patch(`clients/${negotiation.id}/contact`, {
            contact_id: selectedContact.id,
          });

          enqueueSnackbar('Contato atualizado com sucesso!', {
            variant: 'success',
          });

          setRefreshLogs(true);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
        setRefreshLogs(false);
      }
    },
    [enqueueSnackbar, setLoading, negotiation, name],
  );

  const handleChangeName = useCallback(async event => {
    setName(event.target.value);
  }, []);

  const handleSelectStep = useCallback(
    async (step: Step) => {
      try {
        if (step && negotiation && currentNegotiationFunnel) {
          setCurrentNegotiationFunnel({
            ...currentNegotiationFunnel,
            last_step_id: step.id,
          });
          setStepId(step.id);
          setLoading(true);

          await api.put(`steps/${step.id}/clients/${negotiation.id}`);

          enqueueSnackbar('Etapa atualizada com sucesso!', {
            variant: 'success',
          });

          setRefreshLogs(true);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
        setRefreshLogs(false);
      }
    },
    [enqueueSnackbar, setLoading, negotiation, currentNegotiationFunnel],
  );

  const handleEnableFields = useCallback(() => {
    setViewingMode(false);
  }, []);

  const handlePastedImage = useCallback(
    async (files?: FileList) => {
      try {
        const file = files?.[0];

        if (file && file.size !== 0 && negotiation) {
          setLoading(true);

          await createNegotiationFile({
            negotiation_id: negotiation.id,
            file,
          });

          enqueueSnackbar('Arquivo anexado com sucesso!', {
            variant: 'success',
          });

          setRefreshFiles(true);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
        setRefreshFiles(false);
      }
    },
    [enqueueSnackbar, setLoading, negotiation],
  );

  const handleChangeClientLandline = useCallback(
    async (phone?: string) => {
      try {
        if (phone) {
          setLoading(true);

          const { data } = await api.get<ContactDTO | undefined>(
            `contacts/${phone}`,
          );

          setContact(data);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  const handleDraggedFiles = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      try {
        e.stopPropagation();
        e.preventDefault();

        if (negotiation) {
          setLoading(true);

          const files = Array.from(e.dataTransfer.files);

          await Promise.all(
            files.map(file =>
              createNegotiationFile({
                negotiation_id: negotiation.id,
                file,
              }),
            ),
          );

          enqueueSnackbar('Arquivo(s) anexado(s) com sucesso!', {
            variant: 'success',
          });

          setRefreshFiles(true);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
        setRefreshFiles(false);
      }
    },
    [enqueueSnackbar, setLoading, negotiation],
  );

  const handleSelectCurrentNegotiationFunnel = useCallback(
    (negotiation_funnel: NegotiationFunnel) => {
      setCurrentNegotiationFunnel(negotiation_funnel);
    },
    [],
  );

  const status = useMemo(() => {
    if (!negotiation || !currentNegotiationFunnel)
      return NegotiationStatus.InProgress;

    const status = getNegotiationStatus({
      ...negotiation,
      negotiation_funnel: currentNegotiationFunnel,
    });

    return status;
  }, [negotiation, currentNegotiationFunnel]);

  const handleChangeResponsible = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setUserId(id);
    },
    [],
  );

  return (
    <Container
      onPaste={e => handlePastedImage(e.clipboardData.files)}
      onDrop={handleDraggedFiles}
      onDragOver={e => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      }}
    >
      {negotiation && currentNegotiationFunnel && (
        <Form
          ref={formRef}
          initialData={{
            ...negotiation,
            user_id: currentNegotiationFunnel.user_id,
          }}
          onSubmit={validateFormData}
        >
          <CustomGrid container spacing={2}>
            <CustomGrid item xs={12}>
              <Header
                negotiation={negotiation}
                negotiation_funnel={currentNegotiationFunnel}
                contact={contact}
                status={status}
                steps={steps}
                photo={photo}
                handleSelectContact={handleSelectContact}
                handleSelectStep={handleSelectStep}
                handleSelectCurrentNegotiationFunnel={
                  handleSelectCurrentNegotiationFunnel
                }
                handleEnableFields={handleEnableFields}
                setViewingMode={setViewingMode}
                viewingMode={viewingMode}
                handleCancel={() => window.location.reload()}
              />
            </CustomGrid>
            <CustomGrid item xs={6}>
              <Content>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                  >
                    <Typography color="primary" variant="button">
                      Geral
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <CustomGrid container spacing={1}>
                      <CustomGrid item xs={12}>
                        <Input
                          id="name"
                          name="name"
                          label="Cliente"
                          disabled={viewingMode}
                          onChange={handleChangeName}
                          value={name}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <InputPhone
                          id="client_landline"
                          name="client_landline"
                          label="Telefone"
                          disabled={viewingMode || !!contact?.phone}
                          defaultValue={contact?.phone}
                          length={length => setMinPhone(length)}
                          phone={phone => handleChangeClientLandline(phone)}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <Input
                          id="client_email"
                          name="client_email"
                          label="E-mail"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <SelectForm
                          name="user_id"
                          label="Responsável"
                          value={userId}
                          onChange={handleChangeResponsible}
                          values={users
                            .filter(
                              ({ active, id }) =>
                                active ||
                                id === currentNegotiationFunnel.user_id,
                            )
                            .map(user => ({
                              id: user.id,
                              name: user.name,
                            }))}
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <SelectForm
                          name="channel_id"
                          label="Canal de prospecção"
                          values={channels.map(channel => {
                            return {
                              id: channel.id,
                              name: channel.name,
                            };
                          })}
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                    </CustomGrid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                  >
                    <Typography color="primary" variant="button">
                      Contrato em negociação
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <CustomGrid container spacing={1}>
                      <CustomGrid item xs={12}>
                        <SelectForm
                          name="product_id"
                          label="Produto"
                          values={products.map(product => {
                            return {
                              id: product.id,
                              name: product.name,
                            };
                          })}
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <InputNumber
                          id="value"
                          name="value"
                          label="Valor previsto"
                          thousandSeparator="."
                          decimalSeparator=","
                          isNumericString
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <InputNumber
                          id="expectation_date"
                          name="expectation_date"
                          label="Data de expectativa"
                          format="##/##/####"
                          mask="_"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <Scope path="ages">
                        {ages.map(age => (
                          <CustomGrid key={age.id} item xs={3}>
                            <Input
                              id={age.id}
                              name={age.id}
                              label={age.name}
                              disabled={viewingMode}
                            />
                          </CustomGrid>
                        ))}
                      </Scope>
                    </CustomGrid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                  >
                    <Typography color="primary" variant="button">
                      Contrato vigente
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <CustomGrid container spacing={1}>
                      <CustomGrid item xs={12}>
                        <SelectForm
                          name="product_id_current"
                          label="Produto atual"
                          values={products.map(product => {
                            return {
                              id: product.id,
                              name: product.name,
                            };
                          })}
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <InputNumber
                          id="value_current"
                          name="value_current"
                          label="Valor atual"
                          thousandSeparator="."
                          decimalSeparator=","
                          isNumericString
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <InputNumber
                          id="anniversary_date"
                          name="anniversary_date"
                          label="Data de aniversário (contrato)"
                          format="##/##/####"
                          mask="_"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                    </CustomGrid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                  >
                    <Typography color="primary" variant="button">
                      Empresa
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <CustomGrid container spacing={1}>
                      <CustomGrid item xs={12}>
                        <Input
                          id="company_name"
                          name="company_name"
                          label="Nome"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <InputNumber
                          id="company_employer_number"
                          name="company_employer_number"
                          label="CNPJ"
                          format="##.###.###/####-##"
                          mask="_"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <Input
                          id="company_segment"
                          name="company_segment"
                          label="Segmento"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                    </CustomGrid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                  >
                    <Typography color="primary" variant="button">
                      Pessoa
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <CustomGrid container spacing={1}>
                      <CustomGrid item xs={6}>
                        <Input
                          id="client_name"
                          name="client_name"
                          label="Nome do cliente"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <InputNumber
                          id="client_social_number"
                          name="client_social_number"
                          label="CPF"
                          format="###.###.###-##"
                          mask="_"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                    </CustomGrid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                  >
                    <Typography color="primary" variant="button">
                      Endereço
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <CustomGrid container spacing={1}>
                      <CustomGrid item xs={6}>
                        <InputNumber
                          id="address_zip_code"
                          name="address_zip_code"
                          label="CEP"
                          format="##.###-###"
                          mask="_"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <Input
                          id="address_state"
                          name="address_state"
                          label="Estado"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <Input
                          id="address_city"
                          name="address_city"
                          label="Cidade"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <Input
                          id="address_neighborhood"
                          name="address_neighborhood"
                          label="Bairro"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <Input
                          id="address_street"
                          name="address_street"
                          label="Rua"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                      <CustomGrid item xs={6}>
                        <Input
                          id="address_complement"
                          name="address_complement"
                          label="Complemento"
                          disabled={viewingMode}
                        />
                      </CustomGrid>
                    </CustomGrid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Content>
            </CustomGrid>
            <CustomGrid item xs={6}>
              <Content>
                <Paper
                  style={{
                    borderRadius: 0,
                  }}
                >
                  <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab
                      label="Anotações"
                      value={0}
                      onClick={() => setSelectedTab(0)}
                    />
                    <Tab
                      label="Anexos"
                      value={1}
                      onClick={() => setSelectedTab(1)}
                    />
                    <Tab
                      label="Logs"
                      value={2}
                      onClick={() => setSelectedTab(2)}
                    />
                    <Tab
                      label="Atividades"
                      value={3}
                      onClick={() => setSelectedTab(3)}
                    />
                  </Tabs>
                  {selectedTab === 0 && currentNegotiationFunnel && (
                    <TabLogbooks
                      negotiation_id={currentNegotiationFunnel.negotiation_id}
                      funnel_id={currentNegotiationFunnel.funnel_id}
                      status={status}
                      annotationPerspective
                    />
                  )}
                  {selectedTab === 1 && currentNegotiationFunnel && (
                    <TabFiles
                      negotiation_id={currentNegotiationFunnel.negotiation_id}
                      funnel_id={currentNegotiationFunnel.funnel_id}
                      status={status}
                      refresh={refreshFiles}
                    />
                  )}
                  {selectedTab === 2 && currentNegotiationFunnel && (
                    <TabLogbooks
                      negotiation_id={currentNegotiationFunnel.negotiation_id}
                      funnel_id={currentNegotiationFunnel.funnel_id}
                      status={status}
                      refresh={refreshLogs}
                    />
                  )}
                  {selectedTab === 3 && currentNegotiationFunnel && (
                    <Schedule
                      negotiation_id={currentNegotiationFunnel.negotiation_id}
                      contact_id={negotiation.contact.id}
                      contact_name={negotiation.contact.name}
                      disabledContact
                      disabledNegotiation
                      small
                      height="371px"
                    />
                  )}
                </Paper>
              </Content>
            </CustomGrid>
          </CustomGrid>
        </Form>
      )}
    </Container>
  );
};

export default ClientPage;
