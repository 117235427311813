import styled from 'styled-components';

import { ExpansionPanel } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  border-radius: 4px;
`;

export const SearchRow = styled.div`
  padding-top: 16px;
`;

export const Header = styled.div`
  padding: 8px 16px 16px;
  display: flex;
  button {
    margin-top: 0 !important;
  }
`;

export const CustomExpansionPanel = styled(ExpansionPanel)`
  .MuiExpansionPanelSummary-content {
    margin: 0;
    width: 100vh;
  }
  .MuiList-root {
    width: 100%;
  }
  .MuiListItem-root {
    padding: 0;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .MuiList-padding {
    padding: 0;
  }
`;
