import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  margin-bottom: 8px;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #0000008a;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 208px;

  > span {
    margin: 0 8px;
  }
`;

export { Container, Header, Content };
