import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const Container = styled(Box)<{
  mobile: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 8px 16px;
  background-color: #6fa7ba;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  button {
    margin-top: 0 !important;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: row;

    div + div {
      margin-left: ${props => (props.mobile ? '12px' : '8px')};
    }
  }
`;

export const LeftActionsRow = styled.div<{
  mobile: boolean;
}>`
  button {
    margin-right: ${props => (props.mobile ? '12px' : '8px')};
  }
`;
