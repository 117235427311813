import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';

import { useSession } from '../../../hooks/SessionContext';
import {
  useMessageTemplate,
  TemplateMessageDTO,
} from '../../../hooks/MessageTemplateContext';

import PermissionType from '../../../models/PermissionType';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';
import MessageTemplateStatus from '../../../components/MessageTemplateStatus';

import Settings from '..';
import AddEditTemplateMessages from './AddEditTemplateMessages';

import { Header, SearchRow, CustomExpansionPanel } from './styles';

const TemplateMessages: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);

  const { messageTemplates } = useMessageTemplate();
  const [filteredTemplateMessages, setFilteredTemplateMessages] = useState<
    TemplateMessageDTO[]
  >([]);

  const { user } = useSession();

  useEffect(() => {
    setFilteredTemplateMessages(messageTemplates);
  }, [messageTemplates]);

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredTemplateMessages(
        messageTemplates.filter(
          messageTemplate =>
            searched.length === 0 ||
            messageTemplate.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [messageTemplates],
  );

  return (
    <Settings route="template-messages">
      <PageTitle pageName="Mensagens de Template" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      {user.permissions.includes(PermissionType.AdminUser) && (
        <Header>
          {isNew ? (
            <AddEditTemplateMessages setIsNew={setIsNew} />
          ) : (
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                fullWidth
                onClick={() => setIsNew(true)}
              >
                <Add fontSize="small" />
                Mensagem
              </Button>
            </div>
          )}
        </Header>
      )}
      {filteredTemplateMessages.map(messageTemplate => (
        <CustomExpansionPanel key={messageTemplate.name}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <List>
              <ListItem key={`${messageTemplate.name}_listitem`}>
                <ListItemText
                  primary={messageTemplate.name}
                  secondary={messageTemplate.body}
                />
                <MessageTemplateStatus status={messageTemplate.status} />
              </ListItem>
            </List>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <AddEditTemplateMessages
                  messageTemplate={messageTemplate}
                  setIsNew={setIsNew}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </CustomExpansionPanel>
      ))}
    </Settings>
  );
};

export default TemplateMessages;
