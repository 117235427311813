import React, { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import { SwapHoriz, Send } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';

import handleResponseError from '../../utils/handleResponseError';

import { useWindowDimensions } from '../../hooks/WindowDimensionsContext';

import api from '../../services/api';

import NegotiationStatus from '../../models/NegotiationStatus';
import NegotiationDateRange from '../../models/NegotiationDateRange';

import FilterDTO from '../../dtos/FilterDTO';

import FilterHeader from '../../components/FilterHeader';
import ButtonOpacity from '../../components/ButtonOpacity';
import MessageTemplateDialog from '../Chat/Contact/Message/MessageTemplateDialog';

import TransferBulkDialog from './TransferBulkDialog';

import { Container } from './styles';

type BulkTransferDTO = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  hierarchy: {
    id: string;
    name: string;
  };
  last_funnel: {
    id: string;
    name: string;
    status: NegotiationStatus;
    step: {
      id: string;
      name: string;
    };
    created_at: string;
    responsible: {
      id: string;
      name: string;
    } | null;
  };
};

type DataRow = {
  id: string;
  name: string;
  funnel: string;
  step: string;
  created_at: string;
  hierarchy: string;
  responsible?: string;
};

const NegotiationsListView: React.FC = () => {
  const TAKE = 100;

  const [showTransferDialog, setShowTransferDialog] = useState<boolean>(false);
  const [showTemplateDialog, setShowTemplateDialog] = useState<boolean>(false);
  const [negotiationsId, setNegotiationsId] = useState<string[]>([]);
  const [rows, setRows] = useState<DataRow[]>([]);
  const [filters, setFilters] = useState<FilterDTO>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: TAKE,
  });
  const [pagination, setPagination] = React.useState({
    count: 0,
    take: TAKE,
    skip: 0,
  });
  const [
    rowSelectionModel,
    setRowSelectionModel,
  ] = React.useState<GridRowSelectionModel>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { windowDimensions } = useWindowDimensions();

  const parseNegotiationToDataRow = useCallback(
    (negotiation: BulkTransferDTO): DataRow => {
      const { id, name, created_at, hierarchy, last_funnel } = negotiation;

      return {
        id,
        name,
        hierarchy: hierarchy.name,
        funnel: last_funnel.name,
        step: last_funnel.step.name,
        responsible: last_funnel.responsible?.name,
        created_at: format(parseISO(created_at), 'dd/MM/yyyy HH:mm'),
      };
    },
    [],
  );

  const loadNegotiations = useCallback(
    async (filters: FilterDTO, page?: number) => {
      try {
        setLoading(true);

        setFilters(filters);

        const {
          hierarchy,
          userId,
          funnelId,
          productId,
          channelId,
          status,
          dateStart,
          dateEnd,
          name,
        } = filters;

        const { data, headers } = await api.get<BulkTransferDTO[]>(
          'bulk-transfers',
          {
            params: {
              take: TAKE,
              skip: page || paginationModel.page,
              user_id: userId,
              user_hierarchy: hierarchy ? '1' : '0',
              funnel_id: funnelId,
              product_id: productId,
              channel_id: channelId,
              status,
              start_date: dateStart,
              end_date: dateEnd,
              name,
            },
          },
        );

        const {
          pagination_total_count: count,
          pagination_take: take,
          pagination_skip: skip,
        } = headers;

        setPagination({
          count,
          take,
          skip,
        });

        setRows(data.map(parseNegotiationToDataRow));
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, parseNegotiationToDataRow, paginationModel],
  );

  const refreshNegotiations = useCallback(() => {
    setShowTransferDialog(false);
    loadNegotiations(filters);
    setRowSelectionModel([]);
  }, [loadNegotiations, filters]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Negociação', flex: 1 },
    { field: 'funnel', headerName: 'Funil', flex: 1 },
    { field: 'step', headerName: 'Etapa', flex: 1 },
    { field: 'created_at', headerName: 'Criada em', flex: 1 },
    { field: 'hierarchy', headerName: 'Hierarquia', flex: 1 },
    {
      field: 'responsible',
      headerName: 'Responsável',
      flex: 1,
    },
  ];

  const selectionTitle = useMemo((): string => {
    if (negotiationsId.length === 0) return 'Nenhuma negociação selecionada';

    return `${negotiationsId.length} ${
      negotiationsId.length > 1
        ? 'negociações selecionadas'
        : 'negociação selecionada'
    }`;
  }, [negotiationsId]);

  const apiRef = useGridApiRef();

  const getSelectedRows = useCallback((): string[] => {
    const keys = apiRef.current.getSelectedRows().keys();

    return Array.from(keys).map(row => row.toString().split('.')[0]);
  }, [apiRef]);

  return (
    <>
      <TransferBulkDialog
        open={showTransferDialog}
        negotiationsId={negotiationsId}
        selectionTitle={selectionTitle}
        handleOk={refreshNegotiations}
        handleCancel={() => setShowTransferDialog(false)}
      />
      <MessageTemplateDialog
        open={showTemplateDialog}
        negotiationsId={negotiationsId}
        selectionTitle={selectionTitle}
        setOpen={setShowTemplateDialog}
      />
      <FilterHeader
        handleChartFilters={loadNegotiations}
        hiddenStatusFilter={false}
        hiddenNameFilter={false}
        dateRangeDefault={NegotiationDateRange.ThisMonth}
        leftActions={{
          ...(
            <>
              <ButtonOpacity
                startIcon={<SwapHoriz />}
                iconSize="20px"
                onClick={() => {
                  setNegotiationsId(getSelectedRows());

                  setShowTransferDialog(true);
                }}
                disabled={
                  filters.status === NegotiationStatus.Won ||
                  filters.status === NegotiationStatus.Lost
                }
              >
                Transferir
              </ButtonOpacity>
              <span
                style={{
                  width: windowDimensions.resolution === 'mobile' ? 12 : 8,
                }}
              />
              <ButtonOpacity
                startIcon={<Send />}
                onClick={() => {
                  setNegotiationsId(getSelectedRows());

                  setShowTemplateDialog(true);
                }}
              >
                Mensagem
              </ButtonOpacity>
            </>
          ),
        }}
      />
      <Container>
        <DataGrid
          apiRef={apiRef}
          loading={loading}
          rows={rows}
          columns={columns}
          getRowId={row => `${row.id}.${row.hierarchy}`}
          checkboxSelection
          paginationModel={paginationModel}
          pageSizeOptions={[TAKE]}
          rowCount={pagination.count}
          paginationMode="server"
          onPaginationModelChange={model => {
            setPaginationModel(model);
            loadNegotiations(filters, model.page);
          }}
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          keepNonExistentRowsSelected
          localeText={{
            noRowsLabel: 'Nenhuma negociação encontrada. Verifique os filros.',
            noResultsOverlayLabel: 'Nenhum resultado encontrado',
            footerTotalRows: 'Total Negociações',
            footerRowSelected: count =>
              count <= 1
                ? `${count} negociação selecionada`
                : `${count} negociações selecionadas`,
            footerTotalVisibleRows: (visibleCount, totalCount) =>
              `${visibleCount} de ${totalCount}`,
            columnMenuSortAsc: 'Ordenação crescente',
            columnMenuSortDesc: 'Ordenação decrescente',
            columnHeaderSortIconLabel: 'Ordenar',
            columnsPanelShowAllButton: 'Mostrar todas',
            columnsPanelHideAllButton: 'Ocultar todas',
            columnMenuManageColumns: 'Gerenciar colunas',
            columnMenuHideColumn: 'Ocultar coluna',
            columnMenuFilter: 'Filtrar',
            filterPanelOperator: 'Operador',
            filterPanelColumns: 'Coluna',
            filterPanelInputLabel: 'Valor',
          }}
        />
      </Container>
    </>
  );
};

export default NegotiationsListView;
