import styled from 'styled-components';

export const GroupItens = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  width: 100%;
  padding: 12px 16px 8px;

  button {
    margin-left: 8px;
  }
`;
