import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { useSnackbar } from 'notistack';

import { useLoader } from './LoaderContext';

import api from '../services/api';

import handleResponseError from '../utils/handleResponseError';

import DefaultFile from '../models/DefaultFile';

export type CreateDefaultFileDTO = {
  name: string;
  file: File;
};

export type UpdateDefaultFileDTO = {
  id: string;
  name: string;
  file: File | null;
};

export type DeleteDefaultFileDTO = {
  id: string;
};

export type DefaultFileBase64DTO = {
  id: string;
};

type State = {
  defaultFiles: DefaultFile[];
  handleCreateDefaultFile: (data: CreateDefaultFileDTO) => Promise<void>;
  handleUpdateDefaultFile: (data: UpdateDefaultFileDTO) => Promise<void>;
  handleDeleteDefaultFile: (data: DeleteDefaultFileDTO) => Promise<void>;
  getDefaultFileBase64: (data: DefaultFileBase64DTO) => Promise<string>;
};

const DefaultFilesContext = createContext<State>({} as State);

const DefaultFilesProvider: React.FC = ({ children }) => {
  const [defaultFiles, setDefaultFiles] = useState<DefaultFile[]>([]);

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadDefaultFiles = async () => {
      try {
        setLoading(true);

        const { data } = await api.get<DefaultFile[]>('default-files');

        setDefaultFiles(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadDefaultFiles();
  }, [enqueueSnackbar, setLoading]);

  const handleCreateDefaultFile = useCallback(
    async ({ name, file }: CreateDefaultFileDTO): Promise<void> => {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await api.post<DefaultFile[]>(
        'default-files',
        formData,
        {
          params: {
            name,
          },
        },
      );

      setDefaultFiles(data);
    },
    [],
  );

  const handleUpdateDefaultFile = useCallback(
    async ({ id, name, file }: UpdateDefaultFileDTO): Promise<void> => {
      const formData = new FormData();

      if (file !== null) formData.append('file', file);

      const { data } = await api.put<DefaultFile[]>(
        `default-files/${id}`,
        formData,
        {
          params: {
            name,
          },
        },
      );

      setDefaultFiles(data);
    },
    [],
  );

  const handleDeleteDefaultFile = useCallback(
    async ({ id }: DeleteDefaultFileDTO): Promise<void> => {
      const { data } = await api.delete<DefaultFile[]>(`default-files/${id}`);

      setDefaultFiles(data);
    },
    [],
  );

  const getDefaultFileBase64 = useCallback(
    async ({ id }: DefaultFileBase64DTO): Promise<string> => {
      const { data } = await api.get<{
        base64: string;
      }>(`default-files/${id}`);

      return data.base64;
    },
    [],
  );

  return (
    <DefaultFilesContext.Provider
      value={{
        defaultFiles,
        handleCreateDefaultFile,
        handleUpdateDefaultFile,
        handleDeleteDefaultFile,
        getDefaultFileBase64,
      }}
    >
      {children}
    </DefaultFilesContext.Provider>
  );
};

const useDefaultFiles = (): State => {
  const context = useContext(DefaultFilesContext);

  if (!context) {
    throw new Error(
      'useDefaultFiles must be used within a DefaultFilesProvider',
    );
  }

  return context;
};

export { DefaultFilesContext, DefaultFilesProvider, useDefaultFiles };
