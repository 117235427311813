import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useSnackbar } from 'notistack';

import api from '../services/api';

import { useLoader } from './LoaderContext';

import handleResponseError from '../utils/handleResponseError';

type Credential = {
  api_key: string;
  created_at: string;
};

interface State {
  credential: Credential | null;
  handleCreateCredential: () => Promise<void>;
}

export const CredentialContext = createContext<State>({} as State);

export const CredentialProvider: React.FC = ({ children }) => {
  const [credential, setCredential] = useState<Credential | null>(null);

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadCredential = async () => {
      try {
        setLoading(true);

        const { data } = await api.get<Credential | null>('credential');

        setCredential(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadCredential();
  }, [enqueueSnackbar, setLoading]);

  const handleCreateCredential = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const { data } = await api.post<Credential>('credential');

      setCredential(data);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading]);

  return (
    <CredentialContext.Provider
      value={{
        credential,
        handleCreateCredential,
      }}
    >
      {children}
    </CredentialContext.Provider>
  );
};

export const useCredential = (): State => {
  const context = useContext(CredentialContext);

  if (!context) {
    throw new Error('useCredential must be used within a CredentialProvider');
  }

  return context;
};
