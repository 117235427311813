import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 360px;
  min-height: 360px;
  background-color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 8px;

  @media screen and (min-width: 1440px) {
    width: calc(50% - 16px);
  }

  @media screen and (min-width: 2160px) {
    width: calc((100% / 3) - 16px);
  }
`;

export const Title = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
`;

export const Content = styled.div`
  width: 100%;
  height: 330px;

  .recharts-responsive-container {
    width: 100%;
    height: 100%;
  }

  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
  }
`;
