import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const MenuItemName = styled(Typography)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export { MenuItemName };
