import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  AddOutlined,
  FilterListOutlined,
  PaymentOutlined,
  EmojiPeopleOutlined,
  MonetizationOnOutlined,
  DateRangeOutlined,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import { FunnelContext } from '../../../hooks/FunnelContext';
import { StatusContext } from '../../../hooks/StatusContext';
import { PeriodContext } from '../../../hooks/PeriodContext';
import { useResponsible } from '../../../hooks/ResponsibleContext';
import { useLoader } from '../../../hooks/LoaderContext';
import { useWindowDimensions } from '../../../hooks/WindowDimensionsContext';

import api from '../../../services/api';

import handleResponseError from '../../../utils/handleResponseError';

import NegotiationStatus from '../../../models/NegotiationStatus';
import NegotiationDateRange from '../../../models/NegotiationDateRange';
import MailingTarget from '../../../models/MailingTarget';

import ButtonOpacity from '../../../components/ButtonOpacity';
import SelectOpacity from '../../../components/SelectOpacity';
import InputGenericSearch from '../../../components/InputGenericSearch';
import MailingDialog from '../../../components/MailingDialog';

import SelectUsersGroup from '../SelectUsersGroup';
import CreateClientDialog from '../../Client/CreateClientDialog';

import { Container, LeftActionsRow } from './styles';

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<NegotiationStatus>>;
}

const Header: React.FC<Props> = ({ setStatus }) => {
  const [openCreateClientDialog, setOpenCreateClientDialog] = useState<boolean>(
    false,
  );
  const [mailingTarget, setMailingTarget] = useState<MailingTarget>();
  const {
    funnels,
    selectedFunnel,
    selectedProduct,
    selectedChannel,
    selectFunnel,
    selectProduct,
    selectChannel,
  } = useContext(FunnelContext);
  const { selectedResponsible, users, selectResponsible } = useResponsible();
  const { selectedStatus, selectStatus } = useContext(StatusContext);
  const {
    selectedPeriod,
    selectPeriod,
    selectDateRange,
    defineDateRange,
  } = useContext(PeriodContext);
  const { windowDimensions } = useWindowDimensions();
  const [openChoosePeriod, setOpenChoosePeriod] = useState<boolean>(false);
  const [dateStart, setDateStart] = useState<Date>(new Date());
  const [dateEnd, setDateEnd] = useState<Date>(new Date());

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  useEffect(() => {
    if (!selectedFunnel && funnels.length > 0) selectFunnel(funnels[0].id);
  }, [selectFunnel, selectedFunnel, funnels]);

  const handleCancel = () => {
    setOpenChoosePeriod(false);
  };

  const handleOk = () => {
    if (dateStart <= dateEnd) {
      defineDateRange(dateStart, dateEnd);
      setOpenChoosePeriod(false);
    }
  };

  const handleChangeFunnel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      if (selectedResponsible && !selectedResponsible.user.funnels.includes(id))
        selectResponsible({
          team: false,
          user: users[0],
        });

      selectFunnel(id);
    },
    [selectFunnel, selectResponsible, selectedResponsible, users],
  );

  const handleChangeProduct = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      selectProduct(id === 'all' ? undefined : id);
    },
    [selectProduct],
  );

  const handleChangeChannel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      selectChannel(id === 'all' ? undefined : id);
    },
    [selectChannel],
  );

  const handleChangeStatus = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setStatus(event.target.value as NegotiationStatus);
      selectStatus(event.target.value as NegotiationStatus);
    },
    [setStatus, selectStatus],
  );

  const handleChangeRange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const negotiationDateRange = event.target.value as NegotiationDateRange;
      selectPeriod(negotiationDateRange);
      selectDateRange(negotiationDateRange);
      if (negotiationDateRange === NegotiationDateRange.CustomDate) {
        setOpenChoosePeriod(true);
      }
    },
    [selectPeriod, selectDateRange],
  );

  const handleOpenChoosePeriod = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const negotiationDateRange = event.target.value as NegotiationDateRange;
      if (negotiationDateRange === NegotiationDateRange.CustomDate) {
        setOpenChoosePeriod(true);
      }
    },
    [],
  );

  const handleRandomMailingTarget = useCallback(async () => {
    try {
      if (selectedFunnel) {
        setLoading(true);

        const { data } = await api.get<MailingTarget>(
          `/mailings/targets?funnel_id=${selectedFunnel.id}`,
        );

        setMailingTarget(data);
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, selectedFunnel]);

  return (
    <>
      <Container mobile={windowDimensions.resolution === 'mobile'}>
        {mailingTarget && (
          <MailingDialog
            open={!!mailingTarget}
            handleCancel={() => setMailingTarget(undefined)}
            handleCreateNegotiation={() => setOpenCreateClientDialog(true)}
            handleRandomMailingTarget={handleRandomMailingTarget}
            mailingTarget={mailingTarget}
          />
        )}
        {openCreateClientDialog && (
          <CreateClientDialog
            open={openCreateClientDialog}
            setOpen={setOpenCreateClientDialog}
            mailingTarget={mailingTarget}
            funnel_id={selectedFunnel?.id}
          />
        )}
        <LeftActionsRow mobile={windowDimensions.resolution === 'mobile'}>
          <ButtonOpacity
            startIcon={<AddOutlined />}
            onClick={() => setOpenCreateClientDialog(true)}
          >
            Cliente
          </ButtonOpacity>
          <ButtonOpacity
            startIcon={<AddOutlined />}
            onClick={handleRandomMailingTarget}
          >
            Mailing
          </ButtonOpacity>
        </LeftActionsRow>
        <div>
          <InputGenericSearch />
          <SelectOpacity
            id="funnel"
            label="Funil"
            value={selectedFunnel?.id ?? ''}
            values={funnels
              .filter(
                funnel =>
                  !selectedResponsible ||
                  selectedResponsible.user.funnels.includes(funnel.id),
              )
              .map(funnel => {
                return {
                  id: funnel.id,
                  name: funnel.name,
                };
              })}
            onChange={handleChangeFunnel}
            startAdornment={<FilterListOutlined />}
          />
          {selectedFunnel && selectedFunnel.products.length > 0 && (
            <SelectOpacity
              id="product"
              label="Product"
              value={selectedProduct?.id ?? 'all'}
              values={[
                {
                  id: 'all',
                  name: 'Qualquer',
                },
                ...selectedFunnel.products,
              ].map(product => {
                return {
                  id: product.id,
                  name: product.name,
                };
              })}
              onChange={handleChangeProduct}
              startAdornment={<PaymentOutlined />}
            />
          )}

          {selectedFunnel && selectedFunnel.channels.length > 0 && (
            <SelectOpacity
              id="channel"
              label="Channel"
              value={selectedChannel?.id ?? 'all'}
              values={[
                {
                  id: 'all',
                  name: 'Qualquer',
                },
                ...selectedFunnel.channels,
              ].map(channel => {
                return {
                  id: channel.id,
                  name: channel.name,
                };
              })}
              onChange={handleChangeChannel}
              startAdornment={<EmojiPeopleOutlined />}
            />
          )}
          {selectedFunnel && <SelectUsersGroup funnel_id={selectedFunnel.id} />}
          <SelectOpacity
            id="status"
            label="Situação"
            value={selectedStatus}
            values={[
              {
                id: NegotiationStatus.InProgress,
                name: 'Em aberto',
              },
              {
                id: NegotiationStatus.Won,
                name: 'Ganho',
              },
              {
                id: NegotiationStatus.Lost,
                name: 'Perdido',
              },
            ]}
            onChange={handleChangeStatus}
            startAdornment={<MonetizationOnOutlined />}
          />
          <SelectOpacity
            id="range"
            label="Período"
            value={selectedPeriod}
            values={[
              {
                id: NegotiationDateRange.All,
                name: 'Todos',
              },
              {
                id: NegotiationDateRange.Today,
                name: 'Hoje',
              },
              {
                id: NegotiationDateRange.Yesterday,
                name: 'Ontem',
              },
              {
                id: NegotiationDateRange.ThisWeek,
                name: 'Esta semana',
              },
              {
                id: NegotiationDateRange.LastWeek,
                name: 'Semana passada',
              },
              {
                id: NegotiationDateRange.ThisMonth,
                name: 'Este mês',
              },
              {
                id: NegotiationDateRange.LastMonth,
                name: 'Mês passado',
              },
              {
                id: NegotiationDateRange.CustomDate,
                name: 'Data Personalizada',
              },
            ]}
            onChange={handleChangeRange}
            onClick={() => handleOpenChoosePeriod}
            startAdornment={<DateRangeOutlined />}
          />
        </div>
      </Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openChoosePeriod}
      >
        <DialogTitle id="confirmation-dialog-title">
          Data Personalizada
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {dateStart.getTime() > dateEnd.getTime() && (
              <Alert severity="error">
                A data inicio não pode ser maior que a data fim!
              </Alert>
            )}
            <Grid item xs={12}>
              Selecione o periodo
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="dataInicio"
                label="Data Inicio"
                type="date"
                defaultValue={format(dateStart, 'yyyy-MM-dd')}
                onChange={event => setDateStart(new Date(event.target.value))}
                style={{
                  marginLeft: '1px',
                  marginRight: '1px',
                  width: 200,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="dataFim"
                label="Data Fim"
                type="date"
                defaultValue={format(dateEnd, 'yyyy-MM-dd')}
                onChange={event => setDateEnd(new Date(event.target.value))}
                style={{
                  marginLeft: '1px',
                  marginRight: '1px',
                  width: 200,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
