import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { useSnackbar } from 'notistack';

import handleResponseError from '../utils/handleResponseError';

import api from '../services/api';

import { useSession } from './SessionContext';

import PermissionType from '../models/PermissionType';

type PaginationHeader = {
  pagination_take: string;
  pagination_skip: string;
  pagination_total_count: string;
};

export interface UserDTO {
  id: string;
  name: string;
  email: string;
  phone: string;
  active: boolean;
  permissions: string[];
  funnels: string[];
}

type UserNameDTO = {
  [id: string]: string;
};

export interface ResponsibleDTO {
  user: UserDTO;
  team: boolean;
}

interface State {
  users: UserDTO[];
  teams: UserDTO[];
  usersName: UserNameDTO;
  selectedResponsible?: ResponsibleDTO;
  selectResponsible: (responsible: ResponsibleDTO) => void;
  loadResponsibles: () => Promise<void>;
}

export const ResponsibleContext = createContext<State>({} as State);

export const ResponsibleProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [teams, setTeams] = useState<UserDTO[]>([]);
  const [usersName, setUsersName] = useState<UserNameDTO>({});

  const [
    selectedResponsible,
    setSelectedResponsible,
  ] = useState<ResponsibleDTO>();

  const { user } = useSession();

  const { enqueueSnackbar } = useSnackbar();

  const loadResponsibles = useCallback(async () => {
    try {
      if (!user) return;

      const take = 20;
      let skip = 0;
      let count: number | undefined;

      const users: UserDTO[] = [];

      while (!count || count > skip) {
        const { headers, data } = await api.get<UserDTO[]>('users', {
          params: {
            take,
            skip,
          },
        });

        users.push(...data);

        const { pagination_total_count } = headers as PaginationHeader;

        count = Number(pagination_total_count);

        skip += take;
      }

      const sortedUsers = users.sort((u1, u2) => {
        if (u1.name < u2.name && u2.id !== user.id) return -1;

        if (u1.name > u2.name) return 1;

        return 0;
      });

      if (sortedUsers.length !== 0) {
        const defaultUser = sortedUsers.find(
          sortedUser => sortedUser.id === user.id,
        );

        if (defaultUser)
          setSelectedResponsible({
            user: defaultUser,
            team: false,
          });
      }

      const mappedResponsibles = sortedUsers.map(user =>
        user.active
          ? user
          : {
              ...user,
              name: `${user.name} (Inativo)`,
            },
      );

      setUsers(mappedResponsibles);

      const mappedTeams = mappedResponsibles
        .filter(
          responsible =>
            responsible.active &&
            responsible.permissions.includes(PermissionType.Superior),
        )
        .map(responsible => ({
          ...responsible,
          name: `${responsible.name} (Equipe)`,
        }));

      setTeams(mappedTeams);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, user]);

  useEffect(() => {
    loadResponsibles();
  }, [loadResponsibles]);

  useEffect(() => {
    const loadUsersName = async () => {
      try {
        const { data } = await api.get<UserNameDTO>('users/names');

        setUsersName(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    };

    loadUsersName();
  }, [enqueueSnackbar]);

  const selectResponsible = useCallback((responsible: ResponsibleDTO) => {
    setSelectedResponsible(responsible);
  }, []);

  return (
    <ResponsibleContext.Provider
      value={{
        users,
        teams,
        usersName,
        selectedResponsible,
        selectResponsible,
        loadResponsibles,
      }}
    >
      {children}
    </ResponsibleContext.Provider>
  );
};

export const useResponsible = (): State => {
  const context = useContext(ResponsibleContext);

  if (!context) {
    throw new Error('useResponsible must be used within a ResponsibleProvider');
  }

  return context;
};
