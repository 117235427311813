import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  overflow-x: auto;

  /* overscroll-behavior-x: contain; */

  ::-webkit-scrollbar {
    height: 9px;
  }

  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #6fa7ba;
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
  }
`;
