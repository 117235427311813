import React, { useRef, useCallback } from 'react';
import { Paper } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Send } from '@material-ui/icons';

import { useLoader } from '../../../../hooks/LoaderContext';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import { createNegotiationLogbook } from '../../../../services/NegotiationLogbookService';

import NegotiationLogbook from '../../../../models/NegotiationLogbook';
import NegotiationLogbookType from '../../../../models/NegotiationLogbookType';

import Input from '../../../../components/Input';
import {
  ButtonGreenOutlined,
  ButtonIconGreenOutlined,
} from '../../../../styles/button';

import { GroupItens } from './styles';

interface TabLogbooksToolbarDTO {
  negotiation_id: string;
  setNegotiationLogbooks: React.Dispatch<
    React.SetStateAction<NegotiationLogbook[]>
  >;
  small?: boolean;
}

const TabLogbooksToolbar: React.FC<TabLogbooksToolbarDTO> = ({
  negotiation_id,
  setNegotiationLogbooks,
  small,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async (data: { description: string }) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Este campo é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { description } = data;

        setLoading(true);

        const negotiationLogbook = await createNegotiationLogbook({
          negotiation_id,
          description,
          slug: NegotiationLogbookType.CreateAnnotation,
        });

        formRef.current?.reset();

        setNegotiationLogbooks(oldLogbooks => [
          negotiationLogbook,
          ...oldLogbooks,
        ]);

        enqueueSnackbar('Anotação salva com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [negotiation_id, enqueueSnackbar, setNegotiationLogbooks, setLoading],
  );

  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 0,
      }}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <GroupItens>
          <Input
            id="description"
            name="description"
            label="Escreva aqui uma anotação..."
            fullWidth
          />
          {small ? (
            <ButtonIconGreenOutlined
              startIcon={<Send />}
              onClick={() => formRef.current?.submitForm()}
            />
          ) : (
            <ButtonGreenOutlined
              startIcon={<Send />}
              onClick={() => formRef.current?.submitForm()}
            >
              Enviar
            </ButtonGreenOutlined>
          )}
        </GroupItens>
      </Form>
    </Paper>
  );
};

export default TabLogbooksToolbar;
