import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';
import { useSnackbar } from 'notistack';

import apiConfig from '../config/api';

import { ContactDTO } from '../services/ContactService';

import { useSession } from './SessionContext';
import { ContactContext } from './ContactContext';

import Message from '../models/Message';
import MessageStatus from '../models/MessageStatus';
import ChatbotContact from '../models/ChatbotContact';

interface State {
  isSearchMessage: boolean;
  changeSearchMessage(value: boolean): void;
  keySearch: string;
  changeKeySearch(value: string | undefined): void;
  subscribePhone: (phone: string) => void;
}

const MessageContext = createContext<State>({} as State);

const MessageProvider: React.FC = ({ children }) => {
  const [connection, setConnection] = useState<Socket<
    DefaultEventsMap,
    DefaultEventsMap
  > | null>(null);
  const [isSearchMessage, setIsSearchMessage] = useState<boolean>(false);
  const [keySearch, setkeySearch] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSession();
  const {
    updateContact,
    updateChatbot,
    readContact,
    updateMessageStatus,
    addNewContact,
    updateContactConversationExpirationDate,
    updateContactLastReceivedMessageDate,
    removeMessage,
  } = useContext(ContactContext);

  useEffect(() => {
    const socket = io(apiConfig.API_MIDDLEWARE_URL, {
      query: {
        authorization: `Bearer ${token}`,
      },
    });

    socket.on('connect', () => setConnection(socket));
  }, [token]);

  const subscribePhone = useCallback(
    (phone: string): void => {
      connection?.emit('subscribe_phone', phone);
    },
    [connection],
  );

  useEffect(() => {
    connection?.on('event', (data: Message) => {
      updateContact(data);
    });
  }, [connection, updateContact]);

  useEffect(() => {
    connection?.on('contact', async (contact: ContactDTO) => {
      await addNewContact(contact);
    });
  }, [connection, addNewContact]);

  useEffect(() => {
    connection?.on('chatbot', async (chatbot: ChatbotContact) =>
      updateChatbot(chatbot),
    );
  }, [connection, updateChatbot]);

  useEffect(() => {
    connection?.on(
      'read-contact',
      async ({ phone }: { phone: string; date: Date }) => readContact(phone),
    );
  }, [connection, readContact]);

  useEffect(() => {
    connection?.on(
      'conversationExpirationDate',
      ({ phone, date }: { phone: string; date: string }) =>
        updateContactConversationExpirationDate(phone, date),
    );
  }, [connection, updateContactConversationExpirationDate]);

  useEffect(() => {
    connection?.on(
      'lastReceivedMessageDate',
      ({ phone, date }: { phone: string; date: string }) =>
        updateContactLastReceivedMessageDate(phone, date),
    );
  }, [connection, updateContactLastReceivedMessageDate]);

  useEffect(() => {
    connection?.on(
      'message-status',
      ({
        phone,
        key,
        status,
      }: {
        phone: string;
        key: string;
        status: MessageStatus;
      }) => {
        updateMessageStatus(phone, key, status);
      },
    );
  }, [connection, updateMessageStatus]);

  useEffect(() => {
    connection?.on(
      'message-fail',
      ({
        phone,
        temp_id,
        whatsapp,
        error,
      }: {
        phone: string;
        temp_id: string;
        whatsapp: boolean;
        error: string;
      }) => {
        removeMessage(phone, temp_id, whatsapp);

        enqueueSnackbar(error, {
          variant: 'error',
        });
      },
    );
  }, [connection, removeMessage, enqueueSnackbar]);

  const changeSearchMessage = useCallback(value => {
    setIsSearchMessage(value);
  }, []);

  const changeKeySearch = useCallback(value => {
    setkeySearch(value);
  }, []);

  return (
    <MessageContext.Provider
      value={{
        isSearchMessage,
        changeSearchMessage,
        keySearch,
        changeKeySearch,
        subscribePhone,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export { MessageContext, MessageProvider };
