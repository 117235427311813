import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const GroupItens = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;

  label {
    margin-bottom: 4px;
  }
`;
