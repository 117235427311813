import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding: 8px;
  border: 1px solid #ddd;
`;

export const Item = styled.div<{
  color: string;
}>`
  padding-bottom: 8px;
  color: ${({ color }) => color};
`;

export const Title = styled.p`
  padding-bottom: 8px;
`;

export const TotalValue = styled.p`
  padding-left: 16px;
`;
