import React from 'react';
import { Typography } from '@material-ui/core';

import Message from '../../../../../models/Message';

import MessageName from '../MessageName';
import MessageInfo from '../MessageInfo';

import { Content } from './styles';

type Props = {
  message: Message;
};

const MessageAudio: React.FC<Props> = ({ message }) => {
  return message.base64 ? (
    <>
      <MessageName user_id={message.new_user_id} />
      <Content>
        {message.base64 && <audio src={message.base64} controls />}
        <MessageInfo
          time={message.time}
          status={message.status}
          from_me={message.from_me}
        />
      </Content>
    </>
  ) : (
    <>
      {message.base64_status === 'error' ? (
        <Typography variant="caption">
          Não foi possível carregar áudio.
        </Typography>
      ) : (
        <Typography variant="caption">Carregando áudio...</Typography>
      )}
    </>
  );
};

export default MessageAudio;
