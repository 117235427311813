import React, { createContext, useCallback, useState, useContext } from 'react';
import { addDays } from 'date-fns';
import { uuid } from 'uuidv4';

import api from '../services/api';
import apiWhatsapp from '../services/api_whatsapp';

interface SessionState {
  user: User;
  token: string;
  waba_configured: boolean;
  expired: Date;
}

interface SignInCredentials {
  phone?: string;
  email?: string;
  password: string;
}

interface SignUpCredentials {
  name: string;
  email: string;
  phone: string;
  password: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  phone: string;
  permissions: string[];
}

interface SessionContextProps {
  user: User;
  expired: Date;
  token: string;
  waba_configured: boolean;
  signIn(credentials: SignInCredentials): Promise<void>;
  signUp(credentials: SignUpCredentials): Promise<void>;
  signOut(): void;
  handleChangePermissions(permissions: string[]): void;
  handleChangeWabaConfigured: () => void;
}

export const SessionContext = createContext<SessionContextProps>(
  {} as SessionContextProps,
);

export const SessionProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<SessionState>(() => {
    const user = localStorage.getItem('@grupo-afinidade:user');
    const permissions = localStorage.getItem('@grupo-afinidade:permissions');
    const token = localStorage.getItem('@grupo-afinidade:token');
    const expired = localStorage.getItem('@grupo-afinidade:expired');
    const waba_configured = localStorage.getItem(
      '@grupo-afinidade:waba_configured',
    );

    api.defaults.headers.Authorization = `Bearer ${token}`;
    apiWhatsapp.defaults.headers.Authorization = `Bearer ${token}`;

    if (user && permissions && token && expired && waba_configured)
      return {
        user: {
          ...JSON.parse(user),
          permissions: JSON.parse(permissions),
        },
        token,
        expired: new Date(Number(expired)),
        waba_configured: waba_configured === '1',
      };

    return {} as SessionState;
  });

  const signIn = useCallback(
    async ({ phone, email, password }: SignInCredentials) => {
      const response = await api.post('sessions', {
        phone,
        email,
        password,
      });

      const { user, token, waba_configured }: SessionState = response.data;
      const expired = addDays(new Date(), 1);

      api.defaults.headers.Authorization = `Bearer ${token}`;
      apiWhatsapp.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem('@grupo-afinidade:user', JSON.stringify(user));
      localStorage.setItem(
        '@grupo-afinidade:permissions',
        JSON.stringify(user.permissions),
      );
      localStorage.setItem('@grupo-afinidade:token', token);
      localStorage.setItem(
        '@grupo-afinidade:waba_configured',
        String(waba_configured ? 1 : 0),
      );
      localStorage.setItem(
        '@grupo-afinidade:expired',
        expired.getTime().toString(),
      );

      const data: string | null = localStorage.getItem(
        '@grupo-afinidade:push_notification',
      );
      const parsedData: {
        deviceId: string | null;
        account: string | null;
      } = data
        ? JSON.parse(data)
        : {
            deviceId: null,
            account: null,
          };
      const { account } = parsedData;
      let { deviceId } = parsedData;

      if ('serviceWorker' in navigator) {
        if (!account || account !== user.email) {
          const registrations = await navigator.serviceWorker.getRegistrations();
          await Promise.all(
            registrations.map(registration => registration.unregister()),
          );
        }

        let registration:
          | ServiceWorkerRegistration
          | undefined = await navigator.serviceWorker.getRegistration();

        if (!registration)
          registration = await navigator.serviceWorker.register('sw.js');

        await registration.update();

        let subscription = await registration.pushManager.getSubscription();

        if (subscription && (!account || account !== user.email)) {
          await subscription.unsubscribe();
          subscription = null;
        }

        if (!subscription) {
          const { data: vapidPublicKey } = await api.get<string>(
            '/push-notifications/vapid-pubkey',
          );

          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey,
          });
        }

        if (!deviceId) deviceId = uuid();

        await api.post<void>('/push-notifications/register', {
          subscription,
          deviceId,
        });

        localStorage.setItem(
          '@grupo-afinidade:push_notification',
          JSON.stringify({
            account: user.email,
            deviceId,
          }),
        );
      }

      setData({ user, token, waba_configured, expired });
    },
    [],
  );

  const signUp = useCallback(async ({ name, phone, email, password }) => {
    await api.post('users', {
      name,
      phone,
      email,
      password,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@grupo-afinidade:user');
    localStorage.removeItem('@grupo-afinidade:token');
    localStorage.removeItem('@grupo-afinidade:permissions');
    localStorage.removeItem('@grupo-afinidade:waba_configured');
    localStorage.removeItem('@grupo-afinidade:expired');

    setData({} as SessionState);
  }, []);

  const handleChangePermissions = useCallback((permissions: string[]) => {
    localStorage.setItem(
      '@grupo-afinidade:permissions',
      JSON.stringify(permissions),
    );

    setData(({ user, token, waba_configured, expired }) => {
      return {
        user: {
          ...user,
          permissions,
        },
        token,
        waba_configured,
        expired,
      };
    });
  }, []);

  const handleChangeWabaConfigured = useCallback(() => {
    localStorage.setItem('@grupo-afinidade:waba_configured', '1');

    setData(oldData => ({
      ...oldData,
      waba_configured: true,
    }));
  }, []);

  return (
    <SessionContext.Provider
      value={{
        user: data.user,
        expired: data.expired,
        token: data.token,
        waba_configured: data.waba_configured,
        signIn,
        signUp,
        signOut,
        handleChangePermissions,
        handleChangeWabaConfigured,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = (): SessionContextProps => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }

  return context;
};
