import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import LossReason from '../../../models/LossReason';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import Settings from '..';
import AddEditLossReason from './AddEditLossReason';

import { Header, SearchRow } from './styles';

const LossesReasons: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [lossesReasons, setLossesReasons] = useState<LossReason[]>([]);
  const [filteredLossesReasons, setFilteredLossesReasons] = useState<
    LossReason[]
  >([]);

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const loadLossesReasons = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get<LossReason[]>('losses-reasons');

      setLossesReasons(data);
      setFilteredLossesReasons(data);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading]);

  useEffect(() => {
    loadLossesReasons();
  }, [loadLossesReasons]);

  useEffect(() => {
    if (isNew) setFilteredLossesReasons(lossesReasons);
  }, [isNew, lossesReasons]);

  const refresh = useCallback(async () => {
    await loadLossesReasons();
  }, [loadLossesReasons]);

  const handleAddProduct = () => {
    setIsNew(true);
  };

  const handleOnSave = () => {
    setIsNew(false);
  };

  const handleSearch = useCallback(
    (searched: string) =>
      setFilteredLossesReasons(
        lossesReasons.filter(
          lossReason =>
            searched.length === 0 ||
            lossReason.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      ),
    [lossesReasons],
  );

  return (
    <Settings route="losses-reasons">
      <PageTitle pageName="Motivos de Perda" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      <Header>
        {!isNew ? (
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<Add fontSize="small" />}
              onClick={handleAddProduct}
            >
              Motivo de Perda
            </Button>
          </div>
        ) : (
          <AddEditLossReason handleOnSave={handleOnSave} refresh={refresh} />
        )}
      </Header>
      {filteredLossesReasons.map(lossReason => (
        <ExpansionPanel key={String(lossReason.id)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">{lossReason.name}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <AddEditLossReason lossReason={lossReason} refresh={refresh} />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Settings>
  );
};

export default LossesReasons;
