import React, { useRef, useCallback, useState } from 'react';
import {
  Button,
  Box,
  FormLabel,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api';

import { useFunnel } from '../../../../hooks/FunnelContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import LossReason from '../../../../models/LossReason';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import Input from '../../../../components/Input';

interface Props {
  handleOnSave?: () => void;
  lossReason?: LossReason;
  refresh: () => Promise<void>;
}

interface FormData {
  name: string;
}

const AddEditLossReason: React.FC<Props> = ({
  handleOnSave,
  refresh,
  lossReason,
}) => {
  const [checkedFunnelsIds, setCheckedFunnelsIds] = useState<string[]>(
    lossReason?.funnels?.map(funnel => funnel.id) ?? [],
  );

  const { enqueueSnackbar } = useSnackbar();
  const { funnels } = useFunnel();
  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name } = data;

        const funnels_ids = checkedFunnelsIds.map(id => id);

        if (lossReason) {
          await api.put<LossReason>(`/losses-reasons/${lossReason.id}`, {
            name,
            funnels_ids,
          });
        } else {
          await api.post<LossReason>('/losses-reasons', {
            name,
            funnels_ids,
          });
        }

        refresh();

        handleOnSave?.();

        enqueueSnackbar(
          `Motivo de perda ${lossReason ? 'alterado' : 'salvo'} com sucesso!`,
          {
            variant: 'success',
          },
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleOnSave,
      refresh,
      lossReason,
      checkedFunnelsIds,
    ],
  );

  const handleDelete = useCallback(async () => {
    try {
      if (lossReason) {
        setLoading(true);

        await api.delete(`/losses-reasons/${lossReason.id}`);

        refresh();

        handleOnSave?.();
      }

      enqueueSnackbar('Motivo de perda excluído com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [handleOnSave, enqueueSnackbar, refresh, setLoading, lossReason]);

  const handleCheckAll = useCallback(() => {
    setCheckedFunnelsIds(oldCheckedFunnelsIds =>
      oldCheckedFunnelsIds.length === funnels.length
        ? []
        : funnels.map(funnel => funnel.id),
    );
  }, [funnels]);

  const handleCheckFunnel = useCallback((name: string) => {
    setCheckedFunnelsIds(oldCheckedFunnelsIds =>
      oldCheckedFunnelsIds.includes(name)
        ? [...oldCheckedFunnelsIds.filter(id => id !== name)]
        : [...oldCheckedFunnelsIds, name],
    );
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input
        id="name"
        name="name"
        label="Nome do motivo de perda"
        style={{ maxWidth: '400px' }}
        defaultValue={lossReason?.name}
      />
      <div>
        <Box mb="1rem">
          <FormLabel>
            <Typography variant="h6">Funis</Typography>
          </FormLabel>
          {funnels.length > 0 && (
            <div>
              <Checkbox
                checked={funnels.length === checkedFunnelsIds.length}
                onChange={handleCheckAll}
              />
              <FormLabel>Selecionar todos</FormLabel>
              <Divider />
            </div>
          )}
          {funnels.map(funnel => (
            <div key={funnel.id}>
              <Checkbox
                id={funnel.id}
                name={funnel.id}
                checked={checkedFunnelsIds.includes(funnel.id)}
                onChange={e => handleCheckFunnel(e.target.name)}
              />
              <FormLabel>{funnel.name}</FormLabel>
            </div>
          ))}
        </Box>
      </div>
      <Button
        color="primary"
        size="small"
        variant="contained"
        style={{ borderRadius: '32px', marginRight: '6px' }}
        type="submit"
      >
        {lossReason ? 'Alterar' : 'Salvar'}
      </Button>
      {lossReason ? (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          style={{ borderRadius: '32px' }}
          type="button"
          onClick={() => handleDelete()}
        >
          Excluir
        </Button>
      ) : (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          style={{ borderRadius: '32px' }}
          type="button"
          onClick={() => handleOnSave?.()}
        >
          Cancelar
        </Button>
      )}
    </Form>
  );
};

export default AddEditLossReason;
