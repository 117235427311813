import React, { useState, useEffect, useCallback } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Tooltip,
} from '@material-ui/core';
import { useField } from '@unform/core';

interface RadioGroupCustomProps extends RadioGroupProps {
  name: string;
  options: {
    id: string | number;
    name: string;
    tooltip?: string;
  }[];
  disabled?: boolean;
  getValue?: (value: number | string) => void;
}

const CustomRadioGroup: React.FC<RadioGroupCustomProps> = ({
  name,
  options,
  disabled = false,
  getValue,
  ...rest
}) => {
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState<number>(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
    });
    getValue?.(value);
  }, [registerField, getValue, fieldName, value]);

  const handleValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setValue(Number(e.target.value)),
    [],
  );

  return (
    <FormControl variant="outlined" size="small" fullWidth error={!!error}>
      <RadioGroup id={name} value={value} onChange={handleValue} {...rest}>
        {options.map(option => (
          <Tooltip title={option.tooltip || ''}>
            <FormControlLabel
              value={option.id}
              label={option.name}
              checked={option.id === value}
              control={<Radio />}
              disabled={disabled}
            />
          </Tooltip>
        ))}
      </RadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CustomRadioGroup;
