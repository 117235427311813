import 'dotenv/config';

export default {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:3333',
  //API_URL: process.env.REACT_APP_API_URL || 'https://grupo-afinidade-backend.herokuapp.com/',
  API_MIDDLEWARE_URL: process.env.REACT_APP_API_MIDDLEWARE_URL || 'http://localhost:4001',
  //API_MIDDLEWARE_URL:process.env.REACT_APP_API_MIDDLEWARE_URL || 'https://grupo-afinidade-middleware.herokuapp.com/',
};



 
 
