import styled from 'styled-components';

const Container = styled.section<{
  status: string;
}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => {
    switch (props.status) {
      case 'approved':
        return 'green';
      case 'submitted':
        return '#0066ff';
      case 'pending':
        return '#0066ff';
      default:
        return 'red';
    }
  }};
`;

export { Container };
