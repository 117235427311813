import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Close, Done, InsertDriveFile } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import handleResponseError from '../../utils/handleResponseError';

import { useLoader } from '../../hooks/LoaderContext';

import { Container, FileRow, DocumentRow } from './styles';

interface Props {
  base64: string;
  name?: string;
  confirm: (e: any) => void;
  cancel: (e: any) => void;
}

const PreviewFile: React.FC<Props> = ({ base64, name, confirm, cancel }) => {
  const [file, setFile] = useState<{
    url: string;
    type: string;
  }>();

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  useEffect(() => {
    const loadBlob = async () => {
      try {
        setLoading(true);

        const res = await fetch(base64);
        const blob = await res.blob();

        setFile({
          url: URL.createObjectURL(blob),
          type: blob.type,
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadBlob();
  }, [enqueueSnackbar, setLoading, base64]);

  const isPdf = useMemo(() => {
    if (!file) return false;

    return file.type === 'application/pdf';
  }, [file]);

  const isImage = useMemo(() => {
    if (!file) return false;

    return ['image/png', 'image/jpeg', 'image/webp', 'image/gif'].includes(
      file.type,
    );
  }, [file]);

  const isVideo = useMemo(() => {
    if (!file) return false;

    return ['video/mp4'].includes(file.type);
  }, [file]);

  const isDocument = useMemo(() => {
    return !isPdf && !isImage && !isVideo;
  }, [isPdf, isImage, isVideo]);

  return file ? (
    <Container>
      <IconButton onClick={cancel}>
        <Close />
      </IconButton>
      <FileRow isPdf={isPdf}>
        {isImage && <img alt={name} src={file.url} />}
        {isVideo && <video src={file.url} controls />}
        {isPdf && <embed src={file.url} type={file.type} />}
        {isDocument && (
          <DocumentRow>
            <InsertDriveFile />
            <span>{name}</span>
          </DocumentRow>
        )}
      </FileRow>
      <IconButton onClick={confirm}>
        <Done />
      </IconButton>
    </Container>
  ) : (
    <></>
  );
};

export default PreviewFile;
