import React from 'react';

import { Container } from './styles';

type Props = {
  hidden: boolean;
};

const ChildQuestion: React.FC<Props> = ({ hidden, children }) => (
  <Container hidden={hidden}>{children}</Container>
);

export { ChildQuestion };
