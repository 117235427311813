import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  FormControl,
  Button,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  addMinutes,
  differenceInMinutes,
  endOfMinute,
  format,
  isAfter,
  parseISO,
  setHours,
  setMinutes,
  startOfMinute,
  subDays,
  subHours,
  subMinutes,
} from 'date-fns';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import getValidationErrors from '../../../utils/getValidationErrors';
import handleResponseError from '../../../utils/handleResponseError';

import { useLoader } from '../../../hooks/LoaderContext';
import { useSession } from '../../../hooks/SessionContext';

import api from '../../../services/api';

import Negotiation from '../../../models/Negotiation';

import { ScheduleDTO } from '../types';

import Input from '../../../components/Input';
import RadioGroup from '../../../components/RadioGroup';
import SelectSearch from '../../../components/SelectSearch';
import SelectForm from '../../../components/SelectForm';

import { CustomDialogActions, ResponsibleSchedule } from './styles';

enum ReminderType {
  OnTime = 'No horário',
  FiveMinutesBefore = '5 minutos antes',
  TenMinutesBefore = '10 minutos antes',
  FifteenMinutesBefore = '15 minutos antes',
  ThirtyMinutesBefore = '30 minutos antes',
  FortyFiveMinutesBefore = '45 minutos antes',
  OneHourBefore = '1 hora antes',
  TwoHoursBefore = '2 horas antes',
  OneDayBefore = '1 dia antes',
}

type FormData = {
  date: string;
  reminder_date: string;
  reminder_type: ReminderType;
  description: string;
  message: string;
  type: number;
  negotiation_id: string;
};

type Props = DialogProps & {
  negotiation_id?: string;
  contact_id?: string;
  contact_name?: string;
  chosed_date?: Date;
  schedule?: ScheduleDTO;
  disabledContact: boolean;
  disabledNegotiation: boolean;
  handleCreatedSchedule: (schedule: ScheduleDTO) => void;
  handleUpdatedSchedule: (schedule: ScheduleDTO) => void;
  handleDeletedSchedule: (id: string) => void;
  handleCancel: () => void;
};

const ScheduleDialog: React.FC<Props> = ({
  schedule,
  chosed_date,
  negotiation_id,
  contact_id,
  contact_name,
  disabledContact,
  disabledNegotiation,
  handleCreatedSchedule,
  handleUpdatedSchedule,
  handleDeletedSchedule,
  handleCancel,
  ...rest
}) => {
  const [selectedType, setSelectedType] = useState<number | string>(1);
  const [contactId, setContactId] = useState<string | undefined>(
    schedule?.negotiation?.contact.id,
  );
  const [negotiations, setNegotiations] = useState<Negotiation[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const { user } = useSession();

  const formRef = useRef<FormHandles>(null);

  const defaultContact = useMemo(():
    | { id: string; label: string }
    | undefined => {
    if (schedule)
      return {
        id: schedule.negotiation.contact.id,
        label: schedule.negotiation.contact.name,
      };

    if (contact_id && contact_name)
      return {
        id: contact_id,
        label: contact_name,
      };

    return undefined;
  }, [schedule, contact_id, contact_name]);

  const defaultFormData = useMemo(() => {
    if (schedule) {
      const { date, reminder_date, description, type } = schedule;

      const reminderMinutes = differenceInMinutes(
        parseISO(date),
        parseISO(reminder_date),
      );

      let reminder_type: ReminderType;

      switch (reminderMinutes) {
        case 0:
          reminder_type = ReminderType.OnTime;
          break;
        case 5:
          reminder_type = ReminderType.FiveMinutesBefore;
          break;
        case 10:
          reminder_type = ReminderType.TenMinutesBefore;
          break;
        case 15:
          reminder_type = ReminderType.FifteenMinutesBefore;
          break;
        case 30:
          reminder_type = ReminderType.ThirtyMinutesBefore;
          break;
        case 45:
          reminder_type = ReminderType.FortyFiveMinutesBefore;
          break;
        case 60:
          reminder_type = ReminderType.OneHourBefore;
          break;
        case 60 * 2:
          reminder_type = ReminderType.TwoHoursBefore;
          break;
        case 60 * 24:
          reminder_type = ReminderType.OneDayBefore;
          break;
        default:
          reminder_type = ReminderType.FiveMinutesBefore;
          break;
      }

      return {
        negotiation_id: schedule.negotiation_id,
        date: format(parseISO(date), "yyyy-MM-dd'T'HH:mm"),
        reminder_type,
        description,
        type,
      };
    }

    let date = new Date();

    if (chosed_date)
      date = setHours(
        setMinutes(chosed_date, date.getMinutes()),
        date.getHours(),
      );

    return {
      negotiation_id,
      date: format(endOfMinute(addMinutes(date, 60)), "yyyy-MM-dd'T'HH:mm"),
      reminder_type: ReminderType.OnTime,
      type: 1,
    };
  }, [schedule, negotiation_id, chosed_date]);

  const isPassedDate = useMemo((): boolean => {
    if (schedule)
      return !isAfter(
        startOfMinute(parseISO(schedule.date)),
        endOfMinute(new Date()),
      );

    return false;
  }, [schedule]);

  const isPassedReminderDate = useMemo((): boolean => {
    if (schedule)
      return !isAfter(
        startOfMinute(parseISO(schedule.reminder_date)),
        endOfMinute(new Date()),
      );

    return false;
  }, [schedule]);

  const isScheduleProcessed = useMemo(
    (): boolean => isPassedDate && isPassedReminderDate,
    [isPassedDate, isPassedReminderDate],
  );

  const isFromAnotherResponsible = useMemo(
    (): boolean => !!schedule && schedule.user.id !== user.id,
    [schedule, user.id],
  );

  useEffect(() => {
    const loadNegotiationsByContactId = async (): Promise<void> => {
      try {
        setLoading(true);

        if (contactId) {
          const { data } = await api.get<Negotiation[]>(
            `contacts/${contactId}/negotiations`,
            {
              params: {
                status: 'in-progress',
              },
            },
          );

          setNegotiations(data);
        } else {
          setNegotiations([]);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadNegotiationsByContactId();
  }, [enqueueSnackbar, setLoading, contactId]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          contact_id: Yup.string().required('Contato é obrigatório'),
          negotiation_id: Yup.string().required('Negociação é obrigatório'),
          date: Yup.string().required('Data é obrigatório'),
          reminder_type: Yup.string().required('Lembrete é obrigatório'),
          description: Yup.string().required('Descrição é obrigatório'),
          type: Yup.number().required('Tipo é obrigatório'),
          message:
            selectedType === 3
              ? Yup.string().required('Mensagem é obrigatório')
              : Yup.string().notRequired(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          negotiation_id,
          date,
          reminder_type,
          description,
          message,
          type,
        } = data;

        const parsedDate = parseISO(date);

        let parsedReminderDate: Date;

        switch (reminder_type) {
          case ReminderType.OnTime:
            parsedReminderDate = parsedDate;
            break;
          case ReminderType.FiveMinutesBefore:
            parsedReminderDate = subMinutes(parsedDate, 5);
            break;
          case ReminderType.TenMinutesBefore:
            parsedReminderDate = subMinutes(parsedDate, 10);
            break;
          case ReminderType.FifteenMinutesBefore:
            parsedReminderDate = subMinutes(parsedDate, 15);
            break;
          case ReminderType.ThirtyMinutesBefore:
            parsedReminderDate = subMinutes(parsedDate, 30);
            break;
          case ReminderType.FortyFiveMinutesBefore:
            parsedReminderDate = subMinutes(parsedDate, 45);
            break;
          case ReminderType.OneHourBefore:
            parsedReminderDate = subHours(parsedDate, 1);
            break;
          case ReminderType.TwoHoursBefore:
            parsedReminderDate = subHours(parsedDate, 2);
            break;
          case ReminderType.OneDayBefore:
            parsedReminderDate = subDays(parsedDate, 1);
            break;
          default:
            throw new Error('Lembrete é obrigatório!');
        }

        setLoading(true);

        if (schedule) {
          const { data: updatedSchedule } = await api.put<ScheduleDTO>(
            `schedules/${schedule.id}`,
            {
              negotiation_id,
              date: parsedDate,
              reminder_date: parsedReminderDate,
              description,
              message,
              type,
            },
          );

          handleUpdatedSchedule(updatedSchedule);
        } else {
          const { data: createdSchedule } = await api.post<ScheduleDTO>(
            'schedules',
            {
              negotiation_id,
              date: parsedDate,
              reminder_date: parsedReminderDate,
              description,
              message,
              type,
            },
          );

          handleCreatedSchedule(createdSchedule);
        }

        enqueueSnackbar(
          `Agendamento ${schedule ? 'atualizado' : 'criado'} com sucesso!`,
          {
            variant: 'success',
          },
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleCreatedSchedule,
      handleUpdatedSchedule,
      schedule,
      selectedType,
    ],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      if (schedule) {
        await api.delete<void>(`schedules/${schedule.id}`);

        handleDeletedSchedule(schedule.id);
      }

      enqueueSnackbar('Agendamento deletado com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, handleDeletedSchedule, schedule]);

  const mappedNegotiations = useMemo(
    () =>
      negotiations.map(({ id, name, created_at }) => ({
        id,
        name: `${format(parseISO(created_at), 'dd/MM/yyyy')} - ${name}`,
      })),
    [negotiations],
  );

  const mappedReminderTypes = useMemo(
    () =>
      Object.values(ReminderType).map(value => ({
        id: value,
        name: value,
      })),
    [],
  );

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" {...rest}>
      <DialogTitle id="customized-dialog-title">
        Agendamento de Atividade
      </DialogTitle>
      <DialogContent dividers>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={defaultFormData}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ResponsibleSchedule>
                {`Responsável: ${schedule?.user.name ?? user.name}`}
              </ResponsibleSchedule>
            </Grid>
            <Grid item xs={12}>
              <SelectSearch
                name="contact_id"
                label="Contato"
                getValue={value => setContactId(value)}
                defaultValue={defaultContact}
                disabled={
                  isFromAnotherResponsible || isPassedDate || disabledContact
                }
              />
            </Grid>
            {contactId && mappedNegotiations.length > 0 && (
              <Grid item xs={12}>
                <SelectForm
                  name="negotiation_id"
                  label="Negociação"
                  values={mappedNegotiations}
                  disabled={
                    isFromAnotherResponsible ||
                    isPassedDate ||
                    disabledNegotiation
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                name="description"
                label="Descrição"
                disabled={isFromAnotherResponsible || isScheduleProcessed}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  name="type"
                  row
                  disabled={isFromAnotherResponsible || isScheduleProcessed}
                  getValue={value => setSelectedType(value)}
                  options={[
                    {
                      id: 1,
                      name: 'Ligação',
                    },
                    {
                      id: 2,
                      name: 'Reunião',
                    },
                    {
                      id: 3,
                      name: 'Mensagem',
                    },
                  ]}
                />
              </FormControl>
            </Grid>
            {selectedType === 3 && (
              <Grid item xs={12}>
                <Input
                  name="message"
                  label="Mensagem para o cliente"
                  disabled={isFromAnotherResponsible || isScheduleProcessed}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                name="date"
                type="datetime-local"
                label="Data"
                disabled={isFromAnotherResponsible || isPassedDate}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectForm
                name="reminder_type"
                label="Lembrete"
                values={mappedReminderTypes}
                disabled={isFromAnotherResponsible || isPassedReminderDate}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <CustomDialogActions>
        {!isFromAnotherResponsible && schedule && !isScheduleProcessed && (
          <Button onClick={handleDelete} color="primary">
            Excluir
          </Button>
        )}
        <Button onClick={handleCancel} color="primary">
          {isFromAnotherResponsible || isScheduleProcessed
            ? 'Fechar'
            : 'Cancelar'}
        </Button>
        {!isFromAnotherResponsible && !isScheduleProcessed && (
          <Button onClick={() => formRef.current?.submitForm()} color="primary">
            {schedule ? 'Salvar' : 'Agendar'}
          </Button>
        )}
      </CustomDialogActions>
    </Dialog>
  );
};

export default ScheduleDialog;
