import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Divider, IconButton } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import { uuid } from 'uuidv4';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import handleResponseError from '../../../../../utils/handleResponseError';
import getValidationErrors from '../../../../../utils/getValidationErrors';

import { useFunnel } from '../../../../../hooks/FunnelContext';
import { useLoader } from '../../../../../hooks/LoaderContext';

import api from '../../../../../services/api';

import Chatbot from '../../../../../models/Chatbot';

import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';

import {
  AddIconButton,
  ChildQuestion,
  ExpandUnexpandIconButton,
} from '../index';

import { ChatbotDTO, ChatbotQuestionDTO } from '../../types';

import {
  ActionsRow,
  Container,
  Content,
  QuestionRow,
  MessageRow,
  OptionsRow,
} from './styles';

type FormInput = {
  name?: string;
};

type Props = {
  chatbotDTO?: ChatbotDTO;
  handleSavedChatbot: (chatbot: Chatbot) => void;
  handleDeletedChatbot: (id: string) => void;
  cancel?: () => void;
};

export const ChatbotQuestions: React.FC<Props> = ({
  chatbotDTO,
  handleSavedChatbot,
  handleDeletedChatbot,
  cancel,
}) => {
  const [chatbotQuestionsDTO, setChatbotQuestionsDTO] = useState<
    ChatbotQuestionDTO[]
  >(() => {
    if (chatbotDTO && chatbotDTO.questions.length > 0)
      return chatbotDTO.questions;

    const id = uuid();

    return [
      {
        temp_id: id,
        messages: [
          {
            temp_id: uuid(),
            temp_question_id: id,
            text: '',
          },
        ],
        options: [],
      },
    ];
  });
  const [
    chatbotQuestionDTO,
    setChatbotQuestionDTO,
  ] = useState<ChatbotQuestionDTO>();
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const { funnels } = useFunnel();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef<FormHandles>(null);

  const mappedFunnels = funnels.map(({ id, name }) => ({
    id,
    name,
  }));

  const expandAll = useCallback((): void => {
    const ids = chatbotDTO?.questions.reduce(
      (ids, question) => [
        ...ids,
        ...(question.options.length === 0
          ? [question.temp_id]
          : question.options.map(({ temp_id }) => temp_id)),
      ],
      [] as string[],
    );

    setExpandedIds(ids || []);
  }, [chatbotDTO]);

  useEffect(() => expandAll(), [expandAll]);

  useEffect(() => {
    const getHierarchy = ({
      root,
      parent_id,
      type = 'question',
    }: {
      root: boolean;
      parent_id?: string;
      type?: 'question' | 'option';
    }) => {
      const questionDTO = chatbotQuestionsDTO.find(questionDTO => {
        if (root)
          return !questionDTO.temp_option_id && !questionDTO.temp_parent_id;

        if (type === 'question')
          return questionDTO.temp_parent_id === parent_id;

        return questionDTO.temp_option_id === parent_id;
      });

      if (!questionDTO) return undefined;

      const chatbotQuestionDTO: ChatbotQuestionDTO = {
        ...questionDTO,
        child_question: questionDTO.temp_id
          ? getHierarchy({
              root: false,
              parent_id: questionDTO.temp_id,
            })
          : undefined,
        options: questionDTO.options.map(optionDTO => ({
          ...optionDTO,
          child_question: optionDTO.temp_id
            ? getHierarchy({
                root: false,
                parent_id: optionDTO.temp_id,
                type: 'option',
              })
            : undefined,
        })),
      };

      return chatbotQuestionDTO;
    };

    const chatbotQuestion = getHierarchy({
      root: true,
    });

    setChatbotQuestionDTO(chatbotQuestion);
  }, [chatbotQuestionsDTO]);

  const handleSubmit = useCallback(
    async (data: FormInput) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const ids = chatbotQuestionsDTO.reduce(
          (ids, question) => [
            ...ids,
            ...question.messages.map(({ temp_id }) => temp_id),
            ...question.options.map(({ temp_id }) => temp_id),
          ],
          [] as string[],
        );

        const fieldsByIds = ids.reduce(
          (map, id) => ({
            ...map,
            [id]: Yup.string().required('Campo obrigatório'),
          }),
          {} as {
            [field: string]: Yup.StringSchema<string>;
          },
        );

        const schema = Yup.object().shape({
          name: Yup.string()
            .nullable()
            .min(2, 'Mínimo 2 caracteres')
            .required('Nome é obrigatório'),
          ...fieldsByIds,
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataDTO = {
          ...data,
          questionsDTO: chatbotQuestionsDTO,
        };

        let savedChatbot: Chatbot;

        if (chatbotDTO) {
          const { data } = await api.put<Chatbot>(
            `chatbots/${chatbotDTO.id}`,
            dataDTO,
          );

          savedChatbot = data;
        } else {
          const { data } = await api.post<Chatbot>('chatbots', dataDTO);

          savedChatbot = data;
        }

        handleSavedChatbot(savedChatbot);

        enqueueSnackbar('Chatbot salvo com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          enqueueSnackbar('Existem campos não preenchidos!', {
            variant: 'error',
          });
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleSavedChatbot,
      chatbotDTO,
      chatbotQuestionsDTO,
    ],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      if (chatbotDTO) {
        await api.delete<void>(`chatbots/${chatbotDTO.id}`);

        handleDeletedChatbot(chatbotDTO.id);
      }

      enqueueSnackbar('Chatbot deletado com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, handleDeletedChatbot, chatbotDTO]);

  const handleAddQuestionToQuestion = useCallback(
    (question_id: string): void => {
      const parentQuestionDTO = chatbotQuestionsDTO.find(
        ({ temp_id }) => temp_id === question_id,
      );

      if (!parentQuestionDTO) return;

      setChatbotQuestionsDTO(oldChatbotQuestionsDTO => [
        ...oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                funnel_id: undefined,
              }
            : oldChatbotQuestionDTO,
        ),
        {
          temp_id: uuid(),
          temp_parent_id: question_id,
          messages: [
            {
              temp_id: uuid(),
              temp_question_id: question_id,
              text: '',
            },
          ],
          options: [],
          funnel_id: parentQuestionDTO.funnel_id,
        },
      ]);
    },
    [chatbotQuestionsDTO],
  );

  const handleAddQuestionToOption = useCallback(
    (question_id: string, option_id: string): void => {
      const parentQuestionDTO = chatbotQuestionsDTO.find(
        ({ temp_id }) => temp_id === question_id,
      );

      if (!parentQuestionDTO) return;

      const temp_question_id = uuid();

      setChatbotQuestionsDTO(oldChatbotQuestionsDTO => [
        ...oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                options: oldChatbotQuestionDTO.options.map(option =>
                  option.temp_id === option_id
                    ? {
                        ...option,
                        funnel_id: undefined,
                      }
                    : option,
                ),
              }
            : oldChatbotQuestionDTO,
        ),
        {
          temp_id: temp_question_id,
          temp_option_id: option_id,
          messages: [
            {
              temp_id: uuid(),
              temp_question_id,
              text: '',
            },
          ],
          options: [],
          funnel_id: parentQuestionDTO.options.find(
            option => option.temp_id === option_id,
          )?.funnel_id,
        },
      ]);
    },
    [chatbotQuestionsDTO],
  );

  const handleAddMessage = useCallback((question_id: string) => {
    setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
      oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
        oldChatbotQuestionDTO.temp_id === question_id
          ? {
              ...oldChatbotQuestionDTO,
              messages: [
                ...oldChatbotQuestionDTO.messages,
                {
                  temp_id: uuid(),
                  temp_question_id: question_id,
                  text: '',
                },
              ],
            }
          : oldChatbotQuestionDTO,
      ),
    );
  }, []);

  const handleAddOption = useCallback(
    (question_id: string): void => {
      const parentQuestionDTO = chatbotQuestionsDTO.find(
        ({ temp_id }) => temp_id === question_id,
      );

      if (!parentQuestionDTO) return;

      setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
        oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                options: [
                  ...oldChatbotQuestionDTO.options,
                  {
                    temp_id: uuid(),
                    temp_question_id: question_id,
                    text: '',
                    funnel_id: oldChatbotQuestionDTO.funnel_id,
                  },
                ],
                funnel_id: undefined,
              }
            : oldChatbotQuestionDTO,
        ),
      );
    },
    [chatbotQuestionsDTO],
  );

  const handleRemove = useCallback(
    (question_id: string) => {
      const questionsId: string[] = [];

      const findQuestionsIdByHierarchy = (
        question_id: string,
        questionDTO: ChatbotQuestionDTO,
      ) => {
        if (question_id === questionDTO.temp_id) {
          questionsId.push(questionDTO.temp_id);

          if (questionDTO.child_question) {
            findQuestionsIdByHierarchy(
              questionDTO.child_question.temp_id,
              questionDTO.child_question,
            );
          } else {
            questionDTO.options.forEach(option => {
              if (option.child_question)
                findQuestionsIdByHierarchy(
                  option.child_question.temp_id,
                  option.child_question,
                );
            });
          }
        } else if (questionDTO.child_question) {
          findQuestionsIdByHierarchy(question_id, questionDTO.child_question);
        } else {
          questionDTO.options.forEach(option => {
            if (option.child_question)
              findQuestionsIdByHierarchy(question_id, option.child_question);
          });
        }
      };

      if (chatbotQuestionDTO)
        findQuestionsIdByHierarchy(question_id, chatbotQuestionDTO);

      setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
        oldChatbotQuestionsDTO.filter(
          oldChatbotQuestionDTO =>
            !questionsId.includes(oldChatbotQuestionDTO.temp_id),
        ),
      );
    },
    [chatbotQuestionDTO],
  );

  const handleRemoveMessage = useCallback(
    (question_id: string, message_id: string) => {
      setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
        oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                messages: oldChatbotQuestionDTO.messages.filter(
                  message => message.temp_id !== message_id,
                ),
              }
            : oldChatbotQuestionDTO,
        ),
      );
    },
    [],
  );

  const handleRemoveOption = useCallback(
    (question_id: string, option_id: string) => {
      setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
        oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                options: oldChatbotQuestionDTO.options.filter(
                  option => option.temp_id !== option_id,
                ),
              }
            : oldChatbotQuestionDTO,
        ),
      );
    },
    [],
  );

  const handleQuestionData = useCallback(
    (
      question_id: string,
      data: {
        funnel_id?: string;
      },
    ) => {
      setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
        oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                ...data,
              }
            : oldChatbotQuestionDTO,
        ),
      );
    },
    [],
  );

  const handleMessageData = useCallback(
    (
      question_id: string,
      message_id: string,
      data: {
        text?: string;
      },
    ) => {
      setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
        oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                messages: oldChatbotQuestionDTO.messages.map(message =>
                  message.temp_id === message_id
                    ? {
                        ...message,
                        ...data,
                      }
                    : message,
                ),
              }
            : oldChatbotQuestionDTO,
        ),
      );
    },
    [],
  );

  const handleOptionData = useCallback(
    (
      question_id: string,
      option_id: string,
      data: {
        funnel_id?: string;
        text?: string;
      },
    ) => {
      setChatbotQuestionsDTO(oldChatbotQuestionsDTO =>
        oldChatbotQuestionsDTO.map(oldChatbotQuestionDTO =>
          oldChatbotQuestionDTO.temp_id === question_id
            ? {
                ...oldChatbotQuestionDTO,
                options: oldChatbotQuestionDTO.options.map(option =>
                  option.temp_id === option_id
                    ? {
                        ...option,
                        ...data,
                      }
                    : option,
                ),
              }
            : oldChatbotQuestionDTO,
        ),
      );
    },
    [],
  );

  const handleItemHierarchy = useCallback(
    (
      node: ChatbotQuestionDTO,
      even: boolean,
      parentIsOption: boolean,
      parentIsHidden: boolean,
    ) => {
      const isExpandedQuestion = expandedIds.includes(node.temp_id);
      const hasOptions = node.options.length > 0;

      return (
        <QuestionRow
          even={even}
          hasOptions={hasOptions}
          parentIsOption={parentIsOption}
        >
          {(node.temp_parent_id || node.temp_option_id) && (
            <IconButton
              color="primary"
              component="span"
              onClick={() => handleRemove(node.temp_id)}
            >
              <DeleteForeverIcon fontSize="small" />
            </IconButton>
          )}
          <div>
            <MessageRow hasOptions={hasOptions}>
              <div className="rowMessages">
                {!hasOptions && (
                  <ExpandUnexpandIconButton
                    id={node.temp_id}
                    expanded={isExpandedQuestion}
                    setExpandedIds={setExpandedIds}
                  />
                )}
                <ul>
                  {node.messages.map((message, index) => (
                    <li>
                      <Input
                        name={message.temp_id}
                        label={`Mensagem ${index + 1}`}
                        padding="0 16px"
                        multiline
                        maxRows={3}
                        value={message.text}
                        onChange={e => {
                          handleMessageData(node.temp_id, message.temp_id, {
                            text: e.target.value,
                          });
                        }}
                      />
                      {node.messages.length > 1 && (
                        <IconButton
                          color="default"
                          component="span"
                          onClick={() => {
                            handleRemoveMessage(node.temp_id, message.temp_id);
                          }}
                        >
                          <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                      )}
                    </li>
                  ))}
                </ul>
                {!node.child_question && !hasOptions && (
                  <>
                    <Divider orientation="vertical" />
                    <Select
                      name="funnel"
                      label="Funil"
                      value={node.funnel_id}
                      values={mappedFunnels}
                      withDefaultOption={false}
                      onChange={e => {
                        handleQuestionData(node.temp_id, {
                          funnel_id: e.target.value as string | undefined,
                        });
                      }}
                    />
                  </>
                )}
              </div>
              {isExpandedQuestion && !node.child_question && (
                <AddIconButton
                  handleAdd={() => {
                    handleAddQuestionToQuestion(node.temp_id);
                  }}
                />
              )}
              <AddIconButton
                text="Mensagem"
                handleAdd={() => {
                  handleAddMessage(node.temp_id);
                }}
              />
              {node.child_question && (
                <ChildQuestion hidden={!isExpandedQuestion || parentIsHidden}>
                  {handleItemHierarchy(
                    node.child_question,
                    !even,
                    false,
                    !isExpandedQuestion,
                  )}
                </ChildQuestion>
              )}
            </MessageRow>
            {!node.child_question && (
              <>
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                <OptionsRow>
                  <ul>
                    {node.options.map((option, index) => {
                      const isExpandedOption = expandedIds.includes(
                        option.temp_id,
                      );

                      return (
                        <>
                          <li>
                            <ExpandUnexpandIconButton
                              id={option.temp_id}
                              expanded={isExpandedOption}
                              setExpandedIds={setExpandedIds}
                            />
                            <Input
                              name={option.temp_id}
                              label={`Opção ${index + 1}`}
                              padding="0 16px"
                              startAdornment={{
                                ...(
                                  <span
                                    style={{
                                      marginRight: 8,
                                    }}
                                  >
                                    {`${index + 1}.`}
                                  </span>
                                ),
                              }}
                              multiline
                              maxRows={3}
                              value={option.text}
                              onChange={e => {
                                handleOptionData(node.temp_id, option.temp_id, {
                                  text: e.target.value,
                                });
                              }}
                            />
                            <IconButton
                              color="default"
                              component="span"
                              onClick={() => {
                                handleRemoveOption(
                                  node.temp_id,
                                  option.temp_id,
                                );
                              }}
                            >
                              <DeleteForeverIcon fontSize="small" />
                            </IconButton>
                            {!option.child_question && (
                              <>
                                <Divider orientation="vertical" />
                                <Select
                                  name="funnel"
                                  label="Funil"
                                  value={option.funnel_id}
                                  values={mappedFunnels}
                                  withDefaultOption={false}
                                  onChange={e => {
                                    handleOptionData(
                                      node.temp_id,
                                      option.temp_id,
                                      {
                                        funnel_id: e.target.value as
                                          | string
                                          | undefined,
                                      },
                                    );
                                  }}
                                />
                              </>
                            )}
                          </li>
                          {option.child_question ? (
                            <ChildQuestion
                              hidden={!isExpandedOption || parentIsHidden}
                            >
                              {handleItemHierarchy(
                                option.child_question,
                                !even,
                                true,
                                !isExpandedOption,
                              )}
                            </ChildQuestion>
                          ) : (
                            <>
                              {isExpandedOption && (
                                <AddIconButton
                                  handleAdd={() => {
                                    handleAddQuestionToOption(
                                      node.temp_id,
                                      option.temp_id,
                                    );
                                  }}
                                />
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </ul>
                  <AddIconButton
                    text="Opção"
                    handleAdd={() => {
                      handleAddOption(node.temp_id);
                    }}
                  />
                </OptionsRow>
              </>
            )}
          </div>
        </QuestionRow>
      );
    },
    [
      handleAddQuestionToQuestion,
      handleAddQuestionToOption,
      handleAddMessage,
      handleAddOption,
      handleRemove,
      handleRemoveMessage,
      handleRemoveOption,
      handleQuestionData,
      handleMessageData,
      handleOptionData,
      mappedFunnels,
      expandedIds,
    ],
  );

  return (
    <Container>
      <Form
        ref={formRef}
        initialData={{
          name: chatbotDTO?.name,
        }}
        onSubmit={handleSubmit}
      >
        <Input id="name" name="name" label="Nome" />
        {chatbotQuestionDTO && (
          <Content>
            {handleItemHierarchy(chatbotQuestionDTO, true, false, false)}
          </Content>
        )}
        <ActionsRow>
          <Button
            className="saveButton"
            color="primary"
            size="small"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
          <Button
            className="cancelButton"
            color="secondary"
            size="small"
            variant="outlined"
            type="button"
            onClick={chatbotDTO ? handleDelete : cancel}
          >
            {chatbotDTO ? 'Excluir' : 'Cancelar'}
          </Button>
        </ActionsRow>
      </Form>
    </Container>
  );
};
