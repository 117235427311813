import styled from 'styled-components';
import { Grid, Menu } from '@material-ui/core';

export const Container = styled.div``;

export const Content = styled.div`
  height: 56px;
  background: #6fa7ba;
  box-shadow: inset 0 -8px 8px -8px #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px 0 16px;

  .notifications {
    svg {
      color: #fff;
    }
  }
`;

export const Logo = styled.img`
  padding: 0 32px;
  width: 200px;
`;

export const LeftSide = styled(Grid)<{
  mobile: boolean;
}>`
  .nav-links-wrapper {
    display: flex;
  }

  nav {
    span {
      a {
        font-weight: bold;
        font-size: 15px;
        color: #fff;

        &:not(:last-child) {
          margin-right: ${props => (props.mobile ? '12px' : '8px')};
        }

        &.active {
          color: #b6d44c;
          border-bottom: 2px solid #b6d44c;
        }
      }
    }

    .nav-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 6px;
      background-color: rgba(255, 255, 255, 0.2);
      transition: all 0.4s ease-in-out;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

export const RightSide = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const RightBorder = styled(Grid)`
  border-left: 1px solid #eee;
  height: 40px;
`;

export const CustomMenu = styled(Menu)`
  li {
    a {
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      flex-direction: row;
      align-items: center;

      &:visited {
        color: rgba(0, 0, 0, 0.87);
      }
    }

    svg {
      margin-right: 6px;
    }
  }
`;
