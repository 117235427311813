import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  && {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background-color: #ddd;
    color: black;
    margin: 8px;
    border-radius: 4px;
  }
`;

export const List = styled.ul<{
  show: number;
}>`
  min-width: 0;
  max-width: 360px;
  width: 100%;
  padding: 0 8px 8px;

  text {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    line-height: 2;
  }

  li {
    display: flex;
    align-items: center;
    cursor: pointer;

    .MuiAvatar-root {
      width: 20px;
      height: 20px;
      font-size: 10px;
      color: black;
      background-color: #f8f8f8;
      margin-right: 4px;
      border: 1px solid #ccc;
    }

    span {
      margin-right: 4px;
      background-color: blue;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }

    &:hover {
      background-color: #ddd;
      border-radius: 2px;
    }
  }

  li:nth-child(n + ${props => props.show + 1}) {
    display: none;
  }
`;
