import React, { useCallback, useMemo, useState } from 'react';
import { Avatar, Tooltip, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import getAbbreviationByName from '../../../utils/getAbbreviationByName';

import { Day, ScheduleDTO } from '../types';

import ScheduleDialog from '../ScheduleDialog';

import { Title, List } from './styles';

type Props = {
  day: Day;
  show?: number;
  hiddenTitle?: boolean;
  handleOpenPopover?: (day: Day, e: React.MouseEvent<SVGTextElement>) => void;
  handleCreatedSchedule: (schedule: ScheduleDTO) => void;
  handleUpdatedSchedule: (schedule: ScheduleDTO) => void;
  handleDeletedSchedule: (id: string) => void;
};

const SchedulePopover: React.FC<Props> = ({
  day,
  show = day.schedules.length,
  hiddenTitle = false,
  handleOpenPopover,
  handleCreatedSchedule,
  handleUpdatedSchedule,
  handleDeletedSchedule,
}) => {
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleDTO>();
  const [openScheduleDialog, setOpenScheduleDialog] = useState<boolean>(false);

  const formattedDate = useMemo(() => format(day.date, 'dd/MM/yyyy'), [
    day.date,
  ]);

  const getTypeName = useCallback((type: number): string => {
    switch (type) {
      case 1:
        return 'Ligação';
      case 2:
        return 'Reunião';
      case 3:
        return 'Mensagem';
      default:
        return 'Desconhecido';
    }
  }, []);

  const getTitle = useCallback(
    (schedule: ScheduleDTO): string =>
      `${format(parseISO(schedule.date), 'HH:mm')} ${getTypeName(
        schedule.type,
      )}: ${schedule.description}`,
    [getTypeName],
  );

  return (
    <>
      {selectedSchedule && (
        <ScheduleDialog
          open={openScheduleDialog}
          negotiation_id={selectedSchedule.negotiation_id}
          contact_id={selectedSchedule.negotiation.contact.id}
          contact_name={selectedSchedule.negotiation.contact.name}
          schedule={selectedSchedule}
          disabledContact={false}
          disabledNegotiation={false}
          handleCreatedSchedule={handleCreatedSchedule}
          handleUpdatedSchedule={schedule => {
            setOpenScheduleDialog(false);
            handleUpdatedSchedule(schedule);
          }}
          handleDeletedSchedule={scheduleId => {
            setOpenScheduleDialog(false);
            handleDeletedSchedule(scheduleId);
          }}
          handleCancel={() => setOpenScheduleDialog(false)}
        />
      )}
      {!hiddenTitle && <Title>{formattedDate}</Title>}
      <List show={show}>
        {day.schedules.map(schedule => (
          <li
            key={schedule.id}
            onClick={() => {
              setSelectedSchedule(schedule);
              setOpenScheduleDialog(true);
            }}
            onKeyDown={() => setOpenScheduleDialog(false)}
            role="presentation"
          >
            <Tooltip title={schedule.user.name} placement="left">
              <Avatar>{getAbbreviationByName(schedule.user.name)}</Avatar>
            </Tooltip>
            <Tooltip title={getTitle(schedule)} placement="right">
              <Typography component="text">{getTitle(schedule)}</Typography>
            </Tooltip>
          </li>
        ))}
        {day.schedules.length > show && (
          <Typography
            component="text"
            aria-describedby="popover"
            onClick={(e: React.MouseEvent<SVGTextElement>) => {
              handleOpenPopover?.(day, e);
            }}
          >
            {`+${day.schedules.length - show}`}
          </Typography>
        )}
      </List>
    </>
  );
};

export default SchedulePopover;
