import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import handleResponseError from '../../../../utils/handleResponseError';

import { useFunnel } from '../../../../hooks/FunnelContext';
import { UserDTO, useResponsible } from '../../../../hooks/ResponsibleContext';
import { ContactContext } from '../../../../hooks/ContactContext';

import { FindAllContactsAdvancedDTO } from '../../../../services/ContactService';

import Funnel from '../../../../models/Funnel';
import Product from '../../../../models/Product';
import Channel from '../../../../models/Channel';

import Select from '../../../../components/Select';

import { Content, CustomCheckbox } from './styles';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ALL = 'all';
const DEFAULT_OPTION = {
  id: ALL,
  name: 'Todos',
};

const ChooseAdvancedFilters: React.FC<Props> = ({ open, setOpen }) => {
  const { pagination, setPagination } = useContext(ContactContext);

  const [state, setState] = useState<FindAllContactsAdvancedDTO>({
    includeMessages: 1,
    withoutResponsible: 0,
  });
  const {
    unreadMessages,
    withoutResponsible,
    user_id = ALL,
    funnel_id = ALL,
    step_id = ALL,
    product_id = ALL,
    channel_id = ALL,
  } = state;

  const { users } = useResponsible();
  const { funnels } = useFunnel();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setState(oldState => ({
      ...oldState,
      ...pagination,
    }));
  }, [pagination]);

  const handleSubmit = useCallback(async () => {
    try {
      setPagination(oldPagination => ({
        ...oldPagination,
        ...state,
        funnel_id: state.funnel_id === ALL ? undefined : state.funnel_id,
        step_id: state.step_id === ALL ? undefined : state.step_id,
        product_id: state.product_id === ALL ? undefined : state.product_id,
        channel_id: state.channel_id === ALL ? undefined : state.channel_id,
        user_id: state.user_id === ALL ? undefined : state.user_id,
        skip: 0,
        take: 20,
      }));

      setOpen(false);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, setOpen, setPagination, state]);

  const handleChangeUnreadMessages = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState(oldState => ({
        ...oldState,
        unreadMessages: event.target?.checked ? 1 : 0,
      }));
    },
    [],
  );

  const handleChangeWithoutResponsible = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target?.checked) {
        setState(oldState => ({
          ...oldState,
          withoutResponsible: 1,
          user_id: ALL,
        }));
      } else {
        setState(oldState => ({
          ...oldState,
          withoutResponsible: 0,
        }));
      }
    },
    [],
  );

  const handleChangeResponsible = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setState(oldState => ({
        ...oldState,
        user_id: id,
      }));
    },
    [],
  );

  const handleChangeFunnel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setState(oldState => ({
        ...oldState,
        funnel_id: id,
        step_id: ALL,
      }));
    },
    [],
  );

  const handleChangeStep = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setState(oldState => ({
        ...oldState,
        step_id: id,
      }));
    },
    [],
  );

  const handleChangeProduct = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setState(oldState => ({
        ...oldState,
        product_id: id,
      }));
    },
    [],
  );

  const handleChangeChannel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setState(oldState => ({
        ...oldState,
        channel_id: id,
      }));
    },
    [],
  );

  const mappedUsers = useMemo(() => {
    let mappedUsers: UserDTO[] = [];

    if (funnel_id === ALL) {
      mappedUsers = users.filter(user => user.active);
    } else {
      mappedUsers = users.filter(
        user => user.active && user.funnels.includes(funnel_id),
      );
    }

    return [
      DEFAULT_OPTION,
      ...mappedUsers.map(({ id, name }) => ({ id, name })),
    ];
  }, [users, funnel_id]);

  const mappedFunnels = useMemo(() => {
    let mappedFunnels: Funnel[] = [];

    if (user_id === ALL) {
      mappedFunnels = funnels;
    } else {
      const user = users.find(user => user.id === user_id);

      mappedFunnels = user
        ? funnels.filter(funnel => user.funnels.includes(funnel.id))
        : funnels;
    }

    return [
      DEFAULT_OPTION,
      ...mappedFunnels.map(({ id, name }) => ({ id, name })),
    ];
  }, [funnels, users, user_id]);

  const mappedSteps = useMemo(() => {
    if (funnel_id === ALL) return [DEFAULT_OPTION];

    const funnel = funnels.find(funnel => funnel.id === funnel_id);

    return [
      DEFAULT_OPTION,
      ...(funnel?.steps.map(({ id, name }) => ({ id, name })) || []),
    ];
  }, [funnels, funnel_id]);

  const mappedProducts = useMemo(() => {
    let mappedProducts: Product[] = [];

    if (funnel_id === ALL) {
      const products = funnels.reduce((products, funnel) => {
        const ids = products.map(product => product.id);

        return [
          ...products,
          ...funnel.products.filter(product => !ids.includes(product.id)),
        ];
      }, [] as Product[]);

      mappedProducts = products;
    } else {
      const funnel = funnels.find(funnel => funnel.id === funnel_id);

      if (funnel) mappedProducts = funnel.products;
    }

    return [
      DEFAULT_OPTION,
      ...mappedProducts
        .sort((p1, p2) => p1.name.localeCompare(p2.name))
        .map(({ id, name }) => ({
          id,
          name,
        })),
    ];
  }, [funnels, funnel_id]);

  const mappedChannels = useMemo(() => {
    let mappedChannels: Channel[] = [];

    if (funnel_id === ALL) {
      const channels = funnels.reduce((channels, funnel) => {
        const ids = channels.map(channel => channel.id);

        return [
          ...channels,
          ...funnel.channels.filter(channel => !ids.includes(channel.id)),
        ];
      }, [] as Channel[]);

      mappedChannels = channels;
    } else {
      const funnel = funnels.find(funnel => funnel.id === funnel_id);

      if (funnel) mappedChannels = funnel.channels;
    }

    return [
      DEFAULT_OPTION,
      ...mappedChannels
        .sort((p1, p2) => p1.name.localeCompare(p2.name))
        .map(({ id, name }) => ({
          id,
          name,
        })),
    ];
  }, [funnels, funnel_id]);

  const handleClearAll = useCallback(() => {
    setState({
      withoutResponsible: 0,
      unreadMessages: 0,
      user_id: ALL,
      funnel_id: ALL,
      step_id: ALL,
      product_id: ALL,
      channel_id: ALL,
    });
  }, []);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={open}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        Filtros Avançados
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          width: 400,
          padding: 16,
        }}
      >
        <Content>
          <li>
            <FormControlLabel
              control={{
                ...(
                  <CustomCheckbox
                    defaultChecked={unreadMessages === 1}
                    checked={unreadMessages === 1}
                    onChange={handleChangeUnreadMessages}
                    color="primary"
                  />
                ),
              }}
              label="Mensagens não lidas"
            />
          </li>
          <li>
            <FormControlLabel
              control={{
                ...(
                  <CustomCheckbox
                    defaultChecked={withoutResponsible === 1}
                    checked={withoutResponsible === 1}
                    onChange={handleChangeWithoutResponsible}
                    color="primary"
                  />
                ),
              }}
              label="Sem responsável"
            />
          </li>
          {withoutResponsible === 0 && users && (
            <li>
              <Select
                name="user_id"
                label="Responsável"
                value={user_id}
                withDefaultOption={false}
                fullWidth
                values={mappedUsers}
                onChange={handleChangeResponsible}
              />
            </li>
          )}
          {funnels && (
            <li>
              <Select
                name="funnel_id"
                label="Funil"
                value={funnel_id}
                withDefaultOption={false}
                fullWidth
                values={mappedFunnels}
                onChange={handleChangeFunnel}
              />
            </li>
          )}
          <li>
            <Select
              name="step_id"
              label="Etapa"
              value={step_id}
              withDefaultOption={false}
              fullWidth
              disabled={funnel_id === ALL}
              values={mappedSteps}
              onChange={handleChangeStep}
            />
          </li>
          <li>
            <Select
              name="product_id"
              label="Produto"
              value={product_id}
              withDefaultOption={false}
              fullWidth
              values={mappedProducts}
              onChange={handleChangeProduct}
            />
          </li>
          <li>
            <Select
              name="channel_id"
              label="Canal"
              value={channel_id}
              withDefaultOption={false}
              fullWidth
              values={mappedChannels}
              onChange={handleChangeChannel}
            />
          </li>
        </Content>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setOpen(false)} color="primary">
          Fechar
        </Button>
        <Button autoFocus onClick={handleClearAll} color="primary">
          Limpar
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseAdvancedFilters;
