import React from 'react';
import { TextFieldProps } from '@material-ui/core';

import { CustomTextField } from './styles';

const InputOpacity: React.FC<TextFieldProps> = ({ ...rest }) => {
  return <CustomTextField {...rest} />;
};

export default InputOpacity;
