import React, { useMemo } from 'react';

import { useResponsible } from '../../../../../hooks/ResponsibleContext';

import { Container } from './styles';

interface Props {
  user_id?: string;
}

const MessageName: React.FC<Props> = ({ user_id }) => {
  const { usersName } = useResponsible();

  const name = useMemo(() => (user_id ? usersName[user_id] : 'Desconhecido'), [
    usersName,
    user_id,
  ]);

  return (
    <>
      {user_id && (
        <Container>
          <span>{name}</span>
        </Container>
      )}
    </>
  );
};

export default MessageName;
