import React, { useMemo, useState } from 'react';

import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ExpandMore, Visibility, VisibilityOff } from '@material-ui/icons';
import { FaCopy } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';

import { useCredential } from '../../../hooks/CredentialContext';

import Settings from '..';
import PageTitle from '../../../components/PageTitle';

import {
  Content,
  CredentialRow,
  CopyContainer,
  ColumnContainer,
  ColumnName,
  RowContainer,
  RowName,
} from './styles';

type Field = {
  name: string;
  required: boolean;
  type: 'string' | 'number';
  description: string;
};

const ExternalAccessCredential: React.FC = () => {
  const [showApiKey, setShowApiKey] = useState<boolean>(false);

  const queries = useMemo(
    (): Field[] => [
      {
        name: 'type',
        type: 'string',
        required: true,
        description: 'Valor: api_key',
      },
      {
        name: 'key',
        type: 'string',
        required: true,
        description: 'Credencial de acesso externo gerada',
      },
    ],
    [],
  );

  const fields = useMemo(
    (): Field[] => [
      {
        name: 'nome',
        required: true,
        type: 'string',
        description: 'Nome do cliente',
      },
      {
        name: 'telefone',
        required: true,
        type: 'string',
        description: 'Telefone do cliente',
      },
      {
        name: 'email',
        required: false,
        type: 'string',
        description: 'Email do cliente',
      },
      {
        name: 'cpf',
        required: false,
        type: 'string',
        description: 'CPF do cliente',
      },
      {
        name: 'nome_empresa',
        required: false,
        type: 'string',
        description: 'Nome da empresa do cliente',
      },
      {
        name: 'cnpj_empresa',
        required: false,
        type: 'string',
        description: 'CNPJ da empresa do cliente',
      },
      {
        name: 'segmento_empresa',
        required: false,
        type: 'string',
        description: 'Segmento da empresa do cliente',
      },
      {
        name: 'valor_contrato',
        required: false,
        type: 'number',
        description: 'Valor do contrato em negociação',
      },
      {
        name: 'cep',
        required: false,
        type: 'string',
        description: 'CEP do endereço',
      },
      {
        name: 'estado',
        required: false,
        type: 'string',
        description: 'Estado do endereço',
      },
      {
        name: 'cidade',
        required: false,
        type: 'string',
        description: 'Cidade do endereço',
      },
      {
        name: 'bairro',
        required: false,
        type: 'string',
        description: 'Bairro do endereço',
      },
      {
        name: 'rua',
        required: false,
        type: 'string',
        description: 'Rua do endereço',
      },
      {
        name: 'complemento',
        required: false,
        type: 'string',
        description: 'Complemento do endereço',
      },
    ],
    [],
  );

  const url = useMemo(() => 'https://api.moovesales.com.br/leads', []);

  const { credential, handleCreateCredential } = useCredential();

  return (
    <Settings route="external-access-credential">
      <PageTitle pageName="Credencial de Acesso Externo" />
      <Content>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={handleCreateCredential}
        >
          {credential ? 'Substituir Credencial Atual' : 'Gerar Credencial'}
        </Button>
        <CredentialRow>
          {credential ? (
            <>
              <div>
                <span>Credencial: </span>
                {showApiKey ? (
                  <>
                    <span>{credential.api_key}</span>
                    <IconButton
                      onClick={() => setShowApiKey(false)}
                      color="inherit"
                      component="span"
                      size="small"
                    >
                      <VisibilityOff />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    onClick={() => setShowApiKey(true)}
                    color="inherit"
                    component="span"
                    size="small"
                  >
                    <Visibility />
                  </IconButton>
                )}
              </div>
              <div>
                <span>Data de criação: </span>
                <span>
                  {format(parseISO(credential.created_at), 'dd/MM/yyyy HH:mm')}
                </span>
              </div>
            </>
          ) : (
            <div>Credencial ainda não foi gerada!</div>
          )}
        </CredentialRow>
        <ExpansionPanel key="post-leads">
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Grid container>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Typography variant="body1">POST</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Typography variant="body1">{url}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <CopyContainer container>
                <Grid item xs={12}>
                  <span>Copiar url</span>
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                    }}
                    color="inherit"
                    component="span"
                    size="small"
                  >
                    <FaCopy />
                  </IconButton>
                </Grid>
                {credential && (
                  <Grid item xs={12}>
                    <span>Copiar url com credencial</span>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${url}?type=api_key&key=${credential.api_key}`,
                        );
                      }}
                      color="inherit"
                      component="span"
                      size="small"
                    >
                      <FaCopy />
                    </IconButton>
                  </Grid>
                )}
              </CopyContainer>
              <Typography variant="h6">Query</Typography>
              <ColumnContainer container>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <ColumnName>Campo</ColumnName>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <ColumnName>Obrigatório</ColumnName>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <ColumnName>Tipo</ColumnName>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6}>
                  <ColumnName>Descrição</ColumnName>
                </Grid>
              </ColumnContainer>
              {queries.map(query => (
                <RowContainer container>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <RowName>{query.name}</RowName>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <RowName>{query.required ? 'Sim' : 'Não'}</RowName>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <RowName>{query.type}</RowName>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={6}>
                    <RowName>{query.description}</RowName>
                  </Grid>
                </RowContainer>
              ))}
              <Typography variant="h6">Body</Typography>
              <ColumnContainer container>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <ColumnName>Campo</ColumnName>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <ColumnName>Obrigatório</ColumnName>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <ColumnName>Tipo</ColumnName>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6}>
                  <ColumnName>Descrição</ColumnName>
                </Grid>
              </ColumnContainer>
              {fields.map(field => (
                <RowContainer container>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <RowName>{field.name}</RowName>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <RowName>{field.required ? 'Sim' : 'Não'}</RowName>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <RowName>{field.type}</RowName>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={6}>
                    <RowName>{field.description}</RowName>
                  </Grid>
                </RowContainer>
              ))}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Content>
    </Settings>
  );
};

export default ExternalAccessCredential;
