import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';

type Props = {
  id: string;
  expanded: boolean;
  setExpandedIds: Dispatch<SetStateAction<string[]>>;
};

const ExpandUnexpandIconButton: React.FC<Props> = ({
  id,
  expanded,
  setExpandedIds,
}) => {
  const handleExpand = useCallback(
    () => setExpandedIds(oldExpandedIds => [...oldExpandedIds, id]),
    [setExpandedIds, id],
  );

  const handleUnexpand = useCallback(
    () =>
      setExpandedIds(oldExpandedIds =>
        oldExpandedIds.filter(expandedId => expandedId !== id),
      ),
    [setExpandedIds, id],
  );

  return (
    <IconButton
      color="primary"
      component="span"
      onClick={expanded ? handleUnexpand : handleExpand}
    >
      {expanded ? (
        <KeyboardArrowDownIcon fontSize="medium" />
      ) : (
        <KeyboardArrowRightIcon fontSize="medium" />
      )}
    </IconButton>
  );
};

export { ExpandUnexpandIconButton };
