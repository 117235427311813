import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const CustomButton = styled(Button)`
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: inline-block;
  }
`;
