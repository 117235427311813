import styled, { css } from 'styled-components';

interface ContainerProps {
  isActive?: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: calc(100% - 56px);
  width: 100%;
  flex: 0 0 320px;

  border-right: 1px solid #ddd;

  h6 {
    font-size: 1.1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ul {
    height: 100%;
    position: relative;

    ${props =>
      props.isActive &&
      css`
        &:before {
          content: '';
          position: absolute;
          width: calc(100% - 4px);
          height: 100%;
          z-index: 1;
          border: 2px dashed rgba(111, 167, 186, 0.6);
          background: rgba(111, 167, 186, 0.1);
          box-shadow: none;
          border-radius: 8px;
        }

        * {
          opacity: 0.7;
        }
      `}
  }
`;

export const Header = styled.div`
  && {
    width: 100%;
    max-height: 55px;
    padding: 8px;
    border-radius: 0;
    /* margin-bottom: 8px; */
    border-bottom: 1px solid #ddd;
    /* border-top: 1px solid #ddd; */

    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
    }

    /* box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.8); */

    /* margin-bottom: 8px; */
    /* background-color: rgba(111, 167, 186, 0.1);
    color: #000; */

    background-color: #fff;
    color: #6fa7ba;

    svg {
      font-size: 24px;
    }
  }
`;
