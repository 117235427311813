import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { getStatusByNegotiationFunnel } from '../../../../utils/getNegotiationStatus';

import NegotiationFunnel from '../../../../models/NegotiationFunnel';

import TabLogbooks from '../../../Client/TabLogbooks';

import { Container, Title } from './styles';

interface Props {
  negotiationFunnel: NegotiationFunnel;
}

const AnnotationSession: React.FC<Props> = ({ negotiationFunnel }) => {
  const status = useMemo(
    () => getStatusByNegotiationFunnel(negotiationFunnel),
    [negotiationFunnel],
  );

  return (
    <Container>
      <Title>
        <Typography variant="h6">Comentários</Typography>
      </Title>
      <TabLogbooks
        status={status}
        negotiation_id={negotiationFunnel.negotiation_id}
        funnel_id={negotiationFunnel.funnel_id}
        small
        annotationPerspective
      />
    </Container>
  );
};

export default AnnotationSession;
