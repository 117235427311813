import styled from 'styled-components';

import { Button } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    width: 304px;
    margin-bottom: 32px;
  }
`;

export const Content = styled.div`
  a {
    color: #9cba32;

    &:visited {
      color: #9cba32;
    }

    &:hover {
      color: #6fa7ba;
    }
  }
`;

export const CustomButton = styled(Button)`
  margin-bottom: 32px;
`;

export const MailRow = styled.div`
  margin-bottom: 8px;
`;

export const CodeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const PasswordRow = styled.div`
  margin-bottom: 8px;
`;

export const PasswordConfirmRow = styled.div`
  margin-bottom: 8px;
`;

export const ButtonRow = styled.div`
  margin-bottom: 8px;
`;
