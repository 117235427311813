import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import getValidationErrors from './getValidationErrors';
import handleResponseError from './handleResponseError';

const handleFormDataError = (
  err: unknown,
  formRef?: React.RefObject<FormHandles>,
) => {
  if (err instanceof ValidationError && formRef) {
    const errors = getValidationErrors(err);
    formRef.current?.setErrors(errors);

    return 'Não foi possível salvar o registro! Confira os dados no formulário!';
  }

  return handleResponseError(err);
};

export default handleFormDataError;
