import React from 'react';
import { IconButton } from '@material-ui/core';
import { Add as AddIcon, AddBox as AddBoxIcon } from '@material-ui/icons';

import { Container, CustomButton } from './styles';

type Props = {
  text?: string;
  handleAdd: () => void;
};

const AddIconButton: React.FC<Props> = ({ text, handleAdd }) =>
  text ? (
    <CustomButton
      variant="text"
      color="secondary"
      startIcon={<AddIcon fontSize="medium" />}
      onClick={handleAdd}
    >
      {text}
    </CustomButton>
  ) : (
    <Container>
      <IconButton color="secondary" component="span" onClick={handleAdd}>
        <AddBoxIcon fontSize="medium" />
      </IconButton>
    </Container>
  );

export { AddIconButton };
