import { useSnackbar } from 'notistack';
import React from 'react';
import { useDrop } from 'react-dnd';
import api from '../../../services/api';
import handleResponseError from '../../../utils/handleResponseError';
import { ContainerLabel, CustomGrid } from '../style';

interface Props {
  handleDrop: () => void;
}

const DragAndDropDeleteClient: React.FC<Props> = ({ handleDrop }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [{ isOver }, dropRef] = useDrop({
    accept: 'CARD',
    async drop(item: any, monitor) {
      try {
        if (item.negotiation) {
          await api.delete(`clients/${item.negotiation.id}`);
          await handleDrop();
          enqueueSnackbar('Negociação excluída com sucesso!', {
            variant: 'success',
          });
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      currentItem: monitor.getItem(),
    }),
  });

  return (
    <CustomGrid
      ref={dropRef}
      style={{
        border: isOver ? 'dashed white 4px' : 'dashed grey 2px',
        backgroundColor: isOver ? 'gray' : 'rgba(255,255,255,.8)',
      }}
      item
      xs
    >
      <ContainerLabel
        style={{
          color: isOver ? 'white' : 'gray',
        }}
      >
        EXCLUIR
      </ContainerLabel>
    </CustomGrid>
  );
};

export default DragAndDropDeleteClient;
