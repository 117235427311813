import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useSession } from '../../../hooks/SessionContext';
import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import PermissionType from '../../../models/PermissionType';
import Process from '../../../models/Process';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import Settings from '..';
import AddEditProcesses from './AddEditProcesses';

import { Header, SearchRow } from './styles';

const Processes: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [filteredProcesses, setFilteredProcesses] = useState<Process[]>([]);

  const { user } = useSession();
  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  const loadProcesses = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get<Process[]>('processes');

      setProcesses(data);
      setFilteredProcesses(data);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading]);

  useEffect(() => {
    loadProcesses();
  }, [loadProcesses]);

  useEffect(() => {
    if (isNew) setFilteredProcesses(processes);
  }, [isNew, processes]);

  const handleSave = useCallback(async () => {
    await loadProcesses();
    setIsNew(false);
  }, [loadProcesses]);

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredProcesses(
        processes.filter(
          process =>
            searched.length === 0 ||
            process.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [processes],
  );

  return (
    <Settings route="processes">
      <PageTitle pageName="Processos" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      {user.permissions.includes(PermissionType.AdminUser) && (
        <Header>
          {!isNew ? (
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                fullWidth
                onClick={() => setIsNew(true)}
              >
                <Add fontSize="small" />
                Processo
              </Button>
            </div>
          ) : (
            <AddEditProcesses handleSave={handleSave} />
          )}
        </Header>
      )}
      {filteredProcesses.map(process => (
        <ExpansionPanel key={process.id}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Grid
              container
              style={{
                alignItems: 'center',
              }}
            >
              <Grid item>
                <Typography variant="body1">{process.name}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          {user.permissions.includes(PermissionType.AdminUser) && (
            <ExpansionPanelDetails>
              <AddEditProcesses process={process} handleSave={handleSave} />
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      ))}
    </Settings>
  );
};

export default Processes;
