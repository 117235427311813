import React, { useState } from 'react';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

import formatMobileNumber from '../../../../../utils/formatMobileNumber';

import Space from '../../../../../components/Space';
import CreateContactDialog from '../../../Contacts/CreateContactDialog';

import Message from '../../../../../models/Message';

import MessageName from '../MessageName';
import MessageInfo from '../MessageInfo';

import { Content } from './styles';

type ContactDTO = {
  name: string;
  phones: string[];
};

interface Props {
  message: Message;
}

const MessageContact: React.FC<Props> = ({ message }) => {
  const [contacts] = useState<ContactDTO[]>(JSON.parse(message.text));
  const [
    showCreateContactDialog,
    setShowCreateContactDialog,
  ] = useState<boolean>(false);
  const [suggestedContact, setSuggestedContact] = useState<{
    name?: string;
    phone?: string;
  }>({});

  return (
    <>
      <CreateContactDialog
        open={showCreateContactDialog}
        setOpen={setShowCreateContactDialog}
        sugeredName={suggestedContact.name}
        sugeredPhone={suggestedContact.phone}
      />
      <MessageName user_id={message.new_user_id} />
      <Content>
        <ul>
          {contacts.map(contact =>
            contact.phones.map(phone => (
              <>
                <ListItem
                  onClick={() => {
                    setSuggestedContact({
                      name: contact.name,
                      phone,
                    });

                    setShowCreateContactDialog(true);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText
                    primary={contact.name}
                    secondary={formatMobileNumber(phone)}
                  />
                </ListItem>
                <Space orientation="vertical" />
              </>
            )),
          )}
        </ul>
        <MessageInfo
          time={message.time}
          status={message.status}
          from_me={message.from_me}
        />
      </Content>
    </>
  );
};

export default MessageContact;
