import api from './api';

import NegotiationFile from '../models/NegotiationFile';

const findAllNegotiationFiles = async (
  negotiation_id: string,
  funnel_id: string,
) => {
  const { data } = await api.get<NegotiationFile[]>(
    `negotiations/${negotiation_id}/files?funnel_id=${funnel_id}`,
  );

  return data;
};

interface CreateNegotiationFileDTO {
  negotiation_id: string;
  file: File;
}

const createNegotiationFile = async ({
  negotiation_id,
  file,
}: CreateNegotiationFileDTO) => {
  const bytes = file.size / 1000;

  if (bytes > 1000 * 10)
    throw new Error('O tamanho máximo de arquivo permitido é 10MB.');

  const formData = new FormData();
  formData.append('file', file);

  const { data } = await api.post<NegotiationFile>(
    `negotiations/${negotiation_id}/files`,
    formData,
  );

  return data;
};

const deleteNegotiationFile = async (id: string) => {
  await api.delete(`negotiations/files/${id}`);
};

export {
  findAllNegotiationFiles,
  createNegotiationFile,
  deleteNegotiationFile,
};
