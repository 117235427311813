import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { ClearOutlined, SearchOutlined } from '@material-ui/icons';
import { useWindowDimensions } from '../../hooks/WindowDimensionsContext';
import { useGenericNegotiationSearch } from '../../hooks/GenericNegotiationSearch';

import InputOpacity from '../InputOpacity';

const InputGenericSearch: React.FC = () => {
  const { windowDimensions } = useWindowDimensions();
  const { genericName, handleGenericName } = useGenericNegotiationSearch();

  const [typing, setTyping] = useState<string | undefined>(genericName);

  return (
    <div>
      <InputOpacity
        style={{
          width: windowDimensions.resolution === 'mobile' ? 132 : 256,
        }}
        placeholder={
          windowDimensions.resolution === 'mobile'
            ? 'Pesquisar...'
            : 'Digite um nome ou telefone...'
        }
        value={typing}
        onChange={e => setTyping(e.target.value)}
        onKeyPress={e => {
          if (e.key === 'Enter') handleGenericName(typing);
        }}
        InputProps={{
          startAdornment:
            !typing || typing.length === 0 ? (
              <IconButton
                style={{
                  padding: 0,
                  paddingRight: 8,
                }}
                color="primary"
                size="small"
              >
                <SearchOutlined color="inherit" fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                style={{
                  padding: 0,
                  paddingRight: 8,
                }}
                color="primary"
                size="small"
                onClick={() => {
                  handleGenericName('');
                  setTyping('');
                }}
              >
                <ClearOutlined color="inherit" fontSize="small" />
              </IconButton>
            ),
        }}
      />
    </div>
  );
};

export default InputGenericSearch;
