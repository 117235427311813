import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { getStatusByNegotiationFunnel } from '../../../../utils/getNegotiationStatus';

import NegotiationFunnel from '../../../../models/NegotiationFunnel';

import TabFiles from '../../../Client/TabFiles';

import { Container, Title } from './styles';

interface Props {
  negotiationFunnel: NegotiationFunnel;
}

const AttachmentSession: React.FC<Props> = ({ negotiationFunnel }) => {
  const status = useMemo(
    () => getStatusByNegotiationFunnel(negotiationFunnel),
    [negotiationFunnel],
  );

  return (
    <Container>
      <Title>
        <Typography variant="h6">Anexos</Typography>
      </Title>
      <TabFiles
        status={status}
        negotiation_id={negotiationFunnel.negotiation_id}
        funnel_id={negotiationFunnel.funnel_id}
        small
      />
    </Container>
  );
};

export default AttachmentSession;
