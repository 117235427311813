import styled from 'styled-components';

export const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const LeftActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MailingsRow = styled.div`
  margin-top: 16px;
`;

export const LoadMoreButton = styled.div`
  button {
    width: 100%;
  }
`;
