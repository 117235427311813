import styled from 'styled-components';

export const Container = styled.div<{
  color: string;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  div {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    color: ${({ color }) => color};
  }
`;
