import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api';

import { ContactContext } from '../../../../hooks/ContactContext';
import { MessageContext } from '../../../../hooks/MessageContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import { ContactDTO } from '../../../../services/ContactService';

import Funnel from '../../../../models/Funnel';

import Input from '../../../../components/Input';
import InputPhone from '../../../../components/InputPhone';
import SelectForm from '../../../../components/SelectForm';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreateContact?: (contact: ContactDTO) => void;
  withoutNegotiation?: 0 | 1;
  sugeredName?: string;
  sugeredPhone?: string;
  redirect?: boolean;
}

interface FormData {
  name: string;
  phone: string;
  funnel_id: string;
}

const CreateContactDialog: React.FC<Props> = ({
  open,
  setOpen,
  handleCreateContact,
  withoutNegotiation = 0,
  sugeredName,
  sugeredPhone,
  redirect = false,
}) => {
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [minPhone, setMinPhone] = useState<number>(0);

  const formRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  useEffect(() => {
    async function loadFunnels() {
      try {
        const { data } = await api.get<Funnel[]>('funnels');

        setFunnels(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    }

    loadFunnels();
  }, [enqueueSnackbar]);

  const { createContact } = useContext(ContactContext);

  const { subscribePhone } = useContext(MessageContext);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          phone: Yup.string()
            .nullable()
            .min(minPhone, 'Preencha todos os dígitos')
            .required('Celular é obrigatório'),
          funnel_id:
            withoutNegotiation === 0
              ? Yup.string().required('Funil é obrigatório!')
              : Yup.string().notRequired(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, phone, funnel_id } = data;

        setLoading(true);

        const response = await api.post<ContactDTO>(
          'contacts/new',
          {
            name,
            phone,
            funnel_id,
          },
          {
            params: {
              withoutNegotiation,
            },
          },
        );

        await createContact(response.data, redirect);

        subscribePhone(phone);

        if (handleCreateContact) handleCreateContact(response.data);

        enqueueSnackbar('Contato cadastrado com sucesso!', {
          variant: 'success',
        });

        setOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      setOpen,
      createContact,
      handleCreateContact,
      subscribePhone,
      withoutNegotiation,
      redirect,
      minPhone,
    ],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={open}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Novo contato</DialogTitle>
      <DialogContent
        dividers
        style={{
          width: 400,
          padding: 16,
        }}
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: sugeredName,
            phone: sugeredPhone,
          }}
        >
          <Grid
            item
            style={{
              marginBottom: 8,
            }}
          >
            <Input id="name" name="name" label="Nome" />
          </Grid>
          <Grid
            item
            style={{
              marginBottom: 8,
            }}
          >
            <InputPhone
              id="phone"
              name="phone"
              label="Celular"
              length={length => setMinPhone(length)}
            />
          </Grid>
          {withoutNegotiation === 0 && (
            <Grid item>
              <SelectForm
                name="funnel_id"
                label="Funil"
                values={funnels.map(funnel => {
                  return {
                    id: funnel.id,
                    name: funnel.name,
                  };
                })}
              />
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => formRef.current?.submitForm()} color="primary">
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContactDialog;
