import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import { Divider } from '@material-ui/core';

export const ContainerGlobal = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  border-color: #ddd;
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  background: #f8f8ff;
  overflow: hidden;
  section {
    .emoji-mart-scroll {
      height: 128px;
    }
  }
`;

export const ContainerTeste = styled.div`
  height: 100%;
  padding: 8px 32px;
  display: flex;
  flex-direction: column;
`;

export const AppBar = styled.div`
  box-shadow: none;
  border-color: #ddd;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  background: #fff;
  width: 100%;
  min-height: 64px;

  textarea::-webkit-scrollbar {
    width: 6px;
  }

  textarea::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
`;

export const AppBarActions = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomDivider = styled(Divider)`
  && {
    flex: 1;
    background: transparent;
  }
`;

export const CustomScrollbar = styled(Scrollbars)`
  max-height: 100%;

  div {
    display: flex;
    flex-direction: column-reverse;
  }
`;
