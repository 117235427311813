import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import ProductModel from '../../../models/Product';

import Settings from '..';
import AddEditProduct from './AddEditProduct';

import { Header, SearchRow } from './styles';

const Products: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const loadProducts = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get('products');

      setProducts(data);
      setFilteredProducts(data);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    if (isNew) setFilteredProducts(products);
  }, [isNew, products]);

  const refresh = useCallback(async () => {
    await loadProducts();
  }, [loadProducts]);

  const handleAddProduct = () => {
    setIsNew(true);
  };

  const handleOnSave = () => {
    setIsNew(false);
  };

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredProducts(
        products.filter(
          product =>
            searched.length === 0 ||
            product.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [products],
  );

  return (
    <Settings route="products">
      <PageTitle pageName="Produtos" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      <Header>
        {!isNew ? (
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<Add fontSize="small" />}
              onClick={handleAddProduct}
            >
              Produto
            </Button>
          </div>
        ) : (
          <AddEditProduct handleOnSave={handleOnSave} refresh={refresh} />
        )}
      </Header>
      {filteredProducts.map(product => (
        <ExpansionPanel key={String(product.id)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">{product.name}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <AddEditProduct product={product} refresh={refresh} />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Settings>
  );
};

export default Products;
