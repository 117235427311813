import React, { useRef, useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormLabel,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import api from '../../../../services/api';

import { useSession } from '../../../../hooks/SessionContext';
import { useResponsible } from '../../../../hooks/ResponsibleContext';
import { useFunnel } from '../../../../hooks/FunnelContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import handleFormData from '../../../../utils/handleFormData';
import handleResponseError from '../../../../utils/handleResponseError';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../../components/Input';
import Space from '../../../../components/Space';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserInviteDialog: React.FC<Props> = ({ open, setOpen }) => {
  const [isSuperior, setIsSuperior] = useState<boolean>(false);
  const [checkedUsersIds, setCheckedUsersIds] = useState<string[]>([]);
  const [checkedFunnelsIds, setCheckedFunnelsIds] = useState<string[]>([]);

  const { user } = useSession();
  const { users } = useResponsible();
  const { funnels } = useFunnel();
  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const dataHandled = handleFormData({
          ...data,
        });

        const schema = Yup.object().shape({
          email: Yup.string().nullable().required('Email é obrigatório!'),
        });

        await schema.validate(dataHandled, {
          abortEarly: false,
        });

        const { email } = data;

        const formattedData = {
          email,
          is_superior: isSuperior,
          funnels: checkedFunnelsIds,
          users: isSuperior ? checkedUsersIds : [],
        };

        await api.post('invites', formattedData);

        setOpen(false);
        setIsSuperior(false);
        setCheckedUsersIds([]);
        setCheckedFunnelsIds([]);

        enqueueSnackbar('Convite enviado ao e-mail informado!', {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      enqueueSnackbar,
      setOpen,
      isSuperior,
      checkedUsersIds,
      checkedFunnelsIds,
    ],
  );

  const handleChangeCheckedUsers = useCallback((id: string) => {
    setCheckedUsersIds(oldCheckedUserId =>
      oldCheckedUserId.includes(id)
        ? [...oldCheckedUserId.filter(oldId => oldId !== id)]
        : [...oldCheckedUserId, id],
    );
  }, []);

  const handleChangeCheckedFunnels = useCallback((id: string) => {
    setCheckedFunnelsIds(oldCheckedFunnelId =>
      oldCheckedFunnelId.includes(id)
        ? [...oldCheckedFunnelId.filter(oldId => oldId !== id)]
        : [...oldCheckedFunnelId, id],
    );
  }, []);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
        <DialogTitle id="confirmation-dialog-title">
          Convidar Usuário
        </DialogTitle>
        <DialogContent dividers>
          <Input id="email" name="email" label="Email" type="email" />
          <Space orientation="vertical" size="16px" />
          <strong>Permissões</strong>
          <Space orientation="vertical" />
          <div>
            <Checkbox
              key="superior"
              name="superior"
              checked={isSuperior}
              onChange={() => setIsSuperior(oldIsSuperior => !oldIsSuperior)}
              style={{
                padding: 2,
                marginLeft: -4,
              }}
            />
            <FormLabel>Superior</FormLabel>
          </div>
          <Space orientation="vertical" size="16px" />
          {isSuperior && (
            <>
              <strong>Subordinados</strong>
              <Space orientation="vertical" />
              {users
                .filter(({ id, active }) => active && id !== user.id)
                .map(user => (
                  <div>
                    <Checkbox
                      key={user.id}
                      name={user.id}
                      checked={checkedUsersIds.includes(user.id)}
                      onChange={e => handleChangeCheckedUsers(e.target.name)}
                      style={{
                        padding: 2,
                        marginLeft: -4,
                      }}
                    />
                    <FormLabel>{user.name}</FormLabel>
                  </div>
                ))}
              <Space orientation="vertical" size="16px" />
            </>
          )}
          <strong>Funis</strong>
          <Space orientation="vertical" />
          {funnels.map(funnel => (
            <div>
              <Checkbox
                key={funnel.id}
                name={funnel.id}
                checked={checkedFunnelsIds.includes(funnel.id)}
                onChange={e => handleChangeCheckedFunnels(e.target.name)}
                style={{
                  padding: 2,
                  marginLeft: -4,
                }}
              />
              <FormLabel>{funnel.name}</FormLabel>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button type="submit" autoFocus color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default UserInviteDialog;
