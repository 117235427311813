import React, { createContext, useContext, useState, useCallback } from 'react';

import { getBase64 } from '../utils/showFile';

import StorageType from '../models/StorageType';

type ChatFile = {
  contact_id: string;
  message_id: string;
  url: string;
  type: 'chat';
};

type NegotiationFile = {
  negotiation_id: string;
  file_id: string;
  url: string;
  type: 'negotiation';
};

type File = ChatFile | NegotiationFile;

interface State {
  files: File[];
  addFileInCache: (file: File) => Promise<void>;
  addFilesInCache: (files: File[]) => Promise<void>;
}

export const FileCacheContext = createContext<State>({} as State);

export const FileCacheProvider: React.FC = ({ children }) => {
  const [files, setFiles] = useState<File[]>([]);

  const addFileInCache = useCallback(
    async (file: File): Promise<void> => {
      const findedFile: File | undefined =
        file.type === 'chat'
          ? files.find(
              oldFile =>
                oldFile.type === file.type &&
                oldFile.contact_id === file.contact_id &&
                oldFile.message_id === file.message_id,
            )
          : files.find(
              oldFile =>
                oldFile.type === file.type &&
                oldFile.negotiation_id === file.negotiation_id &&
                oldFile.file_id === file.file_id,
            );

      if (findedFile) {
        setFiles(oldFiles => [
          findedFile,
          ...oldFiles.filter(oldFile => oldFile.url !== findedFile.url),
        ]);
      } else {
        const base64 = await getBase64(file.url, StorageType.Chat);

        if (base64) {
          const response = await fetch(base64);

          const blob = await response.blob();

          setFiles(oldFiles => {
            const url = URL.createObjectURL(blob);

            const exceededFiles = oldFiles.slice(48);

            const urls = exceededFiles.map(file => file.url);

            urls.forEach(url => URL.revokeObjectURL(url));

            return [
              {
                ...file,
                url,
              },
              ...oldFiles.filter(oldFile => !urls.includes(oldFile.url)),
            ];
          });
        }
      }
    },
    [files],
  );

  const addFilesInCache = useCallback(
    async (files: File[]): Promise<void> => {
      for (const file of files) {
        await addFileInCache(file);
      }
    },
    [addFileInCache],
  );

  return (
    <FileCacheContext.Provider
      value={{
        files,
        addFileInCache,
        addFilesInCache,
      }}
    >
      {children}
    </FileCacheContext.Provider>
  );
};

export const useFileCache = (): State => {
  const context = useContext(FileCacheContext);

  if (!context)
    throw new Error('useFileCache must be used within a FileCacheProvider');

  return context;
};
