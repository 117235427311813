import styled from 'styled-components';

const Container = styled.div<{
  hidden: boolean;
}>`
  margin-left: 56px;
  display: ${props => (props.hidden ? 'none' : undefined)};
`;

export { Container };
