import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import Negotiation from '../../../models/Negotiation';
import ChooseLossReason from '../../../pages/Client/Header/ChooseLossReason';
import api from '../../../services/api';
import handleResponseError from '../../../utils/handleResponseError';
import { ContainerLabel, CustomGrid } from '../style';

interface Props {
  handleDrop: () => void;
}

const DragAndDropLoseClient: React.FC<Props> = ({ handleDrop }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [chooseLossReason, setChooseLossReason] = useState<boolean>(false);
  const [negotiation, setNegotiation] = useState<Negotiation>();

  const [{ isOver }, dropRef] = useDrop({
    accept: 'CARD',
    async drop(item: any, monitor) {
      setNegotiation(item.negotiation);
      setChooseLossReason(true);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      currentItem: monitor.getItem(),
    }),
  });

  const handleLossNegotiation = useCallback(
    async (loss_reason_id: string) => {
      try {
        await api.post(`clients/${negotiation?.id}/negotiations`, {
          won: false,
          loss_reason_id,
        });

        setChooseLossReason(false);

        handleDrop();

        enqueueSnackbar('Negociação concluída com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [negotiation, enqueueSnackbar, handleDrop],
  );

  return (
    <>
      {chooseLossReason && negotiation && (
        <ChooseLossReason
          chooseLossReason={chooseLossReason}
          funnelId={negotiation.negotiation_funnel.funnel_id}
          setChooseLossReason={() => {
            handleDrop();
            setChooseLossReason(false);
          }}
          handleLossNegotiation={handleLossNegotiation}
        />
      )}
      <CustomGrid
        ref={dropRef}
        style={{
          border: isOver ? 'dashed white 4px' : 'dashed grey 2px',
          backgroundColor: isOver ? 'red' : 'rgba(255,255,255,.8)',
        }}
        item
        xs
      >
        <ContainerLabel
          style={{
            color: isOver ? 'white' : 'red',
          }}
        >
          PERDER
        </ContainerLabel>
      </CustomGrid>
    </>
  );
};

export default DragAndDropLoseClient;
