import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

interface State {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export const LoaderContext = createContext<State>({} as State);

export const LoaderProvider: React.FC = ({ children }) => {
  const [count, setCount] = useState<number>(0);

  const setLoading = useCallback((value: boolean) => {
    setCount(oldCount => {
      if (value) return ++oldCount;

      if (oldCount > 1) return --oldCount;

      return 0;
    });
  }, []);

  const loading = useMemo(() => count !== 0, [count]);

  return (
    <LoaderContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = (): State => {
  const context = useContext(LoaderContext);

  if (!context)
    throw new Error('useLoader must be used within a LoaderProvider');

  return context;
};
