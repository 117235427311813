enum MessageType {
  Chat = 'chat',
  Image = 'image',
  Ptt = 'ptt',
  Document = 'document',
  Audio = 'audio',
  Video = 'video',
  Location = 'location',
  CallLog = 'call_log',
  Card = 'vcard',
  Multiple_Card = 'multi_vcard',
  Contacts = 'contacts',
}

export default MessageType;
