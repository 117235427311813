import styled from 'styled-components';
import { DialogContent } from '@material-ui/core';

export const CustomDialogContent = styled(DialogContent)`
  && {
    display: flex;
    align-items: center;

    svg {
      color: #ffbf00;
    }

    p {
      margin-left: 8px;
      font-size: 18px;
    }
  }
`;
