import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';

import apiWhatsapp from '../services/api_whatsapp';

import MessageTemplate, {
  Component,
  HeaderComponent,
  BodyComponent,
} from '../models/MessageTemplate';

export interface TemplateMessageDTO
  extends Omit<MessageTemplate, 'components'> {
  body: string;
  header?: 'IMAGE' | 'DOCUMENT';
}

export type CreateTemplateMessageDTO = {
  category: number;
  name: string;
  message: string;
  allow_category_change?: boolean;
  default_file_id?: string;
  body_examples?: string[];
};

export type DeleteTemplateMessageDTO = {
  name: string;
};

type State = {
  messageTemplates: TemplateMessageDTO[];
  handleCreateMessageTemplate: (
    data: CreateTemplateMessageDTO,
  ) => Promise<void>;
  handleDeleteMessageTemplate: (
    data: DeleteTemplateMessageDTO,
  ) => Promise<void>;
};

const MessageTemplateContext = createContext<State>({} as State);

const MessageTemplateProvider: React.FC = ({ children }) => {
  const [messageTemplates, setMessageTemplates] = useState<
    TemplateMessageDTO[]
  >([]);

  const handleComponents = useCallback((components: Component[]): {
    body: string;
    header?: 'IMAGE' | 'DOCUMENT';
  } => {
    const header: HeaderComponent | undefined = components.find(
      ({ type }) => type.toLowerCase() === 'header',
    ) as HeaderComponent | undefined;
    const body: BodyComponent = components.find(
      ({ type }) => type.toLowerCase() === 'body',
    ) as BodyComponent;

    return {
      header: header?.format,
      body: body.text,
    };
  }, []);

  useEffect(() => {
    const load = async () => {
      const { data } = await apiWhatsapp.get<MessageTemplate[]>(
        'messages/templates',
      );

      const mappedData: TemplateMessageDTO[] = data
        .filter(
          ({ components }) =>
            components.length ===
            components.filter(
              ({ type }) =>
                type.toLowerCase() === 'header' ||
                type.toLowerCase() === 'body',
            ).length,
        )
        .map(({ components, ...rest }) => {
          const { header, body } = handleComponents(components);

          return {
            ...rest,
            header,
            body,
          };
        });

      setMessageTemplates(mappedData);
    };

    load();
  }, [handleComponents]);

  const handleCreateMessageTemplate = useCallback(
    async ({
      category,
      name,
      message,
      allow_category_change,
      default_file_id,
      body_examples,
    }: CreateTemplateMessageDTO): Promise<void> => {
      const { data } = await apiWhatsapp.post<MessageTemplate>(
        '/messages/templates/new',
        {
          category: category === 1 ? 'UTILITY' : 'MARKETING',
          name,
          message,
          allow_category_change,
          default_file_id,
          body_examples,
        },
      );

      const { components, ...rest } = data;

      const { header, body } = handleComponents(components);

      setMessageTemplates(oldMessageTemplates => [
        {
          ...rest,
          header,
          body,
        },
        ...oldMessageTemplates,
      ]);
    },
    [handleComponents],
  );

  const handleDeleteMessageTemplate = useCallback(
    async ({ name }: DeleteTemplateMessageDTO): Promise<void> => {
      await apiWhatsapp.delete<void>(`/messages/templates/${name}`);

      setMessageTemplates(oldMessageTemplates =>
        oldMessageTemplates.filter(
          messageTemplates => messageTemplates.name !== name,
        ),
      );
    },
    [],
  );

  return (
    <MessageTemplateContext.Provider
      value={{
        messageTemplates,
        handleCreateMessageTemplate,
        handleDeleteMessageTemplate,
      }}
    >
      {children}
    </MessageTemplateContext.Provider>
  );
};

const useMessageTemplate = (): State => {
  const context = useContext(MessageTemplateContext);

  if (!context) {
    throw new Error(
      'useMessageTemplate must be used within a MessageTemplateProvider',
    );
  }

  return context;
};

export { MessageTemplateContext, MessageTemplateProvider, useMessageTemplate };
