import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { useSnackbar } from 'notistack';
import {
  IconButton,
  Typography,
  TextField,
  Toolbar,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@mui/material';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MessageIcon from '@material-ui/icons/Message';
import {
  ArrowBack,
  ErrorOutline,
  Person,
  MicOutlined,
  Send,
  SearchOutlined,
  Folder,
  InsertEmoticon,
  Stop,
  Clear,
} from '@material-ui/icons';
import {
  addHours,
  format,
  isAfter,
  isBefore,
  parseISO,
  subHours,
  subMinutes,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useHistory, useLocation } from 'react-router-dom';
import { Picker } from 'emoji-mart';

import apiWhatsapp from '../../../services/api_whatsapp';
import {
  getBase64,
  getFileFromBase64,
  sendFile,
  sendPtt,
} from '../../../services/MessageService';

import { ContactContext } from '../../../hooks/ContactContext';
import { FunnelContext } from '../../../hooks/FunnelContext';
import { MessageContext } from '../../../hooks/MessageContext';
import { useLoader } from '../../../hooks/LoaderContext';
import { useWindowDimensions } from '../../../hooks/WindowDimensionsContext';
import { useSession } from '../../../hooks/SessionContext';
import { useDefaultFiles } from '../../../hooks/DefaultFilesContext';

import handleResponseError from '../../../utils/handleResponseError';
import getNegotiationStatus from '../../../utils/getNegotiationStatus';

import { ContactDTO } from '../../../services/ContactService';

import MessageType from '../../../models/MessageType';
import Negotiation from '../../../models/Negotiation';

import EditContactDialog from '../../../components/EditContactDialog';
import DragAndDropFile from '../../../components/DragAndDropFile/DragAndDropFile';
import PreviewFile from '../../../components/PreviewFile';

import ChooseContact from '../../Client/ChooseContact';
import { ButtonBlue } from '../../Client/Header/styles';
import Message from './Message';
import MessageTemplateChoose from './MessageTemplateChoose';
import DefaultFileChoose from './DefaultFileChoose';
import { Content } from './Message/styles';

import {
  AppBar,
  CustomDivider,
  ContainerTeste,
  ContainerGlobal,
  CustomScrollbar,
} from './styles';

interface Props {
  contact?: ContactDTO;
  negotiation?: Negotiation;
}

const ChatContact: React.FC<Props> = ({ negotiation }) => {
  const { contact } = useLocation<Props>().state || {};
  const [text, setText] = useState<string>('');
  const {
    selectedContact = contact,
    updateContact,
    updateMessageKey,
  } = useContext(ContactContext);
  const [showFooter, setShowFooter] = useState<
    'emotes' | 'files' | 'templates' | null
  >(null);
  const [file, setFile] = useState<any | undefined>();
  const [nameFile, setNameFile] = useState<string | undefined>('');
  const [image, setImage] = useState<any | undefined>();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null,
  );
  const [audioData, setAudioData] = useState<{
    blob: Blob;
    base64: string;
  } | null>(null);
  const { selectedFunnel } = useContext(FunnelContext);
  const { setLoading } = useLoader();
  const { windowDimensions } = useWindowDimensions();
  const { user } = useSession();
  const { getDefaultFileBase64 } = useDefaultFiles();
  const history = useHistory();
  const { changeSearchMessage, keySearch } = useContext(MessageContext);
  const [isExpiredConversation, setExpiredConversation] = useState<boolean>(
    true,
  );
  const [showChooseContact, setShowChooseContact] = useState<boolean>(false);

  const hasWhatsapp = useMemo(
    (): boolean => !!selectedContact && selectedContact.whatsapp,
    [selectedContact],
  );

  const isChatbotRunning = useMemo(
    (): boolean =>
      !!selectedContact &&
      selectedContact.chatbot !== null &&
      !selectedContact.chatbot.ended_at,
    [selectedContact],
  );

  const checkExpiredConversation = useCallback((): boolean => {
    if (!hasWhatsapp) return true;

    if (!selectedContact) return true;

    if (!selectedContact.last_received_message_date) return true;

    const lastReceivedMessageDate = parseISO(
      selectedContact.last_received_message_date,
    );

    if (!selectedContact.conversation_expiration_date)
      return isBefore(addHours(lastReceivedMessageDate, 24), new Date());

    const conversationExpirationDate = parseISO(
      selectedContact.conversation_expiration_date,
    );

    if (
      isBefore(new Date(), addHours(lastReceivedMessageDate, 24)) &&
      isBefore(
        addHours(lastReceivedMessageDate, 24),
        conversationExpirationDate,
      )
    )
      return false;

    if (isAfter(lastReceivedMessageDate, conversationExpirationDate))
      return isBefore(addHours(lastReceivedMessageDate, 24), new Date());

    return (
      isBefore(conversationExpirationDate, new Date()) ||
      isBefore(
        lastReceivedMessageDate,
        subHours(subMinutes(conversationExpirationDate, 1), 24),
      )
    );
  }, [hasWhatsapp, selectedContact]);

  useEffect(() => setExpiredConversation(checkExpiredConversation()), [
    checkExpiredConversation,
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSendFile = useCallback(async () => {
    try {
      setLoading(true);

      setFile(null);
      setNameFile('');
      setImage(null);

      if (selectedContact && nameFile) {
        const base64 = file;
        const real_file = image;
        const filename = nameFile;

        const { phone } = selectedContact;

        const temp_key = `${new Date().getTime()}-${phone}`;

        let type: MessageType;

        switch (real_file.type) {
          case 'image/jpeg':
          case 'image/png':
            type = MessageType.Image;
            break;
          case 'video/mp4':
            type = MessageType.Video;
            break;
          default:
            type = MessageType.Document;
        }

        updateContact({
          key: temp_key,
          from_me: true,
          text: filename,
          base64,
          phone,
          unread_message: false,
          type,
          time: new Date(),
          new_user_id: user.id,
        });

        const key = await sendFile({
          phone,
          file: real_file,
        });

        updateMessageKey(phone, key, temp_key);
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    setLoading,
    updateContact,
    updateMessageKey,
    user.id,
    selectedContact,
    file,
    nameFile,
    image,
  ]);

  const handleSendMessageText = useCallback(async () => {
    const handledText = text.trim();

    if (selectedContact && handledText.length > 0) {
      const temp_key = `${new Date().getTime()}-${selectedContact.phone}`;

      updateContact({
        key: temp_key,
        from_me: true,
        text: handledText,
        phone: selectedContact.phone,
        unread_message: false,
        type: MessageType.Chat,
        time: new Date(),
        new_user_id: user.id,
      });

      setText('');

      const { phone } = selectedContact;

      try {
        const { data } = await apiWhatsapp.post<string>('messages/new', {
          phone,
          text: handledText,
        });
        updateMessageKey(selectedContact.phone, data, temp_key);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    }
  }, [
    user.id,
    selectedContact,
    text,
    updateContact,
    updateMessageKey,
    enqueueSnackbar,
  ]);

  const handleSendMessageTemplate = useCallback(
    async (
      text: string,
      name: string,
      params: string[],
      default_file_id?: string,
      type?: MessageType,
    ) => {
      if (selectedContact) {
        const { phone } = selectedContact;

        const temp_key = `${new Date().getTime()}-${phone}`;

        const handledType = type || MessageType.Chat;

        if (handledType === MessageType.Chat) {
          updateContact({
            key: temp_key,
            from_me: true,
            text,
            phone,
            unread_message: false,
            type: handledType,
            time: new Date(),
            new_user_id: user.id,
          });
        } else {
          if (!default_file_id)
            throw new Error('Campo default_file_id é obrigatório!');

          const base64 = await getDefaultFileBase64({
            id: default_file_id,
          });

          updateContact({
            key: temp_key,
            from_me: true,
            text,
            caption: text,
            phone,
            unread_message: false,
            type: handledType,
            time: new Date(),
            base64,
            base64_status: 'finish',
            new_user_id: user.id,
          });
        }

        setText('');

        const { data } = await apiWhatsapp.post<string>('messages/templates', {
          phone,
          text,
          name,
          params,
          default_file_id,
          contact: {
            name: selectedContact.name,
            temp_message_id: temp_key,
          },
        });

        updateMessageKey(selectedContact.phone, data, temp_key);
      }
    },
    [
      updateContact,
      updateMessageKey,
      getDefaultFileBase64,
      user.id,
      selectedContact,
    ],
  );

  const confirmImage = useCallback(
    async e => {
      try {
        const files: File[] = Array.from(e?.target?.files ?? e);

        const file = files[0];

        const { base64 } = await getBase64({
          file,
        });

        if (base64) {
          setFile(base64);
          setNameFile(file.name);
          setImage(file);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, setFile, setImage],
  );

  const handleChoosedDefaultFile = useCallback(
    async ({
      base64,
      filename,
      mimetype,
    }: {
      base64: string;
      filename: string;
      mimetype: string;
    }): Promise<void> => {
      try {
        setLoading(true);

        const file = await getFileFromBase64({
          base64,
          filename,
          mimetype,
        });

        setFile(base64);
        setNameFile(file.name);
        setImage(file);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  const handleDateMensage = useCallback(
    (message, index: number) => {
      if (selectedContact?.messages[index + 1]?.time) {
        const date: Date = selectedContact?.messages[index + 1]?.time
          ? selectedContact?.messages[index + 1]?.time
          : message?.time;
        return (
          format(zonedTimeToUtc(message?.time, 'America/Sao_Paulo'), 'dd') !==
          format(zonedTimeToUtc(date, 'America/Sao_Paulo'), 'dd')
        );
      }
      return true;
    },
    [selectedContact],
  );

  const handleRecordAudio = useCallback(async () => {
    let mimeType: string | undefined;

    if (MediaRecorder.isTypeSupported('audio/webm; codecs=opus'))
      mimeType = 'audio/webm; codecs=opus';

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    const mediaRecorder = new MediaRecorder(mediaStream, {
      mimeType,
    });

    const chunks: Blob[] = [];

    mediaRecorder.ondataavailable = ({ data }) => chunks.push(data);

    mediaRecorder.onstop = async () => {
      mediaStream.getTracks().forEach(track => track.stop());

      const blob = new Blob(chunks, { type: mimeType });

      const arrayBuffer = await blob.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const base64 = `data:audio/ogg;base64,${buffer.toString('base64')}`;

      setAudioData({
        blob,
        base64,
      });
    };

    mediaRecorder.start();

    setMediaRecorder(mediaRecorder);
  }, []);

  const handleSendAudio = useCallback(async () => {
    setMediaRecorder(null);
    setAudioData(null);

    if (selectedContact && audioData) {
      const { phone } = selectedContact;
      const { blob, base64 } = audioData;

      const temp_key = `${new Date().getTime()}-${phone}`;

      updateContact({
        key: temp_key,
        from_me: true,
        text: 'audio.ogg',
        base64,
        phone,
        unread_message: false,
        type: MessageType.Audio,
        time: new Date(),
        new_user_id: user.id,
      });

      const { id, base64: outputBase64 } = await sendPtt({
        phone,
        blob,
      });

      updateMessageKey(phone, id, temp_key, outputBase64);
    }
  }, [user.id, selectedContact, audioData, updateContact, updateMessageKey]);

  const handleCancelAudio = useCallback(async () => {
    setMediaRecorder(null);
    setAudioData(null);
  }, []);

  const handleStopAudio = useCallback(async () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    } else {
      handleCancelAudio();
    }
  }, [handleCancelAudio, mediaRecorder]);

  const navigateToEditNegotiation = useCallback(() => {
    if (negotiation) {
      const status = getNegotiationStatus(negotiation);

      history.push(`clients/${negotiation.id}/edit`, {
        contact: selectedContact,
        negotiation: {
          ...negotiation,
          contact: negotiation.contact ?? selectedContact,
        },
        steps: selectedFunnel?.steps,
        funnelId: selectedFunnel?.id,
        status,
      });
    }
  }, [history, selectedFunnel, selectedContact, negotiation]);

  const addEmoji = useCallback(
    e => {
      const sym = e.unified.split('-');
      const codesArray: any[] = [];
      sym.forEach((el: any) => codesArray.push(`0x${el}`));
      const emoji = String.fromCodePoint(...codesArray);
      setText(text + emoji);
    },
    [text, setText],
  );

  const keyDownFunction = useCallback(
    e => {
      if (e.key === 'Escape') {
        setImage(null);
        setFile(null);
        setNameFile('');
      } else if (e.key === 'Enter' && image) {
        handleSendFile();
      }
    },
    [image, setFile, setImage, handleSendFile],
  );

  const scrollTo = useCallback(() => {
    if (keySearch) {
      const elmnt = document.getElementById(keySearch);
      elmnt?.scrollIntoView();
    }
  }, [keySearch]);

  useEffect(() => {
    document.addEventListener('keydown', keyDownFunction, false);
    scrollTo();
    return () => {
      document.removeEventListener('keydown', keyDownFunction, false);
    };
  }, [keyDownFunction, scrollTo, keySearch]);

  const handleSendContact = useCallback(
    async (contact?: ContactDTO) => {
      if (selectedContact && contact) {
        const temp_key = `${new Date().getTime()}-${selectedContact.phone}`;

        updateContact({
          key: temp_key,
          from_me: true,
          text: JSON.stringify([
            {
              name: contact.name,
              phones: [contact.phone],
            },
          ]),
          phone: selectedContact.phone,
          unread_message: false,
          type: MessageType.Contacts,
          time: new Date(),
          new_user_id: user.id,
        });

        setShowChooseContact(false);

        const { phone } = selectedContact;

        try {
          const { data } = await apiWhatsapp.post<string>('messages/contact', {
            phone,
            contact,
          });

          updateMessageKey(selectedContact.phone, data, temp_key);
        } catch (err) {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } else {
        setShowChooseContact(false);
      }
    },
    [
      user.id,
      selectedContact,
      updateContact,
      updateMessageKey,
      enqueueSnackbar,
    ],
  );

  return (
    <ContainerGlobal>
      {showChooseContact && (
        <ChooseContact
          title="Compartilhar contato"
          handleClose={handleSendContact}
        />
      )}
      <AppBar>
        {selectedContact && (
          <Toolbar>
            {windowDimensions.resolution === 'mobile' && (
              <IconButton
                edge="start"
                color="primary"
                onClick={() => history.goBack()}
                style={{
                  padding: 0,
                  paddingRight: 4,
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
            {!hasWhatsapp && (
              <Tooltip title="Contato sem número de whatsapp válido, não é possível interagir com o chat!">
                <IconButton>
                  <ErrorOutline htmlColor="#EEAD2D" />
                </IconButton>
              </Tooltip>
            )}
            <Avatar src={selectedContact?.photo} alt={selectedContact?.name} />
            <EditContactDialog
              phone={selectedContact.phone}
              name={selectedContact.name}
            />
            <CustomDivider />
            {negotiation && (
              <ButtonBlue onClick={() => navigateToEditNegotiation()}>
                Ver Cliente
              </ButtonBlue>
            )}
            {selectedContact.messages.length > 0 && (
              <IconButton
                onClick={() => changeSearchMessage(true)}
                edge="end"
                color="primary"
                component="span"
              >
                <SearchOutlined />
              </IconButton>
            )}
            {hasWhatsapp && (
              <>
                <IconButton
                  onClick={() => setShowChooseContact(true)}
                  edge="end"
                  color="primary"
                  component="span"
                  disabled={isChatbotRunning || isExpiredConversation}
                >
                  <Person />
                </IconButton>
                {!isChatbotRunning && !isExpiredConversation && (
                  <input
                    style={{
                      display: 'none',
                    }}
                    id="chat-upload"
                    type="file"
                    onChange={confirmImage}
                    onClick={event => {
                      event.currentTarget.value = ''; //eslint-disable-line
                    }}
                  />
                )}
                <label htmlFor="chat-upload">
                  <IconButton
                    edge="end"
                    color="primary"
                    component="span"
                    disabled={isChatbotRunning || isExpiredConversation}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </label>
              </>
            )}
          </Toolbar>
        )}
      </AppBar>
      {isChatbotRunning && (
        <Alert severity="warning">
          O chatbot está em execução, aguarde a conclusão para responder.
        </Alert>
      )}
      <DragAndDropFile
        handleDrop={
          !hasWhatsapp || isChatbotRunning || isExpiredConversation
            ? () => null
            : confirmImage
        }
        disabled={!hasWhatsapp || isChatbotRunning || isExpiredConversation}
      >
        <CustomScrollbar autoHide>
          <ContainerTeste>
            {selectedContact && (
              <>
                {selectedContact.messages.map((message, index) => (
                  <>
                    <div id={`${message.key}`}>
                      <Message key={message.key} message={message} />
                    </div>
                    {message?.time && handleDateMensage(message, index) && (
                      <Content
                        style={{
                          marginLeft: '45%',
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                        me={false}
                        isImageOrPdf={false}
                      >
                        <Typography>
                          {format(
                            zonedTimeToUtc(message?.time, 'America/Sao_Paulo'),
                            'dd/MM/yyyy',
                          )}
                        </Typography>
                      </Content>
                    )}
                  </>
                ))}
              </>
            )}
          </ContainerTeste>
        </CustomScrollbar>
      </DragAndDropFile>
      {hasWhatsapp && !isChatbotRunning && !isExpiredConversation && (
        <div
          style={{
            borderTop: '1px solid #ddd',
          }}
        >
          <AppBar>
            {selectedContact && (
              <>
                <Toolbar>
                  <Tooltip title="Emotes">
                    <IconButton
                      edge="start"
                      color={showFooter === 'emotes' ? 'primary' : 'default'}
                      onClick={() => {
                        setShowFooter(oldShowFooter =>
                          oldShowFooter === 'emotes' ? null : 'emotes',
                        );
                      }}
                    >
                      <InsertEmoticon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Arquivos Padrões">
                    <IconButton
                      edge="start"
                      color={showFooter === 'files' ? 'primary' : 'default'}
                      onClick={() => {
                        setShowFooter(oldShowFooter =>
                          oldShowFooter === 'files' ? null : 'files',
                        );
                      }}
                    >
                      <Folder />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Mensagens de Template">
                    <IconButton
                      edge="start"
                      color={showFooter === 'templates' ? 'primary' : 'default'}
                      onClick={() => {
                        setShowFooter(oldShowFooter =>
                          oldShowFooter === 'templates' ? null : 'templates',
                        );
                      }}
                    >
                      <MessageIcon />
                    </IconButton>
                  </Tooltip>
                  {audioData === null ? (
                    <TextField
                      style={{
                        marginTop: '1rem',
                        marginBottom: '1rem',
                      }}
                      name="text"
                      id="outlined-basic"
                      label="Digite uma mensagem"
                      variant="outlined"
                      fullWidth
                      size="small"
                      multiline
                      maxRows={5}
                      onPaste={e => confirmImage(e.clipboardData.files)}
                      value={text}
                      onChange={e => setText(e.target.value.trimStart())}
                    />
                  ) : (
                    <audio
                      src={audioData.base64}
                      controls
                      autoPlay
                      style={{
                        width: '100%',
                      }}
                    />
                  )}
                  <CustomDivider />
                  {audioData ? (
                    <>
                      <IconButton
                        edge="end"
                        color="primary"
                        onClick={handleSendAudio}
                      >
                        <Send />
                      </IconButton>
                      <IconButton
                        edge="end"
                        color="primary"
                        onClick={handleCancelAudio}
                      >
                        <Clear />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      {mediaRecorder ? (
                        <IconButton
                          edge="end"
                          color="primary"
                          onClick={handleStopAudio}
                        >
                          <Stop />
                        </IconButton>
                      ) : (
                        <>
                          {text.trim().length > 0 ? (
                            <IconButton
                              edge="end"
                              color="primary"
                              onClick={handleSendMessageText}
                            >
                              <Send />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              color="primary"
                              onClick={handleRecordAudio}
                            >
                              <MicOutlined />
                            </IconButton>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Toolbar>
              </>
            )}
          </AppBar>
        </div>
      )}
      {hasWhatsapp &&
        !isChatbotRunning &&
        (isExpiredConversation || showFooter === 'templates') && (
          <MessageTemplateChoose
            handleSendMessageTemplate={handleSendMessageTemplate}
          />
        )}
      {showFooter === 'emotes' && (
        <Picker
          showSkinTones={false}
          showPreview={false}
          style={{ width: '100%' }}
          onSelect={addEmoji}
          i18n={{
            search: 'Pesquisar emoji',
            notfound: 'Nenhum emoji encontrado',
            categories: {
              search: 'Resultados da pesquisa',
              recent: 'Usado frequentemente',
              people: 'Pessoas e corpo',
              nature: 'Animais e Natureza',
              foods: 'Alimentos e Bebidas',
              activity: 'Atividade',
              places: 'Viagem e lugares',
              objects: 'Objetos',
              symbols: 'Simbolos',
              flags: 'Bandeiras',
              custom: 'Personalizadas',
            },
          }}
        />
      )}
      {showFooter === 'files' && (
        <DefaultFileChoose
          handleChoosedDefaultFile={handleChoosedDefaultFile}
        />
      )}
      {file && (
        <PreviewFile
          base64={file}
          name={nameFile}
          confirm={() => handleSendFile()}
          cancel={() => {
            setFile(null);
            setImage(null);
            setNameFile('');
          }}
        />
      )}
    </ContainerGlobal>
  );
};

export default ChatContact;
