import React from 'react';
import { AppBar } from '@material-ui/core';

import { Title } from './styles';

interface Props {
  pageName: string;
  variant?: 'h3';
}

const PageTitle: React.FC<Props> = ({ pageName, variant }) => {
  return (
    <AppBar position="static">
      <Title variant={variant}>{pageName}</Title>
    </AppBar>
  );
};

export default PageTitle;
