import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { SessionContext } from '../../hooks/SessionContext';

import PermissionType from '../../models/PermissionType';

import { Container, CustomTabs, CustomTab } from './styles';

interface Props {
  route?: string;
}

const Settings: React.FC<Props> = ({ children, route = 'profiles' }) => {
  const { user, waba_configured } = useContext(SessionContext);

  const history = useHistory();

  const isAdmin = useMemo(() => {
    return user.permissions.includes(PermissionType.AdminUser);
  }, [user.permissions]);

  const isSuperior = useMemo(() => {
    return user.permissions.includes(PermissionType.Superior);
  }, [user.permissions]);

  return (
    <Container>
      <CustomTabs orientation="vertical" variant="scrollable" value={route}>
        {isAdmin && !waba_configured && (
          <CustomTab
            label="Vincular Whatsapp"
            value="link-whatsapp"
            onClick={() => history.replace('link-whatsapp')}
          />
        )}
        <CustomTab
          label="Perfil"
          value="profiles"
          onClick={() => history.replace('profiles')}
        />
        {(isAdmin || isSuperior) && (
          <CustomTab
            label="Usuários"
            value="users"
            onClick={() => history.replace('users')}
          />
        )}
        {(isAdmin || isSuperior) && (
          <CustomTab
            label="Equipes"
            value="teams"
            onClick={() => history.replace('teams')}
          />
        )}
        <CustomTab
          label="Funis"
          value="funnels"
          onClick={() => history.replace('funnels')}
        />
        {isAdmin && (
          <CustomTab
            label="Processos"
            value="processes"
            onClick={() => history.replace('processes')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Produtos"
            value="products"
            onClick={() => history.replace('products')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Canais de Prospecção"
            value="channels"
            onClick={() => history.replace('channels')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Motivos de Perda"
            value="losses-reasons"
            onClick={() => history.replace('losses-reasons')}
          />
        )}
        {(isAdmin || isSuperior) && (
          <CustomTab
            label="Mailings"
            value="mailings"
            onClick={() => history.replace('mailings')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Mensagens de Template"
            value="template-messages"
            onClick={() => history.replace('template-messages')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Mensagens Automáticas"
            value="automatic-messages"
            onClick={() => history.replace('automatic-messages')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Arquivos Padrões"
            value="default-files"
            onClick={() => history.replace('default-files')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Chatbots"
            value="chatbots"
            onClick={() => history.replace('chatbots')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Credencial de Acesso Externo"
            value="external-access-credential"
            onClick={() => history.replace('external-access-credential')}
          />
        )}
        {isAdmin && (
          <CustomTab
            label="Exportar Dados"
            value="data-export"
            onClick={() => history.replace('data-export')}
          />
        )}
      </CustomTabs>
      <Box style={{ width: '100%' }}>{children}</Box>
    </Container>
  );
};

export default Settings;
