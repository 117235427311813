import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  FormLabel,
  Typography,
  Grid,
  Divider,
  IconButton,
} from '@material-ui/core';
import { Add as AddIcon, Clear as ClearIcon } from '@material-ui/icons';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import api from '../../../../../services/api';

import { useLoader } from '../../../../../hooks/LoaderContext';

import getValidationErrors from '../../../../../utils/getValidationErrors';
import handleResponseError from '../../../../../utils/handleResponseError';

import ChatbotRule from '../../../../../models/ChatbotRule';
import ChatbotTestPhone from '../../../../../models/ChabotTestPhone';

import Button from '../../../../../components/Button';
import InputPhone from '../../../../../components/InputPhone';
import SelectForm from '../../../../../components/SelectForm';

import { Time } from '../index';

import { ActionsRow, Container, Item, Section } from './styles';

type RuleInput = {
  chatbot_id?: string;
  start_hour?: string;
  start_minute?: string;
  end_hour?: string;
  end_minute?: string;
};

type PhoneInput = {
  phone?: string;
};

type RuleOutput = {
  chatbot_id: string;
  start_hour: string;
  start_minute: string;
  end_hour: string;
  end_minute: string;
};

type PhoneOutput = {
  phone: string;
};

type FormInput = {
  rules: RuleInput[];
  phones: PhoneInput[];
};

type FormOutput = {
  rules: RuleOutput[];
  phones: PhoneOutput[];
};

type ChatbotRuleDTO = {
  chatbot_id: string;
  start: number;
  end: number;
};

type Props = {
  title: string;
  test?: boolean;
  goBack: () => void;
  chatbots: {
    id: string;
    name: string;
  }[];
};

export const ChatbotRules: React.FC<Props> = ({
  title,
  test = false,
  goBack,
  chatbots,
}) => {
  const [rules, setRules] = useState<RuleInput[]>([]);
  const [phones, setPhones] = useState<PhoneInput[]>([]);

  const formRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  const handleChangeInput = useCallback(
    (index: number, data: RuleInput) =>
      setRules(oldRules => {
        oldRules[index] = {
          ...oldRules[index],
          ...data,
        };

        return oldRules;
      }),
    [],
  );

  const handleChangePhone = useCallback(
    (index: number, data: PhoneInput) =>
      setPhones(oldPhones => {
        oldPhones[index] = {
          ...oldPhones[index],
          ...data,
        };

        return oldPhones;
      }),
    [],
  );

  const getHourMinuteFromTime = useCallback((time: number): {
    hour: number;
    minute: number;
  } => {
    const minute = time % 60;
    const hour = (time - minute) / 60;

    return {
      hour,
      minute,
    };
  }, []);

  const getTimeFromHourMinute = useCallback(
    (hour: number, minute: number): number => hour * 60 + minute,
    [],
  );

  const addOneMinuteToTime = useCallback(
    (time: number): number => {
      let { hour, minute } = getHourMinuteFromTime(time);

      if (minute === 59) {
        minute = 0;

        if (hour === 23) {
          hour = 0;
        } else {
          hour += 1;
        }
      } else {
        minute += 1;
      }

      return getTimeFromHourMinute(hour, minute);
    },
    [getHourMinuteFromTime, getTimeFromHourMinute],
  );

  const subOneMinuteFromTime = useCallback(
    (time: number): number => {
      let { hour, minute } = getHourMinuteFromTime(time);

      if (minute === 0) {
        minute = 59;

        if (hour === 0) {
          hour = 23;
        } else {
          hour -= 1;
        }
      } else {
        minute -= 1;
      }

      return getTimeFromHourMinute(hour, minute);
    },
    [getHourMinuteFromTime, getTimeFromHourMinute],
  );

  const formatTime = useCallback((hour: number, minute: number): {
    hour: string;
    minute: string;
    time: string;
  } => {
    const formattedHour = hour <= 9 ? `0${hour}` : `${hour}`;
    const formattedMinute = minute <= 9 ? `0${minute}` : `${minute}`;

    return {
      hour: formattedHour,
      minute: formattedMinute,
      time: `${formattedHour}:${formattedMinute}`,
    };
  }, []);

  const handleAddRule = useCallback(
    () =>
      setRules(oldRules => {
        let start_hour: string | undefined;
        let start_minute: string | undefined;

        if (oldRules.length > 0) {
          const lastRule = oldRules[oldRules.length - 1];

          if (lastRule.end_hour && lastRule.end_minute) {
            const nextStartTime = addOneMinuteToTime(
              getTimeFromHourMinute(
                Number(lastRule.end_hour),
                Number(lastRule.end_minute),
              ),
            );

            const { hour, minute } = getHourMinuteFromTime(nextStartTime);

            const formattedNextTime = formatTime(hour, minute);

            start_hour = formattedNextTime.hour;
            start_minute = formattedNextTime.minute;
          }
        }

        return [
          ...oldRules,
          {
            start_hour,
            start_minute,
          },
        ];
      }),
    [
      addOneMinuteToTime,
      formatTime,
      getHourMinuteFromTime,
      getTimeFromHourMinute,
    ],
  );

  const handleRemoveRule = useCallback(
    (index: number) =>
      setRules(oldRules => {
        oldRules.splice(index, 1);

        return [...oldRules];
      }),
    [],
  );

  const handleAddPhone = useCallback(
    () =>
      setPhones(oldPhones => {
        return [...oldPhones, {}];
      }),
    [],
  );

  const handleRemovePhone = useCallback(
    (index: number) =>
      setPhones(oldPhones => {
        oldPhones.splice(index, 1);

        return [...oldPhones];
      }),
    [],
  );

  useEffect(() => {
    const loadChatbotsRules = async () => {
      try {
        setLoading(true);

        const { data } = await api.get<{
          rules: ChatbotRule[];
          phones: ChatbotTestPhone[];
        }>('chatbots/rules');

        const { rules, phones } = data;

        const parsedRules = rules
          .filter(rule => rule.test === test)
          .map(rule => {
            const {
              hour: start_hour,
              minute: start_minute,
            } = getHourMinuteFromTime(rule.start);
            const formattedStartTime = formatTime(start_hour, start_minute);

            const {
              hour: end_hour,
              minute: end_minute,
            } = getHourMinuteFromTime(rule.end);
            const formattedEndTime = formatTime(end_hour, end_minute);

            const ruleInput: RuleInput = {
              chatbot_id: rule.chatbot_id,
              start_hour: formattedStartTime.hour,
              start_minute: formattedStartTime.minute,
              end_hour: formattedEndTime.hour,
              end_minute: formattedEndTime.minute,
            };

            return ruleInput;
          });

        setRules(parsedRules);
        setPhones(
          phones.map(({ phone }) => ({
            phone,
          })),
        );
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadChatbotsRules();
  }, [enqueueSnackbar, setLoading, formatTime, getHourMinuteFromTime, test]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        rules: Yup.array().of(
          Yup.object().shape({
            chatbot_id: Yup.string()
              .nullable()
              .required('Chatbot é obrigatório'),
            start_hour: Yup.string().nullable().required('Hora é obrigatório'),
            start_minute: Yup.string()
              .nullable()
              .required('Minuto é obrigatório'),
            end_hour: Yup.string().nullable().required('Hora é obrigatório'),
            end_minute: Yup.string()
              .nullable()
              .required('Minuto é obrigatório'),
          }),
        ),
        phones: Yup.array().of(
          Yup.object().shape({
            phone: Yup.string().nullable().required('Telefone é obrigatório'),
          }),
        ),
      });

      const formInput: FormInput = {
        rules,
        phones,
      };

      await schema.validate(formInput, {
        abortEarly: false,
      });

      const formOutput = formInput as FormOutput;

      const chatbotRulesDTO: ChatbotRuleDTO[] = formOutput.rules.map(data => {
        const {
          chatbot_id,
          start_hour,
          start_minute,
          end_hour,
          end_minute,
        } = data;

        const start = Number(start_hour) * 60 + Number(start_minute);
        const end = Number(end_hour) * 60 + Number(end_minute);

        return {
          chatbot_id,
          start,
          end,
        };
      });

      chatbotRulesDTO.forEach((rule, index) => {
        const prevRule: ChatbotRuleDTO | undefined =
          index > 0 ? chatbotRulesDTO[index - 1] : undefined;
        let nextRule: ChatbotRuleDTO | undefined;

        if (chatbotRulesDTO.length > index + 1) {
          nextRule = chatbotRulesDTO[index + 1];
        } else if (index > 0) {
          nextRule = chatbotRulesDTO[0];
        }

        if (prevRule && rule.start <= prevRule.end) {
          const { hour, minute } = getHourMinuteFromTime(
            addOneMinuteToTime(prevRule.end),
          );

          const formatted = formatTime(hour, minute);

          formRef.current?.setErrors({
            [`rules[${index}].start_hour`]: formatted.hour,
            [`rules[${index}].start_minute`]: formatted.minute,
          });

          throw new Error(
            `Horário de Início mínimo permitido é ${formatted.time}`,
          );
        }

        if (index + 1 < chatbotRulesDTO.length && rule.start > rule.end) {
          const { hour, minute } = getHourMinuteFromTime(
            addOneMinuteToTime(rule.start),
          );

          const formatted = formatTime(hour, minute);

          formRef.current?.setErrors({
            [`rules[${index}].end_hour`]: formatted.hour,
            [`rules[${index}].end_minute`]: formatted.minute,
          });

          throw new Error(
            `Horário de Término mínimo permitido é ${formatted.time}`,
          );
        }

        if (nextRule && nextRule.start <= rule.end && rule.start > rule.end) {
          const { hour, minute } = getHourMinuteFromTime(
            subOneMinuteFromTime(nextRule.start),
          );

          const formatted = formatTime(hour, minute);

          formRef.current?.setErrors({
            [`rules[${index}].end_hour`]: formatted.hour,
            [`rules[${index}].end_minute`]: formatted.minute,
          });

          throw new Error(
            `Horário de Término máximo permitido é ${formatted.time}`,
          );
        }
      });

      await api.post<void>(
        'chatbots/rules',
        {
          rules: chatbotRulesDTO,
          ...(test
            ? {
                phones: formOutput.phones.map(({ phone }) => phone),
              }
            : {}),
        },
        {
          params: {
            type: test ? 'test' : 'prod',
          },
        },
      );

      enqueueSnackbar('Salvo com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else {
        const message = handleResponseError(err);
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    setLoading,
    addOneMinuteToTime,
    subOneMinuteFromTime,
    getHourMinuteFromTime,
    formatTime,
    rules,
    phones,
    test,
  ]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Section>
          <FormLabel>
            <Typography variant="h6">{title}</Typography>
          </FormLabel>
          {rules.map((rule, index) => (
            <Scope path={`rules[${index}]`}>
              <Item>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <div className="chatbotSelect">
                      <SelectForm
                        name="chatbot_id"
                        label="Chatbot"
                        values={chatbots}
                        defaultValue={rule.chatbot_id}
                        onChange={e => {
                          handleChangeInput(index, {
                            chatbot_id: e.target.value as string,
                          });
                        }}
                        hiddenDefaultOption
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Time
                      header="Horário de Início"
                      nameHour="start_hour"
                      nameMinute="start_minute"
                      defaultHour={rule.start_hour}
                      defaultMinute={rule.start_minute}
                      getHour={hour => {
                        handleChangeInput(index, {
                          start_hour: hour,
                        });
                      }}
                      getMinute={minute => {
                        handleChangeInput(index, {
                          start_minute: minute,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Time
                      header="Horário de Término"
                      nameHour="end_hour"
                      nameMinute="end_minute"
                      defaultHour={rule.end_hour}
                      defaultMinute={rule.end_minute}
                      getHour={hour => {
                        handleChangeInput(index, {
                          end_hour: hour,
                        });
                      }}
                      getMinute={minute => {
                        handleChangeInput(index, {
                          end_minute: minute,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={() => handleRemoveRule(index)}
                >
                  <ClearIcon fontSize="medium" />
                </IconButton>
              </Item>
              <Divider />
            </Scope>
          ))}
          <Button
            startIcon={<AddIcon />}
            color="primary"
            size="small"
            variant="outlined"
            onClick={handleAddRule}
          >
            Horário
          </Button>
        </Section>
        {test && (
          <Section>
            <FormLabel>
              <Typography variant="h6">Telefones para Teste</Typography>
            </FormLabel>
            {phones.map(({ phone }, index) => (
              <Scope path={`phones[${index}]`}>
                <Item>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <InputPhone
                        id="phone"
                        name="phone"
                        label="Telefone"
                        defaultValue={phone}
                        phone={phone => {
                          handleChangePhone(index, {
                            phone,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => handleRemovePhone(index)}
                  >
                    <ClearIcon fontSize="medium" />
                  </IconButton>
                </Item>
                <Divider />
              </Scope>
            ))}
            <Button
              startIcon={<AddIcon />}
              color="primary"
              size="small"
              variant="outlined"
              onClick={handleAddPhone}
            >
              Telefone
            </Button>
          </Section>
        )}
        <ActionsRow>
          <Button
            className="saveButton"
            color="primary"
            size="small"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
          <Button
            className="cancelButton"
            color="secondary"
            size="small"
            variant="outlined"
            type="button"
            onClick={goBack}
          >
            Voltar
          </Button>
        </ActionsRow>
      </Form>
    </Container>
  );
};
