import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.7);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  svg {
    width: 48px;
    height: 48px;
    color: #fff;
  }
`;

interface FileRowProps {
  isPdf: boolean;
}

export const FileRow = styled.div<FileRowProps>`
  width: ${props => (props.isPdf ? '100%' : 'auto')};
  height: ${props => (props.isPdf ? '100%' : 'auto')};
  max-width: 75%;
  max-height: 75%;

  img {
    max-width: 850px;
    max-height: 650px;
    width: auto;
    height: auto;
  }

  embed {
    width: 100%;
    height: 100%;
  }
`;

export const DocumentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 300px;

  svg {
    width: 184px;
    height: 184px;
    color: #dcdcdc;
  }

  span {
    color: #dcdcdc;
  }
`;
