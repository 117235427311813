import styled from 'styled-components';
import { DialogActions, DialogTitle, Grid } from '@material-ui/core';

export const CustomDialogActions = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const CustomDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 0;
  }
`;

export const CustomGrid = styled(Grid)`
  && {
    display: flex;
    align-items: baseline;

    h6 {
      margin-right: 8px;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;
