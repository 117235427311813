import React, { useMemo } from 'react';
import { Button, Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { FaFileExcel } from 'react-icons/fa';

import { showBase64 } from '../../../../../utils/showFile';

import Message from '../../../../../models/Message';

import MessageInfo from '../MessageInfo';

import { Content, CaptionRow } from './styles';

type Props = {
  message: Message;
};

const MessageDocument: React.FC<Props> = ({ message }) => {
  const getIcon = useMemo(() => {
    const ext = message.text.split('.').pop()?.toUpperCase();

    if (!ext) return <InsertDriveFileIcon />;

    if (['DOC', 'DOCX'].includes(ext))
      return <DescriptionIcon style={{ color: 'blue' }} />;

    if (ext === 'PDF') return <PictureAsPdfIcon style={{ color: 'red' }} />;

    if (['XLS', 'XLSX', 'CSV'].includes(ext))
      return <FaFileExcel style={{ color: 'green' }} />;

    return <InsertDriveFileIcon />;
  }, [message.text]);

  return message.base64 ? (
    <>
      {message.caption && (
        <CaptionRow>
          <Typography variant="caption">{message.caption}</Typography>
        </CaptionRow>
      )}
      <Content>
        <Button
          color={message.from_me ? 'inherit' : 'primary'}
          onClick={() => {
            showBase64(message.base64, message.text);
          }}
          size="small"
          startIcon={getIcon}
        >
          {message.custom_text ?? message.text}
        </Button>
        <MessageInfo
          time={message.time}
          status={message.status}
          from_me={message.from_me}
        />
      </Content>
    </>
  ) : (
    <>
      {message.base64_status === 'error' ? (
        <Typography variant="caption">
          Não foi possível carregar documento.
        </Typography>
      ) : (
        <Typography variant="caption">Carregando documento...</Typography>
      )}
    </>
  );
};

export default MessageDocument;
