import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSession } from '../../hooks/SessionContext';
import { useLoader } from '../../hooks/LoaderContext';

import getValidationErrors from '../../utils/getValidationErrors';
import handleResponseError from '../../utils/handleResponseError';

import Input from '../../components/Input';
import InputPhone from '../../components/InputPhone';
import Space from '../../components/Space';

import logo from '../../assets/logo1.png';

import { Container, Header, Content, CustomGrid, CustomButton } from './styles';

interface FormData {
  name: string;
  phone: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const [minPhone, setMinPhone] = useState<number>(0);

  const formRef = useRef<FormHandles>(null);
  const { signUp } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          phone: Yup.string()
            .nullable()
            .min(minPhone, 'Preencha todos os dígitos')
            .required('Celular é obrigatório'),
          email: Yup.string().email().required('E-mail é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, phone, email, password } = data;

        await signUp({
          name,
          phone: phone.replace(/\D/g, ''),
          email,
          password,
        });

        enqueueSnackbar('Cadastro efetuado com sucesso!', {
          variant: 'success',
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, signUp, setLoading, history, minPhone],
  );

  return (
    <Container>
      <Header>
        <img src={logo} alt="Moove" />
        <Typography variant="h4" color="textSecondary">
          Cadastre-se
        </Typography>
      </Header>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <CustomGrid item>
            <Input id="name" name="name" label="Nome" />
          </CustomGrid>
          <Space orientation="vertical" />
          <CustomGrid item>
            <InputPhone
              id="phone"
              name="phone"
              label="Celular"
              length={length => setMinPhone(length)}
            />
          </CustomGrid>
          <Space orientation="vertical" />
          <CustomGrid item>
            <Input id="email" name="email" label="E-mail" />
          </CustomGrid>
          <Space orientation="vertical" />
          <CustomGrid item>
            <Input
              id="password"
              name="password"
              label="Senha"
              type="password"
            />
          </CustomGrid>
          <CustomGrid item>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              CADASTRAR
            </CustomButton>
            <Typography align="center" color="textSecondary">
              Já possui uma conta? Entre
              <Link to="/"> aqui</Link>
            </Typography>
          </CustomGrid>
        </Form>
      </Content>
    </Container>
  );
};

export default SignUp;
