import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Button, Box, FormLabel, Typography, Grid } from '@material-ui/core';
import { Close, Add, ArrowDownward } from '@material-ui/icons';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { uuid } from 'uuidv4';

import api from '../../../../services/api';

import { useFunnel } from '../../../../hooks/FunnelContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import Funnel from '../../../../models/Funnel';
import Process from '../../../../models/Process';

import Input from '../../../../components/Input';
import Select from '../../../../components/Select';

import { InputWrapper } from './styles';

interface Props {
  handleSave: () => Promise<void>;
  process?: Process;
}

interface FormData {
  name: string;
}

type SelectedFunnel = {
  id?: string;
  key: string;
};

const AddEditProcesses: React.FC<Props> = ({ handleSave, process }) => {
  const [selectedFunnels, setSelectedFunnels] = useState<SelectedFunnel[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { funnels, loadFunnels } = useFunnel();
  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  const mappedFunnels = useMemo(
    () => funnels.map(({ id, name }) => ({ id, name })),
    [funnels],
  );

  useEffect(() => {
    const loadFunnels = async () => {
      try {
        let funnels: Funnel[] = [];

        if (process) {
          setLoading(true);

          const { data } = await api.get<Funnel[]>(
            `processes/${process.id}/funnels`,
          );

          funnels = data;
        }

        setSelectedFunnels(
          funnels.length === 0
            ? [
                {
                  key: uuid(),
                },
              ]
            : funnels.map(({ id }) => ({
                id,
                key: uuid(),
              })),
        );
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadFunnels();
  }, [enqueueSnackbar, setLoading, process]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name } = data;

        if (process) {
          await api.put(`/processes/${process.id}`, {
            name,
            funnels_ids: selectedFunnels.map(
              selectedFunnel => selectedFunnel.id,
            ),
          });
        } else {
          await api.post<Funnel>('/processes', {
            name,
            funnels_ids: selectedFunnels.map(
              selectedFunnel => selectedFunnel.id,
            ),
          });
        }

        await handleSave();

        enqueueSnackbar(
          `Processo ${process ? 'atualiado' : 'salvo'} com sucesso!`,
          {
            variant: 'success',
          },
        );
        await loadFunnels();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleSave,
      loadFunnels,
      process,
      selectedFunnels,
    ],
  );

  const handleDeleteFunnel = useCallback(async () => {
    try {
      setLoading(true);

      if (process) {
        await api.delete(`/processes/${process.id}`);

        enqueueSnackbar('Processo deletado com sucesso!', {
          variant: 'success',
        });

        await handleSave();
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, handleSave, process]);

  const handleFunnelSelect = useCallback(
    (key: string, event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setSelectedFunnels(oldSelectedFunnels =>
        oldSelectedFunnels.map(funnel => {
          if (funnel.key === key)
            return {
              ...funnel,
              id,
            };

          return funnel;
        }),
      );
    },
    [],
  );

  const handleAdd = useCallback((key: string) => {
    setSelectedFunnels(oldSelectedFunnels => {
      const index = oldSelectedFunnels.findIndex(
        oldSelectedFunnel => oldSelectedFunnel.key === key,
      );

      oldSelectedFunnels.splice(index + 1, 0, {
        key: uuid(),
      });

      return [...oldSelectedFunnels];
    });
  }, []);

  const handleRemove = useCallback((key: string) => {
    setSelectedFunnels(oldSelectedFunnels => {
      const index = oldSelectedFunnels.findIndex(
        oldSelectedFunnel => oldSelectedFunnel.key === key,
      );

      oldSelectedFunnels.splice(index, 1);

      return [...oldSelectedFunnels];
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormLabel>
            <Typography variant="h6">
              {process ? 'Editar Processo' : 'Novo Processo'}
            </Typography>
          </FormLabel>
          <Input
            id="name"
            name="name"
            label="Nome do processo"
            style={{ maxWidth: '400px' }}
            defaultValue={process?.name}
          />
          <div>
            <Box mb="1rem">
              <FormLabel>
                <Typography variant="h6">Funis</Typography>
              </FormLabel>
              {selectedFunnels.map(funnel => (
                <InputWrapper key={`wrapper-${funnel.key}`}>
                  <div>
                    <Select
                      key={`funnel-${funnel.key}`}
                      name={`funnel-${funnel.key}`}
                      label="Funil"
                      defaultValue={funnel.id}
                      value={funnel.id}
                      fullWidth
                      values={mappedFunnels}
                      onChange={e => {
                        handleFunnelSelect(funnel.key, e);
                      }}
                    />
                    <ArrowDownward />
                  </div>
                  <Button
                    key={`button-add-${funnel.key}`}
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ borderRadius: '32px' }}
                    type="button"
                    onClick={() => handleAdd(funnel.key)}
                  >
                    <Add fontSize="small" />
                  </Button>
                  <Button
                    key={`button-remove-${funnel.key}`}
                    color="secondary"
                    size="small"
                    variant="outlined"
                    style={{ borderRadius: '32px' }}
                    type="button"
                    onClick={() => handleRemove(funnel.key)}
                  >
                    <Close fontSize="small" />
                  </Button>
                </InputWrapper>
              ))}
            </Box>
          </div>
          <Button
            color="primary"
            size="small"
            variant="contained"
            style={{ borderRadius: '32px', marginRight: '6px' }}
            type="submit"
          >
            {process ? 'Atualizar' : 'Salvar'}
          </Button>
          {process ? (
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              style={{ borderRadius: '32px' }}
              type="button"
              onClick={() => handleDeleteFunnel()}
            >
              Deletar
            </Button>
          ) : (
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              style={{ borderRadius: '32px' }}
              type="button"
              onClick={() => handleSave()}
            >
              Cancelar
            </Button>
          )}
        </Form>
      </Grid>
    </Grid>
  );
};

export default AddEditProcesses;
