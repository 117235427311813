import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanel,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Add, ExpandMore } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import Chatbot from '../../../models/Chatbot';

import Settings from '..';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import { ChatbotQuestions, ChatbotRules } from './components';

import { ActionsRow, AlertsRow, Container, Header, SearchRow } from './styles';

import {
  ChatbotDTO,
  ChatbotMessageDTO,
  ChatbotOptionDTO,
  ChatbotQuestionDTO,
} from './types';

const Chatbots: React.FC = () => {
  const [chatbots, setChatbots] = useState<Chatbot[]>([]);
  const [chatbotsDTO, setChatbotsDTO] = useState<ChatbotDTO[]>([]);
  const [filteredChatbotsDTO, setFilteredChatbotsDTO] = useState<ChatbotDTO[]>(
    [],
  );
  const [isNew, setIsNew] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(true);
  const [visibility, setVisibility] = useState<
    'chatbots' | 'rules' | 'test-rules'
  >('chatbots');

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadChatbots = async () => {
      try {
        setLoading(true);

        const { data } = await api.get<Chatbot[]>('chatbots');

        setChatbots(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadChatbots();
  }, [enqueueSnackbar, setLoading]);

  useEffect(() => {
    const chatbotsDTO = chatbots.map(chatbot => {
      const { id, name, questions } = chatbot;

      const questionsDTO = questions.map(question => {
        const {
          id,
          parent_id,
          option_id,
          funnel_id,
          messages,
          options,
        } = question;

        const questionDTO: ChatbotQuestionDTO = {
          temp_id: id,
          temp_parent_id: parent_id,
          temp_option_id: option_id,
          funnel_id,
          messages: messages.map(message => {
            const { id, question_id, text } = message;

            const messageDTO: ChatbotMessageDTO = {
              temp_id: id,
              temp_question_id: question_id,
              text,
            };

            return messageDTO;
          }),
          options: options.map(option => {
            const { id, question_id, text, funnel_id } = option;

            const optionDTO: ChatbotOptionDTO = {
              temp_id: id,
              temp_question_id: question_id,
              text,
              funnel_id,
            };

            return optionDTO;
          }),
        };

        return questionDTO;
      });

      const chatbotDTO: ChatbotDTO = {
        id,
        name,
        questions: questionsDTO,
      };

      return chatbotDTO;
    });

    setChatbotsDTO(chatbotsDTO);
    setFilteredChatbotsDTO(chatbotsDTO);
  }, [chatbots]);

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredChatbotsDTO(
        chatbotsDTO.filter(
          chatbotDTO =>
            searched.length === 0 ||
            chatbotDTO.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [chatbotsDTO],
  );

  const handleSavedChatbot = useCallback((chatbot: Chatbot) => {
    setChatbots(oldChatbots => {
      const chatbots = [
        ...oldChatbots.filter(({ id }) => id !== chatbot.id),
        chatbot,
      ];

      chatbots.sort((c1, c2) => {
        if (c1.name < c2.name) return -1;

        return c1.name > c2.name ? 1 : 0;
      });

      return chatbots;
    });
    setIsNew(false);
  }, []);

  const handleDeletedChatbot = useCallback(
    (id: string) =>
      setChatbots(oldChatbots =>
        oldChatbots.filter(oldChatbot => oldChatbot.id !== id),
      ),
    [],
  );

  return (
    <Settings route="chatbots">
      <PageTitle pageName="Chatbots" />
      {visibility === 'rules' && (
        <ChatbotRules
          title="Configurar Regras"
          chatbots={chatbots.map(({ id, name }) => ({
            id,
            name,
          }))}
          goBack={() => setVisibility('chatbots')}
        />
      )}
      {visibility === 'test-rules' && (
        <ChatbotRules
          title="Configurar Regras para Teste"
          test
          chatbots={chatbots.map(({ id, name }) => ({
            id,
            name,
          }))}
          goBack={() => setVisibility('chatbots')}
        />
      )}
      {visibility === 'chatbots' && (
        <>
          {showInfo && (
            <AlertsRow>
              <Alert severity="info" onClose={() => setShowInfo(false)}>
                <span>
                  Após criar seu chatbot, você poderá criar uma regra com ele.
                </span>
                <br />
                <br />
                <span>
                  Em cada regra deverá ser definido o período de execução do seu
                  chatbot, fornecendo horário de início e horário de término.
                </span>
                <br />
                <br />
                <span>
                  É possível ter várias regras com diferentes chatbots.
                </span>
                <br />
                <br />
                <span>
                  No botão Regras Produção, o chatbot será acionado assim que um
                  cliente iniciar uma nova conversa. Isso acontece quando se
                  tenha passado pelo menos 24h desde o envio da última mensagem
                  do usuário do sistema para este cliente.
                </span>
                <br />
                <br />
                <span>
                  No botão Regras Teste, o chatbot somente será acionado para
                  telefones definidos. Isso serve para você definir um whatsapp
                  pessoal e testar a execução do seu chatbot antes de utilizá-lo
                  em uma regra de produção. Aqui uma nova conversa é considerada
                  quando se tenha passado pelo menos 5 minutos desde o envio da
                  última mensagem do usuário do sistema.
                </span>
              </Alert>
            </AlertsRow>
          )}
          <SearchRow>
            <InputSearch
              name="search"
              label="Digite um nome aqui"
              visible={!isNew}
              clear={isNew}
              handleSearch={handleSearch}
            />
          </SearchRow>
          <Header>
            {isNew ? (
              <Container>
                <ChatbotQuestions
                  cancel={() => setIsNew(false)}
                  handleSavedChatbot={handleSavedChatbot}
                  handleDeletedChatbot={handleDeletedChatbot}
                />
              </Container>
            ) : (
              <ActionsRow>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Add fontSize="small" />}
                  onClick={() => setIsNew(true)}
                >
                  Chatbot
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setVisibility('rules')}
                >
                  Regras Produção
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setVisibility('test-rules')}
                >
                  Regras Teste
                </Button>
              </ActionsRow>
            )}
          </Header>
          {filteredChatbotsDTO.map(chatbotDTO => (
            <ExpansionPanel key={chatbotDTO.id}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="body1">{chatbotDTO.name}</Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <Container>
                      <ChatbotQuestions
                        chatbotDTO={chatbotDTO}
                        handleSavedChatbot={handleSavedChatbot}
                        handleDeletedChatbot={handleDeletedChatbot}
                      />
                    </Container>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </>
      )}
    </Settings>
  );
};

export default Chatbots;
