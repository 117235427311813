import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 8px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
`;

export const Title = styled.div`
  margin: 8px 16px 0;
`;
