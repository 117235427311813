import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { format, formatDistanceStrict, parseISO } from 'date-fns'; //eslint-disable-line
import { pt } from 'date-fns/locale'; //eslint-disable-line
import {
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Paper,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { DeleteOutline } from '@material-ui/icons';

import { findAllNegotiationLogbooks } from '../../../services/NegotiationLogbookService';
import { deleteAnnotation } from '../../../services/AnnotationService';

import getAbbreviationByName from '../../../utils/getAbbreviationByName';
import handleResponseError from '../../../utils/handleResponseError';

import NegotiationLogbook from '../../../models/NegotiationLogbook';
import NegotiationLogbookType from '../../../models/NegotiationLogbookType';
import NegotiationStatus from '../../../models/NegotiationStatus';

import TabLogbooksToolbar from './TabLogbooksToolbar';

import { EmptyContainer, GroupItems } from './styles';

interface TabLogbooksProps {
  negotiation_id: string;
  funnel_id: string;
  status: NegotiationStatus;
  annotationPerspective?: boolean;
  small?: boolean;
  refresh?: boolean;
}

const TabLogbooks: React.FC<TabLogbooksProps> = ({
  negotiation_id,
  funnel_id,
  status,
  annotationPerspective = false,
  small = false,
  refresh = false,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [logbooks, setLogbooks] = useState<NegotiationLogbook[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const loadLogbooks = useCallback(async () => {
    try {
      setLoading(true);

      if (negotiation_id)
        setLogbooks(
          await findAllNegotiationLogbooks(negotiation_id, funnel_id),
        );
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, negotiation_id, funnel_id]);

  useEffect(() => {
    loadLogbooks();
  }, [loadLogbooks]);

  useEffect(() => {
    if (refresh) loadLogbooks();
  }, [loadLogbooks, refresh]);

  const filteredLogbooks = useMemo(() => {
    const annotationLogbookTypes = [
      NegotiationLogbookType.CreateAnnotation,
      NegotiationLogbookType.CreateAnnotationExternalRequest,
    ];

    return annotationPerspective
      ? logbooks.filter(logbook =>
          annotationLogbookTypes.includes(logbook.slug),
        )
      : logbooks.filter(
          logbook => !annotationLogbookTypes.includes(logbook.slug),
        );
  }, [logbooks, annotationPerspective]);

  const handleDeleteAnnotation = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        await deleteAnnotation(id);

        setLogbooks(oldLogbooks =>
          oldLogbooks.filter(oldLogbook => oldLogbook.id !== id),
        );

        enqueueSnackbar('Anotação deletada com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, setLogbooks],
  );

  const getFormattedPeriod = useCallback(
    (data: string) =>
      formatDistanceStrict(parseISO(data), new Date(), {
        addSuffix: true,
        locale: pt,
      }),
    [],
  );

  const getFormattedDateTime = useCallback(
    (data: string) => format(parseISO(data), 'dd/MM/yyyy HH:mm:ss'),
    [],
  );

  const getLogMessage = useCallback(
    (slug: NegotiationLogbookType, description?: string) => {
      switch (slug) {
        case NegotiationLogbookType.CreateAnnotation:
          return `escreveu: ${description}`;
        case NegotiationLogbookType.CreateNegotiation:
          return 'Criou negociação';
        case NegotiationLogbookType.CreateNegotiationChatbot:
          return 'Negociação criada pelo chatbot';
        case NegotiationLogbookType.CreateNegotiationReceptiveFunnel:
          return 'Negociação criada pelo funil receptivo';
        case NegotiationLogbookType.CreateNegotiationExternalRequest:
          return 'Criou negociação via requisição externa';
        case NegotiationLogbookType.UpdateNegotiation:
          return description
            ? `Alterou negociação:<br/><br/>${description
                .split('<br/>')
                .map(line => {
                  const [from, to] = line.split('->');

                  if (from.includes(':')) {
                    const [field, parsedFrom] = from.split(':');

                    return `${field}: ${parsedFrom} | Para: ${to}`;
                  }

                  return `De: ${from} | Para: ${to}`;
                })
                .join('<br/>')}`
            : 'Alterou negociação';
        case NegotiationLogbookType.WonNegotiation:
          return 'Ganhou negociação';
        case NegotiationLogbookType.LostNegotiation:
          return 'Perdeu negociação';
        case NegotiationLogbookType.ReopenedNegotiation:
          return 'Reabriu negociação';
        case NegotiationLogbookType.ExchangeNegotiationFunnel:
          return 'Trocou funil';
        case NegotiationLogbookType.CreateFile:
          return 'Anexou arquivo';
        case NegotiationLogbookType.AutomaticMessage:
          return 'Enviou uma mensagem automática';
        default:
          return 'Log desconhecido';
      }
    },
    [],
  );

  return (
    <>
      {negotiation_id &&
        status === NegotiationStatus.InProgress &&
        annotationPerspective && (
          <TabLogbooksToolbar
            negotiation_id={negotiation_id}
            setNegotiationLogbooks={setLogbooks}
            small={small}
          />
        )}
      <Paper
        elevation={small ? 0 : 1}
        style={{
          borderRadius: 0,
        }}
      >
        {loading ? (
          <EmptyContainer>
            <CircularProgress />
          </EmptyContainer>
        ) : (
          <>
            {filteredLogbooks.length === 0 ? (
              <EmptyContainer>Nenhum registro encontrado.</EmptyContainer>
            ) : (
              <List
                style={{
                  width: '100%',
                }}
              >
                {filteredLogbooks.map(logbook => (
                  <React.Fragment key={logbook.id}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>
                          {!logbook.user ||
                          logbook.slug ===
                            NegotiationLogbookType.AutomaticMessage
                            ? 'SI '
                            : getAbbreviationByName(logbook.user.name)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={{
                          ...(
                            <GroupItems>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {!logbook.user ||
                                logbook.slug ===
                                  NegotiationLogbookType.AutomaticMessage
                                  ? 'Sistema'
                                  : logbook.user.name}
                              </Typography>
                              <Tooltip
                                title={getFormattedDateTime(logbook.created_at)}
                              >
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {getFormattedPeriod(logbook.created_at)}
                                </Typography>
                              </Tooltip>
                              <GroupItems>
                                {status === NegotiationStatus.InProgress &&
                                  logbook.slug ===
                                    NegotiationLogbookType.CreateAnnotation && (
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        handleDeleteAnnotation(logbook.id);
                                      }}
                                    >
                                      <DeleteOutline
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  )}
                              </GroupItems>
                            </GroupItems>
                          ),
                        }}
                        secondary={{
                          ...(
                            <>
                              {logbook.slug ===
                              NegotiationLogbookType.CreateAnnotationExternalRequest ? (
                                <>
                                  <div>
                                    <Typography
                                      variant="subtitle2"
                                      color="textPrimary"
                                    >
                                      Dados restantes da negociação criada via
                                      requisição externa:
                                    </Typography>
                                  </div>
                                  <pre>
                                    {JSON.stringify(
                                      JSON.parse(logbook.description),
                                      null,
                                      2,
                                    )}
                                  </pre>
                                </>
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {getLogMessage(
                                    logbook.slug,
                                    logbook.description,
                                  )
                                    .split('<br/>')
                                    .map(line => (
                                      <>
                                        {line}
                                        <br />
                                      </>
                                    ))}
                                </Typography>
                              )}
                            </>
                          ),
                        }}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                ))}
              </List>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default TabLogbooks;
