import styled from 'styled-components';

const Container = styled.section`
  margin-bottom: 8px;

  span {
    font-size: 11px;
    font-weight: 700;
    color: #f2f8fb;
  }
`;

export { Container };
