import React, { useMemo, useEffect, useCallback } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { subMinutes, subSeconds, isAfter } from 'date-fns';

import { useSession } from '../hooks/SessionContext';
import { ContactProvider } from '../hooks/ContactContext';
import { MessageProvider } from '../hooks/MessageContext';
import { FunnelProvider } from '../hooks/FunnelContext';
import { ResponsibleProvider } from '../hooks/ResponsibleContext';
import { StatusProvider } from '../hooks/StatusContext';
import { PeriodProvider } from '../hooks/PeriodContext';
import { GenericNegotiationSearchProvider } from '../hooks/GenericNegotiationSearch';
import { NotificationProvider } from '../hooks/NotificationContext';
import { MessageTemplateProvider } from '../hooks/MessageTemplateContext';
import { DefaultFilesProvider } from '../hooks/DefaultFilesContext';
import { FileCacheProvider } from '../hooks/FileCacheContext';
import { WindowDimensionsProvider } from '../hooks/WindowDimensionsContext';
import { LinkWhatsappProvider } from '../hooks/LinkWhatsappContext';
import { CredentialProvider } from '../hooks/CredentialContext';

import PermissionType from '../models/PermissionType';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Funnel from '../pages/Funnel';
import Chat from '../pages/Chat';
import ChatContact from '../pages/Chat/Contact';
import Chart from '../pages/Chart';
import Schedule from '../pages/Schedule';
import Team from '../pages/Team';
import Client from '../pages/Client';
import PasswordRecovery from '../pages/PasswordRecovery';
import ProfilesSettings from '../pages/Settings/Profile';
import UsersSettings from '../pages/Settings/Users';
import TeamsSettings from '../pages/Settings/Teams';
import FunnelsSettings from '../pages/Settings/Funnels';
import ProductsSettings from '../pages/Settings/Products';
import ChannelsSettings from '../pages/Settings/Channels';
import LossesReasonsSettings from '../pages/Settings/LossesReasons';
import MailingsSettings from '../pages/Settings/Mailings';
import ProcessesSettings from '../pages/Settings/Processes';
import TemplateMessages from '../pages/Settings/TemplateMessages';
import AutomaticMessages from '../pages/Settings/AutomaticMessages';
import DefaultFiles from '../pages/Settings/DefaultFiles';
import LinkWhatsapp from '../pages/Settings/LinkWhatsapp';
import ChatBots from '../pages/Settings/Chatbots';
import ExternalAccessCredential from '../pages/Settings/ExternalAccessCredential';
import DataExport from '../pages/Settings/DataExport';
import NegotiationsListView from '../pages/NegotiationsListView';

const Routes: React.FC = () => {
  const history = useHistory();
  const { user, expired, waba_configured, signOut } = useSession();

  const isExpiredSession = useCallback(
    () =>
      !expired || isAfter(new Date(), subSeconds(subMinutes(expired, 30), 5)),
    [expired],
  );

  useEffect(() => {
    if (isExpiredSession()) {
      signOut();

      history.push('/');

      return undefined;
    }

    const interval = setInterval(() => {
      if (isExpiredSession()) signOut();
    }, 1000 * 60 * 30);

    return () => clearInterval(interval);
  }, [signOut, isExpiredSession, history]);

  const isAdmin = useMemo(() => {
    return user?.permissions?.includes(PermissionType.AdminUser);
  }, [user]);

  const isSuperior = useMemo(() => {
    return user?.permissions?.includes(PermissionType.Superior);
  }, [user]);

  return (
    <Switch>
      <WindowDimensionsProvider>
        <Route path="/" exact component={SignIn} />
        <Route path="/register" component={SignUp} />
        <Route path="/password-recovery" component={PasswordRecovery} />
        {!isExpiredSession() && (
          <LinkWhatsappProvider>
            <FileCacheProvider>
              <NotificationProvider>
                <ContactProvider>
                  <MessageProvider>
                    <FunnelProvider>
                      <ResponsibleProvider>
                        <StatusProvider>
                          <PeriodProvider>
                            <GenericNegotiationSearchProvider>
                              <MessageTemplateProvider>
                                <DefaultFilesProvider>
                                  <Route
                                    path="/chats"
                                    isPrivate
                                    component={Chat}
                                  />
                                  <Route
                                    path="/contact"
                                    isPrivate
                                    component={ChatContact}
                                  />
                                  <Route
                                    path="/charts"
                                    isPrivate
                                    component={Chart}
                                  />
                                  <Route
                                    path="/schedules"
                                    isPrivate
                                    component={Schedule}
                                  />
                                  <Route
                                    path="/negotiations-list"
                                    isPrivate
                                    component={NegotiationsListView}
                                  />
                                  <Route
                                    path="/teams"
                                    isPrivate
                                    component={Team}
                                  />
                                  {isAdmin && !waba_configured && (
                                    <Route
                                      path="/settings/link-whatsapp"
                                      isPrivate
                                      component={LinkWhatsapp}
                                    />
                                  )}
                                  <Route
                                    path="/settings/profiles"
                                    isPrivate
                                    component={ProfilesSettings}
                                  />
                                  {(isAdmin || isSuperior) && (
                                    <Route
                                      path="/settings/users"
                                      isPrivate
                                      component={UsersSettings}
                                    />
                                  )}
                                  {(isAdmin || isSuperior) && (
                                    <Route
                                      path="/settings/teams"
                                      isPrivate
                                      component={TeamsSettings}
                                    />
                                  )}
                                  <Route
                                    path="/settings/funnels"
                                    isPrivate
                                    component={FunnelsSettings}
                                  />
                                  {isAdmin && (
                                    <Route
                                      path="/settings/processes"
                                      isPrivate
                                      component={ProcessesSettings}
                                    />
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/products"
                                      isPrivate
                                      component={ProductsSettings}
                                    />
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/channels"
                                      isPrivate
                                      component={ChannelsSettings}
                                    />
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/losses-reasons"
                                      isPrivate
                                      component={LossesReasonsSettings}
                                    />
                                  )}
                                  {(isAdmin || isSuperior) && (
                                    <Route
                                      path="/settings/mailings"
                                      isPrivate
                                      component={MailingsSettings}
                                    />
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/template-messages"
                                      isPrivate
                                      component={TemplateMessages}
                                    />
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/automatic-messages"
                                      isPrivate
                                      component={AutomaticMessages}
                                    />
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/default-files"
                                      isPrivate
                                      component={DefaultFiles}
                                    />
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/chatbots"
                                      isPrivate
                                      component={ChatBots}
                                    />
                                  )}
                                  {isAdmin && (
                                    <CredentialProvider>
                                      <Route
                                        path="/settings/external-access-credential"
                                        isPrivate
                                        component={ExternalAccessCredential}
                                      />
                                    </CredentialProvider>
                                  )}
                                  {isAdmin && (
                                    <Route
                                      path="/settings/data-export"
                                      isPrivate
                                      component={DataExport}
                                    />
                                  )}
                                  <Route
                                    path="/funnels"
                                    isPrivate
                                    component={Funnel}
                                  />
                                  <Route
                                    path="/clients/:id/edit"
                                    isPrivate
                                    component={Client}
                                  />
                                  <Route
                                    path="/clients/new"
                                    isPrivate
                                    component={Client}
                                  />
                                </DefaultFilesProvider>
                              </MessageTemplateProvider>
                            </GenericNegotiationSearchProvider>
                          </PeriodProvider>
                        </StatusProvider>
                      </ResponsibleProvider>
                    </FunnelProvider>
                  </MessageProvider>
                </ContactProvider>
              </NotificationProvider>
            </FileCacheProvider>
          </LinkWhatsappProvider>
        )}
      </WindowDimensionsProvider>
    </Switch>
  );
};

export default Routes;
