import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useField } from '@unform/core';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import InputGeneric, { InputGenericProps } from '../InputGeneric';

interface InputNumberProps extends NumberFormatProps {
  name: string;
}

const InputNumber: React.FC<InputNumberProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<NumberFormatProps>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState<string>(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => (!value || value.length === 0 ? undefined : value),
    });
  }, [fieldName, registerField, value]);

  const CustomInput = useCallback(
    (props: React.FC<InputGenericProps>) => {
      return <InputGeneric error={error} {...props} />;
    },
    [error],
  );

  return (
    <NumberFormat
      label={label}
      defaultValue={defaultValue}
      inputRef={inputRef}
      customInput={CustomInput}
      onValueChange={values => {
        setValue(values.value);
      }}
      {...rest}
    />
  );
};

export default InputNumber;
