import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from '@unform/core';

import { findAllContacts } from '../../services/ContactService';

type Option = {
  id: string;
  label: string;
};

type Props = TextFieldProps & {
  name: string;
  label: string;
  defaultValue?: Option;
  getValue?: (value?: string) => void;
};

const SelectSearch: React.FC<Props> = ({
  name,
  label,
  defaultValue,
  disabled,
  getValue,
  ...rest
}) => {
  const [values, setValues] = useState<Option[]>(
    defaultValue ? [defaultValue] : [],
  );
  const [value, setValue] = useState<string | undefined>(defaultValue?.id);
  const [loading, setLoading] = useState<boolean>(false);

  const inputRef = useRef<Props>(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
    });
    getValue?.(value);
  }, [registerField, getValue, fieldName, value]);

  const handleFindContactsByNamePhone = useCallback(
    async (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        try {
          setLoading(true);

          const searchedWord = inputRef.current?.value as string;

          const regexOnlyNumbers = new RegExp('^[0-9]+$');

          const isPhone = regexOnlyNumbers.test(searchedWord);

          const data = await findAllContacts({
            name: isPhone ? undefined : searchedWord,
            phone: isPhone ? searchedWord : undefined,
            take: 20,
            skip: 0,
            includeMessages: 0,
          });

          setValues(
            data.map(({ id, name }) => ({
              id,
              label: name,
            })),
          );
        } finally {
          setLoading(false);
        }
      }
    },
    [],
  );

  return (
    <Autocomplete
      id={name}
      options={values}
      defaultValue={defaultValue}
      getOptionLabel={option => option.label}
      onChange={(_, value) => setValue(value?.id)}
      disabled={disabled}
      loading={loading}
      loadingText="Carregando contatos..."
      noOptionsText="Digite nome ou telefone e tecle enter..."
      size="small"
      fullWidth
      autoHighlight
      renderInput={params => (
        <FormControl fullWidth error={!!error}>
          <TextField
            inputRef={inputRef}
            label={label}
            error={!!error}
            variant="outlined"
            onKeyPress={handleFindContactsByNamePhone}
            {...params}
            {...rest}
            disabled={disabled}
          />
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectSearch;
