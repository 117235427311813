import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div``;

export const QuestionRow = styled(Paper)<{
  even: boolean;
  hasOptions: boolean;
  parentIsOption: boolean;
}>`
  && {
    display: flex;
    align-items: start;
    background-color: ${props => (props.even ? 'white' : '#f8f8f8')};
    width: 100%;
    padding: 8px;
    margin: ${props => (props.parentIsOption ? '8px 0' : '8px 0 0')};
    width: fit-content;
    border-radius: 4px;

    .MuiOutlinedInput-multiline {
      padding: 8px;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }
`;

export const MessageRow = styled.div<{
  hasOptions: boolean;
}>`
  .rowMessages {
    display: flex;
    align-items: center;

    hr {
      height: auto;
      max-height: max-content;
      margin: 0 16px;
      align-self: stretch;
    }
  }

  ul {
    width: 100%;
    margin-left: ${props => (props.hasOptions ? '24px' : 0)};
  }

  li {
    display: flex;
  }

  li + li {
    margin-top: 8px;
  }
`;

export const OptionsRow = styled.div`
  width: 100%;

  li {
    display: flex;

    hr {
      height: auto;
      max-height: max-content;
      margin: 0 16px;
    }
  }

  li + li {
    margin-top: 8px;
  }

  li + div + li {
    margin-top: 8px;
  }
`;

export const ActionsRow = styled.div`
  margin-top: 32px;

  hr {
    margin: 8px 0;
  }

  .saveButton,
  .cancelButton {
    border-radius: 32px;
  }

  .saveButton {
    margin-right: 8px;
  }
`;
