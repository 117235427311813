import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  FilterListOutlined,
  PaymentOutlined,
  EmojiPeopleOutlined,
  DateRangeOutlined,
  PeopleOutlined,
  MonetizationOnOutlined,
} from '@material-ui/icons';

import { Divider, Typography } from '@material-ui/core';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';

import { useResponsible } from '../../hooks/ResponsibleContext';
import { useFunnel } from '../../hooks/FunnelContext';
import { useStatus } from '../../hooks/StatusContext';
import { useGenericNegotiationSearch } from '../../hooks/GenericNegotiationSearch';
import { useWindowDimensions } from '../../hooks/WindowDimensionsContext';

import Channel from '../../models/Channel';
import Product from '../../models/Product';
import NegotiationDateRange from '../../models/NegotiationDateRange';
import PermissionType from '../../models/PermissionType';
import NegotiationStatus from '../../models/NegotiationStatus';

import FilterDTO from '../../dtos/FilterDTO';

import SelectOpacity from '../SelectOpacity';
import InputGenericSearch from '../InputGenericSearch';
import CustomDateDialog from '../CustomDateDialog';

import { Container, CustomMenuItem } from './styles';

interface Props {
  handleChartFilters: (filters: FilterDTO) => void;
  hiddenStatusFilter?: boolean;
  hiddenDateFilter?: boolean;
  hiddenNameFilter?: boolean;
  dateRangeDefault?:
    | NegotiationDateRange.ThisMonth
    | NegotiationDateRange.LastSixMonths;
  middleActions?: React.ReactNode;
  leftActions?: React.ReactNode;
}

const FilterHeader: React.FC<Props> = ({
  handleChartFilters,
  hiddenStatusFilter = true,
  hiddenDateFilter = false,
  hiddenNameFilter = true,
  dateRangeDefault = NegotiationDateRange.LastSixMonths,
  middleActions,
  leftActions,
}) => {
  const ALL = 'all';
  const NAME = 'Todos';

  const DEFAULT_OPTION = {
    id: ALL,
    name: NAME,
  };

  const { users, selectedResponsible, selectResponsible } = useResponsible();
  const {
    funnels,
    selectedFunnel,
    selectedChannel,
    selectedProduct,
    selectFunnel,
    selectChannel,
    selectProduct,
  } = useFunnel();
  const { selectedStatus, selectStatus } = useStatus();
  const { genericName } = useGenericNegotiationSearch();
  const { windowDimensions } = useWindowDimensions();

  const [dateRange, setDateRange] = useState<NegotiationDateRange>(
    dateRangeDefault,
  );
  const [dateStartEnd, setDateStartEnd] = useState<{
    start?: Date;
    end?: Date;
  }>({
    start:
      dateRangeDefault === NegotiationDateRange.ThisMonth
        ? startOfMonth(new Date())
        : subMonths(startOfMonth(new Date()), 5),
    end:
      dateRangeDefault === NegotiationDateRange.ThisMonth
        ? endOfMonth(new Date())
        : endOfDay(new Date()),
  });
  const [openCustomDateDialog, setOpenCustomDate] = useState<boolean>(false);

  const mappedTeams = useMemo(() => {
    const filteredTeams = selectedFunnel
      ? users.filter(
          user =>
            user.permissions.includes(PermissionType.Superior) &&
            user.funnels.includes(selectedFunnel.id),
        )
      : users.filter(user =>
          user.permissions.includes(PermissionType.Superior),
        );

    return filteredTeams.map(({ id, name }) => ({
      id,
      name,
    }));
  }, [selectedFunnel, users]);

  const mappedUsers = useMemo(() => {
    const filteredUsers = selectedFunnel
      ? users.filter(user => user.funnels.includes(selectedFunnel.id))
      : users;

    return filteredUsers.map(({ id, name }) => ({
      id,
      name,
    }));
  }, [selectedFunnel, users]);

  const mappedFunnels = useMemo(() => {
    if (users.length === 0) return [];

    const filteredFunnelsIds = selectedResponsible
      ? users.filter(user => user.id === selectedResponsible.user.id)[0].funnels
      : users[0].funnels;

    return funnels
      .filter(funnel => filteredFunnelsIds.includes(funnel.id))
      .map(({ id, name }) => ({
        id,
        name,
      }));
  }, [users, funnels, selectedResponsible]);

  const mappedProducts = useMemo(() => {
    let products: Product[] = [];

    if (selectedFunnel) {
      products = selectedFunnel.products;
    } else {
      products = funnels.reduce(
        (products, funnel) => [
          ...products,
          ...funnel.products.filter(
            product => !products.map(({ id }) => id).includes(product.id),
          ),
        ],
        [] as Product[],
      );
    }

    return products
      .sort((p1, p2) => p1.name.localeCompare(p2.name))
      .map(({ id, name }) => ({
        id,
        name,
      }));
  }, [funnels, selectedFunnel]);

  const mappedChannels = useMemo(() => {
    let channels: Channel[] = [];

    if (selectedFunnel) {
      channels = selectedFunnel.channels;
    } else {
      channels = funnels.reduce(
        (channels, funnel) => [
          ...channels,
          ...funnel.channels.filter(
            channel => !channels.map(({ id }) => id).includes(channel.id),
          ),
        ],
        [] as Channel[],
      );
    }

    return channels
      .sort((c1, c2) => c1.name.localeCompare(c2.name))
      .map(({ id, name }) => ({
        id,
        name,
      }));
  }, [funnels, selectedFunnel]);

  useEffect(() => {
    handleChartFilters({
      userId: selectedResponsible?.user.id,
      funnelId: selectedFunnel?.id,
      productId: selectedProduct?.id,
      channelId: selectedChannel?.id,
      status: selectedStatus,
      hierarchy: selectedResponsible?.team,
      dateStart: dateStartEnd.start,
      dateEnd: dateStartEnd.end,
      name: genericName,
    });
  }, [
    handleChartFilters,
    selectedResponsible,
    selectedFunnel,
    selectedProduct,
    selectedChannel,
    selectedStatus,
    dateStartEnd,
    genericName,
  ]);

  const handleChangeUser = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;
      const handledId = id.replaceAll('-team', '');

      const findedUser = users.find(user => user.id === handledId);

      if (findedUser)
        selectResponsible({
          team: id.includes('-team'),
          user: findedUser,
        });
    },
    [selectResponsible, users],
  );

  const handleChangeFunnel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      if (
        id !== ALL &&
        selectedResponsible &&
        !selectedResponsible.user.funnels.includes(id)
      )
        selectResponsible({
          team: false,
          user: users[0],
        });

      selectFunnel(id);
    },
    [selectResponsible, selectFunnel, selectedResponsible, users],
  );

  const handleChangeProduct = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      selectProduct(id === ALL ? undefined : id);
    },
    [selectProduct],
  );

  const handleChangeChannel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      selectChannel(id === ALL ? undefined : id);
    },
    [selectChannel],
  );

  const handleChangeStatus = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as NegotiationStatus;

      selectStatus(id);
    },
    [selectStatus],
  );

  const handleChangeRange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as NegotiationDateRange;

      setDateRange(id);

      const date = new Date();

      switch (id) {
        case NegotiationDateRange.All:
          setDateStartEnd({});
          break;
        case NegotiationDateRange.Today:
          setDateStartEnd({
            start: startOfDay(date),
            end: endOfDay(date),
          });
          break;
        case NegotiationDateRange.Yesterday:
          setDateStartEnd({
            start: subDays(startOfDay(date), 1),
            end: subDays(endOfDay(date), 1),
          });
          break;
        case NegotiationDateRange.ThisWeek:
          setDateStartEnd({
            start: startOfWeek(date),
            end: endOfWeek(date),
          });
          break;
        case NegotiationDateRange.LastWeek:
          setDateStartEnd({
            start: subWeeks(startOfWeek(date), 1),
            end: subWeeks(endOfWeek(date), 1),
          });
          break;
        case NegotiationDateRange.ThisMonth:
          setDateStartEnd({
            start: startOfMonth(date),
            end: endOfMonth(date),
          });
          break;
        case NegotiationDateRange.LastMonth:
          setDateStartEnd({
            start: subMonths(startOfMonth(date), 1),
            end: subMonths(endOfMonth(date), 1),
          });
          break;
        case NegotiationDateRange.LastSixMonths:
          setDateStartEnd({
            start: subMonths(startOfMonth(date), 5),
            end: endOfDay(date),
          });
          break;
        default:
          setOpenCustomDate(true);
      }
    },
    [],
  );

  const handleCustomDate = useCallback((start: Date, end: Date) => {
    setOpenCustomDate(false);

    setDateStartEnd({
      start,
      end,
    });
  }, []);

  return (
    <Container mobile={windowDimensions.resolution === 'mobile'}>
      <CustomDateDialog
        open={openCustomDateDialog}
        handleCustomDate={handleCustomDate}
      />
      {leftActions && <div>{leftActions}</div>}
      {middleActions && <div>{middleActions}</div>}
      <div>
        {!hiddenNameFilter && <InputGenericSearch />}
        <SelectOpacity
          id="funil"
          label="Funil"
          value={selectedFunnel ? selectedFunnel.id : ALL}
          values={[DEFAULT_OPTION, ...mappedFunnels]}
          onChange={handleChangeFunnel}
          startAdornment={<FilterListOutlined />}
        />
        {mappedProducts.length > 0 && (
          <SelectOpacity
            id="product"
            label="Product"
            value={selectedProduct ? selectedProduct.id : ALL}
            values={[DEFAULT_OPTION, ...mappedProducts]}
            onChange={handleChangeProduct}
            startAdornment={<PaymentOutlined />}
          />
        )}
        {mappedChannels.length > 0 && (
          <SelectOpacity
            id="channel"
            label="Channel"
            value={selectedChannel ? selectedChannel.id : ALL}
            values={[DEFAULT_OPTION, ...mappedChannels]}
            onChange={handleChangeChannel}
            startAdornment={<EmojiPeopleOutlined />}
          />
        )}
        <SelectOpacity
          id="user"
          label="Usuário"
          value={
            selectedResponsible
              ? `${selectedResponsible.user.id}${
                  selectedResponsible.team ? '-team' : ''
                }`
              : ''
          }
          onChange={handleChangeUser}
          startAdornment={<PeopleOutlined />}
          color="secondary"
        >
          {mappedUsers.map(({ id, name }) => (
            <CustomMenuItem key={id} value={id}>
              <Typography variant="subtitle2">{name}</Typography>
            </CustomMenuItem>
          ))}
          <Divider />
          {mappedTeams.map(({ id, name }) => (
            <CustomMenuItem key={`${id}-team`} value={`${id}-team`}>
              <Typography variant="subtitle2">{`${name} (Equipe)`}</Typography>
            </CustomMenuItem>
          ))}
        </SelectOpacity>
        {!hiddenStatusFilter && (
          <SelectOpacity
            id="status"
            label="Situação"
            value={selectedStatus}
            values={[
              {
                id: NegotiationStatus.InProgress,
                name: 'Em aberto',
              },
              {
                id: NegotiationStatus.Won,
                name: 'Ganho',
              },
              {
                id: NegotiationStatus.Lost,
                name: 'Perdido',
              },
            ]}
            onChange={handleChangeStatus}
            startAdornment={<MonetizationOnOutlined />}
          />
        )}
        {!hiddenDateFilter && (
          <SelectOpacity
            id="range"
            label="Período"
            value={dateRange}
            values={[
              {
                id: NegotiationDateRange.All,
                name: 'Todos',
              },
              {
                id: NegotiationDateRange.Today,
                name: 'Hoje',
              },
              {
                id: NegotiationDateRange.Yesterday,
                name: 'Ontem',
              },
              {
                id: NegotiationDateRange.ThisWeek,
                name: 'Esta semana',
              },
              {
                id: NegotiationDateRange.LastWeek,
                name: 'Semana passada',
              },
              {
                id: NegotiationDateRange.ThisMonth,
                name: 'Este mês',
              },
              {
                id: NegotiationDateRange.LastMonth,
                name: 'Mês passado',
              },
              {
                id: NegotiationDateRange.LastSixMonths,
                name: 'Últimos 6 meses',
              },
              {
                id: NegotiationDateRange.CustomDate,
                name: 'Data Personalizada',
              },
            ]}
            onChange={handleChangeRange}
            startAdornment={<DateRangeOutlined />}
          />
        )}
      </div>
    </Container>
  );
};

export default FilterHeader;
