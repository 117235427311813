import styled from 'styled-components';

const Content = styled.section`
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;

  span {
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 1;
  }

  > ul {
    margin-right: 8px;
  }

  .MuiListItem-root {
    color: black;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export { Content };
