import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, List, ListItemAvatar, ListItemText } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import { FaFileExcel } from 'react-icons/fa';
import { useSnackbar } from 'notistack';
import { Scrollbars } from 'react-custom-scrollbars';

import handleResponseError from '../../../../utils/handleResponseError';

import { useLoader } from '../../../../hooks/LoaderContext';
import { useDefaultFiles } from '../../../../hooks/DefaultFilesContext';

import DefaultFile from '../../../../models/DefaultFile';

import InputSearch from '../../../../components/InputSearch';

import {
  Container,
  Header,
  RegistersNotFoundText,
  CustomListItem,
} from './styles';

interface Props {
  handleChoosedDefaultFile: (data: {
    base64: string;
    filename: string;
    mimetype: string;
  }) => Promise<void>;
}

const DefaultFileChoose: React.FC<Props> = ({ handleChoosedDefaultFile }) => {
  const [filteredDefaultFiles, setfilteredDefaultFiles] = useState<
    DefaultFile[]
  >([]);

  const { defaultFiles, getDefaultFileBase64 } = useDefaultFiles();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  useEffect(() => {
    setfilteredDefaultFiles(defaultFiles);
  }, [defaultFiles]);

  const handleSearch = useCallback(
    (searched: string) => {
      if (searched.length === 0) {
        setfilteredDefaultFiles(defaultFiles);
      } else {
        setfilteredDefaultFiles(
          defaultFiles.filter(defaultFile =>
            defaultFile.name.toLowerCase().includes(searched.toLowerCase()),
          ),
        );
      }
    },
    [defaultFiles],
  );

  const handleChoosed = useCallback(
    async ({ id, name, mimetype }: DefaultFile) => {
      try {
        setLoading(true);

        const base64 = await getDefaultFileBase64({
          id,
        });

        await handleChoosedDefaultFile({
          base64,
          filename: name,
          mimetype,
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleChoosedDefaultFile,
      getDefaultFileBase64,
    ],
  );

  const getIcon = useCallback((name: string) => {
    const ext = name.split('.').pop()?.toUpperCase();

    if (!ext) return <InsertDriveFileIcon />;

    if (['PNG', 'JPEG'].includes(ext)) return <ImageIcon />;

    if (['DOC', 'DOCX'].includes(ext))
      return <DescriptionIcon style={{ color: 'blue' }} />;

    if (ext === 'PDF') return <PictureAsPdfIcon style={{ color: 'red' }} />;

    if (['XLS', 'XLSX', 'CSV'].includes(ext))
      return <FaFileExcel style={{ color: 'green' }} />;

    return <InsertDriveFileIcon />;
  }, []);

  return (
    <>
      <Container>
        <Header>
          <span>Selecione um Arquivo Padrão</span>
          <InputSearch
            name="search"
            label="Digite algo aqui..."
            handleSearch={handleSearch}
          />
        </Header>
        {filteredDefaultFiles.length === 0 ? (
          <RegistersNotFoundText>
            Nenhum registro encontrado.
          </RegistersNotFoundText>
        ) : (
          <Scrollbars autoHeight>
            <List>
              {filteredDefaultFiles.map(defaultFile => (
                <CustomListItem key={defaultFile.id} button divider>
                  <ListItemAvatar>
                    <Avatar>{getIcon(defaultFile.filename)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={defaultFile.name}
                    onClick={() => handleChoosed(defaultFile)}
                  />
                </CustomListItem>
              ))}
            </List>
          </Scrollbars>
        )}
      </Container>
    </>
  );
};

export default DefaultFileChoose;
