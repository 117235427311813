import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import { EditOutlined, Done, CloseOutlined } from '@material-ui/icons';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import api from '../../services/api';

import { ContactContext } from '../../hooks/ContactContext';
import { useLoader } from '../../hooks/LoaderContext';

import getValidationErrors from '../../utils/getValidationErrors';
import handleResponseError from '../../utils/handleResponseError';
import formatMobileNumber from '../../utils/formatMobileNumber';

import { Container, CustomInput } from './styles';

interface Props {
  phone?: string;
  name?: string;
  handleOpenChooseContact?: () => void;
}

interface FormData {
  name: string;
}

const EditContactDialog: React.FC<Props> = ({
  phone,
  name,
  handleOpenChooseContact,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(name ?? 'Sem contato');

  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { updateContactName } = useContext(ContactContext);

  useEffect(() => {
    setNewName(name ?? 'Sem contato');
    setEditing(false);
  }, [phone, name]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        if (phone) {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            name: Yup.string().required('Nome é obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setLoading(true);

          if (name !== data.name) {
            await api.patch('contacts/name-photo', {
              phone,
              name: data.name,
            });

            updateContactName(phone, data.name);
          }

          setNewName(data.name);

          setEditing(false);

          enqueueSnackbar('Contato atualizado com sucesso!', {
            variant: 'success',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, updateContactName, setLoading, phone, name],
  );

  return (
    <Container>
      {editing ? (
        <>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CustomInput id="name" name="name" defaultValue={name} />
          </Form>
          <IconButton
            onClick={() => formRef.current?.submitForm()}
            edge="end"
            color="primary"
            component="span"
          >
            <Done />
          </IconButton>
          <IconButton
            onClick={() => setEditing(false)}
            edge="end"
            color="inherit"
            component="span"
          >
            <CloseOutlined />
          </IconButton>
        </>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                marginLeft: 8,
              }}
            >
              {phone ? (
                <span>{`${newName} ${formatMobileNumber(phone)}`}</span>
              ) : (
                <Button
                  style={{
                    padding: 0,
                    textDecoration: 'underline',
                  }}
                  onClick={handleOpenChooseContact}
                >
                  {newName}
                </Button>
              )}
            </Typography>
            {phone && (
              <Tooltip title="Editar o nome do contato">
                <IconButton
                  onClick={() => setEditing(true)}
                  edge="end"
                  color="primary"
                  component="span"
                  style={{
                    padding: 0,
                    paddingLeft: 8,
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default EditContactDialog;
