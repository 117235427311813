import styled from 'styled-components';

export const SearchRow = styled.div`
  padding-top: 16px;
`;

export const Header = styled.div`
  padding: 8px 16px 16px;
  display: flex;

  button {
    margin-top: 0 !important;
  }
`;
