import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Tooltip,
  Paper,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import {
  ExpandMoreOutlined,
  CheckCircleOutline,
  BlockOutlined,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useResponsible, UserDTO } from '../../../hooks/ResponsibleContext';
import { useLoader } from '../../../hooks/LoaderContext';

import formatMobileNumber from '../../../utils/formatMobileNumber';
import handleResponseError from '../../../utils/handleResponseError';

import PageTitle from '../../../components/PageTitle';
import ConfirmDialog from '../../../components/ConfirmDialog';
import InputSearch from '../../../components/InputSearch';

import Settings from '..';
import UserPermission from './UserPermission';
import UserFunnel from './UserFunnel';
import Permission from '../../../models/Permission';
import PermissionType from '../../../models/PermissionType';

import UserInviteDialog from './UserInviteDialog';

import { Header, SearchRow } from './styles';

const Users: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [userId, setUserId] = useState<string>();
  const [confirmUserInativation, setConfirmUserInativation] = useState<boolean>(
    false,
  );
  const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<UserDTO[]>([]);

  const { users, loadResponsibles } = useResponsible();
  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    const loadPermissions = async () => {
      try {
        setLoading(true);

        const { data } = await api.get<Permission[]>('permissions');

        setPermissions(
          data.filter(
            permission => permission.slug !== PermissionType.AdminUser,
          ),
        );
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadPermissions();
  }, [enqueueSnackbar, setLoading]);

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredUsers(
        users.filter(
          user =>
            searched.length === 0 ||
            user.name.toLowerCase().includes(searched.toLowerCase()) ||
            user.email.toLowerCase().includes(searched.toLowerCase()) ||
            user.phone.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [users],
  );

  const handleEnableUser = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        await api.patch(`users/${id}/activations`);

        await loadResponsibles();

        enqueueSnackbar('Usuário ativado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, loadResponsibles, setLoading],
  );

  const submitDisableUser = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        setConfirmUserInativation(false);

        await api.patch(`users/${id}/inactivations`);

        await loadResponsibles();

        enqueueSnackbar('Usuário inativado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, loadResponsibles, setLoading],
  );

  const handleDisableUser = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        const { data } = await api.get<{
          open: number;
        }>(`users/${id}/negotiations`);

        if (data.open === 0) {
          submitDisableUser(id);
        } else {
          setUserId(id);
          setConfirmUserInativation(true);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, submitDisableUser, setLoading],
  );

  return (
    <>
      <ConfirmDialog
        open={confirmUserInativation}
        title="Negociações em aberto"
        message="Usuário possui negociações em aberto que serão ocultadas do sistema ao inativá-lo. Deseja prosseguir?"
        handleSubmit={() => {
          if (userId) submitDisableUser(userId);
        }}
        handleCancel={() => setConfirmUserInativation(false)}
      />
      <UserInviteDialog open={openInviteDialog} setOpen={setOpenInviteDialog} />
      <Settings route="users">
        <PageTitle pageName="Usuários" />
        <SearchRow>
          <InputSearch
            name="search"
            label="Digite um nome, e-mail ou telefone aqui"
            visible
            handleSearch={handleSearch}
          />
        </SearchRow>
        <Header>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            style={{ borderRadius: '32px' }}
            type="submit"
            onClick={() => setOpenInviteDialog(true)}
          >
            Convidar Novo Usuário
          </Button>
        </Header>
        <Paper>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label="Ativos"
              value={0}
              onClick={() => {
                setSelectedTab(0);
              }}
            />
            <Tab
              label="Inativos"
              value={1}
              onClick={() => {
                setSelectedTab(1);
              }}
            />
          </Tabs>
        </Paper>
        {filteredUsers
          .filter(user => (selectedTab === 0 ? user.active : !user.active))
          .map(user => (
            <ExpansionPanel key={user.id}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
                <Grid container>
                  <Grid item>
                    {user.active ? (
                      <Tooltip title="Ativo">
                        <IconButton
                          edge="start"
                          color="secondary"
                          component="span"
                          size="medium"
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                          ) => {
                            e.stopPropagation();
                            handleDisableUser(user.id);
                          }}
                        >
                          <CheckCircleOutline />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Inativo">
                        <IconButton
                          edge="start"
                          color="inherit"
                          component="span"
                          size="medium"
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                          ) => {
                            e.stopPropagation();
                            handleEnableUser(user.id);
                          }}
                        >
                          <BlockOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Typography variant="body1">{user.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{user.email}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {formatMobileNumber(user.phone)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container>
                  <Grid item xs={6}>
                    <UserPermission user={user} permissions={permissions} />
                  </Grid>
                  <Grid item xs={6}>
                    <UserFunnel user={user} />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </Settings>
    </>
  );
};

export default Users;
