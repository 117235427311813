import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import api from '../services/api';

import { useSession } from './SessionContext';
import { useLoader } from './LoaderContext';

import handleResponseError from '../utils/handleResponseError';

type GenerateApiKeyDTO = {
  client_id: string;
  channels: string[];
  revoked_channels?: string[];
};

interface State {
  partnerId: string;
  email: string;
  name: string;
  handleGenerateApiKey: (data: GenerateApiKeyDTO) => Promise<void>;
}

export const LinkWhatsappContext = createContext<State>({} as State);

export const LinkWhatsappProvider: React.FC = ({ children }) => {
  const partnerId = useMemo(() => 'W1sJ6SPA', []);

  const { user, handleChangeWabaConfigured } = useSession();
  const history = useHistory();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const handleGenerateApiKey = useCallback(
    async (data: GenerateApiKeyDTO): Promise<void> => {
      try {
        setLoading(true);

        await api.post('instances/configure', data);

        handleChangeWabaConfigured();

        history.replace('funnels');
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },

    [enqueueSnackbar, setLoading, handleChangeWabaConfigured, history],
  );

  return (
    <LinkWhatsappContext.Provider
      value={{
        partnerId,
        name: user.name,
        email: user.email,
        handleGenerateApiKey,
      }}
    >
      {children}
    </LinkWhatsappContext.Provider>
  );
};

export const useLinkWhatsapp = (): State => {
  const context = useContext(LinkWhatsappContext);

  if (!context) {
    throw new Error(
      'useLinkWhatsapp must be used within a LinkWhatsappProvider',
    );
  }

  return context;
};
