import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const CustomButton = styled(Button)<{
  iconSize: string;
}>`
  && {
    height: 24px;
    border-radius: 4px;
    text-transform: none;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.3);
    transition: all 0.4s ease-in-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.4);
    }

    svg {
      width: ${props => props.iconSize};
      height: ${props => props.iconSize};
    }
  }
`;
