import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useSession } from '../../../hooks/SessionContext';
import { useLoader } from '../../../hooks/LoaderContext';

import getValidationErrors from '../../../utils/getValidationErrors';
import handleResponseError from '../../../utils/handleResponseError';

import Settings from '..';
import Input from '../../../components/Input';
import InputPhone from '../../../components/InputPhone';
import PageTitle from '../../../components/PageTitle';
import Space from '../../../components/Space';

import { Container, Content, CustomGrid, CustomButton } from './styles';

interface FormData {
  name: string;
  phone: string;
  email: string;
  password: string;
  password_new: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { user, signOut } = useSession();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState({
    password: false,
    password_new: false,
  });
  const [minPhone, setMinPhone] = useState<number>(0);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          phone: Yup.string()
            .nullable()
            .min(minPhone, 'Preencha todos os dígitos')
            .required('Celular é obrigatório'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('E-mail é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put('/users', data);

        signOut();

        enqueueSnackbar('Perfil atualizado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, signOut, setLoading, minPhone],
  );

  return (
    <Settings>
      <PageTitle pageName="Perfil" />
      <Container>
        <Content>
          <Form initialData={user} ref={formRef} onSubmit={handleSubmit}>
            <CustomGrid item>
              <Input id="name" name="name" label="Nome" />
            </CustomGrid>
            <Space orientation="vertical" />
            <CustomGrid item>
              <InputPhone
                id="phone"
                name="phone"
                label="Celular"
                length={length => setMinPhone(length)}
              />
            </CustomGrid>
            <Space orientation="vertical" />
            <CustomGrid item>
              <Input id="email" name="email" label="E-mail" type="email" />
            </CustomGrid>
            <Space orientation="vertical" />
            <CustomGrid item>
              <Input
                id="password"
                name="password"
                label="Senha"
                type={showPassword.password ? 'text' : 'password'}
                endAdornment={{
                  ...(
                    <IconButton
                      aria-label="Alterar visibilidade da senha"
                      onClick={() => {
                        setShowPassword(oldShowPassword => {
                          return {
                            ...oldShowPassword,
                            password: !oldShowPassword.password,
                          };
                        });
                      }}
                      size="small"
                    >
                      {showPassword.password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </CustomGrid>
            <Space orientation="vertical" />
            <CustomGrid item>
              <Input
                id="password_new"
                name="password_new"
                label="Nova senha"
                type={showPassword.password_new ? 'text' : 'password'}
                endAdornment={{
                  ...(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Alterar visibilidade da nova senha"
                        onClick={() => {
                          setShowPassword(oldShowPassword => {
                            return {
                              ...oldShowPassword,
                              password_new: !oldShowPassword.password_new,
                            };
                          });
                        }}
                        size="small"
                      >
                        {showPassword.password_new ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </CustomGrid>
            <CustomGrid item>
              <CustomButton
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
              >
                SALVAR
              </CustomButton>
            </CustomGrid>
          </Form>
        </Content>
      </Container>
    </Settings>
  );
};

export default Profile;
