import React from 'react';
import {
  Button,
  Dialog,
  DialogProps,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { ReportOutlined } from '@material-ui/icons';

import { CustomDialogContent } from './styles';

interface Props extends DialogProps {
  title: string;
  message: string;
  handleSubmit: () => void;
  handleCancel: () => void;
}

const ConfirmDialog: React.FC<Props> = ({
  title,
  message,
  handleSubmit,
  handleCancel,
  ...rest
}) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth="xs"
    aria-labelledby="confirmation-dialog-title"
    {...rest}
  >
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    <CustomDialogContent dividers>
      <ReportOutlined fontSize="large" />
      <Typography>{message}</Typography>
    </CustomDialogContent>
    <DialogActions>
      <Button color="primary" onClick={handleCancel}>
        Não
      </Button>
      <Button color="primary" onClick={handleSubmit}>
        Sim
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
