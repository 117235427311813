import React, { useMemo, useState, useRef, useCallback } from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import {
  TemplateMessageDTO,
  useMessageTemplate,
} from '../../../../../hooks/MessageTemplateContext';
import { useDefaultFiles } from '../../../../../hooks/DefaultFilesContext';
import { useLoader } from '../../../../../hooks/LoaderContext';

import getValidationErrors from '../../../../../utils/getValidationErrors';
import handleResponseError from '../../../../../utils/handleResponseError';

import api from '../../../../../services/api';

import DefaultFile from '../../../../../models/DefaultFile';
import MessageType from '../../../../../models/MessageType';

import Input from '../../../../../components/Input';
import SelectForm from '../../../../../components/SelectForm';
import Space from '../../../../../components/Space';

import { Container, MessageRow, SelectionTitle } from './styles';

interface Props extends DialogProps {
  messageTemplate?: TemplateMessageDTO;
  negotiationsId?: string[];
  selectionTitle?: string;
  handleSendMessageTemplate?: (
    text: string,
    name: string,
    params: string[],
    default_file_id?: string,
    type?: MessageType,
  ) => Promise<void>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessageTemplateDialog: React.FC<Props> = ({
  open,
  messageTemplate,
  negotiationsId,
  selectionTitle,
  handleSendMessageTemplate,
  setOpen,
  ...rest
}) => {
  const [paramsData, setParamsData] = useState<{
    [param: string]: string | undefined;
  }>({});
  const [selectedTemplateMessage, setSelectedTemplateMessage] = useState<
    TemplateMessageDTO | undefined
  >(messageTemplate);

  const { defaultFiles } = useDefaultFiles();
  const { messageTemplates } = useMessageTemplate();
  const { setLoading } = useLoader();

  const params = useMemo((): string[] => {
    const pattern = /{{\d+}}/g;

    const result = selectedTemplateMessage?.body.match(pattern);

    return result ?? [];
  }, [selectedTemplateMessage]);

  const fullMessage = useMemo((): string | undefined => {
    if (!selectedTemplateMessage) return undefined;

    let handledFullMessage: string = selectedTemplateMessage.body;

    params.forEach(param => {
      const paramValue = paramsData[param];

      handledFullMessage = handledFullMessage.replace(
        param,
        paramValue && paramValue.length > 0 ? paramValue : param,
      );
    });

    return handledFullMessage;
  }, [selectedTemplateMessage, params, paramsData]);

  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef<FormHandles>(null);

  const handleChangeTemplateMessage = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      const id = event.target.value as string;

      setSelectedTemplateMessage(
        messageTemplates.find(template => template.name === id),
      );
    },
    [messageTemplates],
  );

  const handleSubmit = useCallback(
    async (data: { message: string; default_file_id?: string }) => {
      let loading = false;

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          template_name: Yup.string()
            .nullable()
            .required('Template é obrigatório!'),
          default_file_id: selectedTemplateMessage?.header
            ? Yup.string()
                .nullable()
                .required(
                  `${
                    selectedTemplateMessage.header === 'IMAGE'
                      ? 'Imagem'
                      : 'Documento'
                  } é obrigatório`,
                )
            : Yup.string().nullable().notRequired(),
          ...params.reduce(
            (map, param) => ({
              ...map,
              [param]: Yup.string().required(
                `Parâmetro ${param} é obrigatório'`,
              ),
            }),
            {} as {
              [field: string]: Yup.StringSchema<string>;
            },
          ),
        });

        if (Object.keys(schema).length > 0) {
          await schema.validate(data, {
            abortEarly: false,
          });
        }

        if (!selectedTemplateMessage) return;

        const paramsValue = params.map(param => paramsData[param] as string);

        let type: MessageType = MessageType.Chat;

        if (selectedTemplateMessage.header)
          type =
            selectedTemplateMessage.header === 'IMAGE'
              ? MessageType.Image
              : MessageType.Document;

        if (negotiationsId) {
          setLoading(true);
          loading = true;

          await api.patch<void>('bulk-transfers/message', {
            negotiations_ids: negotiationsId,
            template_name: selectedTemplateMessage.name,
            params: paramsValue,
            type,
            full_message: fullMessage,
            default_file_id: data.default_file_id,
          });

          enqueueSnackbar(
            'As negociações estão sendo processadas e a mensagem será enviada para todas que possuem um whatsapp válido!',
            {
              variant: 'info',
            },
          );
        } else {
          if (!fullMessage)
            throw new Error('Mensagem completa não configurada!');

          handleSendMessageTemplate?.(
            fullMessage,
            selectedTemplateMessage.name,
            paramsValue,
            data.default_file_id,
            type,
          );
        }

        setOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        if (loading) setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      setOpen,
      handleSendMessageTemplate,
      selectedTemplateMessage,
      params,
      paramsData,
      fullMessage,
      negotiationsId,
    ],
  );

  const mappedDefaultFiles = useMemo(() => {
    if (!selectedTemplateMessage) return [];

    let filteredDefaultFiles: DefaultFile[];

    if (selectedTemplateMessage.header === 'IMAGE') {
      filteredDefaultFiles = defaultFiles.filter(({ mimetype }) =>
        ['image/jpeg', 'image/png'].includes(mimetype),
      );
    } else {
      filteredDefaultFiles = defaultFiles.filter(
        ({ mimetype }) => !mimetype.includes('image'),
      );
    }

    return filteredDefaultFiles.map(({ id, name }) => ({
      id,
      name,
    }));
  }, [defaultFiles, selectedTemplateMessage]);

  const mappedTemplateMessages = useMemo(
    () =>
      messageTemplates
        .filter(template => template.status === 'approved')
        .map(template => ({
          id: template.name,
          name: template.body,
        })),
    [messageTemplates],
  );

  return (
    <Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        open={open}
        {...rest}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <DialogTitle>Enviar Mensagem de Template</DialogTitle>
          <DialogContent dividers>
            {selectionTitle && (
              <>
                <SelectionTitle>{selectionTitle}</SelectionTitle>
                <Space orientation="vertical" />
              </>
            )}
            <SelectForm
              id="template_name"
              name="template_name"
              label="Template"
              values={mappedTemplateMessages}
              defaultValue={selectedTemplateMessage?.name}
              hiddenDefaultOption
              onChange={handleChangeTemplateMessage}
              disabled={negotiationsId && negotiationsId.length === 0}
            />
            <Space orientation="vertical" />
            {fullMessage && <MessageRow>{fullMessage}</MessageRow>}
            <Space orientation="vertical" />
            {selectedTemplateMessage && selectedTemplateMessage.header && (
              <div>
                <SelectForm
                  id="default_file_id"
                  name="default_file_id"
                  label={
                    selectedTemplateMessage.header === 'IMAGE'
                      ? 'Imagem'
                      : 'Documento'
                  }
                  values={mappedDefaultFiles}
                  hiddenDefaultOption
                />
                <Space orientation="vertical" />
              </div>
            )}
            {params.map(param => (
              <div>
                <Input
                  id={param}
                  name={param}
                  label={param}
                  onChange={e => {
                    const paramValue = e.target.value;

                    setParamsData(oldParamsData => ({
                      ...oldParamsData,
                      [param]: paramValue,
                    }));
                  }}
                />
                <Space orientation="vertical" />
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={negotiationsId && negotiationsId.length === 0}
            >
              Enviar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Container>
  );
};

export default MessageTemplateDialog;
