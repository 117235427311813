import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  handleDrop: (e: any) => void;
  disabled?: boolean;
}

const DragAndDropFile: React.FC<Props> = ({
  children,
  handleDrop,
  disabled = false,
}) => {
  const [drag, setDrag] = useState<boolean>(false);
  const dropRef = useRef<any>(React.createRef());
  const [dragCounter, setDragCounter] = useState<number>(0);

  useEffect(() => setDrag(dragCounter !== 0), [dragCounter]);

  const handleDrag = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(oldDragCounter => oldDragCounter + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  }, []);

  const handleDragOut = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(oldDragCount => oldDragCount - 1);
  }, []);

  const handleDropCall = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      setDrag(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleDrop(e.dataTransfer.files);
        e.dataTransfer.clearData();
        setDragCounter(0);
      }
    },
    [setDrag, handleDrop, setDragCounter],
  );

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDropCall);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDropCall);
    };
  });

  return (
    <div
      style={{ display: 'inline-block', position: 'relative', height: '100%' }}
      ref={dropRef}
    >
      {drag && !disabled && (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
            }}
          >
            <div>Arraste o arquivo</div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default DragAndDropFile;
