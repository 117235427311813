import React, { useCallback } from 'react';
import { Paper } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import handleResponseError from '../../../../utils/handleResponseError';

import { createNegotiationFile } from '../../../../services/NegotiationFileService';

import { useLoader } from '../../../../hooks/LoaderContext';

import NegotiationFile from '../../../../models/NegotiationFile';

import {
  ButtonGreenOutlined,
  ButtonIconGreenOutlined,
} from '../../../../styles/button';
import { GroupItens } from './styles';

interface TabFilesToolbarDTO {
  negotiation_id: string;
  setNegotiationFiles: React.Dispatch<React.SetStateAction<NegotiationFile[]>>;
  small: boolean;
}

const TabFilesToolbar: React.FC<TabFilesToolbarDTO> = ({
  negotiation_id,
  setNegotiationFiles,
  small,
}) => {
  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async (files?: FileList | null) => {
      try {
        if (negotiation_id && files) {
          setLoading(true);

          await Promise.all(
            Array.from(files).map(async file => {
              const data = await createNegotiationFile({
                negotiation_id,
                file,
              });

              setNegotiationFiles(oldNegotiationFiles => [
                data,
                ...oldNegotiationFiles,
              ]);

              return data;
            }),
          );

          enqueueSnackbar('Arquivo(s) anexado(s) com sucesso!', {
            variant: 'success',
          });
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [negotiation_id, enqueueSnackbar, setNegotiationFiles, setLoading],
  );

  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 0,
      }}
    >
      <GroupItens>
        <input
          multiple
          style={{
            display: 'none',
          }}
          id="negotiation-upload"
          type="file"
          onChange={e => handleSubmit(e.target.files)}
        />
        <label htmlFor="negotiation-upload">
          {small ? (
            <ButtonIconGreenOutlined
              startIcon={<CloudUpload />}
              component="span"
              type="button"
            />
          ) : (
            <ButtonGreenOutlined startIcon={<CloudUpload />} component="span">
              Upload
            </ButtonGreenOutlined>
          )}
        </label>
      </GroupItens>
    </Paper>
  );
};

export default TabFilesToolbar;
