import { AxiosError } from 'axios';

const handleResponseError = (error: unknown) => {
  const message =
    (error as AxiosError)?.response?.data?.error ??
    ((error as Error)?.message === 'Network Error'
      ? 'Sem conexão com a internet. Verifique!'
      : (error as Error)?.message) ??
    error ??
    'Desculpe, ocorreu um erro inesperado!';

  return message;
};

export default handleResponseError;
