import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  FormControl,
  Button,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import getValidationErrors from '../../../utils/getValidationErrors';
import handleResponseError from '../../../utils/handleResponseError';

import { useLoader } from '../../../hooks/LoaderContext';
import { useResponsible } from '../../../hooks/ResponsibleContext';
import { useFunnel } from '../../../hooks/FunnelContext';

import api from '../../../services/api';

import RadioGroup from '../../../components/RadioGroup';
import SelectForm from '../../../components/SelectForm';

import { CustomDialogActions, SelectionTitle } from './styles';

type FormData = {
  user_id?: string;
  funnel_id?: string;
  step_id?: string;
};

type Props = DialogProps & {
  negotiationsId: string[];
  selectionTitle: string;
  handleOk: () => void;
  handleCancel: () => void;
};

const TransferBulkDialog: React.FC<Props> = ({
  negotiationsId,
  selectionTitle,
  handleOk,
  handleCancel,
  ...rest
}) => {
  const [selectedType, setSelectedType] = useState<number>(1);
  const [funnelId, setFunnelId] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const { users } = useResponsible();
  const { funnels } = useFunnel();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (selectedType === 2) setFunnelId(null);
  }, [selectedType]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        let schema: Yup.ObjectSchema<object> | undefined;

        switch (selectedType) {
          case 1:
            schema = Yup.object().shape({
              funnel_id: Yup.string()
                .nullable()
                .required('Funil é obrigatório'),
              step_id:
                funnelId === null
                  ? Yup.string().nullable().notRequired()
                  : Yup.string().nullable().required('Etapa é obrigatório'),
            });
            break;
          case 2:
            schema = Yup.object().shape({
              user_id: Yup.string()
                .nullable()
                .required('Responsável é obrigatório'),
            });
            break;
          default:
        }

        await schema?.validate(data, {
          abortEarly: false,
        });

        const { funnel_id, step_id, user_id } = data;

        setLoading(true);

        switch (selectedType) {
          case 1:
            await api.patch<void>('bulk-transfers/funnel', {
              negotiations_ids: negotiationsId,
              funnel_id,
              step_id,
            });
            break;
          case 2:
            await api.patch<void>('bulk-transfers/responsible', {
              negotiations_ids: negotiationsId,
              user_id,
            });
            break;
          default:
        }

        handleOk();

        enqueueSnackbar('Transferências realizadas com sucesso! ', {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleOk,
      negotiationsId,
      selectedType,
      funnelId,
    ],
  );

  const mappedFunnels = useMemo(
    () =>
      funnels.map(({ id, name }) => ({
        id,
        name,
      })),
    [funnels],
  );

  const mappedSteps = useMemo(() => {
    if (!funnelId) return [];

    const funnel = funnels.find(funnel => funnel.id === funnelId);

    if (!funnel) return [];

    return funnel.steps.map(({ id, name }) => ({
      id,
      name,
    }));
  }, [funnelId, funnels]);

  const mappedUsers = useMemo(
    () =>
      users
        .filter(({ active }) => active)
        .map(({ id, name }) => ({
          id,
          name,
        })),
    [users],
  );

  const negotiationsCount = negotiationsId.length;

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" {...rest}>
      <DialogTitle id="customized-dialog-title">
        Transferências em Massa
      </DialogTitle>
      <DialogContent dividers>
        <Form
          ref={formRef}
          initialData={{
            type: 1,
          }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectionTitle>{selectionTitle}</SelectionTitle>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  name="type"
                  row
                  getValue={value => {
                    setSelectedType(value as number);
                  }}
                  options={[
                    {
                      id: 1,
                      name: 'Transferir Funil',
                    },
                    {
                      id: 2,
                      name: 'Trasferir Responsável',
                    },
                  ]}
                />
              </FormControl>
            </Grid>
            {selectedType === 1 && (
              <>
                <Grid item xs={12}>
                  <SelectForm
                    name="funnel_id"
                    label="Funil"
                    values={mappedFunnels}
                    disabled={negotiationsCount < 1}
                    hiddenDefaultOption
                    onChange={e => setFunnelId(e.target.value as string)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectForm
                    name="step_id"
                    label="Etapa"
                    values={mappedSteps}
                    disabled={negotiationsCount < 1 || funnelId === null}
                    hiddenDefaultOption
                  />
                </Grid>
              </>
            )}
            {selectedType === 2 && (
              <Grid item xs={12}>
                <SelectForm
                  name="user_id"
                  label="Responsável"
                  values={mappedUsers}
                  disabled={negotiationsCount < 1}
                  hiddenDefaultOption
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </DialogContent>
      <CustomDialogActions>
        <Button
          onClick={() => {
            setFunnelId(null);
            handleCancel();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => formRef.current?.submitForm()}
          color="primary"
          disabled={negotiationsCount === 0}
        >
          Transferir
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

export default TransferBulkDialog;
