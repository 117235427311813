import styled from 'styled-components';

export const Container = styled.section`
  background-color: white;
  border-top: 1px solid #ddd;
  max-height: fit-content;

  .MuiListItem-root {
    padding: 0 8px;
  }
  .MuiList-padding {
    padding: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  padding: 0 8px;
  color: #6fa7ba;

  div {
    width: 100%;
    max-width: 320px;
    margin-top: 5px;
  }
`;
