import React from 'react';

import { Container } from './styles';

interface MessageTemplateStatusProps {
  status: string;
}

const MessageTemplateStatus: React.FC<MessageTemplateStatusProps> = ({
  status,
}) => {
  return <Container status={status} />;
};

export default MessageTemplateStatus;
