import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useSnackbar } from 'notistack';

import { useLoader } from './LoaderContext';

import {
  FindAllContactsDTO,
  findAllContacts,
  getDetails,
  ContactDetailsDTO,
  ContactDTO,
} from '../services/ContactService';
import { readMessages } from '../services/MessageService';

import { getBase64 } from '../utils/showFile';
import handleResponseError from '../utils/handleResponseError';

import Message from '../models/Message';
import StorageType from '../models/StorageType';
import MessageStatus from '../models/MessageStatus';
import Negotiation from '../models/Negotiation';
import MessageType from '../models/MessageType';
import ChatbotContact from '../models/ChatbotContact';

import sound from '../assets/sounds/whatsapp_web.mp3';

interface State {
  contacts: ContactDTO[];
  selectedContact: ContactDTO | undefined;
  loadContacts: () => Promise<void>;
  selectContact(contact?: ContactDTO): void;
  enableDisableSound(): void;
  createContact(contact: ContactDTO, redirect?: boolean): Promise<void>;
  updateContact(message: Message): void;
  updateChatbot: (chatbot: ChatbotContact) => void;
  readContact: (phone: string) => void;
  addNewContact: (contact: ContactDTO) => Promise<void>;
  updateContactName: (phone: string, name: string) => void;
  updateContactConversationExpirationDate: (
    phone: string,
    date: string,
  ) => void;
  updateContactLastReceivedMessageDate: (phone: string, date: string) => void;
  updateMessageKey: (
    phone: string,
    key: string,
    temp_key: string,
    base64?: string,
  ) => void;
  updateMessageStatus: (
    phone: string,
    key: string,
    status: MessageStatus,
  ) => void;
  removeMessage: (phone: string, temp_key: string, whatsapp: boolean) => void;
  handleContactNegotiation: (phone: string, negotiation?: Negotiation) => void;
  setPagination: React.Dispatch<React.SetStateAction<FindAllContactsDTO>>;
  pagination: FindAllContactsDTO;
  advancedFiltersCount: number;
  contactsDetails?: ContactDetailsDTO;
  activateAudio: boolean;
}

const ContactContext = createContext<State>({} as State);

const ContactProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  const [pagination, setPagination] = useState<FindAllContactsDTO>({
    take: 20,
    skip: 0,
    withoutResponsible: 0,
    includeMessages: 1,
  });
  const [advancedFiltersCount, setAdvancedFiltersCount] = useState<number>(0);
  const [contacts, setContacts] = useState<ContactDTO[]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactDTO>();
  const [contactsDetails, setContactsDetails] = useState<ContactDetailsDTO>();
  const [activateAudio, setActivateAudio] = useState<boolean>(true);

  const selectedContactRef = useRef<ContactDTO | null>(null);

  useEffect(() => {
    let count = 0;

    if (pagination?.withoutResponsible === 1) count += 1;
    if (pagination?.unreadMessages === 1) count += 1;
    if (pagination?.user_id) count += 1;
    if (pagination?.funnel_id) count += 1;
    if (pagination?.step_id) count += 1;
    if (pagination?.product_id) count += 1;
    if (pagination?.channel_id) count += 1;

    setAdvancedFiltersCount(count);
  }, [pagination]);

  const loadContacts = useCallback(async () => {
    try {
      setLoading(true);

      const data = await findAllContacts(pagination);

      const dataFormatted = await Promise.all(
        data.map(async contact => {
          return {
            ...contact,
            photo: await getBase64(contact.photo, StorageType.Contact),
          };
        }),
      );

      if (pagination.take === 1 && dataFormatted.length !== 0) {
        const contact = dataFormatted[0];

        selectedContactRef.current = contact;
        setSelectedContact(selectedContactRef.current);
      } else if (selectedContactRef.current !== null) {
        const contact = dataFormatted.find(
          c => c.id === selectedContactRef.current?.id,
        ) as ContactDTO | undefined;
        selectedContactRef.current = contact || null;
        setSelectedContact(contact);
      } else {
        selectedContactRef.current = null;
        setSelectedContact(undefined);
      }

      setContacts(oldContacts => {
        const oldContactsPhones = oldContacts.map(contact => contact.phone);

        return pagination.skip === 0 || pagination.name
          ? [...dataFormatted]
          : [
              ...oldContacts,
              ...dataFormatted.filter(
                contact => !oldContactsPhones.includes(contact.phone),
              ),
            ];
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, pagination]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  const audio = useMemo(() => new Audio(sound), []);

  const playAudio = useCallback(async (): Promise<boolean> => {
    try {
      await audio.play();

      return true;
    } catch {
      return false;
    }
  }, [audio]);

  const selectContact = useCallback(
    async (contact?: ContactDTO) => {
      try {
        if (contact) {
          selectedContactRef.current = contact;

          setSelectedContact(selectedContactRef.current);

          const messages = await Promise.all(
            contact.messages.map(async message => {
              if (
                message.type &&
                [
                  MessageType.Image,
                  MessageType.Video,
                  MessageType.Document,
                  MessageType.Audio,
                  MessageType.Ptt,
                ].includes(message.type) &&
                !message.base64 &&
                !message.base64_status
              ) {
                let base64: string | undefined;
                let base64_status: 'loading' | 'finish' | 'error' = 'loading';

                try {
                  base64 = await getBase64(message.text, StorageType.Chat);

                  base64_status = 'finish';
                } catch {
                  base64_status = 'error';
                }

                return {
                  ...message,
                  unread_message: false,
                  base64,
                  base64_status,
                };
              }

              return {
                ...message,
                unread_message: false,
              };
            }),
          );

          selectedContactRef.current = {
            ...contact,
            messages,
          };

          setSelectedContact(selectedContactRef.current);

          setContacts(oldContacts =>
            oldContacts.map(oldContact => {
              if (oldContact.phone === contact.phone)
                return {
                  ...contact,
                  messages,
                };

              return oldContact;
            }),
          );

          setContactsDetails(oldContactsDetails => {
            if (!oldContactsDetails) return undefined;

            const isUnreadedContact = oldContactsDetails.unreaded_conversations.phones.includes(
              contact.phone,
            );

            return isUnreadedContact
              ? {
                  ...oldContactsDetails,
                  unreaded_conversations: {
                    quantity:
                      oldContactsDetails.unreaded_conversations.quantity - 1,
                    phones: oldContactsDetails.unreaded_conversations.phones.filter(
                      phone => phone !== contact.phone,
                    ),
                  },
                }
              : oldContactsDetails;
          });

          await readMessages({
            phone: contact.phone,
          });
        } else {
          selectedContactRef.current = null;
          setSelectedContact(undefined);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar],
  );

  const createContact = useCallback(
    async (contact: ContactDTO, redirect?: boolean) => {
      setContacts(oldContacts => {
        const target = oldContacts.find(c => c.phone === contact.phone);

        if (redirect)
          selectContact({
            ...contact,
            messages: target?.messages ?? [],
          });

        const rest = target
          ? oldContacts.filter(c => c.phone !== contact.phone)
          : oldContacts;

        return [
          {
            ...contact,
            messages: target?.messages ?? [],
          },
          ...rest,
        ];
      });

      setContactsDetails(oldContactsDetails => {
        if (!oldContactsDetails)
          return {
            conversations: 1,
            unreaded_conversations: {
              quantity: 1,
              phones: [contact.phone],
            },
          };

        if (
          oldContactsDetails.unreaded_conversations.phones.includes(
            contact.phone,
          )
        )
          return oldContactsDetails;

        return {
          ...oldContactsDetails,
          unreaded_conversations: {
            quantity: oldContactsDetails.unreaded_conversations.quantity + 1,
            phones: [
              ...oldContactsDetails.unreaded_conversations.phones,
              contact.phone,
            ],
          },
        };
      });

      await playAudio();
    },
    [playAudio, selectContact],
  );

  const addNewContact = useCallback(async (contact: ContactDTO) => {
    const photo = await getBase64(contact.photo, StorageType.Contact);

    setContacts(oldContacts => [
      {
        ...contact,
        photo,
      },
      ...oldContacts,
    ]);
  }, []);

  const setSelectedContactData = useCallback((phone: string, data: object) => {
    if (
      selectedContactRef.current !== null &&
      selectedContactRef.current.phone === phone
    ) {
      selectedContactRef.current = {
        ...selectedContactRef.current,
        ...data,
      };

      setSelectedContact(selectedContactRef.current);
    }
  }, []);

  const updateContact = useCallback(
    async (message: Message) => {
      try {
        setContacts(oldContacts => {
          const targetContact = oldContacts.find(
            contact => contact.phone === message.phone,
          );

          if (targetContact) {
            const messagesContact = targetContact.messages.filter(
              m => m.key !== message.key,
            );

            const contact: ContactDTO = {
              ...targetContact,
              messages: [message, ...messagesContact],
            };

            return [
              contact,
              ...oldContacts.filter(c => c.phone !== message.phone),
            ];
          }

          return oldContacts;
        });

        if (
          selectedContactRef.current !== null &&
          selectedContactRef.current.phone === message.phone
        ) {
          if (
            message.type &&
            [
              MessageType.Image,
              MessageType.Video,
              MessageType.Document,
              MessageType.Audio,
              MessageType.Ptt,
            ].includes(message.type) &&
            !message.base64 &&
            !message.base64_status
          ) {
            let base64: string | undefined;
            let base64_status: 'loading' | 'finish' | 'error' = 'loading';

            try {
              base64 = await getBase64(message.text, StorageType.Chat);

              base64_status = 'finish';
            } catch {
              base64_status = 'error';
            }

            selectedContactRef.current = {
              ...selectedContactRef.current,
              messages: [
                {
                  ...message,
                  base64,
                  base64_status,
                },
                ...selectedContactRef.current.messages,
              ],
            };
          } else {
            selectedContactRef.current = {
              ...selectedContactRef.current,
              messages: [message, ...selectedContactRef.current.messages],
            };
          }

          setSelectedContact(selectedContactRef.current);

          setContactsDetails(oldContactsDetails => {
            if (!oldContactsDetails) return undefined;

            const isUnreadedContact = oldContactsDetails.unreaded_conversations.phones.includes(
              message.phone,
            );

            return isUnreadedContact
              ? {
                  ...oldContactsDetails,
                  unreaded_conversations: {
                    quantity:
                      oldContactsDetails.unreaded_conversations.quantity - 1,
                    phones: oldContactsDetails.unreaded_conversations.phones.filter(
                      phone => phone !== message.phone,
                    ),
                  },
                }
              : oldContactsDetails;
          });

          if (!document.hasFocus()) await playAudio();

          await readMessages({
            phone: message.phone,
          });
        } else {
          setContactsDetails(oldContactsDetails => {
            if (!oldContactsDetails)
              return {
                conversations: 1,
                unreaded_conversations: {
                  quantity: 1,
                  phones: [message.phone],
                },
              };

            if (
              oldContactsDetails.unreaded_conversations.phones.includes(
                message.phone,
              )
            )
              return oldContactsDetails;

            return {
              ...oldContactsDetails,
              unreaded_conversations: {
                quantity:
                  oldContactsDetails.unreaded_conversations.quantity + 1,
                phones: [
                  ...oldContactsDetails.unreaded_conversations.phones,
                  message.phone,
                ],
              },
            };
          });

          await playAudio();
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, playAudio],
  );

  const updateChatbot = useCallback((chatbot: ChatbotContact) => {
    if (
      selectedContactRef.current !== null &&
      selectedContactRef.current.id === chatbot.contact_id
    ) {
      selectedContactRef.current = {
        ...selectedContactRef.current,
        chatbot,
      };

      setSelectedContact(selectedContactRef.current);
    }

    setContacts(oldContacts =>
      oldContacts.map(oldContact =>
        oldContact.id === chatbot.contact_id
          ? {
              ...oldContact,
              chatbot,
            }
          : oldContact,
      ),
    );
  }, []);

  const readContact = useCallback((phone: string) => {
    if (
      selectedContactRef.current !== null &&
      selectedContactRef.current.phone === phone
    ) {
      selectedContactRef.current = {
        ...selectedContactRef.current,
        messages: selectedContactRef.current.messages.map(message => ({
          ...message,
          unread_message: false,
        })),
      };

      setSelectedContact(selectedContactRef.current);
    }

    const isAllMessagesRead = true;

    setContacts(oldContacts =>
      oldContacts.map(oldContact =>
        oldContact.phone === phone
          ? {
              ...oldContact,
              messages: oldContact.messages.map(message => ({
                ...message,
                unread_message: false,
              })),
            }
          : oldContact,
      ),
    );

    if (isAllMessagesRead)
      setContactsDetails(oldContactsDetails => {
        if (!oldContactsDetails) return undefined;

        if (oldContactsDetails.unreaded_conversations.phones.includes(phone))
          return {
            ...oldContactsDetails,
            unreaded_conversations: {
              quantity: oldContactsDetails.unreaded_conversations.quantity - 1,
              phones: oldContactsDetails.unreaded_conversations.phones.filter(
                p => p !== phone,
              ),
            },
          };

        return oldContactsDetails;
      });
  }, []);

  const updateContactName = useCallback(
    (phone: string, name: string) => {
      setSelectedContactData(phone, {
        name,
      });

      setContacts(oldContacts =>
        oldContacts.map(oldContact =>
          oldContact.phone === phone
            ? {
                ...oldContact,
                name,
              }
            : oldContact,
        ),
      );
    },
    [setSelectedContactData],
  );

  const updateContactConversationExpirationDate = useCallback(
    (phone: string, date: string) => {
      setSelectedContactData(phone, {
        conversation_expiration_date: date,
      });

      setContacts(oldContacts =>
        oldContacts.map(oldContact =>
          oldContact.phone === phone
            ? {
                ...oldContact,
                conversation_expiration_date: date,
              }
            : oldContact,
        ),
      );
    },
    [setSelectedContactData],
  );

  const updateContactLastReceivedMessageDate = useCallback(
    (phone: string, date: string) => {
      setSelectedContactData(phone, {
        last_received_message_date: date,
      });

      setContacts(oldContacts =>
        oldContacts.map(oldContact =>
          oldContact.phone === phone
            ? {
                ...oldContact,
                last_received_message_date: date,
              }
            : oldContact,
        ),
      );
    },
    [setSelectedContactData],
  );

  const updateMessageKey = useCallback(
    (phone: string, key: string, temp_key: string, base64?: string) => {
      if (
        selectedContactRef.current !== null &&
        selectedContactRef.current.phone === phone
      ) {
        selectedContactRef.current = {
          ...selectedContactRef.current,
          messages: selectedContactRef.current.messages.map(oldMessage =>
            oldMessage.key === temp_key
              ? {
                  ...oldMessage,
                  key,
                  base64: base64 || oldMessage.base64,
                }
              : oldMessage,
          ),
        };

        setSelectedContact(selectedContactRef.current);
      }

      setContacts(oldContacts =>
        oldContacts.map(oldContact =>
          oldContact.phone === phone
            ? {
                ...oldContact,
                messages: oldContact.messages.map(oldMessage =>
                  oldMessage.key === temp_key
                    ? {
                        ...oldMessage,
                        key,
                      }
                    : oldMessage,
                ),
              }
            : oldContact,
        ),
      );
    },
    [],
  );

  const updateMessageStatus = useCallback(
    (phone: string, key: string, status: MessageStatus) => {
      if (
        selectedContactRef.current !== null &&
        selectedContactRef.current.phone === phone
      ) {
        selectedContactRef.current = {
          ...selectedContactRef.current,
          messages: selectedContactRef.current.messages.map(oldMessage =>
            oldMessage.key === key
              ? {
                  ...oldMessage,
                  status,
                }
              : oldMessage,
          ),
        };

        setSelectedContact(selectedContactRef.current);
      }

      setContacts(oldContacts =>
        oldContacts.map(oldContact =>
          oldContact.phone === phone
            ? {
                ...oldContact,
                messages: oldContact.messages.map(oldMessage =>
                  oldMessage.key === key
                    ? {
                        ...oldMessage,
                        status,
                      }
                    : oldMessage,
                ),
              }
            : oldContact,
        ),
      );
    },
    [],
  );

  const removeMessage = useCallback(
    (phone: string, temp_key: string, whatsapp: boolean) => {
      if (
        selectedContactRef.current !== null &&
        selectedContactRef.current.phone === phone
      ) {
        selectedContactRef.current = {
          ...selectedContactRef.current,
          whatsapp,
          messages: selectedContactRef.current.messages.filter(
            oldMessage => oldMessage.key !== temp_key,
          ),
        };

        setSelectedContact(selectedContactRef.current);
      }

      setContacts(oldContacts =>
        oldContacts.map(oldContact =>
          oldContact.phone === phone
            ? {
                ...oldContact,
                whatsapp,
                messages: oldContact.messages.filter(
                  oldMessage => oldMessage.key !== temp_key,
                ),
              }
            : oldContact,
        ),
      );
    },
    [],
  );

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const datails = await getDetails();

        setContactsDetails(datails);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadDetails();
  }, [enqueueSnackbar, setLoading]);

  const enableDisableSound = useCallback(async () => {
    if (activateAudio) {
      setActivateAudio(false);
    } else {
      setActivateAudio(true);
    }
  }, [activateAudio, setActivateAudio]);

  const handleContactNegotiation = useCallback(
    (phone: string, negotiation?: Negotiation) => {
      setSelectedContactData(phone, {
        negotiation,
      });

      setContacts(oldContacts =>
        oldContacts.map(oldContact =>
          oldContact.phone === phone
            ? {
                ...oldContact,
                negotiation,
              }
            : oldContact,
        ),
      );
    },
    [setSelectedContactData],
  );

  return (
    <ContactContext.Provider
      value={{
        contacts,
        selectedContact,
        loadContacts,
        selectContact,
        createContact,
        addNewContact,
        updateContact,
        updateChatbot,
        readContact,
        updateContactName,
        updateContactConversationExpirationDate,
        updateContactLastReceivedMessageDate,
        updateMessageKey,
        updateMessageStatus,
        removeMessage,
        setPagination,
        enableDisableSound,
        handleContactNegotiation,
        pagination,
        advancedFiltersCount,
        contactsDetails,
        activateAudio,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export { ContactContext, ContactProvider };
