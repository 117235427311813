import React, { useEffect, useState } from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';

import { getBase64 } from '../../../../utils/showFile';

import Contact from '../../../../models/Contact';
import StorageType from '../../../../models/StorageType';

interface Props {
  contact: Contact;
}

const ContactItem: React.FC<Props> = ({ contact }) => {
  const [photo, setPhoto] = useState<string>();

  useEffect(() => {
    const loadPhoto = async () => {
      const base64 = await getBase64(contact.photo, StorageType.Contact);

      setPhoto(base64);
    };

    loadPhoto();
  }, [contact.photo]);

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar src={photo} alt={contact.name} />
      </ListItemAvatar>
      <ListItemText primary={contact.name} secondary={contact.phone} />
    </ListItem>
  );
};

export default ContactItem;
