import React, { useCallback, useContext, useState } from 'react';

import { ContactContext } from '../../hooks/ContactContext';
import { MessageContext } from '../../hooks/MessageContext';
import { useWindowDimensions } from '../../hooks/WindowDimensionsContext';

import Negotiation from '../../models/Negotiation';
import NegotiationFunnel from '../../models/NegotiationFunnel';

import Contacts from './Contacts';
import Contact from './Contact';
import NegotiationResume from './NegotiationResume';

import SearchMessage from './SearchMessage';

import { CustomGrid } from './styles';

const Chat: React.FC = () => {
  const [negotiation, setNegotiation] = useState<Negotiation>();
  const [
    negotiationFunnel,
    setNegotiationFunnel,
  ] = useState<NegotiationFunnel>();

  const { selectedContact } = useContext(ContactContext);
  const { isSearchMessage } = useContext(MessageContext);
  const { windowDimensions } = useWindowDimensions();

  const handleSelectedNegotiation = useCallback((negotiation?: Negotiation) => {
    setNegotiation(negotiation);
    setNegotiationFunnel(negotiation?.negotiation_funnel);
  }, []);

  const handleSelectedNegotiationFunnel = useCallback(
    (negotiationFunnel?: NegotiationFunnel) =>
      setNegotiationFunnel(negotiationFunnel),
    [],
  );

  const handleChangeNegotiationFunnel = useCallback(
    (negotiationFunnel: NegotiationFunnel) => {
      setNegotiation(oldNegotiation => {
        if (!oldNegotiation) return undefined;

        oldNegotiation.negotiations_funnels.shift();

        return {
          ...oldNegotiation,
          negotiation_funnel: negotiationFunnel,
          negotiations_funnels: [
            negotiationFunnel,
            ...oldNegotiation.negotiations_funnels,
          ],
        };
      });
      setNegotiationFunnel(negotiationFunnel);
    },
    [],
  );

  return (
    <CustomGrid container>
      <CustomGrid
        item
        xs={windowDimensions.resolution === 'mobile' ? 12 : 3}
        style={{
          borderColor: '#ddd',
          borderStyle: 'solid',
          borderWidth: '0 1px 0 1px',
        }}
      >
        <Contacts />
      </CustomGrid>
      {windowDimensions.resolution !== 'mobile' && (
        <CustomGrid item xs={6}>
          {selectedContact && <Contact negotiation={negotiation} />}
        </CustomGrid>
      )}
      {windowDimensions.resolution !== 'mobile' && (
        <CustomGrid item xs={3}>
          {selectedContact && !isSearchMessage && (
            <NegotiationResume
              contact={selectedContact}
              negotiation={negotiation}
              negotiationFunnel={negotiationFunnel}
              handleSelectedNegotiation={handleSelectedNegotiation}
              handleSelectedNegotiationFunnel={handleSelectedNegotiationFunnel}
              handleChangeNegotiationFunnel={handleChangeNegotiationFunnel}
            />
          )}
          {selectedContact && isSearchMessage && <SearchMessage />}
        </CustomGrid>
      )}
    </CustomGrid>
  );
};

export default Chat;
