import styled from 'styled-components';

const Content = styled.section`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  float: right;
`;

const CaptionRow = styled.div``;

export { Content, CaptionRow };
