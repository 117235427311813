import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Content = styled.div`
  margin: 16px;
`;

export const CredentialRow = styled.div`
  margin: 32px 0;

  div {
    margin-top: 8px;
  }
`;

export const CopyContainer = styled(Grid)`
  && {
    margin: 16px 0;
  }
`;

export const ColumnContainer = styled(Grid)`
  && {
    margin: 16px 0;
  }
`;

export const ColumnName = styled.span`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const RowContainer = styled(Grid)`
  && {
    margin-bottom: 8px;
  }
`;

export const RowName = styled.span`
  margin-bottom: 8px;
`;
