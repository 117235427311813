import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

interface Props extends ButtonProps {
  component?: string;
}

const CustomButton: React.FC<Props> = ({ ...rest }) => {
  return <Button {...rest} />;
};

export default CustomButton;
