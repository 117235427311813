import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 56px);
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
