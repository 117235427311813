import React, { useRef, useState, useCallback, useMemo } from 'react';
import { Button, Checkbox, FormLabel, Typography } from '@material-ui/core';
import { Alert } from '@mui/material';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { useLoader } from '../../../../hooks/LoaderContext';
import {
  useMessageTemplate,
  TemplateMessageDTO,
  CreateTemplateMessageDTO,
  DeleteTemplateMessageDTO,
} from '../../../../hooks/MessageTemplateContext';
import { useDefaultFiles } from '../../../../hooks/DefaultFilesContext';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import DefaultFile from '../../../../models/DefaultFile';

import Input from '../../../../components/Input';
import RadioGroup from '../../../../components/RadioGroup';
import SelectForm from '../../../../components/SelectForm';
import Space from '../../../../components/Space';

import TemplateMessagePreview from '../TemplateMessagePreview';

import { Container, ActionsRow, AllowCategoryChangeRow } from './styles';

type CustomCreateTemplateMessageDTO = CreateTemplateMessageDTO & {
  [field: string]: string | number;
};

type Props = {
  messageTemplate?: TemplateMessageDTO;
  setIsNew: (value: React.SetStateAction<boolean>) => void;
};

const AddEditDefaultMessage: React.FC<Props> = ({
  messageTemplate,
  setIsNew,
}) => {
  const [message, setMessage] = useState<string | null>(
    messageTemplate?.body ?? null,
  );
  const [selectedFileType, setSelectedFileType] = useState<number>(() => {
    if (messageTemplate?.header === 'IMAGE') return 2;

    if (messageTemplate?.header === 'DOCUMENT') return 3;

    return 1;
  });
  const [allowCategoryChange, setAllowCategoryChange] = useState<boolean>(true);
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const {
    handleCreateMessageTemplate,
    handleDeleteMessageTemplate,
  } = useMessageTemplate();
  const { defaultFiles } = useDefaultFiles();

  const formRef = useRef<FormHandles>(null);

  const isNew = messageTemplate === undefined;

  const params = useMemo((): string[] => {
    const pattern = /{{\d+}}/g;

    if (!message) return [];

    const result = message.match(pattern);

    return result ?? [];
  }, [message]);

  const handleAddParam = useCallback(() => {
    setMessage(oldMessage => `${oldMessage}{{${++params.length}}}`);
    document.getElementById('message')?.focus();
  }, [params]);

  const handleSubmit = useCallback(
    async (data: CustomCreateTemplateMessageDTO) => {
      try {
        setLoading(true);

        const fields = params.reduce(
          (map, param) => ({
            ...map,
            [`${param}_example`]: Yup.string().required(
              `Exemplo do Parâmetro ${param} é obrigatório`,
            ),
          }),
          {} as {
            [field: string]: Yup.StringSchema<string>;
          },
        );

        const schema: Yup.ObjectSchema<CustomCreateTemplateMessageDTO> = Yup.object().shape(
          {
            category: Yup.number().required('Categoria é obrigatório'),
            name: Yup.string().required('Nome é obrigatório'),
            message: Yup.string().required('Mensagem é obrigatório'),
            default_file_id: Yup.string().when('file_type', {
              is: value => [2, 3].includes(value),
              then: Yup.string().required('Arquivo de Exemplo é obrigatório'),
            }),
            ...fields,
          },
        );

        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        params.forEach((param, index) => {
          if (!param.includes(String(index + 1)))
            throw new Error(
              'Os parâmetros necessitam estar em ordem crescente, iniciando em 1.',
            );
        });

        const body_examples = params.map(
          param => (data as { [param: string]: string })[`${param}_example`],
        );

        await handleCreateMessageTemplate({
          ...data,
          name: data.name
            .toLowerCase()
            .trim()
            .replace(/\s/g, '_')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          allow_category_change: allowCategoryChange,
          body_examples,
        });

        setIsNew(false);

        enqueueSnackbar(`Mensagem de Template criada com sucesso!`, {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      setIsNew,
      handleCreateMessageTemplate,
      params,
      allowCategoryChange,
    ],
  );

  const handleDelete = useCallback(
    async (data: DeleteTemplateMessageDTO) => {
      try {
        setLoading(true);

        await handleDeleteMessageTemplate(data);

        enqueueSnackbar(`Mensagem de Template deletada com sucesso!`, {
          variant: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, handleDeleteMessageTemplate],
  );

  const mappedDefaultFiles = useMemo(() => {
    let filteredDefaultFiles: DefaultFile[];

    if (selectedFileType === 2) {
      filteredDefaultFiles = defaultFiles.filter(({ mimetype }) =>
        ['image/jpeg', 'image/png'].includes(mimetype),
      );
    } else {
      filteredDefaultFiles = defaultFiles.filter(
        ({ mimetype }) => !mimetype.includes('image'),
      );
    }

    return filteredDefaultFiles.map(({ id, name }) => ({
      id,
      name,
    }));
  }, [defaultFiles, selectedFileType]);

  return (
    <>
      {message && (
        <TemplateMessagePreview
          message={message}
          isImage={selectedFileType === 2}
          isDocument={selectedFileType === 3}
          mappedDefaultFiles={mappedDefaultFiles}
          open={openPreview}
          setOpen={setOpenPreview}
        />
      )}
      <Container>
        <Form
          ref={formRef}
          initialData={
            messageTemplate
              ? {
                  name: messageTemplate.name,
                  message: messageTemplate.body,
                  file_type: selectedFileType,
                }
              : {
                  file_type: selectedFileType,
                  category: 1,
                }
          }
          onSubmit={handleSubmit}
        >
          {messageTemplate ? (
            <FormLabel>
              <Typography variant="h6">Editar</Typography>
            </FormLabel>
          ) : (
            <FormLabel>
              <Typography variant="h6">Criar </Typography>
            </FormLabel>
          )}
          <br />
          <br />
          {messageTemplate ? (
            <>
              <p
                style={{
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
              >
                {`Categoria: ${messageTemplate.category}`}
              </p>
              <br />
              <br />
            </>
          ) : (
            <>
              <Alert severity="info">
                <span>
                  Utility: Envie atualizações de conta, atualizações de pedidos,
                  alertas e muito mais para compartilhar informações
                  importantes.
                </span>
                <br />
                <br />
                <span>
                  Marketing: Envie ofertas promocionais, anúncios de produtos e
                  muito mais para aumentar a conscientização e o engajamento.
                </span>
              </Alert>
              <Space orientation="vertical" size="16px" />
              <RadioGroup
                name="category"
                row
                disabled={!isNew}
                options={[
                  {
                    id: 1,
                    name: 'Utility',
                    tooltip:
                      'Envie atualizações de conta, atualizações de pedidos, alertas e muito mais para compartilhar informações importantes.',
                  },
                  {
                    id: 2,
                    name: 'Marketing',
                    tooltip:
                      'Envie ofertas promocionais, anúncios de produtos e muito mais para aumentar a conscientização e o engajamento.',
                  },
                ]}
              />
              <Space orientation="vertical" />
            </>
          )}
          {!messageTemplate && (
            <>
              <AllowCategoryChangeRow>
                <Checkbox
                  color="secondary"
                  checked={allowCategoryChange}
                  onChange={e => setAllowCategoryChange(e.target.checked)}
                />
                <Typography>
                  Permitir que a Meta altere a categoria do template ao invés de
                  reprová-lo por estar na categoria errada
                </Typography>
              </AllowCategoryChangeRow>
              <Space orientation="vertical" size="24px" />
            </>
          )}
          <Input id="name" name="name" label="Nome" disabled={!isNew} />
          <Space orientation="vertical" size="16px" />
          <RadioGroup
            name="file_type"
            row
            disabled={!isNew}
            getValue={value => setSelectedFileType(value as number)}
            options={[
              {
                id: 1,
                name: 'Sem arquivo',
              },
              {
                id: 2,
                name: 'Imagem',
              },
              {
                id: 3,
                name: 'Documento',
              },
            ]}
          />
          <Space orientation="vertical" size="16px" />
          {!messageTemplate && [2, 3].includes(selectedFileType) && (
            <>
              <SelectForm
                id="default_file_id"
                name="default_file_id"
                label="Arquivo de Exemplo"
                values={mappedDefaultFiles}
                hiddenDefaultOption
              />
              <Space orientation="vertical" size="16px" />
            </>
          )}
          <Input
            id="message"
            name="message"
            label="Mensagem"
            multiline
            rows={5}
            rowsMin={5}
            onChange={e => setMessage(e.target.value)}
            value={message}
            disabled={!isNew}
          />
          {isNew &&
            params.map(param => (
              <>
                <Space orientation="vertical" size="16px" />
                <Input
                  id={`${param}_example`}
                  name={`${param}_example`}
                  label={`Exemplo do Parâmetro ${param}`}
                />
              </>
            ))}
          <Space orientation="vertical" size="16px" />
          <ActionsRow>
            {isNew && (
              <Button
                color="primary"
                size="small"
                variant="contained"
                type="submit"
              >
                Salvar
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setOpenPreview(true)}
            >
              Visualizar
            </Button>
            {isNew && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleAddParam}
              >
                Add parâmetro
              </Button>
            )}
            {isNew ? (
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                type="button"
                onClick={() => setIsNew(false)}
              >
                Cancelar
              </Button>
            ) : (
              <>
                {messageTemplate &&
                  ![
                    'conversa_simples',
                    'conversa_simples2',
                    'conversa_simples3',
                  ].includes(messageTemplate.name) && (
                    <Button
                      color="secondary"
                      size="small"
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        handleDelete({
                          name: messageTemplate.name,
                        });
                      }}
                      disabled={
                        messageTemplate.status === 'submitted' ||
                        messageTemplate.status === 'pending'
                      }
                    >
                      Deletar
                    </Button>
                  )}
              </>
            )}
          </ActionsRow>
        </Form>
      </Container>
    </>
  );
};

export default AddEditDefaultMessage;
