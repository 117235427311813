import React, { useEffect, useCallback, useState } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { UserDTO, useResponsible } from '../../../hooks/ResponsibleContext';

import formatMobileNumber from '../../../utils/formatMobileNumber';

import PermissionType from '../../../models/PermissionType';

import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import Settings from '..';
import TeamDetail from './TeamDetail';

import { Container, SearchRow } from './styles';

const Teams: React.FC = () => {
  const [filteredUsers, setFilteredUsers] = useState<UserDTO[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<UserDTO>();

  const { users } = useResponsible();

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredUsers(
        users.filter(
          user =>
            searched.length === 0 ||
            user.name.toLowerCase().includes(searched.toLowerCase()) ||
            user.email.toLowerCase().includes(searched.toLowerCase()) ||
            user.phone.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [users],
  );

  return (
    <Settings route="teams">
      <PageTitle pageName="Equipes" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome, e-mail ou telefone aqui"
          visible
          handleSearch={handleSearch}
        />
      </SearchRow>
      <Container>
        {filteredUsers
          .filter(
            ({ active, permissions }) =>
              active && permissions.includes(PermissionType.Superior),
          )
          .map(team => (
            <ExpansionPanel
              key={team.id}
              expanded={selectedTeam?.id === team.id}
              onClick={() => {
                if (selectedTeam?.id !== team.id)
                  setSelectedTeam(oldSelectedTeam =>
                    oldSelectedTeam?.id === team?.id ? undefined : team,
                  );
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1">{team.name}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ minWidth: '200px' }}>
                    <Typography variant="body2">{team.email}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ minWidth: '200px' }}>
                    <Typography variant="body2">
                      {formatMobileNumber(team.phone)}
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              {selectedTeam?.id === team.id && (
                <ExpansionPanelDetails>
                  <TeamDetail superior={team} />
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          ))}
      </Container>
    </Settings>
  );
};

export default Teams;
