import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Button, FormLabel, Typography, Grid } from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api';

import { useFunnel } from '../../../../hooks/FunnelContext';
import { useLoader } from '../../../../hooks/LoaderContext';
import { useMessageTemplate } from '../../../../hooks/MessageTemplateContext';
import { useDefaultFiles } from '../../../../hooks/DefaultFilesContext';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';
import handleFormData from '../../../../utils/handleFormData';

import Funnel from '../../../../models/Funnel';
import AutomaticMessage from '../../../../models/AutomaticMessage';
import DefaultFile from '../../../../models/DefaultFile';

import { AutomaticMessageDTO } from '../index';

import Input from '../../../../components/Input';
import SelectForm from '../../../../components/SelectForm';
import RadioGroup from '../../../../components/RadioGroup';

import { InputWrapper } from './styles';

interface Props {
  handleSave: (automaticMessages: AutomaticMessageDTO[]) => void;
  handleCancel: () => void;
  automaticMessage?: AutomaticMessage;
}

interface FormData {
  funnel?: Funnel;
  funnel_id?: string | null;
  product_id?: string | null;
  from_step_id?: string | null;
  to_step_id?: string | null;
  default_file_id?: string | null;
  template_name?: string | null;
  template_message?: string | null;
  type?: number;
  status?: 'won' | 'loss' | null;
}

const AddEditAutomaticMessages: React.FC<Props> = ({
  handleSave,
  handleCancel,
  automaticMessage,
}) => {
  const ANY = 'any';
  const [selectedType, setSelectedType] = useState<1 | 2>(1);
  const [selectedFunnel, setSelectedFunnel] = useState<Funnel>();
  const [selectedFromStepId, setSelectedFromStepId] = useState<string>(ANY);
  const [selectedToStepId, setSelectedToStepId] = useState<string>(ANY);
  const [templateName, setTemplateName] = useState<string>();
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();
  const { funnels } = useFunnel();
  const { messageTemplates } = useMessageTemplate();
  const { defaultFiles } = useDefaultFiles();
  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (automaticMessage) {
      const {
        funnel_id,
        from_step_id,
        to_step_id,
        template_name,
        won,
        wait_minutes,
      } = automaticMessage;

      let handledWaitMinutes = wait_minutes;

      const days = Math.floor(handledWaitMinutes / (24 * 60));
      handledWaitMinutes %= 24 * 60;

      const hours = Math.floor(handledWaitMinutes / 60);
      const minutes = handledWaitMinutes % 60;

      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSelectedFunnel(funnels.find(funnel => funnel.id === funnel_id));
      setSelectedFromStepId(from_step_id || ANY);
      setSelectedToStepId(to_step_id || ANY);
      setTemplateName(template_name);
      setSelectedType(won === null ? 1 : 2);
    }
  }, [funnels, automaticMessage]);

  const initialData = useMemo((): FormData => {
    if (!automaticMessage)
      return {
        type: 1,
        product_id: ANY,
        from_step_id: ANY,
        to_step_id: ANY,
      };

    const {
      funnel_id,
      product_id,
      from_step_id,
      to_step_id,
      default_file_id,
      won,
      template_name,
      parameters,
    } = automaticMessage;

    const fields = (parameters || []).reduce(
      (map, { parameter, value }) => ({
        ...map,
        [`{{${parameter}}}`]: value,
      }),
      {} as {
        [field: string]: string;
      },
    );

    return {
      funnel_id,
      product_id: product_id || ANY,
      from_step_id: from_step_id || ANY,
      to_step_id: to_step_id || ANY,
      default_file_id,
      template_name,
      type: won === null ? 1 : 2,
      ...(won === null
        ? {}
        : {
            status: won ? 'won' : 'loss',
          }),
      ...fields,
    };
  }, [automaticMessage]);

  const templateMessage = useMemo(
    () => messageTemplates.find(({ name }) => name === templateName),
    [messageTemplates, templateName],
  );

  const params = useMemo((): string[] => {
    if (!templateMessage) return [];

    const pattern = /{{\d+}}/g;

    const result = templateMessage.body.match(pattern);

    return result ?? [];
  }, [templateMessage]);

  const mappedTemplateMessages = useMemo(
    () =>
      messageTemplates.map(messageTemplate => ({
        id: messageTemplate.name,
        name: `${messageTemplate.name} - ${messageTemplate.body}`,
      })),
    [messageTemplates],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        let handledData: FormData = handleFormData(data);

        if (selectedType === 1) {
          handledData = {
            ...handledData,
            status: null,
          };
        } else {
          handledData = {
            ...handledData,
            to_step_id: null,
          };
        }

        if (!templateMessage?.header)
          handledData = {
            ...handledData,
            default_file_id: null,
          };

        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.number().nullable().required('Tipo é obrigatório'),
          funnel_id: Yup.string().nullable().required('Funil é obrigatório'),
          status: Yup.string()
            .nullable()
            .when('type', {
              is: 2,
              then: Yup.string().required('Status é obrigatório'),
            }),
          template_name: Yup.string()
            .nullable()
            .required('Mensagem é obrigatório'),
          default_file_id: templateMessage?.header
            ? Yup.string()
                .nullable()
                .required(
                  `${
                    templateMessage.header === 'IMAGE' ? 'Imagem' : 'Documento'
                  } é obrigatório`,
                )
            : Yup.string().nullable().notRequired(),
          ...params.reduce(
            (map, param) => ({
              ...map,
              [param]: Yup.string()
                .nullable()
                .required(`Parâmetro ${param} é obrigatório`),
            }),
            {} as {
              [field: string]: Yup.StringSchema<string | null>;
            },
          ),
        });

        await schema.validate(handledData, {
          abortEarly: false,
        });

        if (!templateMessage)
          throw new Error('Nenhuma mensagem de template selecionada!');

        handledData = {
          ...handledData,
          template_message: templateMessage.body,
        };

        const parameters = params.map(param => ({
          parameter: Number(param.replace(/\D+/g, '')),
          value: (handledData as { [param: string]: string })[param],
        }));

        const wait_minutes = days * 24 * 60 + hours * 60 + minutes;

        let automaticMessages: AutomaticMessageDTO[];

        if (automaticMessage) {
          const { data } = await api.put<AutomaticMessageDTO[]>(
            `automatic-messages/${automaticMessage.id}`,
            {
              ...handledData,
              parameters,
              wait_minutes,
            },
          );

          automaticMessages = data;
        } else {
          const { data } = await api.post<AutomaticMessageDTO[]>(
            'automatic-messages',
            {
              ...handledData,
              parameters,
              wait_minutes,
            },
          );

          automaticMessages = data;
        }

        handleSave(automaticMessages);

        enqueueSnackbar(
          `Mensagem automática ${
            automaticMessage ? 'atualizada' : 'criada'
          } com sucesso!`,
          {
            variant: 'success',
          },
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleSave,
      automaticMessage,
      templateMessage,
      selectedType,
      params,
      days,
      hours,
      minutes,
    ],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      if (automaticMessage) {
        const { data } = await api.delete<AutomaticMessageDTO[]>(
          `automatic-messages/${automaticMessage.id}`,
        );

        handleSave(data);

        enqueueSnackbar('Mensagem automática deletada com sucesso!', {
          variant: 'success',
        });
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, handleSave, automaticMessage]);

  const handleChangeFunnel = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setSelectedFromStepId(ANY);
      setSelectedToStepId(ANY);
      setSelectedFunnel(funnels.find(funnel => funnel.id === id));
    },
    [funnels],
  );

  const mappedFunnels = useMemo(
    () =>
      funnels.map(funnel => ({
        id: funnel.id,
        name: funnel.name,
      })),
    [funnels],
  );

  const mappedSteps = useCallback(
    (hidden_step_id: string) =>
      selectedFunnel
        ? selectedFunnel.steps
            .filter(({ id }) => id !== hidden_step_id)
            .map(({ id, name }) => ({
              id,
              name,
            }))
        : [],
    [selectedFunnel],
  );

  const mappedDefaultFiles = useMemo(() => {
    let filteredDefaultFiles: DefaultFile[] = [];

    if (!templateMessage) return filteredDefaultFiles;

    if (templateMessage.header === 'IMAGE') {
      filteredDefaultFiles = defaultFiles.filter(({ mimetype }) =>
        ['image/jpeg', 'image/png'].includes(mimetype),
      );
    } else if (templateMessage.header === 'DOCUMENT') {
      filteredDefaultFiles = defaultFiles.filter(
        ({ mimetype }) => !mimetype.includes('image'),
      );
    }

    return filteredDefaultFiles.map(({ id, name }) => ({
      id,
      name,
    }));
  }, [defaultFiles, templateMessage]);

  const mappedProducts = useCallback(
    () =>
      selectedFunnel
        ? selectedFunnel.products.map(({ id, name }) => ({
            id,
            name,
          }))
        : [],
    [selectedFunnel],
  );

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={initialData}
        style={{
          width: '100%',
        }}
      >
        <FormLabel>
          <Typography variant="h6">
            {automaticMessage ? 'Editar' : 'Criar'}
          </Typography>
        </FormLabel>
        <div
          style={{
            marginBottom: 32,
          }}
        >
          <InputWrapper>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <RadioGroup
                    name="type"
                    row
                    getValue={value => setSelectedType(value as 1 | 2)}
                    options={[
                      {
                        id: 1,
                        name: 'Troca de Etapa',
                      },
                      {
                        id: 2,
                        name: 'Troca de Situação',
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <SelectForm
                    id="funnel_id"
                    name="funnel_id"
                    label="Funil"
                    values={mappedFunnels}
                    onChange={handleChangeFunnel}
                    hiddenDefaultOption
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <SelectForm
                    id="product_id"
                    name="product_id"
                    label="Produto"
                    values={mappedProducts()}
                    defaultOptionName="Qualquer"
                    defaultOptionValue={ANY}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <SelectForm
                    id="from_step_id"
                    name="from_step_id"
                    label={selectedType === 1 ? 'De Etapa' : 'Etapa'}
                    values={mappedSteps(selectedToStepId)}
                    defaultOptionName="Qualquer"
                    defaultOptionValue={ANY}
                    value={selectedFromStepId}
                    onChange={e => {
                      setSelectedFromStepId(e.target.value as string);
                    }}
                  />
                </Grid>
                {selectedType === 1 && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectForm
                      id="to_step_id"
                      name="to_step_id"
                      label="Para Etapa"
                      values={mappedSteps(selectedFromStepId)}
                      defaultOptionName="Qualquer"
                      defaultOptionValue={ANY}
                      value={selectedToStepId}
                      onChange={e => {
                        setSelectedToStepId(e.target.value as string);
                      }}
                    />
                  </Grid>
                )}
                {selectedType === 2 && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectForm
                      id="status"
                      name="status"
                      label="Situação"
                      values={[
                        {
                          id: 'won',
                          name: 'Ganhou',
                        },
                        {
                          id: 'loss',
                          name: 'Perdeu',
                        },
                      ]}
                      hiddenDefaultOption
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <SelectForm
                    id="template_name"
                    name="template_name"
                    label="Mensagem"
                    values={mappedTemplateMessages}
                    onChange={e => setTemplateName(e.target.value as string)}
                    hiddenDefaultOption
                  />
                </Grid>
                {templateMessage?.header && (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SelectForm
                      id="default_file_id"
                      name="default_file_id"
                      label={
                        templateMessage.header === 'IMAGE'
                          ? 'Imagem'
                          : 'Documento'
                      }
                      values={mappedDefaultFiles}
                      hiddenDefaultOption
                    />
                  </Grid>
                )}
                {params.map(param => (
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Input id={param} name={param} label={param} />
                  </Grid>
                ))}
              </Grid>
            </div>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormLabel>
                    <Typography variant="h6">Temporizador</Typography>
                  </FormLabel>
                </Grid>
                <Grid item xs={12} sm={4} md={2} lg={2}>
                  <Input
                    id="days"
                    name="days"
                    label="Dias"
                    value={days}
                    onChange={e => {
                      const handledDays = Number(
                        e.target.value.replace(/\D/g, ''),
                      );

                      setDays(handledDays);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2} lg={2}>
                  <Input
                    id="hours"
                    name="hours"
                    label="Horas"
                    value={hours}
                    onChange={e => {
                      const handledHours = Number(
                        e.target.value.replace(/\D/g, ''),
                      );

                      setHours(handledHours > 23 ? 23 : handledHours);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2} lg={2}>
                  <Input
                    id="minutes"
                    name="minutes"
                    label="Minutos"
                    value={minutes}
                    onChange={e => {
                      const handledMinutes = Number(
                        e.target.value.replace(/\D/g, ''),
                      );

                      setMinutes(handledMinutes > 59 ? 59 : handledMinutes);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </InputWrapper>
        </div>
        <Button
          color="primary"
          size="small"
          variant="contained"
          style={{ borderRadius: '32px', marginRight: '6px' }}
          type="submit"
        >
          {automaticMessage ? 'Atualizar' : 'Salvar'}
        </Button>
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          style={{ borderRadius: '32px' }}
          type="button"
          onClick={automaticMessage ? handleDelete : handleCancel}
        >
          {automaticMessage ? 'Deletar' : 'Cancelar'}
        </Button>
      </Form>
    </>
  );
};

export default AddEditAutomaticMessages;
