import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import InputGeneric, { InputGenericProps } from '../InputGeneric';

interface InputProps extends InputGenericProps {
  name: string;
}

const Input: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<InputGenericProps>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <InputGeneric
      inputRef={inputRef}
      defaultValue={defaultValue}
      error={error}
      {...rest}
    />
  );
};

export default Input;
