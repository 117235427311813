import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Container = styled.div``;

const MessageRow = styled.div`
  width: fit-content;
  padding: 8px;
  border-radius: 8px;
  background: #40adbd;
  color: #fff;
  border: 1px solid #ddd;
  white-space: pre-wrap;
`;

const SelectionTitle = styled(Typography)`
  && {
    color: rgba(0, 0, 0, 0.38);
  }
`;

export { Container, MessageRow, SelectionTitle };
