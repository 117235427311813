import styled from 'styled-components';

export const Container = styled.li`
  white-space: normal;

  p {
    text-align: left;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
`;
