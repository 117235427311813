import React from 'react';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  SelectProps,
  MenuItem,
} from '@material-ui/core';

interface Props extends SelectProps {
  name: string;
  label: string;
  height?: number;
  value?: string | null;
  values: {
    id: string;
    name: string;
  }[];
  withDefaultOption?: boolean;
}

const CustomSelect: React.FC<Props> = ({
  label,
  height = 36,
  value = '',
  values,
  error,
  withDefaultOption = true,
  ...rest
}) => {
  if (
    value !== null &&
    value !== '' &&
    !values.map(({ id }) => id).includes(value)
  )
    return <div />;

  return (
    <FormControl variant="outlined" size="small" fullWidth error={!!error}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...rest}
        label={label}
        value={value || ''}
        style={{
          height,
        }}
      >
        {withDefaultOption && (
          <MenuItem
            key="none"
            value=""
            hidden
            style={{
              height: 36,
            }}
          />
        )}
        {values.map(value => (
          <MenuItem key={value.id} value={value.id} hidden>
            {value.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
