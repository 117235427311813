import React, { createContext, useState, useContext, useCallback } from 'react';

interface State {
  genericName?: string;
  handleGenericName: (name?: string) => void;
}

export const GenericNegotiationSearchContext = createContext<State>(
  {} as State,
);

export const GenericNegotiationSearchProvider: React.FC = ({ children }) => {
  const [genericName, setGenericName] = useState<string>();

  const handleGenericName = useCallback((name?: string) => {
    setGenericName(name?.trim()?.length === 0 ? undefined : name);
  }, []);

  return (
    <GenericNegotiationSearchContext.Provider
      value={{
        genericName,
        handleGenericName,
      }}
    >
      {children}
    </GenericNegotiationSearchContext.Provider>
  );
};

export const useGenericNegotiationSearch = (): State => {
  const context = useContext(GenericNegotiationSearchContext);

  if (!context)
    throw new Error(
      'useGenericNegotiationSearch must be used within a GenericNegotiationSearchProvider',
    );

  return context;
};
