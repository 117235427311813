import React, { useCallback, useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import handleResponseError from '../../../utils/handleResponseError';
import { useLoader } from '../../../hooks/LoaderContext';
import api from '../../../services/api';
import apiWhatsapp from '../../../services/api_whatsapp';
import Settings from '..';
import PageTitle from '../../../components/PageTitle';
import Select from '../../../components/Select';
import { Container } from './styles';

const options = ['csv', 'txt', 'xls', 'xlsx', 'ods'];

const DataExport: React.FC = () => {
  const [userExtension, setUserExtension] = useState<string>('csv');
  const [negotiationExtension, setNegotiationExtension] = useState<string>(
    'csv',
  );
  const [conversationExtension, setConversationExtension] = useState<string>(
    'csv',
  );

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeUserExtension = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setUserExtension(id);
    },
    [],
  );

  const handleChangeNegotiationExtension = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setNegotiationExtension(id);
    },
    [],
  );

  const handleChangeConversationExtension = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as string;

      setConversationExtension(id);
    },
    [],
  );

  const handleDownloadUsersData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get('data-export/users', {
        responseType: 'blob',
        params: {
          extension: userExtension,
        },
      });

      const url = URL.createObjectURL(data);

      const link = document.createElement('a');
      link.href = url;
      link.download = `data.${userExtension}`;
      link.click();

      URL.revokeObjectURL(url);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, userExtension]);

  const handleDownloadNegotiationsData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get('data-export', {
        responseType: 'blob',
        params: {
          extension: negotiationExtension,
        },
      });

      const url = URL.createObjectURL(data);

      const link = document.createElement('a');
      link.href = url;
      link.download = `data.${negotiationExtension}`;
      link.click();

      URL.revokeObjectURL(url);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, negotiationExtension]);

  const handleDownloadConversationsData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await apiWhatsapp.get('data-export/negotiations', {
        responseType: 'blob',
        params: {
          extension: conversationExtension,
        },
      });

      const url = URL.createObjectURL(data);

      const link = document.createElement('a');
      link.href = url;
      link.download = `data.${conversationExtension}`;
      link.click();

      URL.revokeObjectURL(url);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, conversationExtension]);

  return (
    <Settings route="data-export">
      <PageTitle pageName="Exportar Dados" />
      <Container>
        <Typography variant="h6">De usuários:</Typography>
        <Select
          name="userExtension"
          label="Extensão"
          value={userExtension}
          withDefaultOption={false}
          fullWidth
          values={options.map(option => ({
            id: option,
            name: option,
          }))}
          onChange={handleChangeUserExtension}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleDownloadUsersData}
        >
          Exportar
        </Button>
        <Divider />
        <Typography variant="h6">De negociações:</Typography>
        <Select
          name="negotiationExtension"
          label="Extensão"
          value={negotiationExtension}
          withDefaultOption={false}
          fullWidth
          values={options.map(option => ({
            id: option,
            name: option,
          }))}
          onChange={handleChangeNegotiationExtension}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleDownloadNegotiationsData}
        >
          Exportar
        </Button>
        <Divider />
        <Typography variant="h6">De conversas:</Typography>
        <Select
          name="conversationExtension"
          label="Extensão"
          value={conversationExtension}
          withDefaultOption={false}
          fullWidth
          values={options.map(option => ({
            id: option,
            name: option,
          }))}
          onChange={handleChangeConversationExtension}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleDownloadConversationsData}
        >
          Exportar
        </Button>
      </Container>
    </Settings>
  );
};

export default DataExport;
