import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';

import { useDefaultFiles } from '../../../hooks/DefaultFilesContext';

import DefaultFile from '../../../models/DefaultFile';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import Settings from '..';
import AddEditDefaultFiles from './AddEditDefaultFiles';

import { Header, SearchRow } from './styles';

const DefaultFiles: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [filteredDefaultFiles, setFilteredDefaultFiles] = useState<
    DefaultFile[]
  >([]);

  const { defaultFiles } = useDefaultFiles();

  useEffect(() => {
    setFilteredDefaultFiles(defaultFiles);
  }, [defaultFiles]);

  const handleFinish = useCallback(() => setIsNew(false), []);

  const handleSearch = useCallback(
    (searched: string) =>
      setFilteredDefaultFiles(
        defaultFiles.filter(
          defaultFile =>
            searched.length === 0 ||
            defaultFile.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      ),
    [defaultFiles],
  );

  return (
    <Settings route="default-files">
      <PageTitle pageName="Arquivos Padrões" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      <Header>
        {isNew ? (
          <AddEditDefaultFiles handleFinish={handleFinish} />
        ) : (
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<Add fontSize="small" />}
              onClick={() => setIsNew(true)}
            >
              Arquivo Padrão
            </Button>
          </div>
        )}
      </Header>
      {filteredDefaultFiles.map(defaultFile => (
        <ExpansionPanel key={String(defaultFile.id)}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="body1">{defaultFile.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="body1">
                  {defaultFile.kb < 1024
                    ? `${defaultFile.kb}KB`
                    : `${(defaultFile.kb / 1024).toFixed(2)}MB`}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <AddEditDefaultFiles
                  defaultFile={defaultFile}
                  handleFinish={handleFinish}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Settings>
  );
};

export default DefaultFiles;
