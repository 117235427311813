export const removeMaskNumber = (phone: string) => {
  return phone.replace(/\D/g, '');
};

export default function formatMobileNumber(phoneNumber: string) {
  const cleaned = removeMaskNumber(phoneNumber);

  if (![12, 13].includes(cleaned.length)) return null;

  const match =
    cleaned.length === 12
      ? cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/)
      : cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);

  if (!match) return null;

  return `0${match[2]}${match[3]}${match[4]}`;
}
