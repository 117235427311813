import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';

const Content = styled.ul`
  li + li {
    margin-top: 16px;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  && {
    padding: 0 8px;
  }
`;

export { Content, CustomCheckbox };
