import React from 'react';
import { Button } from '@material-ui/core';

import { Container } from './styles';

type Props = {
  loadMore: () => Promise<void>;
};

const NotificationLoadMoreButton: React.FC<Props> = ({ loadMore }) => (
  <Container>
    <Button variant="outlined" color="default" fullWidth onClick={loadMore}>
      Carregar mais...
    </Button>
  </Container>
);

export default NotificationLoadMoreButton;
