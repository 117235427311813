import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';

import SelectForm from '../../../../../components/SelectForm';

import { Container, Content, Header } from './styles';

type Props = {
  header: string;
  nameHour: string;
  nameMinute: string;
  defaultHour?: string;
  defaultMinute?: string;
  getHour?: (hour?: string) => void;
  getMinute?: (hour?: string) => void;
};

export const Time: React.FC<Props> = ({
  header,
  nameHour,
  nameMinute,
  defaultHour,
  defaultMinute,
  getHour,
  getMinute,
}) => {
  const [hour, setHour] = useState<string | undefined>(defaultHour);
  const [minute, setMinute] = useState<string | undefined>(defaultMinute);

  useEffect(() => getHour?.(hour), [getHour, hour]);

  useEffect(() => getMinute?.(minute), [getMinute, minute]);

  const hours = useMemo((): string[] => {
    const formattedHours: string[] = [];

    let count = 0;

    while (count <= 23) {
      if (count < 10) {
        formattedHours.push(`0${count}`);
      } else {
        formattedHours.push(String(count));
      }

      count++;
    }

    return formattedHours;
  }, []);

  const minutes = useMemo((): string[] => {
    const formattedMinutes: string[] = [];

    let count = 0;

    while (count <= 59) {
      if (count < 10) {
        formattedMinutes.push(`0${count}`);
      } else {
        formattedMinutes.push(String(count));
      }

      count++;
    }

    return formattedMinutes;
  }, []);

  return (
    <Container>
      <Header>
        <Typography component="p">{header}</Typography>
      </Header>
      <Content>
        <SelectForm
          id={nameHour}
          name={nameHour}
          label="hora"
          value={hour}
          values={hours.map(hour => ({
            id: hour,
            name: hour,
          }))}
          hiddenDefaultOption
          onChange={e => setHour(e.target.value as string)}
        />
        <span>:</span>
        <SelectForm
          id={nameMinute}
          name={nameMinute}
          label="minuto"
          value={minute}
          values={minutes.map(minute => ({
            id: minute,
            name: minute,
          }))}
          hiddenDefaultOption
          onChange={e => setMinute(e.target.value as string)}
        />
      </Content>
    </Container>
  );
};
