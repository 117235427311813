import styled from 'styled-components';
import { Card, Divider, Avatar } from '@material-ui/core';

import ButtonOpacity from '../../../components/ButtonOpacity';

export const CustomCard = styled(Card)`
  && {
    padding: 16px;
    margin-top: 16px;
  }
`;

interface Props {
  isNegotiationInProgress: boolean;
}

export const CustomAvatar = styled(Avatar)<Props>`
  && {
    background-color: rgba(153, 153, 153, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(153, 153, 153, 0.9);

    &:hover {
      background-color: rgba(
        153,
        153,
        153,
        ${props => (props.isNegotiationInProgress ? 0.9 : 0.8)}
      );
      box-shadow: inset 0 0 8px 0
        rgba(
          153,
          153,
          153,
          ${props => (props.isNegotiationInProgress ? 1 : 0.9)}
        );
      cursor: ${props =>
        props.isNegotiationInProgress ? 'pointer' : 'default'};
    }
  }
`;

export const LeftTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomDivider = styled(Divider)`
  && {
    flex: 1;
    background: transparent;
  }
`;

export const ContactRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const StepsRow = styled.div`
  margin-bottom: 16px;
`;

export const StatusRow = styled.div`
  display: flex;
  /* margin-bottom: 16px; */
`;

export const FooterRow = styled.div`
  display: flex;
  float: right;
`;

export const ButtonGreen = styled(ButtonOpacity)`
  && {
    background-color: rgba(156, 186, 50, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(156, 186, 50, 0.9);
    height: 28px;
    margin-left: 8px;

    &:hover {
      background-color: rgba(156, 186, 50, 0.9);
      box-shadow: inset 0 0 8px 0 rgba(156, 186, 50, 1);
    }
  }
`;

export const ButtonBlue = styled(ButtonOpacity)`
  && {
    background-color: rgb(111 167 186);
    box-shadow: inset 0 0 8px 0 rgba(153, 153, 153, 0.9);
    height: 28px;
    margin-left: 8px;

    &:hover {
      background-color: rgb(111 167 186);
      box-shadow: inset 0 0 8px 0 rgba(153, 153, 153, 1);
    }
  }
`;

export const ButtonRed = styled(ButtonOpacity)`
  && {
    background-color: rgba(220, 0, 78, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(220, 0, 78, 0.9);
    height: 28px;
    margin-left: 8px;

    &:hover {
      background-color: rgba(220, 0, 78, 0.9);
      box-shadow: inset 0 0 8px 0 rgba(220, 0, 78, 1);
    }
  }
`;

export const ButtonRedOutlined = styled(ButtonOpacity)`
  && {
    color: rgba(220, 0, 78, 0.9);
    border: 1px solid rgba(220, 0, 78, 0.9);
    height: 28px;
    margin-left: 8px;

    &:hover {
      color: rgba(220, 0, 78, 1);
      border: 1px solid rgba(220, 0, 78, 1);
    }
  }
`;

export const ButtonIcon = styled(ButtonOpacity)`
  && {
    background-color: rgba(153, 153, 153, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(153, 153, 153, 0.9);
    height: 28px;
    width: 22px;
    min-width: 22px;
    padding-left: 20px;
    margin-left: 8px;

    &:hover {
      background-color: rgba(153, 153, 153, 0.9);
      box-shadow: inset 0 0 8px 0 rgba(153, 153, 153, 1);
    }
  }
`;

export const ButtonIconOutlined = styled(ButtonOpacity)`
  && {
    color: rgba(220, 0, 78, 0.9);
    border: 1px solid rgba(220, 0, 78, 0.9);
    height: 28px;
    width: 22px;
    min-width: 22px;
    padding-left: 20px;
    margin-left: 8px;

    &:hover {
      color: rgba(220, 0, 78, 1);
      border: 1px solid rgba(220, 0, 78, 1);
    }
  }
`;

export const FunnelRow = styled.div`
  display: flex;
  width: min-content;
  margin-left: auto;

  .MuiFormControl-root {
    width: 354px;
  }
`;
