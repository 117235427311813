import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  createRef,
  useRef,
} from 'react';
import { Grid } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import { useSnackbar } from 'notistack';

import { FunnelContext } from '../../hooks/FunnelContext';
import { useLoader } from '../../hooks/LoaderContext';
import { ResponsibleContext } from '../../hooks/ResponsibleContext';
import { StatusContext } from '../../hooks/StatusContext';
import { PeriodContext } from '../../hooks/PeriodContext';
import { useGenericNegotiationSearch } from '../../hooks/GenericNegotiationSearch';
import DNDContext from './context';

import handleResponseError from '../../utils/handleResponseError';

import api from '../../services/api';

import NegotiationStatus from '../../models/NegotiationStatus';
import Step from '../../models/Step';

import Header from './Header';
import List from './List';
import Card from './Card';

import { Container } from './styles';
import DragAndDropDeleteClient from '../../components/DragAndDropClient/DragAndDropDeleteClient/DragAndDropDeleteClient';
import DragAndDropWinClient from '../../components/DragAndDropClient/DragAndDropWinClient/DragAndDropWinClient';
import DragAndDropLoseClient from '../../components/DragAndDropClient/DragAndDropLoseClient/DragAndDropLoseClient';
import PermissionType from '../../models/PermissionType';
import { SessionContext } from '../../hooks/SessionContext';

interface CustomStepModel extends Step {
  count: number;
  value_sum: number;
  skip: number;
  scrollRef?: React.MutableRefObject<Scrollbars | null>;
}

const Funnel: React.FC = () => {
  const { setLoading } = useLoader();
  const { selectedFunnel, selectedProduct, selectedChannel } = useContext(
    FunnelContext,
  );
  const { selectedResponsible } = useContext(ResponsibleContext);
  const { selectedStatus } = useContext(StatusContext);
  const { dateRange } = useContext(PeriodContext);
  const { genericName } = useGenericNegotiationSearch();

  const [steps, setSteps] = useState<CustomStepModel[]>([]);
  const [status, setStatus] = useState<NegotiationStatus>(selectedStatus);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(SessionContext);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dropRef = useRef<any>(React.createRef());

  const loadMoreNegotiations = useCallback(
    async (step: CustomStepModel) => {
      step.scrollRef?.current?.forceUpdate();

      const scrollTop = step.scrollRef?.current?.getValues().top ?? 0;

      if (scrollTop > 0.7 && step.negotiations.length < step.count) {
        try {
          setLoading(true);

          const response = await api.get<CustomStepModel>(
            `steps/${step.id}/negotiations`,
            {
              params: {
                status,
                dateStart: dateRange?.dateStart?.toISOString(),
                dateEnd: dateRange?.dateEnd?.toISOString(),
                userId: selectedResponsible?.user.id,
                productId: selectedProduct?.id,
                channelId: selectedChannel?.id,
                generic: genericName,
                hierarchy: selectedResponsible?.team,
                take: 30,
                skip: step.skip + 30,
              },
            },
          );

          const data: CustomStepModel = {
            ...step,
            negotiations: response.data.negotiations,
            skip: step.skip + 30,
            count: Number(response.data.count ?? 0),
            value_sum: Number(response.data.value_sum ?? 0),
          };

          setSteps(oldSteps => [
            ...oldSteps.map(oldStep => {
              if (oldStep.id === step.id)
                return {
                  ...data,
                  negotiations: [...oldStep.negotiations, ...data.negotiations],
                };

              return oldStep;
            }),
          ]);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    },
    [
      setLoading,
      dateRange,
      status,
      selectedResponsible,
      selectedProduct,
      selectedChannel,
      genericName,
    ],
  );

  const loadSteps = useCallback(async () => {
    try {
      if (selectedFunnel) {
        setLoading(true);

        const formattedSteps = await Promise.all(
          (selectedFunnel.steps ?? []).map(async step => {
            const response = await api.get<CustomStepModel>(
              `steps/${step.id}/negotiations`,
              {
                params: {
                  status,
                  dateStart: dateRange?.dateStart?.toISOString(),
                  dateEnd: dateRange?.dateEnd?.toISOString(),
                  userId: selectedResponsible?.user.id,
                  productId: selectedProduct?.id,
                  channelId: selectedChannel?.id,
                  generic: genericName,
                  hierarchy: selectedResponsible?.team,
                  take: 30,
                  skip: 0,
                },
              },
            );

            const data: CustomStepModel = {
              ...step,
              negotiations: response.data.negotiations,
              skip: 0,
              count: Number(response.data.count ?? 0),
              value_sum: Number(response.data.value_sum ?? 0),
              scrollRef: createRef(),
            };

            return data;
          }),
        );

        setSteps(formattedSteps);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsDragging(false);
      setLoading(false);
    }
  }, [
    setLoading,
    selectedFunnel,
    dateRange,
    status,
    selectedResponsible,
    selectedProduct,
    selectedChannel,
    genericName,
  ]);

  useEffect(() => {
    async function loadSteps() {
      try {
        if (selectedFunnel) {
          setLoading(true);

          const formattedSteps = await Promise.all(
            (selectedFunnel.steps ?? []).map(async step => {
              const response = await api.get<CustomStepModel>(
                `steps/${step.id}/negotiations`,
                {
                  params: {
                    status,
                    dateStart: dateRange?.dateStart?.toISOString(),
                    dateEnd: dateRange?.dateEnd?.toISOString(),
                    userId: selectedResponsible?.user.id,
                    productId: selectedProduct?.id,
                    channelId: selectedChannel?.id,
                    generic: genericName,
                    hierarchy: selectedResponsible?.team,
                    take: 30,
                    skip: 0,
                  },
                },
              );

              const data: CustomStepModel = {
                ...step,
                negotiations: response.data.negotiations,
                skip: 0,
                count: Number(response.data.count ?? 0),
                value_sum: Number(response.data.value_sum ?? 0),
                scrollRef: createRef(),
              };

              return data;
            }),
          );

          setSteps(formattedSteps);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    loadSteps();
  }, [
    setLoading,
    selectedFunnel,
    dateRange,
    status,
    selectedResponsible,
    selectedProduct,
    selectedChannel,
    genericName,
  ]);

  const move = useCallback(
    async (fromList: number, toList: number, from: number) => {
      setIsDragging(false);
      if (fromList !== toList) {
        try {
          const negotiation = steps[fromList].negotiations[from];

          setSteps(oldSteps => [
            ...oldSteps.map((oldStep, index) => {
              if (fromList === index)
                return {
                  ...oldStep,
                  count: oldStep.count - 1,
                  value_sum: oldStep.value_sum - Number(negotiation.value),
                  negotiations: oldStep.negotiations.filter(
                    oldNegotiation => oldNegotiation.id !== negotiation.id,
                  ),
                };

              if (toList === index)
                return {
                  ...oldStep,
                  count: oldStep.count + 1,
                  value_sum: oldStep.value_sum + Number(negotiation.value),
                  negotiations: [
                    ...oldStep.negotiations,
                    {
                      ...negotiation,
                      step_id: oldStep.id,
                      negotiation_funnel: {
                        ...negotiation.negotiation_funnel,
                        last_step_id: oldStep.id,
                      },
                      negotiations_funnels: [
                        ...negotiation.negotiations_funnels.map(
                          negotiation_funnel => {
                            if (
                              negotiation_funnel.negotiation_id ===
                              negotiation.id
                            )
                              return {
                                ...negotiation_funnel,
                                last_step_id: oldStep.id,
                              };

                            return negotiation_funnel;
                          },
                        ),
                      ],
                    },
                  ],
                };

              return oldStep;
            }),
          ]);

          await api.put(`/steps/${steps[toList].id}/clients/${negotiation.id}`);

          enqueueSnackbar('Etapa do cliente atualizada com sucesso!', {
            variant: 'success',
          });
        } catch (err) {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      }
    },
    [steps, enqueueSnackbar],
  );

  const handleDragIn = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    },
    [setIsDragging],
  );

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
  });

  return (
    <>
      <Header setStatus={setStatus} />
      <DNDContext.Provider value={{ steps, move }}>
        <Container ref={dropRef}>
          {steps.map((step, listIdx) => (
            <List
              key={step.id}
              name={step.name}
              value_sum={step.value_sum}
              count={step.count}
              listIdx={listIdx}
            >
              <Scrollbars
                key={`${step.id}/scroll`}
                ref={step.scrollRef}
                autoHide
                onScrollStop={() => loadMoreNegotiations(step)}
              >
                <ul>
                  {step.negotiations.map((negotiation, idx) => (
                    <Card
                      key={`${step.id}/card/${negotiation.id}`}
                      idx={idx}
                      listIdx={listIdx}
                      negotiation={negotiation}
                    />
                  ))}
                </ul>
              </Scrollbars>
            </List>
          ))}
        </Container>
        {isDragging && (
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <Grid container spacing={0} alignItems="center" justify="center">
              {user.permissions.includes(PermissionType.Superior) && (
                <DragAndDropDeleteClient handleDrop={loadSteps} />
              )}
              <DragAndDropWinClient handleDrop={loadSteps} />
              <DragAndDropLoseClient handleDrop={loadSteps} />
            </Grid>
          </div>
        )}
      </DNDContext.Provider>
    </>
  );
};

export default Funnel;
