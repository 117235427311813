import React, { useCallback } from 'react';

import { useNotification } from '../../../hooks/NotificationContext';

import NegotiationLogbookType from '../../../models/NegotiationLogbookType';

import NotificationList from '../NotificationList';
import NotificationItem from '../NotificationItem';
import NotificationLoadMoreButton from '../NotificationLoadMoreButton';

const NotificationNegotiation: React.FC = () => {
  const {
    detailedNegotiationsNotifications,
    handleUpdateNotification,
    loadMoreNegotiationsNotifications,
  } = useNotification();

  const {
    notifications,
    take,
    skip,
    total,
  } = detailedNegotiationsNotifications;

  const getLogTypeMessage = useCallback((slug: NegotiationLogbookType) => {
    switch (slug) {
      case NegotiationLogbookType.CreateAnnotation:
        return 'comentou na';
      case NegotiationLogbookType.UpdateNegotiation:
        return 'alterou a';
      case NegotiationLogbookType.WonNegotiation:
        return 'ganhou a';
      case NegotiationLogbookType.LostNegotiation:
        return 'perdeu a';
      case NegotiationLogbookType.ReopenedNegotiation:
        return 'reabriu a';
      case NegotiationLogbookType.ExchangeNegotiationFunnel:
        return 'trocou o funil da';
      case NegotiationLogbookType.CreateFile:
        return 'anexou arquivo na';
      default:
        return '';
    }
  }, []);

  const getDescription = useCallback(
    (
      user_name: string,
      logbook_slug: NegotiationLogbookType,
      negotiation_name: string,
    ): string => {
      if (logbook_slug === NegotiationLogbookType.AutomaticMessage)
        return `Uma mensagem automática foi enviada pelo sistema para o(a) cliente ${negotiation_name}`;

      if (
        logbook_slug === NegotiationLogbookType.CreateNegotiationExternalRequest
      )
        return `Uma negociação criada via requisição externa foi direcionada a você: ${negotiation_name}`;

      return `O usuário ${user_name} ${getLogTypeMessage(
        logbook_slug,
      )} negociação do cliente ${negotiation_name}.`;
    },
    [getLogTypeMessage],
  );

  return (
    <NotificationList>
      {notifications.map(notification => (
        <NotificationItem
          id={notification.negotiation_notification_id}
          description={getDescription(
            notification.negotiation_logbook_user_name,
            notification.negotiation_logbook_slug,
            notification.negotiation_name,
          )}
          read={notification.negotiation_notification_read}
          created_at={notification.negotiation_notification_created_at}
          handleMarkingRead={handleUpdateNotification}
        />
      ))}
      {total > notifications.length && (
        <NotificationLoadMoreButton
          loadMore={() => loadMoreNegotiationsNotifications(take + skip)}
        />
      )}
    </NotificationList>
  );
};

export default NotificationNegotiation;
