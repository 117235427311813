import styled from 'styled-components';

import { Grid, Button } from '@material-ui/core';

export const Container = styled.div``;

export const Content = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const CustomGrid = styled(Grid)``;

export const CustomButton = styled(Button)`
  && {
    margin-top: 16px;
    border-radius: 32px;
  }
`;

export const SearchRow = styled.div`
  padding-top: 16px;
`;

export const Header = styled.div`
  padding: 8px 16px 16px;
  display: flex;

  button {
    margin-top: 0 !important;
  }
`;

export const ActionsRow = styled.div`
  button + button {
    margin-left: 16px;
  }
`;

export const AlertsRow = styled.div`
  margin: 8px;
`;
