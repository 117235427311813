import React, { useState, useCallback, useContext } from 'react';
import {
  IconButton,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import {
  SearchOutlined,
  ClearOutlined,
} from '@material-ui/icons';

import { useLocation } from 'react-router-dom';
import { ContactContext } from '../../../hooks/ContactContext';

import {
  CustomListItemText,
  Header,
  InputSearchRow,
  ListItem,
  CustomScrollbar,
} from './styles';
import Message from '../../../models/Message';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { MessageContext } from '../../../hooks/MessageContext';

interface Props {
  searchedNamePhone?: string;
}

const SearchMessage: React.FC<Props> = () => {
  const { changeKeySearch, changeSearchMessage } = useContext(MessageContext);
  const state = useLocation<Props>().state || {};

  const [searchedMessage, setSearchedMessage] = useState<string>(
    state?.searchedNamePhone ?? '',
  );

  const [messages, setMessages] = useState<Message[]
    | undefined>(
      []
    );

  const { selectedContact } = useContext(
    ContactContext,
  );

  const handleChangeSearch = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (searchedMessage.trim().length > 0) {
        const messagesFilter = selectedContact?.messages;
        setMessages(messagesFilter?.filter(
          message => message.text.includes(searchedMessage)
        ));
      }
    },
    [searchedMessage, selectedContact,
      setMessages],
  );

  const handleOnChange = useCallback(
    (e) => {
      setSearchedMessage(e.target.value)
      if (e.target.value.trim().length === 0) {
        setSearchedMessage('');
        setMessages([]);
        changeKeySearch('');
      } 
    },
    [ setMessages, setSearchedMessage, changeKeySearch],
  );

  const handleClearSearch = useCallback(() => {
    setSearchedMessage('');
    setMessages([]);
    changeKeySearch('');
  }, [setSearchedMessage, setMessages, changeKeySearch]);

  return (
    <>
      <Header>
        <Typography
          style={{
            marginTop: "2%",
          }}>
          {<IconButton
            style={{
              padding: 0,
              paddingRight: 8,
            }}
            color="primary"
            size="small"
            onClick={() => {
              handleClearSearch();
              changeSearchMessage(false);
            }}
          >
            <ClearOutlined color="primary" fontSize="small" />
          </IconButton>} Pesquisar mensagens
        </Typography>
        <InputSearchRow>
          <TextField
            id="search-name-phone"
            name="search-name-phone"
            label="Pesquisar..."
            variant="outlined"
            fullWidth
            size="small"
            value={searchedMessage}
            onChange={e => handleOnChange(e)}
            onKeyPress={handleChangeSearch}
            InputProps={{
              startAdornment:
                searchedMessage.length === 0 ? (
                  <InputAdornment position="start">
                    <SearchOutlined color="disabled" fontSize="small" />
                  </InputAdornment>
                ) : (
                    <IconButton
                      style={{
                        padding: 0,
                        paddingRight: 8,
                      }}
                      color="primary"
                      size="small"
                      onClick={handleClearSearch}
                    >
                      <ClearOutlined color="disabled" fontSize="small" />
                    </IconButton>
                  ),
            }}
          />
        </InputSearchRow>
      </Header>
      <CustomScrollbar>
        {
          messages?.length === 0 && (

            <Typography
              style={{
                color: 'grey',
                marginLeft: "10%",
                marginRight: "10%",
                marginTop: "50%", 
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem'
              }}>
              {'Pesquisar mensagens em ' + selectedContact?.name}
            </Typography>
          )
        }
        {messages && (messages.map(message =>
          <ListItem
            key={message.key}
            button
            divider
            style={{ background: '#fff' }}
            onClick={() => {
              changeKeySearch(message?.key);
              setTimeout(() => {
                changeKeySearch('');
              }, 4000);
            }}
          >
            <CustomListItemText
              primary={message.time && (format(
                zonedTimeToUtc(message.time, 'America/Sao_Paulo'),
                'dd/MM/yyyy',
              ))}
              secondary={message.text}
            />
          </ListItem>
        ))}
      </CustomScrollbar>
    </>
  );
};

export default SearchMessage;