import React, { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../services/api';

import { useLoader } from '../../hooks/LoaderContext';

import getValidationErrors from '../../utils/getValidationErrors';
import handleResponseError from '../../utils/handleResponseError';

import Input from '../../components/Input';

import logo from '../../assets/logo1.png';

import {
  Container,
  Header,
  Content,
  CustomButton,
  MailRow,
  CodeRow,
  PasswordRow,
  PasswordConfirmRow,
  ButtonRow,
} from './styles';

interface FormData {
  email: string;
  code: string;
  password: string;
  password_new: string;
}

const PasswordRecovery: React.FC = () => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    password_new: false,
  });
  const [mail, setMail] = useState<string>();

  const formRef = useRef<FormHandles>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          code: Yup.string().required('Código de verificação é obrigatório'),
          password: Yup.string().required('Nova senha é obrigatório'),
          password_new: Yup.string().required(
            'Repetir nova senha é obrigatório',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { code, password, password_new } = data;

        if (password !== password_new) {
          enqueueSnackbar('Senha diferente da confirmação de senha!', {
            variant: 'error',
          });

          return;
        }

        await api.put('passwords-recoveries', {
          email: mail,
          code,
          password,
        });

        enqueueSnackbar('Senha atualizada com sucesso!', {
          variant: 'success',
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, history, mail],
  );

  const handleSendVerificationCode = useCallback(async () => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as FormData;

      const schema = Yup.object().shape({
        email: Yup.string().email().required('Email é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { email } = data;

      await api.post('passwords-recoveries', {
        email,
      });

      setMail(email);

      enqueueSnackbar('Código de verificação enviado para o seu email!', {
        variant: 'success',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading]);

  return (
    <Container>
      <Header>
        <img src={logo} alt="Moove" />
        <Typography variant="h5" color="textSecondary">
          Recuperação de Senha
        </Typography>
      </Header>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {!mail && (
            <>
              <MailRow>
                <Input id="email" name="email" label="Email" type="email" />
              </MailRow>
              <ButtonRow>
                <CustomButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  fullWidth
                  onClick={handleSendVerificationCode}
                >
                  Enviar código
                </CustomButton>
              </ButtonRow>
            </>
          )}
          {mail && (
            <>
              <CodeRow>
                <Input id="code" name="code" label="Código de Verificação" />
              </CodeRow>
              <PasswordRow>
                <Input
                  id="password"
                  name="password"
                  label="Nova Senha"
                  type={showPassword.password ? 'text' : 'password'}
                  endAdornment={{
                    ...(
                      <IconButton
                        aria-label="Alterar visibilidade da senha"
                        onClick={() => {
                          setShowPassword(oldShowPassword => {
                            return {
                              ...oldShowPassword,
                              password: !oldShowPassword.password,
                            };
                          });
                        }}
                        size="small"
                      >
                        {showPassword.password ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </PasswordRow>
              <PasswordConfirmRow>
                <Input
                  id="password_new"
                  name="password_new"
                  label="Repetir Nova Senha"
                  type={showPassword.password_new ? 'text' : 'password'}
                  endAdornment={{
                    ...(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Alterar visibilidade da nova senha"
                          onClick={() => {
                            setShowPassword(oldShowPassword => {
                              return {
                                ...oldShowPassword,
                                password_new: !oldShowPassword.password_new,
                              };
                            });
                          }}
                          size="small"
                        >
                          {showPassword.password_new ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </PasswordConfirmRow>
              <ButtonRow>
                <CustomButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  fullWidth
                >
                  Alterar
                </CustomButton>
              </ButtonRow>
            </>
          )}

          <Typography align="center" color="textSecondary">
            Voltar para a página de
            <Link to="/"> login</Link>
          </Typography>
        </Form>
      </Content>
    </Container>
  );
};

export default PasswordRecovery;
