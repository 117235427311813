import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

  hr {
    margin: 16px 0;
  }

  h6 {
    margin-bottom: 8px;
  }

  .MuiFormControl-fullWidth {
    width: 112px;
  }

  button {
    width: 112px;
    margin-left: 16px;
  }
`;
