import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import io from 'socket.io-client';
import { useSnackbar } from 'notistack';

import apiConfig from '../config/api';

import api from '../services/api';

import { useSession } from './SessionContext';
import { useLoader } from './LoaderContext';

import handleResponseError from '../utils/handleResponseError';

import NegotiationLogbookType from '../models/NegotiationLogbookType';
import Notification from '../models/Notification';

type PaginationHeader = {
  pagination_take: string;
  pagination_skip: string;
  pagination_total_count: string;
  pagination_unread_count: string;
};

type NotificationDetail = {
  total: number;
  unread: number;
  take: number;
  skip: number;
};

type NotificationScheduleDetail = NotificationDetail & {
  scheduledNotifications: Notification[];
};

type NegotiationNotificationDetail = NotificationDetail & {
  notifications: NegotiationNotificationDTO[];
};

export default interface NegotiationNotificationDTO {
  negotiation_notification_id: string;
  negotiation_notification_read: boolean;
  negotiation_notification_created_at: string;
  negotiation_logbook_slug: NegotiationLogbookType;
  negotiation_logbook_user_id: string;
  negotiation_logbook_user_name: string;
  negotiation_id: string;
  negotiation_name: string;
}

interface State {
  detailedNegotiationsNotifications: NegotiationNotificationDetail;
  detailedNotifications: NotificationScheduleDetail;
  countedTotalNotificationsUnread: number;
  handleUpdateNotification: (id: string) => Promise<void>;
  handleUpdateScheduledNotification: (id: string) => Promise<void>;
  loadMoreNotifications: (skip: number) => Promise<void>;
  loadMoreNegotiationsNotifications: (skip: number) => Promise<void>;
}

export const NotificationContext = createContext<State>({} as State);

export const NotificationProvider: React.FC = ({ children }) => {
  const [
    detailedNegotiationsNotifications,
    setDetailedNegotiationsNotifications,
  ] = useState<NegotiationNotificationDetail>({
    take: 0,
    skip: 0,
    total: 0,
    unread: 0,
    notifications: [],
  });
  const [
    detailedNotifications,
    setDetailedNotifications,
  ] = useState<NotificationScheduleDetail>({
    take: 0,
    skip: 0,
    total: 0,
    unread: 0,
    scheduledNotifications: [],
  });

  const { token } = useSession();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const loadMoreNegotiationsNotifications = useCallback(
    async (skip: number) => {
      try {
        setLoading(true);

        const { headers, data } = await api.get<NegotiationNotificationDTO[]>(
          'negotiations-notifications',
          {
            params: {
              skip,
            },
          },
        );

        const {
          pagination_take,
          pagination_skip,
          pagination_total_count,
          pagination_unread_count,
        } = headers as PaginationHeader;

        setDetailedNegotiationsNotifications(
          oldDetailedNegotiationsNotifications => ({
            take: Number(pagination_take),
            skip: Number(pagination_skip),
            total: Number(pagination_total_count),
            unread: Number(pagination_unread_count),
            notifications:
              skip === 0
                ? data
                : [
                    ...oldDetailedNegotiationsNotifications.notifications,
                    ...data,
                  ],
          }),
        );
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  useEffect(() => {
    loadMoreNegotiationsNotifications(0);
  }, [loadMoreNegotiationsNotifications]);

  const loadMoreNotifications = useCallback(
    async (skip: number) => {
      try {
        setLoading(true);

        const { headers, data } = await api.get<Notification[]>(
          'notifications',
          {
            params: {
              skip,
            },
          },
        );

        const {
          pagination_take,
          pagination_skip,
          pagination_total_count,
          pagination_unread_count,
        } = headers as PaginationHeader;

        setDetailedNotifications(oldDetailedNotifications => ({
          take: Number(pagination_take),
          skip: Number(pagination_skip),
          total: Number(pagination_total_count),
          unread: Number(pagination_unread_count),
          scheduledNotifications:
            skip === 0
              ? data
              : [...oldDetailedNotifications.scheduledNotifications, ...data],
        }));
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  useEffect(() => {
    loadMoreNotifications(0);
  }, [loadMoreNotifications]);

  const countedTotalNotificationsUnread = useMemo(
    () =>
      detailedNegotiationsNotifications.unread + detailedNotifications.unread,
    [detailedNegotiationsNotifications.unread, detailedNotifications.unread],
  );

  const handleUpdateNotification = useCallback(
    async (id: string) => {
      try {
        setDetailedNegotiationsNotifications(
          oldDetailedNegotiationsNotifications => ({
            ...oldDetailedNegotiationsNotifications,
            notifications: oldDetailedNegotiationsNotifications.notifications.map(
              oldNotification =>
                oldNotification.negotiation_notification_id === id
                  ? {
                      ...oldNotification,
                      negotiation_notification_read: true,
                    }
                  : oldNotification,
            ),
          }),
        );

        await api.patch(`negotiations-notifications/${id}`);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar],
  );

  const handleUpdateScheduledNotification = useCallback(
    async (id: string) => {
      try {
        setDetailedNotifications(oldDetailedNotifications => ({
          ...oldDetailedNotifications,
          scheduledNotifications: oldDetailedNotifications.scheduledNotifications.map(
            oldScheduledNotification =>
              oldScheduledNotification.id === id
                ? {
                    ...oldScheduledNotification,
                    read: true,
                  }
                : oldScheduledNotification,
          ),
        }));

        await api.patch(`notifications/${id}`);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar],
  );

  const connection = useMemo(
    () =>
      io(apiConfig.API_URL, {
        query: {
          authorization: `Bearer ${token}`,
        },
      }),
    [token],
  );

  useEffect(() => {
    connection.on(
      'notification',
      (negotiationNotificationDTO: NegotiationNotificationDTO) =>
        setDetailedNegotiationsNotifications(
          oldDetailedNegotiationsNotifications => ({
            ...oldDetailedNegotiationsNotifications,
            notifications: [
              negotiationNotificationDTO,
              ...oldDetailedNegotiationsNotifications.notifications,
            ],
            total: oldDetailedNegotiationsNotifications.total + 1,
            unread: oldDetailedNegotiationsNotifications.unread + 1,
            skip: oldDetailedNegotiationsNotifications.skip + 1,
          }),
        ),
    );
  }, [connection]);

  useEffect(() => {
    connection.on('schedule', (notification: Notification) => {
      setDetailedNotifications(oldDetailedNotifications => ({
        ...oldDetailedNotifications,
        scheduledNotifications: [
          notification,
          ...oldDetailedNotifications.scheduledNotifications,
        ],
        total: oldDetailedNotifications.total + 1,
        unread: oldDetailedNotifications.unread + 1,
        skip: oldDetailedNotifications.skip + 1,
      }));

      enqueueSnackbar(notification.description, {
        variant: 'info',
      });
    });
  }, [enqueueSnackbar, connection]);

  return (
    <NotificationContext.Provider
      value={{
        detailedNegotiationsNotifications,
        detailedNotifications,
        countedTotalNotificationsUnread,
        handleUpdateNotification,
        handleUpdateScheduledNotification,
        loadMoreNotifications,
        loadMoreNegotiationsNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): State => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    );
  }

  return context;
};
