import React, { useCallback, useState, useEffect } from 'react';
import { Badge, IconButton, Popover, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Notifications } from '@material-ui/icons';

import { useNotification } from '../../hooks/NotificationContext';

import NotificationNegotiation from './NotificationNegotiation';
import NotificationSchedule from './NotificationSchedule';

const Notification: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>('1');

  const {
    countedTotalNotificationsUnread,
    detailedNegotiationsNotifications,
    detailedNotifications,
  } = useNotification();

  useEffect(() => {
    setSelectedTab('1');
  }, [anchorEl]);

  const handleOpenPopover = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget),
    [],
  );

  return (
    <>
      <IconButton
        aria-describedby="notification_popover"
        onClick={handleOpenPopover}
      >
        <Badge
          badgeContent={countedTotalNotificationsUnread}
          color="primary"
          showZero
        >
          <Notifications />
        </Badge>
      </IconButton>
      <Popover
        key="notification_popover"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <TabContext key="notifications_tabcontext" value={selectedTab}>
          <TabList
            key="notifications_tablist"
            onChange={(_, value) => setSelectedTab(value)}
            variant="fullWidth"
            autoFocus
          >
            <Tab
              key="negotiations_tab"
              label={{
                ...(
                  <Badge
                    badgeContent={detailedNegotiationsNotifications.unread}
                    showZero
                  >
                    Negociações
                  </Badge>
                ),
              }}
              value="1"
            />
            <Tab
              key="schedules_tab"
              label={{
                ...(
                  <Badge badgeContent={detailedNotifications.unread} showZero>
                    Atividades
                  </Badge>
                ),
              }}
              value="2"
            />
          </TabList>
          <TabPanel
            value="1"
            style={{
              padding: 0,
            }}
          >
            <NotificationNegotiation />
          </TabPanel>
          <TabPanel
            value="2"
            style={{
              padding: 0,
            }}
          >
            <NotificationSchedule />
          </TabPanel>
        </TabContext>
      </Popover>
    </>
  );
};

export default Notification;
