import styled from 'styled-components';
import { DialogActions, Typography } from '@material-ui/core';

export const CustomDialogActions = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const SelectionTitle = styled(Typography)`
  && {
    color: rgba(0, 0, 0, 0.38);
  }
`;
