import React from 'react';
import { SnackbarProvider } from 'notistack';

import { LoaderProvider, LoaderContext } from './LoaderContext';
import { SessionProvider } from './SessionContext';

import Loader from '../components/Loader';

const AppProvider: React.FC = ({ children }) => (
  <LoaderProvider>
    <LoaderContext.Consumer>
      {({ loading }) => <Loader loading={loading} />}
    </LoaderContext.Consumer>
    <SessionProvider>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        preventDuplicate
      >
        {children}
      </SnackbarProvider>
    </SessionProvider>
  </LoaderProvider>
);

export default AppProvider;
