import styled from 'styled-components';

import { Grid, InputLabel, Button, FormControlLabel } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 304px;
    margin-bottom: 32px;
  }
`;

export const Title = styled(InputLabel)`
  && {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
`;

export const Content = styled.div`
  a {
    color: #9cba32;

    &:visited {
      color: #9cba32;
    }

    &:hover {
      color: #6fa7ba;
    }
  }
`;

export const CustomGrid = styled(Grid)`
  && {
    margin-bottom: 8px;
  }
`;

export const CustomInputLabel = styled(InputLabel)`
  && {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #000;
  }
`;

export const CustomButton = styled(Button)`
  && {
    margin: 16px 0;
  }
`;

export const SwitchPhoneEmailRow = styled(FormControlLabel)`
  && {
    float: right;
    margin: 0;
  }
`;
