import React, { useState, useEffect, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { ClearOutlined, SearchOutlined } from '@material-ui/icons';

import InputGeneric from '../InputGeneric';

export interface Props {
  name: string;
  label: string;
  visible?: boolean;
  clear?: boolean;
  handleSearch: (searched: string) => void;
}

const InputSearch: React.FC<Props> = ({
  name,
  label,
  visible = true,
  clear = false,
  handleSearch,
}) => {
  const [searched, setSearched] = useState<string>('');

  useEffect(() => {
    if (clear) setSearched('');
  }, [clear]);

  const handleSearchedChange = useCallback(
    (searched: string) => {
      setSearched(searched);

      handleSearch(searched);
    },
    [handleSearch],
  );

  return (
    <div>
      {visible && (
        <InputGeneric
          id={name}
          name={name}
          label={label}
          value={searched}
          onChange={e => handleSearchedChange(e.target.value)}
          startAdornment={
            searched.length === 0 ? (
              <SearchOutlined color="primary" fontSize="small" />
            ) : (
              <IconButton
                style={{
                  padding: 0,
                }}
                onClick={() => handleSearchedChange('')}
              >
                <ClearOutlined color="primary" fontSize="small" />
              </IconButton>
            )
          }
        />
      )}
    </div>
  );
};

export default InputSearch;
