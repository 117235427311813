import api from './api';

import NegotiationLogbook from '../models/NegotiationLogbook';
import NegotiationLogbookType from '../models/NegotiationLogbookType';

const findAllNegotiationLogbooks = async (
  negotiation_id: string,
  funnel_id: string,
) => {
  const { data } = await api.get<NegotiationLogbook[]>(
    `negotiations/${negotiation_id}/logbooks?funnel_id=${funnel_id}`,
  );

  return data;
};

interface CreateNegotiationLogbookDTO {
  negotiation_id: string;
  slug: NegotiationLogbookType;
  description: string;
}

const createNegotiationLogbook = async ({
  negotiation_id,
  slug,
  description,
}: CreateNegotiationLogbookDTO) => {
  const { data } = await api.post<NegotiationLogbook>(
    `negotiations/${negotiation_id}/logbooks`,
    {
      slug,
      description,
    },
  );

  return data;
};

export { findAllNegotiationLogbooks, createNegotiationLogbook };
