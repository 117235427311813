import React, { useContext, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import { useDrop, DragObjectWithType } from 'react-dnd';

import DNDContext from '../context';

import { formatPrice } from '../../../utils/formatPrice';

import { Container, Header } from './styles';

interface ListProps {
  name: string;
  count: number;
  value_sum: number;
  listIdx: number;
}

interface DragItemProps extends DragObjectWithType {
  idx: number;
  listIdx: number;
}

const List: React.FC<ListProps> = ({
  children,
  name,
  count,
  value_sum,
  listIdx,
}) => {
  const { move } = useContext(DNDContext);

  const [{ canDrop, isOver, currentItem }, dropRef] = useDrop({
    accept: 'CARD',
    drop(item: DragItemProps, monitor) {
      const draggedListIndex = item.listIdx;
      const targetListIndex = listIdx;

      const draggedIndex = item.idx;

      move(draggedListIndex, targetListIndex, draggedIndex);

      // eslint-disable-next-line no-param-reassign
      item.listIdx = targetListIndex;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      currentItem: monitor.getItem(),
    }),
  });

  const totalValue = useMemo(() => {
    return formatPrice(value_sum);
  }, [value_sum]);

  return (
    <Container
      ref={dropRef}
      isActive={canDrop && isOver && currentItem.listIdx !== listIdx}
    >
      <Header>
        <div>
          <Typography variant="caption">{`${count} cliente(s)`}</Typography>
          <Typography variant="caption">{totalValue}</Typography>
        </div>
        <Typography variant="h6">{name}</Typography>
        <KeyboardArrowRightOutlined />
      </Header>
      {children}
    </Container>
  );
};

export default List;
