import React, { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import Negotiation from '../../../models/Negotiation';
import Funnel from '../../../models/Funnel';

import ChooseNextFunnel from '../../../pages/Client/Header/ChooseNextFunnel';

import { ContainerLabel, CustomGrid } from '../style';

interface Props {
  handleDrop: () => void;
}

const DragAndDropWinClient: React.FC<Props> = ({ handleDrop }) => {
  const [negotiation, setNegotiation] = useState<Negotiation>();
  const [nextFunnels, setNextFunnels] = useState<Funnel[]>([]);

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const [{ isOver }, dropRef] = useDrop({
    accept: 'CARD',
    async drop(item: any, monitor) {
      setNegotiation(item.negotiation);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      currentItem: monitor.getItem(),
    }),
  });

  const handleWonNegotiationWithoutFunnel = useCallback(async () => {
    try {
      if (negotiation) {
        await api.post(`clients/${negotiation.id}/negotiations`, {
          won: true,
        });

        setNextFunnels([]);

        handleDrop();

        enqueueSnackbar('Negociação concluída com sucesso!', {
          variant: 'success',
        });
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, handleDrop, negotiation]);

  const handleWonNegotiation = useCallback(
    async (funnel_id: string) => {
      try {
        if (negotiation) {
          await api.post(`clients/${negotiation.id}/negotiations`, {
            won: true,
            funnel_id,
          });

          setNextFunnels([]);

          handleDrop();

          enqueueSnackbar('Negociação concluída com sucesso!', {
            variant: 'success',
          });
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [negotiation, enqueueSnackbar, handleDrop],
  );

  useEffect(() => {
    const loadNextFunnels = async () => {
      try {
        if (negotiation) {
          setLoading(true);

          const { data } = await api.get<Funnel[]>(
            `processes/funnels/${negotiation.negotiation_funnel.funnel_id}`,
          );

          if (data.length > 1) {
            setNextFunnels(data);
          } else {
            handleWonNegotiationWithoutFunnel();
          }
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadNextFunnels();
  }, [
    enqueueSnackbar,
    setLoading,
    handleWonNegotiationWithoutFunnel,
    negotiation,
  ]);

  return (
    <>
      {nextFunnels.length > 0 && (
        <ChooseNextFunnel
          funnels={nextFunnels}
          handleWonNegotiation={handleWonNegotiation}
          handleCancel={() => setNextFunnels([])}
        />
      )}
      <CustomGrid
        ref={dropRef}
        style={{
          border: isOver ? 'dashed white 4px' : 'dashed grey 2px',
          backgroundColor: isOver ? 'green' : 'rgba(255,255,255,.8)',
        }}
        item
        xs
      >
        <ContainerLabel
          style={{
            color: isOver ? 'white' : 'green',
          }}
        >
          GANHAR
        </ContainerLabel>
      </CustomGrid>
    </>
  );
};

export default DragAndDropWinClient;
