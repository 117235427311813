import styled from 'styled-components';

import { Tabs, Tab } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  border-radius: 4px;
  padding-bottom: 16px;
  overflow: auto;
`;

export const CustomTabs = styled(Tabs)`
  border-right: 1px solid #ddd;
`;

export const CustomTab = styled(Tab)`
  && {
    display: flex;
    text-align: center;
    align-items: none;
  }
`;
