import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { createTheme, ThemeProvider as ThemeProvider2 } from '@mui/material';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Routes from './routes';
import AppProvider from './hooks';
import GlobalStyle from './styles/global';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#6fa7ba',
        contrastText: '#fff',
      },
      secondary: {
        main: '#9cba32',
        contrastText: '#fff',
      },
      error: {
        main: '#dc004e',
        contrastText: '#fff',
      },
    },
  },
  ptBR,
);

const theme2 = createTheme(
  {
    palette: {
      primary: {
        main: '#6fa7ba',
        contrastText: '#fff',
      },
      secondary: {
        main: '#9cba32',
        contrastText: '#fff',
      },
      error: {
        main: '#dc004e',
        contrastText: '#fff',
      },
    },
  },
  ptBR,
);

const App: React.FC = () => (
  <BrowserRouter>
    <AppProvider>
      <ThemeProvider theme={theme}>
        <ThemeProvider2 theme={theme2}>
          <DndProvider backend={HTML5Backend}>
            <Routes />
          </DndProvider>
        </ThemeProvider2>
      </ThemeProvider>
    </AppProvider>
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
