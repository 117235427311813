import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

export const Container = styled.div`
  flex-direction: column;
  border-color: #ddd;
  border-style: solid;
  border-width: 0px 1px 1px 0;
  height: 100%;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  p {
    color: #6fa7ba;
    margin-top: 16px;
  }
`;

export const CustomScrollbar = styled(Scrollbars)``;
