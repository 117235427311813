import React from 'react';
import { SelectProps, Typography } from '@material-ui/core';

import { CustomSelect, CustomMenuItem } from './styles';

interface Props extends SelectProps {
  values?: {
    id: string;
    name: string;
  }[];
}

const SelectOpacity: React.FC<Props> = ({ children, values, ...rest }) => {
  return (
    <CustomSelect {...rest}>
      {values?.map(value => (
        <CustomMenuItem id={value.id} value={value.id}>
          <Typography variant="subtitle2">{value.name}</Typography>
        </CustomMenuItem>
      ))}
      {children}
    </CustomSelect>
  );
};

export default SelectOpacity;
