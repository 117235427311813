import React, { useMemo, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import formatMobileNumber from '../../utils/formatMobileNumber';

import MailingTarget from '../../models/MailingTarget';

import { CustomDialogActions, CustomDialogTitle, CustomGrid } from './styles';

type Props = {
  open: boolean;
  handleCancel: () => void;
  handleCreateNegotiation: () => void;
  handleRandomMailingTarget: () => void;
  mailingTarget: MailingTarget;
};

const Mailing: React.FC<Props> = ({
  open,
  mailingTarget,
  handleCancel,
  handleCreateNegotiation,
  handleRandomMailingTarget,
}) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const handlePhone = useMemo(
    () => formatMobileNumber(mailingTarget.phone) ?? undefined,
    [mailingTarget.phone],
  );

  useEffect(() => {
    if (handlePhone) setTimeout(() => ref.current?.click(), 500);
  }, [handlePhone]);

  return (
    <Dialog
      key={`dialog-${handlePhone}`}
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {handlePhone && (
        <Link
          key={`custom-link-${handlePhone}`}
          ref={ref}
          to="/funnels"
          title={`Call ${handlePhone} via 3CX`}
          {...{
            tcxhref: handlePhone,
          }}
          style={{
            display: 'none',
          }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {handlePhone}
        </Link>
      )}
      <CustomDialogTitle disableTypography>
        <h2 className="MuiTypography-root MuiTypography-h6">
          Mailing aleatório
        </h2>
        <IconButton onClick={handleCancel}>
          <Close />
        </IconButton>
      </CustomDialogTitle>
      <DialogContent
        dividers
        style={{
          width: 600,
        }}
      >
        <Grid container spacing={3}>
          {mailingTarget?.social_name && (
            <CustomGrid item xs={12}>
              <Typography variant="h6">Nome:</Typography>
              <Typography>{mailingTarget.social_name}</Typography>
            </CustomGrid>
          )}
          {mailingTarget?.employer_name && (
            <CustomGrid item xs={12}>
              <Typography variant="h6">Razão social:</Typography>
              <Typography>{mailingTarget.employer_name}</Typography>
            </CustomGrid>
          )}
          {handlePhone && (
            <CustomGrid item xs={12}>
              <Typography variant="h6">Telefone:</Typography>
              <Typography key={handlePhone}>{handlePhone}</Typography>
            </CustomGrid>
          )}
          {mailingTarget?.email && (
            <CustomGrid item xs={12}>
              <Typography variant="h6">Email:</Typography>
              <Typography>{mailingTarget.email}</Typography>
            </CustomGrid>
          )}
          {mailingTarget?.social_number && (
            <CustomGrid item xs={12}>
              <Typography variant="h6">CPF:</Typography>
              <Typography>{mailingTarget.social_number}</Typography>
            </CustomGrid>
          )}
          {mailingTarget?.employer_number && (
            <CustomGrid item xs={12}>
              <Typography variant="h6">CNPJ:</Typography>
              <Typography>{mailingTarget.employer_number}</Typography>
            </CustomGrid>
          )}
          {(mailingTarget?.address_city || mailingTarget?.address_state) && (
            <CustomGrid item xs={12}>
              <Typography variant="h6">Endereço:</Typography>
              <Typography>
                {mailingTarget?.address_city ?? ''}
                {mailingTarget?.address_city && mailingTarget?.address_state
                  ? '/'
                  : ''}
                {mailingTarget?.address_state ?? ''}
              </Typography>
            </CustomGrid>
          )}
        </Grid>
      </DialogContent>
      <CustomDialogActions>
        <Button color="primary" onClick={() => handleRandomMailingTarget()}>
          Gerar Novo
        </Button>
        <Button onClick={() => handleCreateNegotiation()} color="primary">
          Criar negociação
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

export default Mailing;
