import api from './api';

import Contact from '../models/Contact';
import Negotiation from '../models/Negotiation';
import NegotiationFunnel from '../models/NegotiationFunnel';
import ChatbotContact from '../models/ChatbotContact';

export interface FindAllContactsAdvancedDTO {
  includeMessages?: 0 | 1;
  withoutResponsible?: 0 | 1;
  unreadMessages?: 0 | 1;
  user_id?: string;
  funnel_id?: string;
  step_id?: string;
  product_id?: string;
  channel_id?: string;
}
export interface FindAllContactsDTO extends FindAllContactsAdvancedDTO {
  name?: string;
  phone?: string;
  take: number;
  skip: number;
}

export type ContactDTO = Contact & {
  last_negotiations_funnels: NegotiationFunnel[];
  chatbot: ChatbotContact | null;
};

const findAllContacts = async ({
  includeMessages = 1,
  withoutResponsible = 0,
  unreadMessages = 0,
  take = 20,
  skip = 0,
  ...rest
}: FindAllContactsDTO): Promise<ContactDTO[]> => {
  const { data } = await api.get<ContactDTO[]>(`contacts`, {
    params: {
      includeMessages,
      withoutResponsible,
      unreadMessages,
      take,
      skip,
      ...rest,
    },
  });

  return data;
};

const findNegotiationByContact = async (contact_id: string) => {
  const { data } = await api.get<Negotiation>(
    `contacts/${contact_id}/negotiation`,
  );

  return data;
};

const findContact = async ({
  name,
  phone,
  includeMessages = 1,
}: FindAllContactsDTO) => {
  const { data } = await api.get<ContactDTO[]>(`contacts`, {
    params: {
      name,
      phone,
      includeMessages,
    },
  });

  return data[0];
};

export interface ContactDetailsDTO {
  conversations: number;
  unreaded_conversations: {
    quantity: number;
    phones: string[];
  };
}

const getDetails = async () => {
  const { data } = await api.get<ContactDetailsDTO>('contacts/details');

  return data;
};

export { findAllContacts, findNegotiationByContact, findContact, getDetails };
