import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const CustomGrid = styled(Grid)`
  width: 100vw;
  height: 7vh;
`;

export const ContainerLabel = styled.div`
  margin-left: 40%;
  margin-top: 3%;
  font-weight: bold;
`;
