import mime from 'mime-types';

import StorageType from '../models/StorageType';

import api from '../services/api';

const getBase64 = async (filename?: string, storageType?: StorageType) => {
  try {
    if (!filename || filename.includes('https')) return undefined;

    if (filename.includes('base64')) return filename;

    const { data } = await api.get<string>(`files`, {
      params: {
        filename,
        storageType,
      },
    });

    const contentType = mime.contentType(
      filename.substr(filename.lastIndexOf('.') + 1),
    );

    return `data:${contentType};base64,${data}`;
  } catch {
    return undefined;
  }
};

const showBase64 = async (base64?: string, filename?: string) => {
  if (base64 && filename) {
    const extensionsNewTab = ['pdf', 'png', 'jpg', 'jpeg'];

    if (
      extensionsNewTab.filter(extension =>
        filename.toLowerCase().includes(extension.toLowerCase()),
      ).length === 0
    ) {
      const a = document.createElement('a');
      a.href = base64;
      a.download = filename;
      a.click();
    } else {
      const response = await fetch(base64);
      const blob = await response.blob();

      const url = URL.createObjectURL(blob);

      const tab = window.open(url);

      if (tab) {
        const interval = setInterval(() => {
          if (tab.closed) {
            URL.revokeObjectURL(url);
            clearInterval(interval);
          }
        }, 1000 * 60 * 2);
      }
    }
  }
};

const showFile = async (filename: string, storageType?: StorageType) => {
  const base64 = await getBase64(filename, storageType);

  if (base64) showBase64(base64, filename);
};

const showFileByUrl = (url: string) => {
  window.open(url, '_blank');
};

export { getBase64, showBase64, showFile, showFileByUrl };
