import React, { useState, useEffect, useCallback } from 'react';
import { format, formatDistanceStrict, parseISO } from 'date-fns'; //eslint-disable-line
import { pt } from 'date-fns/locale'; //eslint-disable-line
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { OpenInNewOutlined, DeleteOutline } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import {
  findAllNegotiationFiles,
  deleteNegotiationFile,
} from '../../../services/NegotiationFileService';

import { showFile } from '../../../utils/showFile';
import getAbbreviationByName from '../../../utils/getAbbreviationByName';
import handleResponseError from '../../../utils/handleResponseError';

import NegotiationFile from '../../../models/NegotiationFile';
import NegotiationStatus from '../../../models/NegotiationStatus';
import StorageType from '../../../models/StorageType';

import TabFilesToolbar from './TabFilesToolbar';

import { GroupItems, EmptyContainer } from './styles';

interface TabFilesProps {
  negotiation_id: string;
  funnel_id: string;
  status: NegotiationStatus;
  small?: boolean;
  refresh?: boolean;
}

const TabFiles: React.FC<TabFilesProps> = ({
  negotiation_id,
  funnel_id,
  status,
  small = false,
  refresh = false,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [negotiationFiles, setNegotiationFiles] = useState<NegotiationFile[]>(
    [],
  );

  const { enqueueSnackbar } = useSnackbar();

  const loadNegotiationFiles = useCallback(async () => {
    try {
      setLoading(true);

      if (negotiation_id)
        setNegotiationFiles(
          await findAllNegotiationFiles(negotiation_id, funnel_id),
        );
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, negotiation_id, funnel_id]);

  useEffect(() => {
    loadNegotiationFiles();
  }, [loadNegotiationFiles]);

  useEffect(() => {
    if (refresh) loadNegotiationFiles();
  }, [loadNegotiationFiles, refresh]);

  const handleDeleteNegotiationFile = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        await deleteNegotiationFile(id);

        setNegotiationFiles(oldNegotiationFiles =>
          oldNegotiationFiles.filter(
            oldNegotiationFile => oldNegotiationFile.id !== id,
          ),
        );

        enqueueSnackbar('Arquivo deletado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  const handleShowFile = useCallback(
    async (name: string) => {
      try {
        setLoading(true);

        await showFile(name, StorageType.Negotiation);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading],
  );

  const getFormattedPeriod = useCallback(
    (data: string) =>
      formatDistanceStrict(parseISO(data), new Date(), {
        addSuffix: true,
        locale: pt,
      }),
    [],
  );

  const getFormattedDateTime = useCallback(
    (data: string) => format(parseISO(data), 'dd/MM/yyyy HH:mm:ss'),
    [],
  );

  return (
    <>
      {negotiation_id && status === NegotiationStatus.InProgress && (
        <TabFilesToolbar
          negotiation_id={negotiation_id}
          setNegotiationFiles={setNegotiationFiles}
          small={small}
        />
      )}
      <Paper
        elevation={small ? 0 : 1}
        style={{
          borderRadius: 0,
        }}
      >
        {loading ? (
          <EmptyContainer>
            <CircularProgress />
          </EmptyContainer>
        ) : (
          <>
            {negotiationFiles.length === 0 ? (
              <EmptyContainer>Nenhum registro encontrado.</EmptyContainer>
            ) : (
              <List
                style={{
                  width: '100%',
                }}
              >
                {negotiationFiles.map(negotiationFile => (
                  <React.Fragment key={negotiationFile.id}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>
                          {getAbbreviationByName(negotiationFile.user.name)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={{
                          ...(
                            <GroupItems>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {negotiationFile.user.name}
                              </Typography>
                              <Tooltip
                                title={getFormattedDateTime(
                                  negotiationFile.created_at,
                                )}
                              >
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {getFormattedPeriod(
                                    negotiationFile.created_at,
                                  )}
                                </Typography>
                              </Tooltip>
                              <GroupItems>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    handleShowFile(negotiationFile.saved_name);
                                  }}
                                >
                                  <OpenInNewOutlined fontSize="small" />
                                </IconButton>
                                {status === NegotiationStatus.InProgress && (
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      handleDeleteNegotiationFile(
                                        negotiationFile.id,
                                      );
                                    }}
                                  >
                                    <DeleteOutline
                                      fontSize="small"
                                      color="error"
                                    />
                                  </IconButton>
                                )}
                              </GroupItems>
                            </GroupItems>
                          ),
                        }}
                        secondary={{
                          ...(
                            <Typography variant="subtitle2" color="textPrimary">
                              {negotiationFile.original_name}
                            </Typography>
                          ),
                        }}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                ))}
              </List>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default TabFiles;
