import styled from 'styled-components';

export const Content = styled.div`
  margin: 16px;

  .connect-button {
    background-color: #9cba32;
    color: white;
    outline: none;
    border: none;
    padding: 8px;
    border-radius: 4px;
  }
`;
