import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  useMessageTemplate,
  TemplateMessageDTO,
} from '../../../../hooks/MessageTemplateContext';

import MessageType from '../../../../models/MessageType';

import InputSearch from '../../../../components/InputSearch';
import MessageTemplateStatus from '../../../../components/MessageTemplateStatus';

import MessageTemplateDialog from '../Message/MessageTemplateDialog';

import { Container, Header } from './styles';

interface Props {
  handleSendMessageTemplate: (
    text: string,
    name: string,
    params: string[],
    default_file_id?: string,
    type?: MessageType,
  ) => Promise<void>;
}

const MessageTemplateChoose: React.FC<Props> = ({
  handleSendMessageTemplate,
}) => {
  const [templateMessage, setTemplateMessage] = useState<TemplateMessageDTO>();
  const [openTemplateMessage, setOpenTemplateMessage] = useState<boolean>(
    false,
  );

  const { messageTemplates } = useMessageTemplate();

  const [filteredTemplateMessages, setFilteredTemplateMessages] = useState<
    TemplateMessageDTO[]
  >([]);

  const approvedTemplateMessages = useMemo(
    () => messageTemplates.filter(({ status }) => status === 'approved'),
    [messageTemplates],
  );

  useEffect(() => {
    setFilteredTemplateMessages(approvedTemplateMessages);
  }, [approvedTemplateMessages]);

  const handleSearch = useCallback(
    (searched: string) => {
      if (searched.length === 0) {
        setFilteredTemplateMessages(approvedTemplateMessages);
      } else {
        setFilteredTemplateMessages(
          approvedTemplateMessages.filter(
            messageTemplate =>
              messageTemplate.name
                .toLowerCase()
                .includes(searched.toLowerCase()) ||
              messageTemplate.body
                .toLowerCase()
                .includes(searched.toLowerCase()),
          ),
        );
      }
    },
    [approvedTemplateMessages],
  );

  return (
    <>
      {templateMessage && (
        <MessageTemplateDialog
          messageTemplate={templateMessage}
          handleSendMessageTemplate={handleSendMessageTemplate}
          open={openTemplateMessage}
          setOpen={setOpenTemplateMessage}
        />
      )}
      <Container>
        <Header>
          <span>Selecione uma Mensagem de Template</span>
          <InputSearch
            name="search"
            label="Digite algo aqui..."
            handleSearch={handleSearch}
          />
        </Header>
        <Scrollbars autoHeight>
          <List>
            {filteredTemplateMessages.map(templateMessage => (
              <ListItem
                key={templateMessage.name}
                button
                divider
                disabled={templateMessage.status !== 'approved'}
              >
                <ListItemText
                  primary={templateMessage.name}
                  secondary={templateMessage.body}
                  onClick={() => {
                    setTemplateMessage(templateMessage);
                    setOpenTemplateMessage(true);
                  }}
                />
                <MessageTemplateStatus status={templateMessage.status} />
              </ListItem>
            ))}
          </List>
        </Scrollbars>
      </Container>
    </>
  );
};

export default MessageTemplateChoose;
