import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div {
    width: 100%;
    max-width: 300px;
    margin-right: 8px;
  }

  button {
    margin: 0 6px 48px;
  }

  div + svg {
    margin: 8px 0 8px 50%;
  }

  :not(div + svg):last-of-type {
    button {
      margin: 0 6px 8px;
    }

    div + svg {
      display: none;
    }
  }
`;
