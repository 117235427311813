import React from 'react';
import { ConnectButton } from '360dialog-connect-button';

import { useLinkWhatsapp } from '../../../hooks/LinkWhatsappContext';

import Settings from '..';
import PageTitle from '../../../components/PageTitle';

import { Content } from './styles';

const LinkWhatsapp: React.FC = () => {
  const { partnerId, name, email, handleGenerateApiKey } = useLinkWhatsapp();

  return (
    <Settings route="link-whatsapp">
      <PageTitle pageName="Vincular Whatsapp" />
      <Content>
        <ConnectButton
          label="Criar Conta de Whatsapp"
          className="connect-button"
          partnerId={partnerId}
          callback={({ client, channels, revokedChannels }) => {
            handleGenerateApiKey({
              client_id: client,
              channels,
              revoked_channels: revokedChannels,
            });
          }}
          queryParameters={{
            name,
            email,
          }}
        />
      </Content>
    </Settings>
  );
};

export default LinkWhatsapp;
