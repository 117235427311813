import React, { useRef, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../../../utils/getValidationErrors';

import Funnel from '../../../../models/Funnel';

import SelectForm from '../../../../components/SelectForm';

interface Props {
  funnels: Funnel[];
  handleWonNegotiation: (funnel_id: string) => void;
  handleCancel: () => void;
}

const ChooseNextFunnel: React.FC<Props> = ({
  funnels,
  handleWonNegotiation,
  handleCancel,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          funnel_id: Yup.string().nullable().required('Funil é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { funnel_id } = data;

        handleWonNegotiation(funnel_id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleWonNegotiation],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        Escolha o próximo funil da negociação
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          width: 400,
          padding: 16,
        }}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <SelectForm
            name="funnel_id"
            label="Funil"
            values={funnels.map(funnel => ({
              id: funnel.id,
              name: funnel.name,
            }))}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => formRef.current?.submitForm()} color="primary">
          Ganhar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseNextFunnel;
