import apiWhatsapp from './api_whatsapp';

interface GetBase64DTO {
  file: File | null;
}

const getBase64 = async ({ file }: GetBase64DTO) => {
  if (!file) throw new Error('Nenhum arquivo foi selecionado!');

  if (file.name?.split('.')?.length === 1)
    throw new Error('O nome do arquivo selecionado é inválido!');

  const bytes = file.size / 1000;

  if (bytes > 1000 * 10)
    throw new Error('O tamanho máximo de arquivo permitido é 10MB.');

  const base64: string | undefined = await new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
  });

  if (!base64) throw new Error('Não foi possível converter para base64!');

  return {
    file,
    base64,
  };
};

interface GetFileFromBase64DTO {
  base64: string;
  filename: string;
  mimetype: string;
}

const getFileFromBase64 = async ({
  base64,
  filename,
  mimetype,
}: GetFileFromBase64DTO): Promise<File> => {
  const response = await fetch(base64);
  const blob = await response.blob();

  const file = new File([blob], filename, {
    type: mimetype,
  });

  return file;
};

interface SendFileDTO {
  phone: string;
  file: File;
}

const sendFile = async ({ phone, file }: SendFileDTO) => {
  const bytes = file.size / 1000;

  if (bytes > 1000 * 10)
    throw new Error('O tamanho máximo de arquivo permitido é 10MB.');

  const formData = new FormData();
  formData.append('file', file);

  const { data } = await apiWhatsapp.post<string>('files/new', formData, {
    params: {
      phone,
    },
  });

  return data;
};

type SendAudioDTO = {
  phone: string;
  blob: Blob;
};

type SendAudioResponseDTO = {
  id: string;
  base64: string;
};

const sendPtt = async ({
  phone,
  blob,
}: SendAudioDTO): Promise<SendAudioResponseDTO> => {
  const formData = new FormData();

  formData.append('file', blob, new Date().getTime().toString());

  const { data } = await apiWhatsapp.post<SendAudioResponseDTO>(
    'audios/new',
    formData,
    {
      params: {
        phone,
      },
    },
  );

  return data;
};

interface ReadMessageDTO {
  phone: string;
}

const readMessages = async ({ phone }: ReadMessageDTO) => {
  await apiWhatsapp.post('messages/read', {
    phone,
  });
};

export { getBase64, getFileFromBase64, sendFile, sendPtt, readMessages };
