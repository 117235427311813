import styled from 'styled-components';
import { Box, MenuItem } from '@material-ui/core';

export const Container = styled(Box)<{
  mobile: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background-color: #6fa7ba;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  button {
    margin-top: 0 !important;
  }

  div + div {
    margin-left: ${props => (props.mobile ? '12px' : '8px')};
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: row;

    div + div {
      margin-left: ${props => (props.mobile ? '12px' : '8px')};
    }
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  && {
    color: #6fa7ba;

    h6 {
      padding-left: 8px;
    }
  }
`;
