import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Button,
  FormLabel,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import api from '../../../../services/api';

import handleResponseError from '../../../../utils/handleResponseError';

import { useResponsible, UserDTO } from '../../../../hooks/ResponsibleContext';
import { useSession } from '../../../../hooks/SessionContext';
import { useLoader } from '../../../../hooks/LoaderContext';

import PermissionType from '../../../../models/PermissionType';

interface Props {
  superior: UserDTO;
}

const TeamDetail: React.FC<Props> = ({ superior }) => {
  const [checkedUsersIds, setCheckedUsersIds] = useState<string[]>([]);

  const { users, loadResponsibles } = useResponsible();
  const { user } = useSession();
  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);

        const { data } = await api.get(`superiors/${superior.id}/users`);

        setCheckedUsersIds(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadUsers();
  }, [enqueueSnackbar, setLoading, superior]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      await api.post(`superiors/${superior.id}/users`, {
        users_ids: checkedUsersIds,
      });

      await loadResponsibles();

      enqueueSnackbar('Usuários atualizados com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    setLoading,
    loadResponsibles,
    checkedUsersIds,
    superior,
  ]);

  const handleChangeUser = useCallback((name: string) => {
    setCheckedUsersIds(oldCheckedUsersIds =>
      oldCheckedUsersIds.includes(name)
        ? [...oldCheckedUsersIds.filter(id => id !== name)]
        : [...oldCheckedUsersIds, name],
    );
  }, []);

  const handleCheckAll = useCallback(() => {
    setCheckedUsersIds(oldCheckedUsersIds =>
      oldCheckedUsersIds.length === users.length
        ? []
        : users.map(user => user.id),
    );
  }, [users]);

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormLabel>
          <Typography variant="h6">Usuários</Typography>
        </FormLabel>
        {users.length > 0 && (
          <div>
            <Checkbox
              checked={
                users.filter(user => user.id !== superior.id).length ===
                checkedUsersIds.filter(id => id !== superior.id).length
              }
              disabled={superior.permissions.includes(PermissionType.AdminUser)}
              onChange={handleCheckAll}
            />
            <FormLabel>Selecionar todos</FormLabel>
            <Divider />
          </div>
        )}
        {users.length > 0 && (
          <div>
            {users
              .filter(
                ({ active, id }) =>
                  active && id !== user.id && id !== superior.id,
              )
              .map(user => (
                <div key={user.email}>
                  <Checkbox
                    checked={checkedUsersIds.includes(user.id)}
                    disabled={superior.permissions.includes(
                      PermissionType.AdminUser,
                    )}
                    onChange={e => handleChangeUser(e.target.name)}
                    name={user.id}
                  />
                  <FormLabel>{user.name}</FormLabel>
                </div>
              ))}
          </div>
        )}
      </Grid>
      {!superior.permissions.includes(PermissionType.AdminUser) &&
        users.length > 0 && (
          <Grid item xs={12}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={{ borderRadius: '32px', marginTop: '16px' }}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Grid>
        )}
    </Grid>
  );
};

export default TeamDetail;
