import styled from 'styled-components';

const Content = styled.section`
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;

  span {
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 1;
  }
`;

export { Content };
