import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Divider, Typography, Link } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';

import { getBase64 } from '../../../utils/showFile';
import handleResponseError from '../../../utils/handleResponseError';

import { useLoader } from '../../../hooks/LoaderContext';

import api from '../../../services/api';

import Negotiation from '../../../models/Negotiation';
import StorageType from '../../../models/StorageType';

import { Container, Actions } from './styles';

type Props = {
  id: string;
  description: string;
  created_at: string;
  read: boolean;
  parameter?: string;
  handleMarkingRead: (id: string) => Promise<void>;
};

const NotificationItem: React.FC<Props> = ({
  id,
  description,
  created_at,
  read,
  parameter,
  handleMarkingRead,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  const getFormattedDateTime = useCallback(
    (data: string) => format(parseISO(data), 'dd/MM/yyyy HH:mm'),
    [],
  );

  const navigateToNegotiation = useCallback(async () => {
    try {
      if (parameter) {
        setLoading(true);

        const {
          negotiation_id,
        }: {
          negotiation_id: string;
        } = JSON.parse(parameter);

        const { data } = await api.get<Negotiation>(
          `clients/${negotiation_id}`,
        );

        const photo = await getBase64(data.contact.photo, StorageType.Contact);

        history.replace(`/clients/${negotiation_id}/edit`, {
          negotiation: data,
          photo,
        });
      }
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, history, parameter]);

  return (
    <Container>
      {parameter ? (
        <Link
          component="button"
          color="inherit"
          onClick={navigateToNegotiation}
        >
          <Typography>{description}</Typography>
        </Link>
      ) : (
        <Typography>{description}</Typography>
      )}
      <Actions>
        <Typography variant="caption" color="textSecondary">
          {getFormattedDateTime(created_at)}
        </Typography>
        {!read && (
          <Button onClick={() => handleMarkingRead(id)}>
            <Typography variant="caption" color="secondary">
              Marcar como lido
            </Typography>
          </Button>
        )}
      </Actions>
      <Divider />
    </Container>
  );
};

export default NotificationItem;
