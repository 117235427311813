import React, { createContext, useState, useCallback, useContext } from 'react';

import NegotiationStatus from '../models/NegotiationStatus';

interface State {
  selectedStatus: NegotiationStatus;
  selectStatus(status: NegotiationStatus): void;
}

export const StatusContext = createContext<State>({} as State);

export const StatusProvider: React.FC = ({ children }) => {
  const [selectedStatus, setSelectedStatus] = useState<NegotiationStatus>(
    NegotiationStatus.InProgress,
  );

  const selectStatus = useCallback((status: NegotiationStatus) => {
    setSelectedStatus(status);
  }, []);

  return (
    <StatusContext.Provider
      value={{
        selectedStatus,
        selectStatus,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export const useStatus = (): State => {
  const context = useContext(StatusContext);

  if (!context)
    throw new Error('useStatus must be used within a StatusProvider');

  return context;
};
