import styled from 'styled-components';

const ActionsRow = styled.div`
  .saveButton,
  .cancelButton {
    border-radius: 32px;
  }

  .saveButton {
    margin-right: 8px;
  }
`;

const Container = styled.div`
  margin: 16px 16px 0;
  padding-bottom: 16px;

  .chatbotSelect {
    margin-top: 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  h6 {
    margin-bottom: 16px;
  }

  hr {
    width: 100%;
    margin: 16px 0;
  }
`;

export { ActionsRow, Container, Item, Section };
