import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useSession } from '../../../hooks/SessionContext';
import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import PermissionType from '../../../models/PermissionType';
import AutomaticMessage from '../../../models/AutomaticMessage';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import Settings from '..';
import AddEditAutomaticMessages from './AddEditAutomaticMessages';

import { Header, SearchRow } from './styles';

export interface AutomaticMessageDTO extends AutomaticMessage {
  funnel: {
    id: string;
    name: string;
  };
  from_step?: {
    id: string;
    name: string;
  };
  to_step?: {
    id: string;
    name: string;
  };
  product?: {
    id: string;
    name: string;
  };
}

const AutomaticMessages: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [automaticMessages, setAutomaticMessages] = useState<
    AutomaticMessageDTO[]
  >([]);
  const [filteredAutomaticMessages, setFilteredAutomaticMessages] = useState<
    AutomaticMessageDTO[]
  >([]);

  const { user } = useSession();
  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadAutomaticMessages = async () => {
      try {
        setLoading(true);

        const { data } = await api.get<AutomaticMessageDTO[]>(
          'automatic-messages',
        );

        setAutomaticMessages(data);
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadAutomaticMessages();
  }, [enqueueSnackbar, setLoading]);

  useEffect(() => {
    setIsNew(false);
    setFilteredAutomaticMessages(automaticMessages);
  }, [automaticMessages]);

  const handleCreateAutomaticMessage = () => {
    setIsNew(true);
    setFilteredAutomaticMessages(automaticMessages);
  };

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredAutomaticMessages(
        automaticMessages.filter(automaticMessage => {
          const funnel_name = automaticMessage.funnel.name.toLowerCase();
          const from_step_name = automaticMessage.from_step?.name.toLowerCase();
          const to_step_name = automaticMessage.to_step?.name.toLowerCase();
          let status: string | undefined;

          if (automaticMessage.won !== null)
            status = automaticMessage.won ? 'ganhou' : 'perdeu';

          return (
            searched.length === 0 ||
            funnel_name.includes(searched.toLowerCase()) ||
            from_step_name?.includes(searched.toLowerCase()) ||
            to_step_name?.includes(searched.toLowerCase()) ||
            status?.includes(searched.toLowerCase())
          );
        }),
      );
    },
    [automaticMessages],
  );

  return (
    <Settings route="automatic-messages">
      <PageTitle pageName="Mensagens Automáticas" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      {user.permissions.includes(PermissionType.AdminUser) && (
        <Header>
          {isNew ? (
            <AddEditAutomaticMessages
              handleSave={setAutomaticMessages}
              handleCancel={() => setIsNew(false)}
            />
          ) : (
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                fullWidth
                onClick={handleCreateAutomaticMessage}
              >
                <Add fontSize="small" />
                Mensagem Automática
              </Button>
            </div>
          )}
        </Header>
      )}
      {filteredAutomaticMessages.map(automaticMessage => (
        <ExpansionPanel key={automaticMessage.id}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography variant="body1">Funil</Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                  }}
                >
                  {automaticMessage.funnel.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography variant="body1">Produto</Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                  }}
                >
                  {automaticMessage.product?.name ?? 'Qualquer'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography variant="body1">
                  {automaticMessage.won === null ? 'De Etapa' : 'Etapa'}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                  }}
                >
                  {automaticMessage.from_step?.name ?? 'Qualquer'}
                </Typography>
              </Grid>
              {automaticMessage.won === null && (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Typography variant="body1">Para Etapa</Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                    }}
                  >
                    {automaticMessage.to_step?.name ?? 'Qualquer'}
                  </Typography>
                </Grid>
              )}
              {automaticMessage.won !== null && (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Typography variant="body1">Situação</Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                    }}
                  >
                    {automaticMessage.won ? 'Ganhou' : 'Perdeu'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </ExpansionPanelSummary>
          {user.permissions.includes(PermissionType.AdminUser) && (
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={12}>
                  <AddEditAutomaticMessages
                    automaticMessage={automaticMessage}
                    handleSave={setAutomaticMessages}
                    handleCancel={() => setIsNew(false)}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      ))}
    </Settings>
  );
};

export default AutomaticMessages;
