import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import Channel from '../../../models/Channel';

import Settings from '..';
import AddEditChannel from './AddEditChannel';

import { Header, SearchRow } from './styles';

const Channels: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [filteredChannels, setFilteredChannels] = useState<Channel[]>([]);

  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const loadChannels = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get('channels');

      setChannels(data);
      setFilteredChannels(data);
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading]);

  useEffect(() => {
    loadChannels();
  }, [loadChannels]);

  useEffect(() => {
    if (isNew) setFilteredChannels(channels);
  }, [isNew, channels]);

  const refresh = useCallback(async () => {
    await loadChannels();
  }, [loadChannels]);

  const handleAddProduct = () => {
    setIsNew(true);
  };

  const handleOnSave = () => {
    setIsNew(false);
  };

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredChannels(
        channels.filter(
          channel =>
            searched.length === 0 ||
            channel.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [channels],
  );

  return (
    <Settings route="channels">
      <PageTitle pageName="Canais" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      <Header>
        {!isNew ? (
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<Add fontSize="small" />}
              onClick={handleAddProduct}
            >
              Canal
            </Button>
          </div>
        ) : (
          <AddEditChannel handleOnSave={handleOnSave} refresh={refresh} />
        )}
      </Header>
      {filteredChannels.map(channel => (
        <ExpansionPanel key={String(channel.id)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">{channel.name}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <AddEditChannel channel={channel} refresh={refresh} />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Settings>
  );
};

export default Channels;
