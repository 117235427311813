import React, { useMemo, useState, useRef, useCallback } from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import Input from '../../../../components/Input';
import SelectForm from '../../../../components/SelectForm';

import { Container, MessageRow } from './styles';

interface Props extends DialogProps {
  message: string;
  isImage: boolean;
  isDocument: boolean;
  mappedDefaultFiles: {
    id: string;
    name: string;
  }[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TemplateMessagePreview: React.FC<Props> = ({
  message,
  isImage,
  isDocument,
  mappedDefaultFiles,
  open,
  setOpen,
  ...rest
}) => {
  const [paramsData, setParamsData] = useState<{
    [param: string]: string | undefined;
  }>({});

  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef<FormHandles>(null);

  const params = useMemo((): string[] => {
    const pattern = /{{\d+}}/g;

    const result = message.match(pattern);

    return result ?? [];
  }, [message]);

  const fullMessage = useMemo(() => {
    let handledFullMessage: string = message;

    params.forEach(param => {
      const paramValue = paramsData[param];

      handledFullMessage = handledFullMessage.replace(
        param,
        paramValue && paramValue.length > 0 ? paramValue : param,
      );
    });

    return handledFullMessage;
  }, [message, params, paramsData]);

  const handleSubmit = useCallback(
    async (data: { message: string }) => {
      try {
        formRef.current?.setErrors({});

        const fields = params.reduce(
          (map, param) => ({
            ...map,
            [param]: Yup.string().required(`Parâmetro ${param} é obrigatório'`),
          }),
          {} as {
            [field: string]: Yup.StringSchema<string>;
          },
        );

        if (Object.keys(fields).length > 0) {
          const schema = Yup.object().shape(fields);

          await schema.validate(data, {
            abortEarly: false,
          });
        }

        setOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      }
    },
    [enqueueSnackbar, setOpen, params],
  );

  return (
    <Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        open={open}
        {...rest}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <DialogTitle>Mensagem de Template</DialogTitle>
          <DialogContent dividers>
            <MessageRow>{fullMessage}</MessageRow>
            {(isImage || isDocument) && (
              <div>
                <SelectForm
                  id="default_file_id"
                  name="default_file_id"
                  label={isImage ? 'Imagem' : 'Documento'}
                  values={mappedDefaultFiles}
                  hiddenDefaultOption
                />
              </div>
            )}
            {params.map(param => (
              <div
                style={{
                  marginBottom: 8,
                }}
              >
                <Input
                  id={param}
                  name={param}
                  label={param}
                  onChange={e => {
                    const paramValue = e.target.value;

                    setParamsData(oldParamsData => ({
                      ...oldParamsData,
                      [param]: paramValue,
                    }));
                  }}
                />
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Container>
  );
};

export default TemplateMessagePreview;
