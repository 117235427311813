import React, { useContext, useState } from 'react';

import { MessageContext } from '../../../../hooks/MessageContext';

import Message from '../../../../models/Message';
import MessageType from '../../../../models/MessageType';

import MessageAudio from './MessageAudio';
import MessageImage from './MessageImage';
import MessageDocument from './MessageDocument';
import MessageText from './MessageText';
import MessageVideo from './MessageVideo';
import MessageContact from './MessageContact';

import { Content } from './styles';

type Props = {
  message: Message;
};

const MessageComponent: React.FC<Props> = ({ message }) => {
  const [isImage] = useState<boolean>(
    message.type === MessageType.Image && !message.text?.includes('pdf'),
  );
  const [isVideo] = useState<boolean>(message.type === MessageType.Video);
  const [isDocument] = useState<boolean>(
    message.type === MessageType.Document || message.text?.includes('pdf'),
  );
  const [isPttOrAudio] = useState<boolean>(
    message.type === MessageType.Ptt || message.type === MessageType.Audio,
  );
  const [isContacts] = useState<boolean>(message.type === MessageType.Contacts);

  const { keySearch } = useContext(MessageContext);

  return (
    <Content
      style={{
        animation: keySearch === message.key ? 'blink' : '',
        animationDuration: keySearch === message.key ? '5s' : '',
        animationDelay: keySearch === message.key ? '0s' : '',
      }}
      me={message.from_me}
      isImageOrPdf={isImage}
    >
      {isImage && <MessageImage message={message} />}
      {isVideo && <MessageVideo message={message} />}
      {isDocument && <MessageDocument message={message} />}
      {isPttOrAudio && <MessageAudio message={message} />}
      {isContacts && <MessageContact message={message} />}
      {!isImage && !isVideo && !isDocument && !isPttOrAudio && !isContacts && (
        <MessageText message={message} />
      )}
    </Content>
  );
};

export default MessageComponent;
