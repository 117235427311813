import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Radio,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Add, StarOutlined, ExpandMore } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';

import { useFunnel } from '../../../hooks/FunnelContext';
import { useSession } from '../../../hooks/SessionContext';
import { useLoader } from '../../../hooks/LoaderContext';

import handleResponseError from '../../../utils/handleResponseError';

import PermissionType from '../../../models/PermissionType';
import FunnelModel from '../../../models/Funnel';

import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import InputSearch from '../../../components/InputSearch';

import Settings from '..';
import AddEditFunnel from './AddEditFunnel';

import { Header, SearchRow } from './styles';

const Funnels: React.FC = () => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [filteredFunnels, setFilteredFunnels] = useState<FunnelModel[]>([]);

  const { funnels, loadFunnels } = useFunnel();
  const { user } = useSession();
  const { setLoading } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFilteredFunnels(funnels);
  }, [funnels]);

  useEffect(() => {
    if (isNew) setFilteredFunnels(funnels);
  }, [isNew, funnels]);

  const handleAddFunnel = () => {
    setIsNew(true);
  };

  const handleOnSave = () => {
    setIsNew(false);
  };

  const handleChangeReceptiveFunnel = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        await api.patch(`funnels/${id}/favorites?type=global`);

        enqueueSnackbar('Funil receptivo atualizado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        await loadFunnels();

        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, loadFunnels],
  );

  const handleChangeFavoriteFunnel = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        await api.patch(`funnels/${id}/favorites`);

        enqueueSnackbar('Funil favorito atualizado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        await loadFunnels();

        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, loadFunnels],
  );

  const handleSearch = useCallback(
    (searched: string) => {
      setFilteredFunnels(
        funnels.filter(
          funnel =>
            searched.length === 0 ||
            funnel.name.toLowerCase().includes(searched.toLowerCase()),
        ),
      );
    },
    [funnels],
  );

  return (
    <Settings route="funnels">
      <PageTitle pageName="Funis" />
      <SearchRow>
        <InputSearch
          name="search"
          label="Digite um nome aqui"
          visible={!isNew}
          clear={isNew}
          handleSearch={handleSearch}
        />
      </SearchRow>
      {user.permissions.includes(PermissionType.AdminUser) && (
        <Header>
          {!isNew ? (
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                fullWidth
                onClick={handleAddFunnel}
              >
                <Add fontSize="small" />
                Funil
              </Button>
            </div>
          ) : (
            <AddEditFunnel handleOnSave={handleOnSave} />
          )}
        </Header>
      )}
      {filteredFunnels.map((funnel, index) => (
        <ExpansionPanel key={funnel.id}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Grid
              container
              style={{
                alignItems: 'center',
              }}
            >
              {user.permissions.includes(PermissionType.AdminUser) && (
                <Grid item>
                  <Tooltip
                    title={
                      funnel.order === 1
                        ? 'Receptivo'
                        : 'Definir como receptivo'
                    }
                  >
                    <Radio
                      checked={funnel.order === 1}
                      onChange={() => handleChangeReceptiveFunnel(funnel.id)}
                      onClick={e => e.stopPropagation()}
                    />
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Tooltip
                  title={index === 0 ? 'Favorito' : 'Definir como favorito'}
                >
                  <IconButton
                    edge="start"
                    component="span"
                    size="medium"
                    color={index === 0 ? 'secondary' : 'inherit'}
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      e.stopPropagation();
                      handleChangeFavoriteFunnel(funnel.id);
                    }}
                  >
                    <StarOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="body1">{funnel.name}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          {user.permissions.includes(PermissionType.AdminUser) && (
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={12}>
                  <AddEditFunnel funnel={funnel} />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      ))}
    </Settings>
  );
};

export default Funnels;
