import styled from 'styled-components';

const CaptionRow = styled.div`
  max-width: 232px;
`;

const Video = styled.video`
  max-width: 232px;
  border-radius: 8px;
  margin-bottom: 8px;
`;

export { CaptionRow, Video };
