import styled from 'styled-components';

import { Grid, Button } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  padding: 32px;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const CustomGrid = styled(Grid)``;

export const CustomButton = styled(Button)`
  && {
    margin-top: 16px;
    border-radius: 32px;
  }
`;
