import styled from 'styled-components';
import { ListItem } from '@material-ui/core';

export const Container = styled.section`
  background-color: white;
  border-top: 1px solid #ddd;

  .MuiListItem-root {
    padding: 0 8px;
  }
  .MuiList-padding {
    padding: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  padding: 0 8px;
  color: #6fa7ba;

  div {
    width: 100%;
    max-width: 320px;
    margin-top: 5px;
  }
`;

export const RegistersNotFoundText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`;

export const CustomListItem = styled(ListItem)`
  && {
    padding: 8px;

    .MuiAvatar-colorDefault {
      color: #6fa7ba;
      background-color: #eee;
    }
  }
`;
