import styled from 'styled-components';

export const GroupItens = styled.div`
  display: flex;
  align-items: baseline;

  width: 100%;
  padding: 8px 16px;

  label {
    margin-left: auto;
  }
`;
