interface KeyValueFormData {
  [key: string]: object | null | undefined;
}

interface ValueFormData {
  value: object | null | undefined;
}

interface KeyValueFormDataHandled {
  [key: string]: object | null;
}

const handleValue = ({ value }: ValueFormData) => {
  return !value ||
    value.toString().trim().length === 0 ||
    value.toString().trim() === 'any'
    ? null
    : value;
};

const handleFormData = (data: KeyValueFormData) => {
  let dataHandled: KeyValueFormDataHandled = {};

  Object.keys(data).forEach(key => {
    const value = data[key];

    const isUniqueValue =
      !value ||
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      Object.keys(value).length === 1;

    const valueHandled = isUniqueValue
      ? handleValue({ value })
      : handleFormData({
          ...(value as KeyValueFormData),
        });

    dataHandled = {
      ...dataHandled,
      [key]: valueHandled,
    };
  });

  return dataHandled;
};

export default handleFormData;
