import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const ActionsRow = styled.div`
  button {
    margin-right: 8px;
    border-radius: 32px;
  }
`;

const AllowCategoryChangeRow = styled.div`
  display: flex;
  align-items: flex-end;

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;
  }
`;

export { Container, ActionsRow, AllowCategoryChangeRow };
