import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useField } from '@unform/core';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import InputGeneric, { InputGenericProps } from '../InputGeneric';

type InputPhoneProps = NumberFormatProps & { value?: never } & {
  name: string;
  defaultValue?: string;
  length?: (length: number) => void;
  phone?: (phone?: string) => void;
};

const InputPhone: React.FC<InputPhoneProps> = ({
  name,
  label,
  defaultValue,
  length,
  phone,
  ...rest
}) => {
  const inputRef = useRef<NumberFormatProps>(null);
  const {
    fieldName,
    defaultValue: defaultValueForm,
    registerField,
    error,
  } = useField(name);
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    if (defaultValue) setValue(defaultValue?.substring(2));
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValueForm) setValue(defaultValueForm?.substring(2));
  }, [defaultValueForm]);

  const [mask, setMask] = useState<'(##) ####-####' | '(##) #####-####'>(
    defaultValue && defaultValue.length === 12
      ? '(##) ####-####'
      : '(##) #####-####',
  );

  const maskLength = useMemo<number>(
    () => (mask === '(##) ####-####' ? 12 : 13),
    [mask],
  );

  const isFilled = useCallback(
    (newValue?: string): boolean =>
      (newValue?.length ?? value?.length) === maskLength - 2,
    [value, maskLength],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => (value && value.length > 0 ? `55${value}` : undefined),
    });
  }, [fieldName, value, registerField]);

  const CustomInput = useCallback(
    (props: React.FC<InputGenericProps>) => {
      return <InputGeneric error={error} {...props} />;
    },
    [error],
  );

  useEffect(() => length?.(maskLength), [maskLength, length]);

  const fixedNumberPrefix = useMemo<string[]>(() => ['2', '3', '4', '5'], []);

  const specializedNumberPrefix = useMemo<{
    [prefix: string]: string[];
  }>(() => {
    return {
      '70': [
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '21',
        '22',
        '24',
      ],
      '77': [
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '21',
        '22',
        '24',
        '31',
        '34',
        '37',
      ],
      '78': [
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '21',
        '22',
        '24',
        '27',
        '31',
        '34',
        '37',
        '41',
        '42',
        '43',
        '44',
        '47',
        '48',
        '51',
        '54',
        '61',
        '62',
        '65',
        '71',
        '73',
        '75',
        '81',
        '85',
      ],
      '79': ['11', '12', '13', '14', '15', '16', '17', '18', '19'],
    };
  }, []);

  const isFixedNumber = useCallback(
    ({ prefix }: { prefix: string }) => fixedNumberPrefix.includes(prefix),
    [fixedNumberPrefix],
  );

  const isSpecializedNumber = useCallback(
    ({ ddd, prefix }: { ddd: string; prefix: string }) =>
      specializedNumberPrefix[prefix]?.includes(ddd),
    [specializedNumberPrefix],
  );

  const handleValue = useCallback(
    (
      value: string,
      selectionStart: number,
    ): {
      value: string;
      mask: '(##) ####-####' | '(##) #####-####';
    } => {
      if (value && value.length >= 4) {
        const [ddd, n8, n7, rest] = [
          value.substring(0, 2),
          value.charAt(2),
          value.charAt(3),
          value.substring(4),
        ];

        switch (value.length) {
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
            if (
              isFixedNumber({
                prefix: n8,
              }) ||
              isSpecializedNumber({
                ddd,
                prefix: `${n8}${n7}`,
              })
            )
              return {
                mask: '(##) ####-####',
                value: `${ddd}${n8}${n7}${rest}`,
              };

            return {
              mask: '(##) #####-####',
              value:
                n8 !== '9' && selectionStart >= 3
                  ? `${ddd}9${n8}${n7}${rest}`
                  : value,
            };
          case 11:
            if (
              isFixedNumber({
                prefix: n8,
              }) ||
              isSpecializedNumber({
                ddd,
                prefix: `${n8}${n7}`,
              })
            )
              return {
                mask: '(##) ####-####',
                value: `${ddd}${n8}${n7}${rest.slice(0, -1)}`,
              };

            return {
              mask: '(##) #####-####',
              value: `${ddd}${n8}${n7}${rest}`,
            };
          default:
            return {
              mask: '(##) #####-####',
              value,
            };
        }
      }

      return {
        mask: '(##) #####-####',
        value,
      };
    },
    [isFixedNumber, isSpecializedNumber],
  );

  const handleValueChange = useCallback(
    (value: string, selectionStart: number) => {
      const { value: handledValue, mask } = handleValue(value, selectionStart);

      setValue(handledValue);
      setMask(mask);

      phone?.(isFilled(handledValue) ? `55${handledValue}` : undefined);
    },
    [isFilled, phone, handleValue],
  );

  return (
    <NumberFormat
      label={label}
      value={value}
      inputRef={inputRef}
      customInput={CustomInput}
      onChange={e => {
        handleValueChange(
          e.target.value.replace(/\D/g, ''),
          e.target.selectionStart === null ? 0 : e.target.selectionStart,
        );
      }}
      onKeyPress={e => {
        if (isFilled()) e.preventDefault();
      }}
      format={mask}
      mask="_"
      {...rest}
    />
  );
};

export default InputPhone;
