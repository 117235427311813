import React from 'react';
import { Typography } from '@material-ui/core';

import Message from '../../../../../models/Message';

import MessageName from '../MessageName';
import MessageInfo from '../MessageInfo';

import { Content } from './styles';

interface Props {
  message: Message;
}

const MessageText: React.FC<Props> = ({ message }) => (
  <>
    <MessageName user_id={message.new_user_id} />
    <Content>
      <Typography variant="caption">{message.text}</Typography>
      <MessageInfo
        time={message.time}
        status={message.status}
        from_me={message.from_me}
      />
    </Content>
  </>
);

export default MessageText;
