import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subDays, subMonths, subWeeks } from 'date-fns';
import React, { createContext, useState, useCallback, useContext } from 'react';

import NegotiationDateRange from '../models/NegotiationDateRange';

interface State {
  selectedPeriod: NegotiationDateRange | undefined;
  selectPeriod(period: NegotiationDateRange | undefined): void;
  dateRange: { dateStart?: Date; dateEnd?: Date; } | undefined;
  selectDateRange(period: NegotiationDateRange | undefined): void;
  defineDateRange(dateStart?: Date, dateEnd?: Date | undefined): void;
}

export const PeriodContext = createContext<State>({} as State);

export const PeriodProvider: React.FC = ({ children }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<NegotiationDateRange>(
    NegotiationDateRange.All
  );
  const [dateRange, setDateRange] = useState<{
    dateStart?: Date;
    dateEnd?: Date;
  }>({});

  const selectDateRange = useCallback(period => {
    const date = new Date();
    switch (period) {
      case NegotiationDateRange.Today:
        setDateRange({
          dateStart: startOfDay(date),
          dateEnd: endOfDay(date),
        });
        break;
      case NegotiationDateRange.Yesterday:
        setDateRange({
          dateStart: subDays(startOfDay(date), 1),
          dateEnd: subDays(endOfDay(date), 1),
        });
        break;
      case NegotiationDateRange.ThisWeek:
        setDateRange({
          dateStart: startOfWeek(date),
          dateEnd: endOfWeek(date),
        });
        break;
      case NegotiationDateRange.LastWeek:
        setDateRange({
          dateStart: subWeeks(startOfWeek(date), 1),
          dateEnd: subWeeks(endOfWeek(date), 1),
        });
        break;
      case NegotiationDateRange.ThisMonth:
        setDateRange({
          dateStart: startOfMonth(date),
          dateEnd: endOfMonth(date),
        });
        break;
      case NegotiationDateRange.LastMonth:
        setDateRange({
          dateStart: subMonths(startOfMonth(date), 1),
          dateEnd: subMonths(endOfMonth(date), 1),
        });
        break;
      default:
        setDateRange({});
    }
  }, []);

  const defineDateRange = useCallback((dateStart, dateEnd) => {
    setDateRange({
      dateStart: dateStart,
      dateEnd: dateEnd,
    })
  }, []);

  const selectPeriod = useCallback(period => {
    setSelectedPeriod(period);
  }, []);

  return (
    <PeriodContext.Provider
      value={{
        selectedPeriod,
        selectPeriod,
        dateRange,
        selectDateRange,
        defineDateRange,
      }}
    >
      {children}
    </PeriodContext.Provider>
  );
};

export const usePeriod = (): State => {
  const context = useContext(PeriodContext);

  if (!context) {
    throw new Error('usePeriod must be used within a PeriodProvider');
  }

  return context;
};
