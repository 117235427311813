import React, { useEffect, useCallback, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { CloudUpload, ExpandMore, Refresh } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { format, parseISO } from 'date-fns';

import mailing_modelo from '../../../assets/mailing_modelo.csv';

import handleResponseError from '../../../utils/handleResponseError';

import { useLoader } from '../../../hooks/LoaderContext';

import {
  loadMailings,
  handleMailingUploadFile,
  MailingDTO,
} from '../../../services/MailingService';

import PageTitle from '../../../components/PageTitle';
import Space from '../../../components/Space';

import Settings from '..';
import MailingFunnel from './MailingFunnel';
import MailingUploadConfirmDialog from './MailingUploadConfirmDialog';

import { ActionsRow, LeftActions, LoadMoreButton, MailingsRow } from './styles';

const Mailings: React.FC = () => {
  const TAKE = 15;

  const [mailingDTO, setMailingDTO] = useState<MailingDTO>({
    total_count: 0,
    mailings: [],
  });
  const [pagination, setPagination] = useState<{
    take: number;
    skip: number;
  }>({
    take: TAKE,
    skip: 0,
  });
  const [selectedFile, setSelectedFile] = useState<File>();
  const [openUploadTypeDialog, setOpenUploadTypeDialog] = useState<boolean>(
    false,
  );

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  const handleLoadMailings = useCallback(async () => {
    try {
      setLoading(true);

      const { take, skip } = pagination;

      const { total_count, mailings } = await loadMailings(take, skip);

      setMailingDTO(oldMailingDTO => ({
        total_count,
        mailings: [...oldMailingDTO.mailings, ...mailings],
      }));
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, setLoading, pagination]);

  const handleLoadMoreMailings = useCallback(
    () =>
      setPagination(({ take, skip }) => ({
        take,
        skip: skip + TAKE,
      })),
    [],
  );

  useEffect(() => {
    handleLoadMailings();
  }, [handleLoadMailings]);

  const refresh = useCallback(() => {
    setMailingDTO({
      total_count: 0,
      mailings: [],
    });

    setPagination({
      take: TAKE,
      skip: 0,
    });
  }, []);

  const handleUploadFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files ?? []);

      if (files.length > 0) {
        setSelectedFile(files[0]);
        setOpenUploadTypeDialog(true);
      }

      e.target.value = '';
    },
    [],
  );

  const handleCreatedDateTime = useCallback(
    (data: string) => format(parseISO(data), 'dd/MM/yyyy HH:mm:ss'),
    [],
  );

  const handleUploadConfirm = useCallback(
    async (separator: string) => {
      try {
        setLoading(true);

        if (selectedFile) {
          setOpenUploadTypeDialog(false);

          await handleMailingUploadFile({
            file: selectedFile,
            separator,
          });

          refresh();

          enqueueSnackbar(
            'O upload está sendo processado. Isso pode demorar!',
            {
              variant: 'success',
            },
          );
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setLoading, refresh, selectedFile],
  );

  const handleCheckedFunnelsIds = useCallback(
    (id: string, funnels_ids: string[]) =>
      setMailingDTO(oldMailingDTO => ({
        ...oldMailingDTO,
        mailings: oldMailingDTO.mailings.map(oldMailing => ({
          ...oldMailing,
          funnels_ids:
            oldMailing.id === id ? funnels_ids : oldMailing.funnels_ids,
        })),
      })),
    [],
  );

  return (
    <Settings route="mailings">
      {selectedFile && (
        <MailingUploadConfirmDialog
          open={openUploadTypeDialog}
          handleUploadConfirm={handleUploadConfirm}
          handleUploadCancel={() => setOpenUploadTypeDialog(false)}
          filename={selectedFile.name}
        />
      )}
      <PageTitle pageName="Mailings" />
      <ActionsRow>
        <LeftActions>
          <input
            id="mailing-upload"
            accept="text/csv"
            type="file"
            onChange={handleUploadFile}
            style={{
              display: 'none',
            }}
          />
          <label htmlFor="mailing-upload">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              component="span"
              startIcon={<CloudUpload />}
            >
              Upload
            </Button>
          </label>
          <Space />
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            component="span"
            startIcon={<Refresh />}
            onClick={refresh}
          >
            Refresh
          </Button>
        </LeftActions>
        <Link href={mailing_modelo} color="secondary">
          Baixar arquivo modelo
        </Link>
      </ActionsRow>
      <MailingsRow>
        {mailingDTO.mailings.map(mailing => (
          <Accordion key={mailing.id}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body1">{mailing.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {handleCreatedDateTime(mailing.created_at)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {`Quantidade disponível: ${mailing.quantity}`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {`Quantidade utilizada: ${mailing.used_quantity}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {!mailing.processed && (
                    <Typography variant="body2" color="secondary">
                      Upload em andamento!
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <MailingFunnel
                mailing={mailing}
                handleCheckedFunnelsIds={handleCheckedFunnelsIds}
              />
            </AccordionDetails>
          </Accordion>
        ))}
        {mailingDTO.total_count > mailingDTO.mailings.length && (
          <LoadMoreButton>
            <Button
              variant="contained"
              onClick={handleLoadMoreMailings}
              color="default"
            >
              Carregar mais...
            </Button>
          </LoadMoreButton>
        )}
      </MailingsRow>
    </Settings>
  );
};

export default Mailings;
