import styled from 'styled-components';

export const Container = styled.div<{
  small: boolean;
}>`
  width: 100%;
  height: ${props => (props.small ? 'auto' : 'calc(100% - 65px)')};
  border: 1px solid #ddd;
`;

export const DayText = styled.div<{
  isAnotherMonth: boolean;
  isOldDate: boolean;
}>`
  text-align: right;
  padding: 0 8px;
  cursor: ${props => (props.isOldDate ? 'default' : 'pointer')};

  &:hover {
    background-color: ${props => (props.isOldDate ? '#f8f8f8' : '#f2f8fb')};
  }

  text {
    font-weight: 500;
    color: ${props => (props.isAnotherMonth ? '#ccc' : 'black')};
  }
`;

export const DayWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #ccc;
  grid-gap: 1px;

  title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
  }
`;

export const DayContainer = styled.div<{
  count: number;
  height: string;
}>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: ${props => props.height};
  background-color: #ddd;
  grid-gap: 1px;
`;

export const DayItem = styled.span<{
  isToday: boolean;
  isWithoutSchedules: boolean;
  isOldDate: boolean;
}>`
  background-color: ${props => (props.isToday ? '#f2f8fb' : 'white')};
  min-width: 0;

  &:hover {
    background-color: ${props => {
      if (props.isOldDate) return '#f8f8f8';

      return props.isToday || props.isWithoutSchedules ? '#f2f8fb' : 'white';
    }};
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    height: auto;
  }
`;

export const SmallHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  text {
    font-size: 14px;
    margin-left: 8px;
  }

  button {
    padding: 8px;
    min-width: auto;
  }

  .MuiButton-label {
    font-size: 12px;
  }
`;

export const CreateArea = styled.div<{
  scheduleListHeight: number;
  isOldDate: boolean;
}>`
  height: calc(100% - 16px - ${props => `${props.scheduleListHeight}px`});
  cursor: ${props => (props.isOldDate ? 'default' : 'pointer')};

  &:hover {
    background-color: ${props => (props.isOldDate ? '#f8f8f8' : '#f2f8fb')};
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 16px;
`;
