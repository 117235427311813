import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const CustomTextField = styled(TextField)`
  && {
    div {
      color: #fff;
      background-color: transparent;
      height: 24px;
      padding: 0 8px;
      width: 100%;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.2);
      box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.3);
      transition: all 0.4s ease-in-out;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.4);
      }

      &:before {
        content: none;
      }

      &:after {
        content: none;
      }
    }
    svg {
      color: #fff;

      :first-child {
        width: 16px;
        height: 16px;
      }
    }

    h6 {
      padding-top: 2px;
    }
  }
`;
