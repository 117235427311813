import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';

import handleResponseError from '../../../utils/handleResponseError';

import api from '../../../services/api';

import Negotiation from '../../../models/Negotiation';
import Contact from '../../../models/Contact';
import NegotiationFunnel from '../../../models/NegotiationFunnel';

import HeaderSession from './HeaderSession';
import CalendarSession from './CalendarSession';
import AnnotationSession from './AnnotationSession';
import AttachmentSession from './AttachmentSession';

import { Container, EmptyContainer, CustomScrollbar } from './styles';

type Props = {
  contact: Contact;
  negotiation?: Negotiation;
  negotiationFunnel?: NegotiationFunnel;
  handleSelectedNegotiation: (negotiation?: Negotiation) => void;
  handleSelectedNegotiationFunnel: (
    negotiationFunnel?: NegotiationFunnel,
  ) => void;
  handleChangeNegotiationFunnel: (negotiationFunnel: NegotiationFunnel) => void;
};

type RouteProps = {
  defaultNegotiationId?: string;
};

const NegotiationResume: React.FC<Props> = ({
  contact,
  negotiation,
  negotiationFunnel,
  handleSelectedNegotiation,
  handleSelectedNegotiationFunnel,
  handleChangeNegotiationFunnel,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [negotiations, setNegotiations] = useState<Negotiation[]>([]);

  const { defaultNegotiationId } = useLocation<RouteProps>().state || {};

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadNegotiations = async (): Promise<void> => {
      try {
        setLoading(true);

        const { data } = await api.get<Negotiation[]>(
          `contacts/${contact.id}/negotiations`,
        );

        setNegotiations(data);

        if (data.length === 0) {
          handleSelectedNegotiation(undefined);
        } else {
          const negotiation = defaultNegotiationId
            ? data.find(negotiation => negotiation.id === defaultNegotiationId)
            : undefined;

          handleSelectedNegotiation(negotiation ?? data[0]);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadNegotiations();
  }, [
    enqueueSnackbar,
    setLoading,
    handleSelectedNegotiation,
    contact.id,
    defaultNegotiationId,
  ]);

  return (
    <Container>
      {loading ? (
        <EmptyContainer>
          <CircularProgress />
          <p>Carregando negociação...</p>
        </EmptyContainer>
      ) : (
        <>
          {negotiation && negotiationFunnel ? (
            <CustomScrollbar>
              <HeaderSession
                negotiations={negotiations}
                negotiation={negotiation}
                negotiationFunnel={negotiationFunnel}
                handleSelectedNegotiation={handleSelectedNegotiation}
                handleSelectedNegotiationFunnel={
                  handleSelectedNegotiationFunnel
                }
                handleChangeNegotiationFunnel={handleChangeNegotiationFunnel}
              />
              <AnnotationSession negotiationFunnel={negotiationFunnel} />
              <AttachmentSession negotiationFunnel={negotiationFunnel} />
              <CalendarSession
                negotiationFunnel={negotiationFunnel}
                contact={contact}
              />
            </CustomScrollbar>
          ) : (
            <EmptyContainer>
              <p>Contato sem negociação!</p>
            </EmptyContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default NegotiationResume;
