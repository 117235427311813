import styled from 'styled-components';

import { FormControl } from '@material-ui/core';

const CustomFormControl = styled(FormControl)`
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }
`;

export { CustomFormControl };
