import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
`;

export const Title = styled.div`
  margin: 8px 16px 0;
`;

export const Content = styled.div`
  padding: 8px 16px;
`;
