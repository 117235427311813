import React from 'react';
import { Popover } from '@material-ui/core';

import { Day, ScheduleDTO } from '../types';

import ScheduleList from '../ScheduleList';

type Props = {
  day: Day;
  anchorEl: SVGTextElement | null;
  handleClose: () => void;
  handleCreatedSchedule: (schedule: ScheduleDTO) => void;
  handleUpdatedSchedule: (schedule: ScheduleDTO) => void;
  handleDeletedSchedule: (id: string) => void;
};

const SchedulePopover: React.FC<Props> = ({
  day,
  anchorEl,
  handleClose,
  handleCreatedSchedule,
  handleUpdatedSchedule,
  handleDeletedSchedule,
}) => (
  <Popover
    key="popover"
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <ScheduleList
      day={day}
      handleCreatedSchedule={handleCreatedSchedule}
      handleUpdatedSchedule={handleUpdatedSchedule}
      handleDeletedSchedule={handleDeletedSchedule}
    />
  </Popover>
);

export default SchedulePopover;
