import React, { useRef, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  SelectProps,
  MenuItem,
} from '@material-ui/core';
import { useField } from '@unform/core';

import { MenuItemName } from './styles';

interface Props extends SelectProps {
  name: string;
  label: string;
  values: {
    id: string;
    name: string;
  }[];
  defaultOptionName?: string;
  defaultOptionValue?: string;
  hiddenDefaultOption?: boolean;
}

const SelectForm: React.FC<Props> = ({
  name,
  label,
  values,
  defaultOptionName = 'none',
  defaultOptionValue = '',
  hiddenDefaultOption = false,
  defaultValue,
  value: inititalValue = defaultValue,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<Props>(null);
  const {
    fieldName,
    defaultValue: defaultValueForm,
    registerField,
    error,
  } = useField(name);
  const [value, setValue] = useState<any>(defaultOptionValue);

  useEffect(() => setValue(inititalValue || defaultValueForm), [
    inititalValue,
    defaultValueForm,
  ]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => value,
    });
  }, [registerField, fieldName, value]);

  return (
    <FormControl variant="outlined" size="small" fullWidth error={!!error}>
      <InputLabel>{label}</InputLabel>
      <Select
        inputRef={inputRef}
        id={name}
        name={name}
        label={label}
        style={{
          height: 36,
        }}
        {...rest}
        {...(value &&
        value !== defaultOptionValue &&
        values.map(({ id }) => id).includes(value)
          ? {
              value,
            }
          : {
              value: defaultOptionValue,
            })}
        onChange={(event, child) => {
          setValue(event.target.value);

          onChange?.(event, child);
        }}
      >
        {!hiddenDefaultOption && (
          <MenuItem
            key={defaultOptionValue}
            value={defaultOptionValue}
            hidden
            divider
            style={{
              height: 40,
            }}
          >
            {defaultOptionName === 'none' ? '' : defaultOptionName}
          </MenuItem>
        )}
        {values.map(value => (
          <MenuItem
            key={String(value.id)}
            value={value.id}
            hidden
            style={{
              maxWidth: 600,
            }}
          >
            <MenuItemName>{value.name}</MenuItemName>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default SelectForm;
