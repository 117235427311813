import React from 'react';

import { Horizontal, Vertical } from './styles';

type Props = {
  orientation?: 'horizontal' | 'vertical';
  size?: string;
};

const Space: React.FC<Props> = ({ orientation = 'horizontal', size = '8px' }) =>
  orientation === 'horizontal' ? (
    <Horizontal size={size} />
  ) : (
    <Vertical size={size} />
  );

export default Space;
