import styled from 'styled-components';

const Horizontal = styled.div<{
  size: string;
}>`
  width: ${props => props.size};
  height: 1px;
`;

const Vertical = styled.div<{
  size: string;
}>`
  width: 1px;
  height: ${props => props.size};
`;

export { Horizontal, Vertical };
