import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import getValidationErrors from '../../../../utils/getValidationErrors';
import handleResponseError from '../../../../utils/handleResponseError';

import { useLoader } from '../../../../hooks/LoaderContext';
import { useDefaultFiles } from '../../../../hooks/DefaultFilesContext';

import DefaultFile from '../../../../models/DefaultFile';

import { ButtonIconGreenOutlined } from '../../../../styles/button';
import Input from '../../../../components/Input';

import { Container, GroupItens } from './styles';

interface Props {
  defaultFile?: DefaultFile;
  handleFinish: () => void;
}

interface FormData {
  name: string;
}

const AddEditDefaultFiles: React.FC<Props> = ({
  defaultFile,
  handleFinish,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState<string | null>(null);
  const [extension, setExtension] = useState<string | null>(null);

  const {
    handleCreateDefaultFile,
    handleUpdateDefaultFile,
    handleDeleteDefaultFile,
  } = useDefaultFiles();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    setFilename(defaultFile?.name || null);
    setExtension(defaultFile?.name.split('.').pop() || null);
  }, [defaultFile]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        if (!defaultFile && file === null)
          throw new Error('Arquivo não selecionado!');

        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (file !== null) {
          const bytes = file.size / 1000;

          if (bytes > 1000 * 10)
            throw new Error('O tamanho máximo de arquivo permitido é 10MB.');
        }

        let handledName: string = data.name;

        if (extension !== null && !handledName.includes(extension))
          handledName = `${handledName}.${extension}`;

        if (defaultFile) {
          await handleUpdateDefaultFile({
            id: defaultFile.id,
            name: handledName,
            file,
          });
        } else {
          if (file === null) throw new Error('Arquivo não selecionado!');

          await handleCreateDefaultFile({
            name: handledName,
            file,
          });
        }

        handleFinish();

        enqueueSnackbar(
          `Arquivo padrão ${defaultFile ? 'alterado' : 'salvo'} com sucesso!`,
          {
            variant: 'success',
          },
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      setLoading,
      handleFinish,
      handleCreateDefaultFile,
      handleUpdateDefaultFile,
      defaultFile,
      file,
      extension,
    ],
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      if (defaultFile)
        await handleDeleteDefaultFile({
          id: defaultFile.id,
        });

      handleFinish();

      enqueueSnackbar('Arquivo padrão excluído com sucesso!', {
        variant: 'success',
      });
    } catch (err) {
      const message = handleResponseError(err);

      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    setLoading,
    handleFinish,
    handleDeleteDefaultFile,
    defaultFile,
  ]);

  const handleSelectedFile = useCallback((files: FileList | null) => {
    const file: File | null = Array.from(files || [])?.[0];

    setFile(file);
    setFilename(file?.name);
    setExtension(file?.name.split('.').pop() || null);
  }, []);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <GroupItens>
          <Input
            id="name"
            name="name"
            label="Nome"
            value={filename || ''}
            onChange={e => setFilename(e.target.value)}
            fullWidth
            disabled={file === null && !defaultFile}
          />
          <input
            style={{
              display: 'none',
            }}
            id={`upload_file_${defaultFile?.id || '0'}`}
            type="file"
            onChange={e => handleSelectedFile(e.target.files)}
          />
          <label htmlFor={`upload_file_${defaultFile?.id || '0'}`}>
            <ButtonIconGreenOutlined
              startIcon={<CloudUpload />}
              component="span"
              type="button"
              style={{
                width: 36,
                height: 36,
              }}
            />
          </label>
        </GroupItens>
        <Button
          color="primary"
          size="small"
          variant="contained"
          style={{ borderRadius: '32px', marginRight: '6px' }}
          type="submit"
        >
          {defaultFile ? 'Alterar' : 'Salvar'}
        </Button>
        {defaultFile ? (
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            style={{ borderRadius: '32px' }}
            type="button"
            onClick={() => handleDelete()}
          >
            Excluir
          </Button>
        ) : (
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            style={{ borderRadius: '32px' }}
            type="button"
            onClick={handleFinish}
          >
            Cancelar
          </Button>
        )}
      </Form>
    </Container>
  );
};

export default AddEditDefaultFiles;
