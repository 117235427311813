import React from 'react';
import { Typography } from '@material-ui/core';

import { showBase64 } from '../../../../../utils/showFile';

import Message from '../../../../../models/Message';

import MessageName from '../MessageName';
import MessageInfo from '../MessageInfo';

import { CaptionRow, Image } from './styles';

type Props = {
  message: Message;
};

const MessageImage: React.FC<Props> = ({ message }) => {
  return (
    <>
      <MessageName user_id={message.new_user_id} />
      <div>
        {message.base64 ? (
          <Image
            alt="Img"
            src={message.base64}
            onClick={() => showBase64(message.base64, message.text)}
          />
        ) : (
          <>
            {message.base64_status === 'error' ? (
              <Typography variant="caption">
                Não foi possível carregar imagem.
              </Typography>
            ) : (
              <Typography variant="caption">Carregando imagem...</Typography>
            )}
          </>
        )}
      </div>
      {message.caption && (
        <CaptionRow>
          <Typography variant="caption">{message.caption}</Typography>
        </CaptionRow>
      )}
      <MessageInfo
        time={message.time}
        status={message.status}
        from_me={message.from_me}
      />
    </>
  );
};

export default MessageImage;
