import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';

import api from '../services/api';

import Funnel from '../models/Funnel';
import Product from '../models/Product';
import Channel from '../models/Channel';

interface State {
  funnels: Funnel[];
  selectedFunnel?: Funnel;
  selectedProduct?: Product;
  selectedChannel?: Channel;
  selectFunnel(id?: string): void;
  selectProduct(id?: string): void;
  selectChannel(id?: string): void;
  loadFunnels: () => Promise<void>;
}

export const FunnelContext = createContext<State>({} as State);

export const FunnelProvider: React.FC = ({ children }) => {
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [selectedFunnel, setSelectedFunnel] = useState<Funnel>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedChannel, setSelectedChannel] = useState<Channel>();

  const loadFunnels = useCallback(async () => {
    const { data } = await api.get<Funnel[]>('funnels');

    setFunnels(data);

    if (data.length !== 0) {
      setSelectedFunnel(data[0]);
    }
  }, []);

  useEffect(() => {
    loadFunnels();
  }, [loadFunnels]);

  const selectFunnel = useCallback(
    (id?: string) => {
      const funnel = funnels?.filter(funnel => funnel.id === id)?.[0];

      setSelectedFunnel(funnel);

      setSelectedProduct(undefined);

      setSelectedChannel(undefined);
    },
    [funnels],
  );

  const selectProduct = useCallback(
    (id?: string) => {
      const products = funnels.reduce(
        (products, funnel) => [...products, ...funnel.products],
        [] as Product[],
      );

      const product = products.filter(product => product.id === id)?.[0];

      setSelectedProduct(product);
    },
    [funnels],
  );

  const selectChannel = useCallback(
    (id?: string) => {
      const channels = funnels.reduce(
        (channels, funnel) => [...channels, ...funnel.channels],
        [] as Channel[],
      );

      const channel = channels.filter(channel => channel.id === id)?.[0];

      setSelectedChannel(channel);
    },
    [funnels],
  );

  return (
    <FunnelContext.Provider
      value={{
        funnels,
        selectedFunnel,
        selectedProduct,
        selectedChannel,
        selectFunnel,
        selectProduct,
        selectChannel,
        loadFunnels,
      }}
    >
      {children}
    </FunnelContext.Provider>
  );
};

export const useFunnel = (): State => {
  const context = useContext(FunnelContext);

  if (!context) {
    throw new Error('useFunnel must be used within a FunnelProvider');
  }

  return context;
};
