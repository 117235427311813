import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 16px;
  flex-direction: row;
  display: flex;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
`;

export const CustomGrid = styled(Grid)``;
