import React, { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Switch, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useLoader } from '../../hooks/LoaderContext';
import { useSession } from '../../hooks/SessionContext';

import getValidationErrors from '../../utils/getValidationErrors';
import handleResponseError from '../../utils/handleResponseError';

import Input from '../../components/Input';
import InputPhone from '../../components/InputPhone';

import {
  Container,
  Header,
  Content,
  CustomGrid,
  CustomButton,
  SwitchPhoneEmailRow,
} from './styles';

import logo from '../../assets/logo1.png';

interface FormData {
  phone?: string;
  email?: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [emailOption, setEmailOption] = useState<boolean>(false);
  const [minPhone, setMinPhone] = useState<number>(0);

  const { setLoading } = useLoader();

  const formRef = useRef<FormHandles>(null);
  const { signIn } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          phone: emailOption
            ? Yup.string().notRequired()
            : Yup.string()
                .nullable()
                .min(minPhone, 'Preencha todos os dígitos')
                .required('Celular é obrigatório'),
          email: emailOption
            ? Yup.string()
                .email('E-mail inválido')
                .required('E-mail é obrigatório')
            : Yup.string().notRequired(),
          password: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { phone, email, password } = data;

        await signIn({
          phone: emailOption ? undefined : phone,
          email: emailOption ? email : undefined,
          password,
        });

        enqueueSnackbar('Login efetuado com sucesso!', {
          variant: 'success',
        });

        history.push('/funnels');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          const message = handleResponseError(err);

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, enqueueSnackbar, signIn, history, emailOption, minPhone],
  );

  const handleSingInMethod = useCallback(() => {
    setEmailOption(oldEmailOption => !oldEmailOption);
  }, []);

  return (
    <Container>
      <Header>
        <img src={logo} alt="Moove" />
      </Header>
      <Content>
        <SwitchPhoneEmailRow
          control={{
            ...(
              <Switch
                name="switch-method"
                checked={emailOption}
                onChange={handleSingInMethod}
                color="secondary"
              />
            ),
          }}
          label="E-mail"
        />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <CustomGrid item>
            {emailOption ? (
              <Input id="email" name="email" label="E-mail" />
            ) : (
              <InputPhone
                id="phone"
                name="phone"
                label="Celular"
                length={length => setMinPhone(length)}
              />
            )}
          </CustomGrid>
          <CustomGrid item>
            <Input
              id="password"
              name="password"
              label="Senha"
              type="password"
            />
          </CustomGrid>
          <Typography align="right">
            <Link to="/password-recovery">Esqueci minha senha</Link>
          </Typography>
          <CustomGrid item>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              <Typography>ENTRAR</Typography>
            </CustomButton>
            <Typography align="center" color="textSecondary">
              Não tem uma conta? Cadastre-se
              <Link to="/register"> aqui</Link>
            </Typography>
          </CustomGrid>
        </Form>
      </Content>
    </Container>
  );
};

export default SignIn;
