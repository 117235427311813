import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

import LossReason from '../../../../models/LossReason';

import SelectForm from '../../../../components/SelectForm';

interface Props {
  chooseLossReason: boolean;
  funnelId: string;
  setChooseLossReason: React.Dispatch<React.SetStateAction<boolean>>;
  handleLossNegotiation: (loss_reason_id: string) => void;
}

const ChooseLossReason: React.FC<Props> = ({
  chooseLossReason,
  funnelId,
  setChooseLossReason,
  handleLossNegotiation,
}) => {
  const [lossReasons, setLossReasons] = useState<LossReason[]>([]);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    const loadLossReasons = async () => {
      const { data } = await api.get<LossReason[]>('losses-reasons');

      setLossReasons(
        data.filter(lossReason =>
          lossReason.funnels.map(funnel => funnel.id).includes(funnelId),
        ),
      );
    };

    loadLossReasons();
  }, [funnelId]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          loss_reason_id: Yup.string()
            .nullable()
            .required('Motivo da perda é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { loss_reason_id } = data;

        handleLossNegotiation(loss_reason_id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleLossNegotiation],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={chooseLossReason}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Informe o motivo</DialogTitle>
      <DialogContent
        dividers
        style={{
          width: 400,
          padding: 16,
        }}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <SelectForm
            name="loss_reason_id"
            label="Motivo"
            values={lossReasons.map(reason => {
              return {
                id: reason.id,
                name: reason.name,
              };
            })}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => setChooseLossReason(false)}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={() => formRef.current?.submitForm()} color="primary">
          Perder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseLossReason;
