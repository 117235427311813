import React from 'react';
import { Typography, ButtonProps } from '@material-ui/core';

import { CustomButton } from './styles';

interface Props extends ButtonProps {
  component?: string;
  iconSize?: string;
}

const Button: React.FC<Props> = ({ children, iconSize = '16px', ...rest }) => {
  return (
    <CustomButton iconSize={iconSize} {...rest}>
      <Typography variant="subtitle2">{children}</Typography>
    </CustomButton>
  );
};

export default Button;
