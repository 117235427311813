import React from 'react';
import { Typography } from '@material-ui/core';

import Message from '../../../../../models/Message';

import MessageName from '../MessageName';
import MessageInfo from '../MessageInfo';

import { CaptionRow, Video } from './styles';

type Props = {
  message: Message;
};

const MessageVideo: React.FC<Props> = ({ message }) => {
  return (
    <>
      <MessageName user_id={message.new_user_id} />
      <div>
        {message.base64 ? (
          <Video src={message.base64} controls />
        ) : (
          <>
            {message.base64_status === 'error' ? (
              <Typography variant="caption">
                Não foi possível carregar vídeo.
              </Typography>
            ) : (
              <Typography variant="caption">Carregando vídeo...</Typography>
            )}
          </>
        )}
      </div>
      {message.caption && (
        <CaptionRow>
          <Typography variant="caption">{message.caption}</Typography>
        </CaptionRow>
      )}
      <MessageInfo
        time={message.time}
        status={message.status}
        from_me={message.from_me}
      />
    </>
  );
};

export default MessageVideo;
