import styled from 'styled-components';
import { Select, MenuItem } from '@material-ui/core';

export const CustomSelect = styled(Select)`
  && {
    height: 24px;
    border-radius: 4px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.3);
    transition: all 0.4s ease-in-out;

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.3);
      box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.4);
    }

    &:before {
      content: none;
    }

    &:after {
      content: none;
    }

    div {
      background-color: transparent;
    }

    svg {
      color: #fff;

      :first-child {
        width: 16px;
        height: 16px;
        margin: 0 6px;
      }
    }

    h6 {
      padding-top: 2px;
    }
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  && {
    color: #6fa7ba;

    h6 {
      padding-left: 8px;
    }
  }
`;
