import React, {
  useCallback,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  addDays,
  addMonths,
  endOfDay,
  format,
  getDate,
  getDay,
  getMonth,
  isAfter,
  isBefore,
  isToday,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import {
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import handleResponseError from '../../utils/handleResponseError';

import { useLoader } from '../../hooks/LoaderContext';
import { useWindowDimensions } from '../../hooks/WindowDimensionsContext';

import api from '../../services/api';

import FilterHeader from '../../components/FilterHeader';
import ButtonOpacity from '../../components/ButtonOpacity';

import FilterDTO from '../../dtos/FilterDTO';

import ScheduleList from './ScheduleList';
import SchedulePopover from './SchedulePopover';
import ScheduleDialog from './ScheduleDialog';

import {
  Container,
  CreateArea,
  DayContainer,
  DayItem,
  DayWeek,
  DayText,
  SmallHeader,
  EmptyContainer,
} from './styles';

import { Day, ScheduleDTO } from './types';

type Props = {
  negotiation_id?: string;
  contact_id?: string;
  contact_name?: string;
  disabledContact?: boolean;
  disabledNegotiation?: boolean;
  small?: boolean;
  height?: string;
};

const SchedulePage: React.FC<Props> = ({
  negotiation_id,
  contact_id,
  contact_name,
  disabledContact = false,
  disabledNegotiation = false,
  small = false,
  height = '100%',
}) => {
  const [count, setCount] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<SVGTextElement | null>(null);
  const [schedules, setSchedules] = useState<ScheduleDTO[]>([]);
  const [days, setDays] = useState<Day[]>([]);
  const [selectedDay, setSelectedDay] = useState<Day>();
  const [openScheduleDialog, setOpenScheduleDialog] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [chosedDate, setChosedDate] = useState<Date>();
  const [filters, setFilters] = useState<FilterDTO>({});
  const [filterDate, setFilterDate] = useState<{
    startDate?: Date;
    endDate?: Date;
  }>({});
  const [smallLoading, setSmallLoading] = useState<boolean>(small);

  const ref = useRef<HTMLDivElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const { windowDimensions } = useWindowDimensions();

  useEffect(() => {
    const loadSchedules = async (): Promise<void> => {
      try {
        setLoading(!small);
        setSmallLoading(small);

        const { startDate, endDate } = filterDate;

        if (startDate && endDate) {
          const { userId, funnelId, channelId, productId, hierarchy } = filters;

          const { data } = await api.get<ScheduleDTO[]>('schedules', {
            params: {
              userId,
              funnelId,
              channelId,
              productId,
              hierarchy,
              startDate,
              endDate,
            },
          });

          setSchedules(data);
        }
      } catch (err) {
        const message = handleResponseError(err);

        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setLoading(false);
        setSmallLoading(false);
      }
    };

    loadSchedules();
  }, [enqueueSnackbar, setLoading, small, filters, filterDate]);

  useEffect(
    (): void =>
      setDays(oldDays =>
        oldDays.map(oldDay => ({
          ...oldDay,
          schedules: schedules.filter(
            schedule =>
              format(parseISO(schedule.date), 'yyyy-MM-dd') ===
              format(oldDay.date, 'yyyy-MM-dd'),
          ),
        })),
      ),
    [schedules],
  );

  const handleResize = useCallback(() => {
    if (ref.current !== null) {
      const height = ref.current.offsetHeight;
      const count = Math.floor((height / 6 - 38) / 26);

      setCount(count);
    }
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', () => handleResize());

    return () => window.removeEventListener('resize', () => handleResize());
  }, [handleResize]);

  const handleFilters = useCallback(
    (filters: FilterDTO) => setFilters(filters),
    [],
  );

  useEffect((): void => {
    let startDate = startOfMonth(date);

    while (getDay(startDate) !== 0) startDate = subDays(startDate, 1);

    const endDate = addDays(startDate, 41);

    const days: Day[] = [];

    let currentDate: Date = startDate;

    while (!isAfter(currentDate, endDate)) {
      days.push({
        day: getDate(currentDate),
        date: currentDate,
        isAnotherMonth: getMonth(date) !== getMonth(currentDate),
        isOldDate: isBefore(startOfDay(currentDate), startOfDay(new Date())),
        schedules: [],
        contentRef: createRef(),
      });

      currentDate = addDays(currentDate, 1);
    }

    setDays(days);
    setFilterDate({
      startDate: startOfDay(startDate),
      endDate: endOfDay(endDate),
    });
  }, [date]);

  const handleOpenPopover = useCallback(
    (day: Day, e: React.MouseEvent<SVGTextElement>) => {
      setSelectedDay(day);
      setAnchorEl(e.currentTarget);
    },
    [],
  );

  const handleCreatedSchedule = useCallback((schedule: ScheduleDTO) => {
    setSchedules(oldSchedules => [...oldSchedules, schedule]);
    setOpenScheduleDialog(false);
  }, []);

  const handleUpdatedSchedule = useCallback(
    (schedule: ScheduleDTO) =>
      setSchedules(oldSchedules =>
        oldSchedules.map(oldSchedule =>
          oldSchedule.id === schedule.id ? schedule : oldSchedule,
        ),
      ),
    [],
  );

  const handleDeletedSchedule = useCallback(
    (id: string) =>
      setSchedules(oldSchedules =>
        oldSchedules.filter(oldSchedule => oldSchedule.id !== id),
      ),
    [],
  );

  const formattedDate = useMemo(() => format(date, 'MM/yyyy'), [date]);

  return (
    <>
      <ScheduleDialog
        open={openScheduleDialog}
        negotiation_id={negotiation_id}
        contact_id={contact_id}
        contact_name={contact_name}
        chosed_date={chosedDate}
        disabledContact={disabledContact}
        disabledNegotiation={disabledNegotiation}
        handleCreatedSchedule={handleCreatedSchedule}
        handleUpdatedSchedule={handleUpdatedSchedule}
        handleDeletedSchedule={handleDeletedSchedule}
        handleCancel={() => setOpenScheduleDialog(false)}
      />
      {smallLoading ? (
        <EmptyContainer>
          <CircularProgress />
        </EmptyContainer>
      ) : (
        <>
          {small ? (
            <SmallHeader>
              <Typography component="text">{formattedDate}</Typography>
              <div>
                <Button
                  onClick={() => setDate(subMonths(date, 1))}
                  startIcon={<KeyboardArrowLeft />}
                />
                <Button
                  onClick={() => setDate(addMonths(date, 1))}
                  startIcon={<KeyboardArrowRight />}
                />
                <Button onClick={() => setDate(new Date())}>hoje</Button>
              </div>
            </SmallHeader>
          ) : (
            <FilterHeader
              handleChartFilters={handleFilters}
              hiddenDateFilter
              middleActions={{
                ...(<span style={{ color: 'white' }}>{formattedDate}</span>),
              }}
              leftActions={{
                ...(
                  <>
                    <ButtonOpacity
                      onClick={() => setDate(subMonths(date, 1))}
                      startIcon={<KeyboardArrowLeft />}
                    />
                    <span
                      style={{
                        width:
                          windowDimensions.resolution === 'mobile' ? 12 : 8,
                      }}
                    />
                    <ButtonOpacity
                      onClick={() => setDate(addMonths(date, 1))}
                      endIcon={<KeyboardArrowRight />}
                    />
                    {windowDimensions.resolution !== 'mobile' && (
                      <>
                        <span
                          style={{
                            width: 8,
                          }}
                        />
                        <ButtonOpacity onClick={() => setDate(new Date())}>
                          hoje
                        </ButtonOpacity>
                      </>
                    )}
                  </>
                ),
              }}
            />
          )}
          <Container small={small}>
            <DayWeek>
              <Typography component="title">dom.</Typography>
              <Typography component="title">seg.</Typography>
              <Typography component="title">ter.</Typography>
              <Typography component="title">qua.</Typography>
              <Typography component="title">qui.</Typography>
              <Typography component="title">sex.</Typography>
              <Typography component="title">sáb.</Typography>
            </DayWeek>
            <DayContainer ref={ref} height={height} count={count}>
              {days.map(day => (
                <DayItem
                  isToday={isToday(day.date)}
                  isWithoutSchedules={day.schedules.length === 0}
                  isOldDate={day.isOldDate}
                >
                  <Tooltip
                    title={
                      day.isOldDate
                        ? 'Não é possível fazer um agendamento para uma data passada'
                        : 'Clique para criar um novo agendamento'
                    }
                    placement="bottom"
                  >
                    <DayText
                      isAnotherMonth={day.isAnotherMonth}
                      isOldDate={day.isOldDate}
                      onClick={() => {
                        if (!day.isOldDate) {
                          setChosedDate(day.date);
                          setOpenScheduleDialog(true);
                        }
                      }}
                    >
                      <text>{day.day}</text>
                    </DayText>
                  </Tooltip>
                  <div ref={day.contentRef}>
                    {day.schedules.length > 0 && (
                      <ScheduleList
                        day={day}
                        show={count}
                        hiddenTitle
                        handleOpenPopover={handleOpenPopover}
                        handleCreatedSchedule={handleCreatedSchedule}
                        handleUpdatedSchedule={handleUpdatedSchedule}
                        handleDeletedSchedule={handleDeletedSchedule}
                      />
                    )}
                  </div>
                  {day.contentRef.current && (
                    <Tooltip
                      title={
                        day.isOldDate
                          ? 'Não é possível fazer um agendamento para uma data passada'
                          : 'Clique para criar um novo agendamento'
                      }
                      placement="top"
                    >
                      <CreateArea
                        scheduleListHeight={day.contentRef.current.clientHeight}
                        isOldDate={day.isOldDate}
                        onClick={() => {
                          if (!day.isOldDate) {
                            setChosedDate(day.date);
                            setOpenScheduleDialog(true);
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                </DayItem>
              ))}
            </DayContainer>
            {selectedDay && (
              <SchedulePopover
                day={selectedDay}
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                handleCreatedSchedule={handleCreatedSchedule}
                handleUpdatedSchedule={handleUpdatedSchedule}
                handleDeletedSchedule={handleDeletedSchedule}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default SchedulePage;
