import styled from 'styled-components';

const Container = styled.div``;

const MessageRow = styled.div`
  width: fit-content;
  padding: 8px;
  border-radius: 8px;
  background: #40adbd;
  color: #fff;
  border: 1px solid #ddd;
  white-space: pre-wrap;
  margin-bottom: 16px;
`;

export { Container, MessageRow };
