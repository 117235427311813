import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  List,
  ListItem as Teste,
  AppBar,
  ListItemText,
} from '@material-ui/core';

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 0 8px;
`;

export const InputSearchRow = styled.div`
  margin-top: 16px;
`;

export const MoreOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NewMessage = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
  background: #40adbd;
`;

export const CustomList = styled(List)`
  && {
    padding: 0;
    height: 100%;
    overflow: auto;
  }
`;

export const ContainerGlobal = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-color: #eee;
  border-style: solid;
  border-width: 0 1px 0 1px;
`;

export const ListItem = styled(Teste)`
  && {
    margin: 0;
  }
`;

export const CustomAppBar = styled(AppBar)`
  && {
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    background: #fff;
    height: 64px;
  }
`;

export const CustomScrollbar = styled(Scrollbars)`
  max-height: calc(100% - 105px);
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomListItemText = styled(ListItemText)`
  p {
    font-size: 0.675rem;
  }
  span {
    font-size: 0.675rem;
    float: right;
  }
`;
