import styled from 'styled-components';

import ButtonOpacity from '../components/ButtonOpacity';

export const ButtonGreen = styled(ButtonOpacity)`
  && {
    background-color: rgba(156, 186, 50, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(156, 186, 50, 0.9);
    margin-left: 8px;

    &:hover {
      background-color: rgba(156, 186, 50, 0.9);
      box-shadow: inset 0 0 8px 0 rgba(156, 186, 50, 1);
    }
  }
`;

export const ButtonRed = styled(ButtonOpacity)`
  && {
    background-color: rgba(220, 0, 78, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(220, 0, 78, 0.9);

    &:hover {
      background-color: rgba(220, 0, 78, 0.9);
      box-shadow: inset 0 0 8px 0 rgba(220, 0, 78, 1);
    }
  }
`;

export const ButtonGreenOutlined = styled(ButtonOpacity)`
  && {
    color: rgba(156, 186, 50, 0.9);
    border: 1px solid rgba(156, 186, 50, 0.9);
    height: 35.5px;

    &:hover {
      color: rgba(156, 186, 50, 1);
      border: 1px solid rgba(156, 186, 50, 1);
    }

    svg {
      margin-left: 8px;
    }

    h6 {
      margin-right: 4px;
    }
  }
`;

export const ButtonRedOutlined = styled(ButtonOpacity)`
  && {
    color: rgba(220, 0, 78, 0.9);
    border: 1px solid rgba(220, 0, 78, 0.9);
    margin-left: 8px;

    &:hover {
      color: rgba(220, 0, 78, 1);
      border: 1px solid rgba(220, 0, 78, 1);
    }
  }
`;

export const ButtonIcon = styled(ButtonOpacity)`
  && {
    background-color: rgba(153, 153, 153, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(153, 153, 153, 0.9);
    width: 22px;
    min-width: 22px;
    padding-left: 20px;
    margin-left: 8px;

    &:hover {
      background-color: rgba(153, 153, 153, 0.9);
      box-shadow: inset 0 0 8px 0 rgba(153, 153, 153, 1);
    }
  }
`;

export const ButtonIconOutlined = styled(ButtonOpacity)`
  && {
    color: rgba(220, 0, 78, 0.9);
    border: 1px solid rgba(220, 0, 78, 0.9);
    width: 22px;
    min-width: 22px;
    padding-left: 20px;
    margin-left: 8px;

    &:hover {
      color: rgba(220, 0, 78, 1);
      border: 1px solid rgba(220, 0, 78, 1);
    }
  }
`;

export const ButtonIconGreenOutlined = styled(ButtonOpacity)`
  && {
    color: rgba(156, 186, 50, 0.9);
    border: 1px solid rgba(156, 186, 50, 0.9);
    width: 22px;
    min-width: 22px;
    padding-left: 20px;
    margin-left: 8px;

    &:hover {
      color: rgba(156, 186, 50, 1);
      border: 1px solid rgba(156, 186, 50, 1);
    }
  }
`;
