import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReatRouteProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useSession } from '../hooks/SessionContext';

import Layout from '../components/Layout';
import LayoutAuth from '../components/Layout/Auth';

const publicRoutes = ['/', '/register', '/password-recovery'];

interface RouteProps extends ReatRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { pathname } = useLocation();
  const { user } = useSession();

  const signed = !!user;

  if (!signed && !publicRoutes.includes(pathname)) return <Redirect to="/" />;

  if (signed && publicRoutes.includes(pathname))
    return <Redirect to="/funnels" />;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return isPrivate ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <LayoutAuth>
            <Component />
          </LayoutAuth>
        );
      }}
    />
  );
};

export default Route;
